import { useState, useEffect } from "react";
import { importAll, joinArray } from "../../Helpers/utility";
import { makeAPICall, REQUEST } from "../../services/apiService";

import { useDispatch, useSelector } from 'react-redux';
import { actionCreators } from "../../redux/actions/actionCreators";
import { mapUtils } from "../../services/mapService";
import renderHTML from 'react-render-html';

/* global $ */
export default function SearchArea({ setSearchBox, searchBox, setSideBar,setInitialize, langId, projectID }) {

  const {themeList, articleFilter,pro} = useSelector((state) => state.landingPage);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [articles, setArticles] = useState([]);
  const lang = langId ? langId : "22";

  // useEffect(() => {
  //   if (searchBox === false) {
  //     $("#search-box-toggle").css({ display: "block" });
  //     $("#search-box").css({ display: "none" });
  //   }
  // }, [searchBox]);

  mapUtils.addMarkers([])

  const images = importAll(
    require.context("../../assets/img", false, /\.(png|jpe?g|svg)$/)
  );

  const showSearchBox = () => {
    $("#search-box-toggle").css({ display: "none" });
    $("#search-box").css({ display: "block" });
    setInitialize(4);

    // refresh text box and list data
    setArticles([]);
    setSearchText('');

    // set side bar open the search box
    setSearchBox(true);
    if(themeList.length > 0){
      setSideBar(false);
     
    }

     // remove marker if selected all themes
     if(mapUtils.markers.length > 0){
      let sideToggle = document.querySelector("#expand-bar");
      sideToggle.classList.add("shSidemenuCol");
      document.querySelector("#expand").classList.add("active");
      document.querySelector("#colapse").classList.remove("active");
      mapUtils.removeMarker(mapUtils.markers);
     }

    removeMaker();

    // set enter search value 
    var input = document.getElementById("search-box");
    input.addEventListener("keyup", function(e) {
    if (e.keyCode === 13) {e.preventDefault();document.getElementById("searchBtn").click();}
    });

  };

  $("#map").click(function(){ 
    $("#search-box").hide();
    $("#search-box-toggle").show();

    // setArticles([]);
    // setSearchText('');

});
$("#expand-bar").click(function(){ 
    $("#search-box").hide();
    $("#search-box-toggle").show();
});
$(".shHeader").click(function(){ 
    $("#search-box").hide();
    $("#search-box-toggle").show();
});
$(".shSubHeader").click(function(){ 
    $("#search-box").hide();
    $("#search-box-toggle").show();
});


const removeMaker=() =>{
  dispatch(actionCreators.landingPage.deselectAllTheme());
  let subListItems = document.querySelectorAll("a.sub-theme i.sub-theme-item");
  for (let j = 0; j < subListItems.length; j++) {
    if (subListItems[j].classList.contains("selected")) {
      subListItems[j].classList.remove("show");
    }
  }
  setSelectedFilter([]);
}


  const suggestArticle = (e,data, type) => {
    // if (searchText?.length > 2) {
      let reqBody = {
        type: REQUEST.GET,
        url: "entity/searchEntity",
        params: {
          keyword: type === 'int' ? e : data,
          type: "All",
          lang: lang,
          sta: "publish",
          project: projectID,
        },
      };
      makeAPICall(reqBody)
        .then((result) => {
          console.log(result);
          if (result) {
            let data = result.data.data;
            if(mapUtils.markers.length >0){
              mapUtils.removeMarker(mapUtils.markers)
            }
            let marker = [];
            for (let i = 0; i < data.length; i++) {
              if(data[i].lat_lng !==  undefined && i < 10){
                var coord = data[i].lat_lng.split(",")
                var obj ={
                  'id': data[i].id,
                  'meta_latitude':parseFloat(coord[0]),
                  'meta_longitude':parseFloat(coord[1]),
                  'title': data[i].title,
                }
                marker.push(obj);
              }
          }  

            if (data.length > 10) {
              data.length = 10;
            }
            mapUtils.addMarkers(marker)
            setArticles(data);
          }
        })
        .catch((err) => console.log(err));
    // } else {
    //   setArticles([]);
    // }
  };

// listConsole() = {
//   let obj = mapUtils.this.markers
//   removeMaker()
//   mam

// }


  return (
    <div className="shMapSearchSec">
      <a
        href="#"
        id="search-box-toggle"
        className="shMapSearchTrig"
        onClick={() => showSearchBox()}
      >
        <img src={images["ic_search@2x.png"].default} alt="" />
      </a>
      <div className="shMapSearchBox" id="search-box">
        <div className="shSearchInputItem">
          <input
            type="text"
            placeholder="Search"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
          <button
            className="btn btn-search" id="searchBtn"
            onClick={() => suggestArticle(searchText,searchText,'text')}
          >
            <img src={images["ic_search@2x.png"].default} alt="" />
          </button>
        </div>
        {articles?.length > 2 && (
          <div className="shMapSearchRes">
            <ul className="shMapSearchResList">
              {articles.map((article) => (
                <li key={article.id}>
                  <a onClick={() => suggestArticle(article.id,article.id,'int')}>
                    <h2>{article.title}</h2>
                    <label>{joinArray(article.subdomain_name, ", ")}</label>
                    <p>{renderHTML(article.summary)}</p>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

const ArticleList = ({ articles }) => {
  return (
    <div className="shMapSearchRes">
      <ul className="shMapSearchResList">
        {articles.map((article) => (
          <li key={article.id}>
            <a href={article.url}>
              <h2>{article.title}</h2>
              <label>{joinArray(article.subdomain_name, ",")}</label>
              <p>{renderHTML(article.summary)}</p>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
