import Loader from 'react-loader-spinner';

export default function LoaderScreen() {
    return (
        <div align="center"><Loader
            type="ThreeDots"
            color="#da3a50"
            height={100}
            width={100}
            timeout={3000} //3 secs
            align="center"
        />
        </div>
    );
}