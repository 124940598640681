import React, { Component } from 'react';
import './Drop.css';

export default class Drop extends Component {
    constructor(props) {
        super(props)
        this.state={
          visible:false
        }
    }
    openModal() {
      this.setState({
          visible: true
      });
  }
    closeModal() {
      this.setState({
          visible: false
      });
  }
    render(){
        return(
              <>
               <div class="only-desktop">
              <div class="boxes">
                <div class="row" style={{display:'-webkit-flex',color : '#fff'}}>
                    <div class="column1">
                         <div style={{whiteSpace:'nowrap'}}>Sahapedia is a not-for-profit and free for everyone.</div>
                         To help us keep it free,consider supporting us.<br/><br/>
                         It only takes a minute.<br/>
                    </div>
                    <div class="column2">
                      <a href=" https://www.sahapedia.org/donate" > 
                      <button class="button button1">Donate now</button>
                      </a>
                    </div>
                </div>
              </div>
              </div>
              <div class="only-mobile">
              <div class="boxes">
                <div class="row" style={{color : '#fff'}}>
                  <div class="entire">                   
                  Sahapedia is a not-for-profit and free for everyone.To help us keep it free,consider supporting us.It only takes a minute.<br/>                           
                  </div> 
                <a href=" https://www.sahapedia.org/donate" > 
                <button class="button button1">Donate now</button> 
                </a> 
              </div>
              </div> 
              </div>
              </>
        );
    }

}