import axios from 'axios';

let url = window.location.host;
// let testing_url = "http://65.0.167.59/sahapedia2.0/backend/";

if(url =="map.sahapedia.org"){
    testing_url = "https://map.sahapedia.org/admin/";
}else if(url == "65.0.167.59"){
    testing_url = "http://65.0.167.59/sahapedia2.0/backend/";

}else if(url =="beta.piana.in"){
    var testing_url = "http://beta.piana.in/sahapedia2.0/backend/";
}else if(url =="testmap.sahapedia.org"){
    var testing_url = "http://testmap.sahapedia.org/admin/";
}else{
    var testing_url = "https://map.sahapedia.org/admin/";
}

export const BASE_API_URL = testing_url;
 //export const BASE_API_URL = 'https://map.sahapedia.org/admin/';
// export const BASE_API_URL = 'http://localhost/sahapedia2.0/backend/';
//export const BASE_API_URL = 'http://beta.piana.in/sahapedia2.0/backend/';

export const getProjectDirectory = () => {

}

export const REQUEST = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE'
}

export const makeAPICall = async (requestParms) => {
    let res = await axios({
        method : requestParms.type,
        url:  requestParms.url ? BASE_API_URL + requestParms.url : BASE_API_URL,
        headers: requestParms.headers ? requestParms.headers : '',
        data: requestParms.data ? requestParms.data : '',
        params: requestParms.params ? requestParms.params : ''
    });
    return res;
}

