import { useEffect, useState } from 'react';

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({height: undefined, width: undefined});

    useEffect(()=>{
        const handleWindowResize = () => {
            setWindowSize({
                height: window.innerHeight,
                width: window.innerWidth
            });
        }

        window.addEventListener("resize", handleWindowResize);

        handleWindowResize();

        return() => window.removeEventListener("resize", handleWindowResize);
    },[]);

    return windowSize;
}


export const useSlideChange = (initialState) => {
    const [current, setCurrent] = useState(null);

    const changeCurrent = state => {
            setCurrent(state);
    }

    useEffect(()=>{

        changeCurrent(initialState);

        return() => changeCurrent(null)
    },[current, initialState])

    return [current, changeCurrent];
}