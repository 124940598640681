import React, { Component } from "react";
import Modal from "react-awesome-modal";
import { HiOutlinePlusCircle } from "react-icons/hi";
import Loader from "react-loader-spinner";
import { BASE_API_URL, makeAPICall, REQUEST } from "../../services/apiService";
import Header from "../header2";
import Signin from "../signin2";
import Content from "./myTrails";
import Heading from "./myTrailsSearchParams";
import  { Redirect } from 'react-router-dom'


export default class Trails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      entityType: [],
      project: [],
    };
  }
  getEntity() {
    fetch(`${BASE_API_URL}master/getProjectList`)
      .then((res) => res.json())
      .then((json) => {
        if (json) {
          this.setState({
            entityType: json,
          });
        } else {
          this.setState({
            entityType: [],
          });
        }
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.location.search !== prevProps.location.search)
    this.loadData();
  }

  componentDidMount() {
  this.loadData();
  }
   loadData() {
    this.getEntity();
    this.projectDetail();
    var user = navigator.cookieEnabled && localStorage.getItem("User");
    const query = new URLSearchParams(this.props.location.search);
    var qryStr = query.get("type");
    if (qryStr == undefined) {
      qryStr = "";
    }
    var type = qryStr;
    var keyword = this.props.searchqry;
    fetch(`${BASE_API_URL}entity/getEntityDetail`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded", // <-- Specifying the Content-Type
      }),
      body: JSON.stringify({
        entityType: "story",
        project_id: type,
        published: "true",
        status:'enabled'
      }),
    })
      .then((res) => res.json())
      .then((json) => {
       
        if (json) {
          this.setState({
            isLoaded: true,
            items: json,
            noData: false,
          });
        } else {
          this.setState({
            isLoaded: true,
            items: [],
            noData: true,
          });
        }
      });
    //   $(window).on("scroll", function () {
    //     if ($(window).scrollTop() > 100) {
    //         $(".shHeader ").addClass("smHeader");
    //     } else {
    //remove the background property so it comes transparent again (defined in your css)
    //       $(".shHeader ").removeClass("smHeader");
    //        $(".shHeader ").removeClass("card-icon-img");
    //   }
    // });
  }
  openModal() {
    this.setState({
      visible: true,
    });
  }

  closeModal() {
    this.setState({
      visible: false,
    });
  }
  projectDetail() {
    const query = new URLSearchParams(window.location.search);
    var project = query.get("type");
    let reqParam = {
      type: REQUEST.POST,
      url: "Entity/getProjects",
      data: JSON.stringify({
        id: project ? project : "1",
      }),
    };
    makeAPICall(reqParam).then((result) => {
      if (result) {
        let res = result.data;
        this.setState({
          project: res["primary_projects"],
        });
      }
      console.log(this.state.project);
    });
  }
  render() {
    const size = window.innerWidth;
    const query = new URLSearchParams(this.props.location.search);
    var qryStr = query.get("type");
    if (qryStr == undefined) {
      qryStr = "";
    }
    var type = qryStr;
    var { isLoaded, entityType, items } = this.state;

    if (!isLoaded) {
      return (
        <div align="center">
          <Loader
            type="ThreeDots"
            color="#da3a50"
            height={100}
            width={100}
            //   timeout={10000} //3 secs
            align="center"
          />
        </div>
      );
    } else {
      return (
        <div>
          {items.length === 0 && <Redirect to='/search/trails'  />}
          <Header></Header>
          <Heading Data={items} Data2={entityType} Entity={type}></Heading>
          <div class="row" style={{padding:'2%',width:'100%'}}>
            <div class="col-md-5"></div>
            {navigator.cookieEnabled && localStorage.getItem("User") ? (
              <div class="col-md-1 create1">
                <a href={localStorage.getItem("User") ? "/createTrail" : "#"}>
                  <HiOutlinePlusCircle
                    style={{ fontSize: "34px", color: "#6b6b6b" }}
                  />
                </a>
              </div>
            ) : (
              <div class="col-md-1 create1">
                <a onClick={() => this.openModal()}>
                  <HiOutlinePlusCircle
                    style={{ fontSize: "34px", color: "#6b6b6b" }}
                  />
                </a>
              </div>
            )}
            {navigator.cookieEnabled && localStorage.getItem("User") ? (
              <div
                class="col-md-2 create2"
                style={{
                  margin: "7px 0px 1px 0px",
                  padding: "0px",
                  color: "#6b6b6b",
                  fontWeight: "600",
                  fontFamily: "sans-serif",
                }}
              >
                <a href={localStorage.getItem("User") ? "/createTrail" : "#"}>
                  Create New Trail
                </a>
              </div>
            ) : (
              <div
                class="col-md-2 create2"
                style={{
                  margin: "7px 0px 1px 0px",
                  padding: "0px",
                  color: "#6b6b6b",
                  fontWeight: "600",
                  fontFamily: "sans-serif",
                }}
              >
                <a onClick={() => this.openModal()}>Create New Trail</a>
              </div>
            )}
            <div class="col-md-4"></div>
          </div>
          <Content Data={items} Entity={type}></Content>
          <Modal
            visible={this.state.visible}
            width="800"
            height="550"
            effect="fadeInUp"
            onClickAway={() => this.closeModal()}
          >
            <div class="articlesign" style={{ backgroundColor: "#e2e2e2" }}>
              <div class="row" style={{ marginLeft: "0px" }}>
                <div class="col-md-6" style={{ backgroundColor: "#fff" }}></div>
                <div
                  class="col-md-6"
                  align="right"
                  style={{
                    color: "grey",
                    padding: "10px 30px",
                    fontSize: "20px",
                  }}
                >
                  <a
                    href="javascript:void(0);"
                    style={{ color: "grey" }}
                    onClick={() => this.closeModal()}
                  >
                    X
                  </a>
                </div>
              </div>
              <Signin
                bookID=""
                bookType=""
                rel=""
                unbook=""
                created=""
                close={() => this.closeModal()}
              ></Signin>
            </div>
          </Modal>
        </div>
      );
    }
  }
}
