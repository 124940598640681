import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { importAll } from '../../Helpers/utility';
import { BASE_API_URL, makeAPICall, REQUEST } from '../../services/apiService';
import LoaderScreen from '../common/loader';
import Bannerfull from './bannerfull';




/* global $ */
export default function TrialDetailFullScreen({ Article = null }) {
    const [entityDetail, setEntityDetail] = useState(null);
    const [entityProjects, setEntityProjects] = useState([]);
    const [entityRelation, setEntityRelation] = useState({ "curated": null, "automated": null });
    const [slider, setSlider] = useState(null);
    const isLoadedCount = useRef(0);
    const pageType = useRef('story');
    const { articleId } = useParams();
    const { articleName } = useParams();
    const article = useRef(null);

    const images = importAll(require.context('../../assets/img', false, /\.(png|jpe?g|svg)$/));

    useEffect(() => {
        let unique_article_id = articleId;
        if (unique_article_id == undefined) {
            unique_article_id = Article.article.id;
            pageType.current = 'theme';
        }

        article.current = unique_article_id;
        if (unique_article_id !== undefined) {
            getEntityDetails(unique_article_id);
            getEntityProjects(unique_article_id);
            getEntityRelations_curated(unique_article_id);
        }

        $(window).on("scroll", function () {
            if ($(window).scrollTop() > 100) {
                $(".shHeader ").addClass("smHeader");
            } else {
                //remove the background property so it comes transparent again (defined in your css)
                $(".shHeader ").removeClass("smHeader");
                $(".shHeader ").removeClass("card-icon-img");
            }
        });

    }, []);


    const getEntityDetails = (article_id) => {
        let reqParam = {
            type: REQUEST.POST,
            url: 'Entity/getEntityDetail',
            data: JSON.stringify({
                entityType: 'story',
                id: article_id
            })
        }
        makeAPICall(reqParam)
            .then((result) => {
                if (result) {
                    let res = result.data[0];
                    setEntityDetail(res);

                    let img = [];
                    res.embedded_ImageGallery?.forEach((carouselImgList) => {
                        carouselImgList.images.forEach(image => {
                            img.push({ "description": image.description, "image": BASE_API_URL + image.file_path });
                        })
                    })
                    res.standalone_images?.forEach((standaloneImg) => {
                        img.push({
                            "description": standaloneImg.description ? standaloneImg.description : "",
                            "image": BASE_API_URL + standaloneImg.file_path
                        });
                    })
                    console.log("from article", img)
                    setSlider(img)


                }
                isLoadedCount.current++;
            })
            .catch(err => { console.log(err); isLoadedCount.current++ });
    }

    const getEntityRelations_curated = (article_id) => {
        let reqParam = {
            type: REQUEST.POST,
            url: 'Entity/getEntityRelations',
            data: JSON.stringify({
                relation: "curated_recommendation",
                entity_id: article_id,
                backend:''
            })
        }
        makeAPICall(reqParam)
            .then((result) => {
                if (result) {
                    let res = result.data;
                    setEntityRelation({ ...entityRelation, "curated": res });
                }
                isLoadedCount.current++;
            })
            .catch(err => { console.log(err); isLoadedCount.current++ });
    }

    const getEntityProjects = (article_id) => {
        let reqParam = {
            type: REQUEST.POST,
            url: 'Entity/getEntityProjects',
            data: JSON.stringify({
                id: article_id
            })
        }
        makeAPICall(reqParam)
            .then((result) => {
                if (result) {
                    let res = result.data;
                    setEntityProjects(res['primary_projects'].concat(res['secondary_projects']));
                }
                isLoadedCount.current++;
            })
            .catch(err => { console.log(err); isLoadedCount.current++ });
    }


    if (isLoadedCount.current < 2) {
        return <LoaderScreen />
    }

    return (
        <div className="wrapper">
            <section class="mainPlaceholder articlePlaceMain">
                {entityDetail && <Bannerfull bannerDetail={entityDetail} articleName={articleName} articleID={article.current} type={pageType.current} />}

            </section>
        </div>
    );
}
