import $ from 'jquery';
import React, { Component } from 'react';
import { makeAPICall, REQUEST } from '../../services/apiService';
import Footer from '../footer';
import Header from '../header2';
import './blog.css';
import Content from './blogContent';

$(window).on("scroll", function () {
    if ($(window).scrollTop() > 100) {
        $(".shHeader ").addClass("smHeader");
    } else {
        //remove the background property so it comes transparent again (defined in your css)
        $(".shHeader ").removeClass("smHeader");
        $(".shHeader ").removeClass("card-icon-img");
    }
});
export default class Blog extends Component {
    constructor(props) {
		super(props);
		this.state={
      project:[],
        }
    }
    componentDidMount(){
        const query = new URLSearchParams(window.location.search);
        var project = query.get("project");
        let reqParam = {
            type: REQUEST.POST,
            url: 'Entity/getProjects',
            data: JSON.stringify({
                id: project
            })
        }
        makeAPICall(reqParam)
            .then((result) => {
                if (result) {
                    let res = result.data;
                this.setState({
                    project:res['primary_projects']
                })
                }
                console.log(this.state.project);
            })
    }
    render() { 
        return ( 
            <div class="blog-page-bl">
                <Header projectDirectory={this.state.project}></Header>
                <header class="blogHeader"style={{marginTop:'-20px'}} ></header>
                <Content></Content>
                <Footer></Footer>
            </div>
         );
    }
}
 
