import React, { useEffect, useRef, useState } from 'react';
import Loader from "react-loader-spinner";
import { useParams } from 'react-router-dom';
import { importAll } from '../../Helpers/utility';
import { BASE_API_URL, makeAPICall, REQUEST } from '../../services/apiService';
import SocialDark from '../common/socialDark1';
import Footer from '../footer';
import Header from '../header2';
export default function TrialsView({ Article = null }) {
    const pageType = useRef('story');
    const [userName, setEntityLang] = useState([]);
    const { name } = useParams();
    const { id } = useParams();
    const { user } = useParams();
    const [load, setLoader]=useState(true);
    const images = importAll(require.context('../../assets/img', false, /\.(png|jpe?g|svg)$/));
    //console.log(entityLang);
    useEffect(() => {
        // Language\
        let reqParams = {
            type: REQUEST.GET,
            url:   `RestApi/getUserName?id=${user}`,
        };
        makeAPICall(reqParams)
            .then((result) => {
                if (result.data){
                    setEntityLang(result.data);
                }
            })
            .catch(err => console.log(err));
    }, []);
    return (
        <div style={{maxWidth:'100%'}}>
            <Header></Header>
            <section class="mainPlaceholder articlePlaceMain">
            <div style={{width:'100%',height:'8%',backgroundColor:'#fff700',justifyContent:'center',alignItems:'center',display:'flex',position:'fixed'}}>
                <p style={{color:'#444444',fontSize:'16px',marginTop:'1%'}}>This trail is created by a Sahapedia user <b>{userName}</b></p>
            </div>
            {
            load ? (
               <div align="center"><Loader
               type="ThreeDots"
               color="#da3a50"
               height={100}
               width={100}
               align="center"
                    /></div>
                ) : null
            }
            <iframe onLoad={()=>setLoader(false)}  style={{width:'100%',height:'85%',margin:'o auto'}} src={`${BASE_API_URL}blogs/crowdsource_trail?id=${id}&user_id=${user}`} alt="" ></iframe>
            <a className="resize row" href= {`${window.location.origin}/TrailViewFull/${name}/${id}/${user}`} style={{color:"#444444"}} >
                <img src={images['resize.png'].default} alt="" />
                Fullscreen
            </a> 
            <div className="shBnTimeline check" style={{marginTop:'-0.5%'}}>
                <div className="shSocialSec clearfix">
                    <ul className="shSocialList">
                        <SocialDark title={name} url={`${window.location.origin}/TrailView/${name}/${id}/${user}`} />
                    </ul>
                </div>   
            </div>
            <Footer>
            </Footer>
            </section>
        </div>
    );
}
