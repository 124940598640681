var url = window.location.host;
if(url == '65.0.167.59'){
    var testing_url = "http://65.0.167.59/sahapedia2.0/backend/";
}
else if(url =="map.sahapedia.org"){
    var testing_url = "https://map.sahapedia.org/admin/";
} 
else if(url =="beta.piana.in"){
    var testing_url = "http://beta.piana.in/sahapedia2.0/backend/";
}else if(url =="testmap.sahapedia.org"){
    var testing_url = "http://testmap.sahapedia.org/admin/";
}else{
    var testing_url = "https://map.sahapedia.org/admin/";
}
var apiURL = testing_url;

module.exports = apiURL;