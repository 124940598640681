import React, { Component } from 'react';
import Loader from "react-loader-spinner";
import apiURL from '../../components/url';
import Footer from '../footer';
import Header from '../header2';
import SearchListing from './searchListing';
import Heading from './searchParams';

export default class Search extends Component {
    constructor(props) {
		super(props);
		this.state={
            items:[],
            entityType:[],
            lang:[],
            count:''

        }
    }
    allData()
    {

        const query = new URLSearchParams(this.props.location.search);
            var qryStr = query.get('keyword');
            if ( qryStr == undefined ) {qryStr = ''}
            var keyword = qryStr;
            var typ = query.get('type');
            if ( typ == undefined ) {typ = 'all'}
            var type = typ;
            var lang= query.get('lang');
            if ( lang == undefined ) {lang = ''}
            var language = lang;
            var category= query.get('category');
            if ( category == undefined ) {category = ''}
            var cat = category;

			fetch(`${apiURL}entity/searchEntity?keyword=${keyword}&type=${type}&lang=${language}&from=0&size=200&sta=publish&count=true&category=${cat}`)
			.then(res => res.json())
			.then(json => {
				if (json){
					this.setState({
						isLoaded: true,
						items: json,
						noData:false,
				})
			}
			else{
				this.setState({
					isLoaded: true,
					items:[],
					noData:true,

				})
			}
            });

    }
    allData2(count)
    {

        const query = new URLSearchParams(this.props.location.search);
            var qryStr = query.get('keyword');
            if ( qryStr == undefined ) {qryStr = ''}
            var keyword = qryStr;
            var typ = query.get('type');
            if ( typ == undefined ) {typ = 'all'}
            var type = typ;
            var lang= query.get('lang');
            if ( lang == undefined ) {lang = ''}
            var language = lang;
            var category= query.get('category');
            if ( category == undefined ) {category = ''}
            var cat = category;
			fetch(`${apiURL}entity/searchEntity?keyword=${keyword}&type=${type}&lang=${language}&from=0&size=${count + 1}&sta=publish&count=true&category=${cat}`)
			.then(res => res.json())
			.then(json => {
				if (json){
					this.setState({
						isLoaded: true,
						items: json,
						noData:false,
				})
			}
			else{
				this.setState({
					isLoaded: true,
					items:[],
					noData:true,

				})
			}
            });

    }
    getEntity()
    {
        fetch(`${apiURL}master/getEntityTypeData`)
        .then(res => res.json())
        .then(json => {
            if (json){
                this.setState({

                    entityType: json,
            })
        }
        else{
            this.setState({
                entityType:[],

            })
        }
        });
    }
    getLang()
    {
        fetch(`${apiURL}master/getLangData`)
        .then(res => res.json())
        .then(json => {
            if (json){
                this.setState({

                    lang: json,
            })
        }
        else{
            this.setState({
                lang:[],

            })
        }
        });
    }
    componentDidMount(){
        this.allData();
        this.getEntity();
        this.getLang();

		}
        count = (childData) =>{
            this.setState({count: childData})
            this.allData2(childData);

            // alert(childData);
        }
    render() {
        var a= navigator.cookieEnabled && localStorage.getItem('myData');
        var user = navigator.cookieEnabled &&localStorage.getItem('User');
        const query = new URLSearchParams(this.props.location.search);
        var qryStr = query.get('keyword');
        if ( qryStr == undefined ) {qryStr = ''}
        var keyword = qryStr;
        var typ = query.get('type');
        if ( typ == undefined ) {typ = 'all'}
        var type = typ;
        var lang= query.get('lang');
        if ( lang == undefined ) {lang = ''}
        var language = lang;
        var { isLoaded, items, entityType, lang} = this.state;
        //alert(entityType);
        if (!isLoaded){
          return  <div align="center"><Loader
          type="ThreeDots"
          color="#da3a50"
          height={100}
          width={100}
          align="center"
        />
        </div>;
		}
        else {
        const query = new URLSearchParams(this.props.location.search);
        var qryStr = query.get('keyword');
        if ( qryStr == undefined ) {qryStr = ''}
        return (
            <div>
                <Header keyword={keyword}></Header>
                <Heading Data = {items} Data2={entityType} Data3={lang} language={language} entity={type} keyword={keyword}></Heading>
                <SearchListing Data = {items} user={a} userId={user} keyword={keyword} count= {this.count}></SearchListing>
                <Footer></Footer>
            </div>
         );
    }
}

}