import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeAPICall, REQUEST } from '../../services/apiService';
import { actionCreators } from '../../redux/actions/actionCreators';

import LandingMap from '../Map/landingMap';
import ArticleCard from './articleCard';
import LandingFilter from './landingFilter';
import NavigateFilter from './navigateFilter';
import SearchArea from './searchArea';
import Sidebar from './sidebar';
import Header from '../header';
import SecondaryHeader from './secondaryHeader';
import Loader from '../common/loader';
import $ from 'jquery';

export default function LandingPagePOI() {
	const [initialize, setInitialize] = useState(0);
	const [loading, setLoading] = useState(true);
	const [sideBar, setSideBar] = useState(true);
	const [searchBox, setSearchBox] = useState(false);
	const dispatch = useDispatch();
	const { projectID, themeID, langId, keywordId } = useParams();
	let reqParam = {
		type: REQUEST.GET,
		url: 'Api/setProjId?projId=' + projectID + '&langId=' + langId,
	}
	makeAPICall(reqParam).then((result) => {});

	const pro = projectID;

	useEffect(() => {

		if (projectID && (!themeID || themeID)) {
			dispatch(actionCreators.landingPage.setProjectId(projectID));
			fetchThemeList(projectID);
		  }
		  if (themeID) {
			  dispatch(actionCreators.landingPage.setProjectThemeId(themeID));
		  }

		// if (projectID && !themeID) {
		// 	dispatch(actionCreators.landingPage.setProjectId(projectID));
		// 	fetchThemeList(projectID);
		// }
		// if (themeID) {
		// 	dispatch(actionCreators.landingPage.setProjectThemeId(themeID));
		// 	fetchThemeData(projectID);
		// 	fetchThemeList("1", themeID);
		// }
		
		
	}, [projectID, themeID]);
	const langID = langId ? langId : '22';
	
	const fetchThemeList = (projectID, themeId = null) => {
		let reqParam = {
			type: REQUEST.POST,
			url: 'project/getProjectThemeFilters',
			data: JSON.stringify({
				entity_type: "POI",
				project_id: pro,
				theme_id: themeId,
				lang: langId ? langId : '22'
			})
		}
		makeAPICall(reqParam).then((result) => {
			setLoading(!loading);
			if (result && result.data && result.data.status !== "false") {
				let resultThemes = null;

				if(!Array.isArray(result.data)) {
					resultThemes = result.data;
					dispatch(actionCreators.landingPage.setThemeList([result.data]));
				} else {
					var status = false;
					for (const key in result.data) {
						if (result.data[key].Id === '0' && result.data[key].ThemeTitle === '') {
							status = true
						} 
					}
					if (status) {
						setInitialize(1);
					} else {
					 if (themeID != null) {
						setInitialize(2); 
					 }
					resultThemes = result.data[0];
						if (result.data.length !== 1) {
							dispatch(actionCreators.landingPage.setThemeList(result.data));
						}
					}
				}

				let color_codes = {
					ALL_COLOR: resultThemes['ALL_COLOR'],
					DEFAULT_COLOR: resultThemes['DEFAULT_COLOR'],
					MULTI_COLOR: resultThemes['MULTI_COLOR']
				};
  				// whenever use refresh the page ARTICLE_FILTER is remove in the session storage because the session maintain timing  of ARTICLE_FILTER some time you have remove all theme cluster and marker not remove in the map; 
  				sessionStorage.removeItem('ARTICLE_FILTER');
				if(resultThemes.Id == "0" || themeID) {
					let theme_color = {};
					result.data.forEach((theme) => {
						theme_color[theme.Id] = theme.color;
					});
					color_codes['THEME_COLORS'] = theme_color;
					//   no need this line  because this line handling in side bar component when user put the theme id in url other wise all theme selected if theme available  in project id 
          			//  setTimeout(()=>dispatch(actionCreators.landingPage.themeItemClick(resultThemes.Id)), 1000); 
				} else {
					let theme_color = {};
					result.data.forEach((theme) => {
						theme_color[theme.Id] = theme.color;
					});
					color_codes['THEME_COLORS'] = theme_color;
				}
				navigator.cookieEnabled &&		localStorage.setItem('COLOR_CODES', JSON.stringify(color_codes));

				// if (result.data[0]) {
				// 	let color_codes = {
				// 		ALL_COLOR: result.data[0]['ALL_COLOR'],
				// 		DEFAULT_COLOR: result.data[0]['DEFAULT_COLOR'],
				// 		MULTI_COLOR: result.data[0]['MULTI_COLOR']
				// 	};
				// 	dispatch(actionCreators.landingPage.setThemeList(result.data));

				// 	if (result.data[0].Id == "0" || (themeID && result.data.length === 1)) {
				// 		setTimeout(()=>dispatch(actionCreators.landingPage.themeItemClick(result.data[0].Id)), 1000);
				// 	} else {
				// 		let theme_color = {};
				// 		result.data.forEach((theme) => {
				// 			theme_color[theme.Id] = theme.color;
				// 		});
				// 		color_codes['THEME_COLORS'] = theme_color;
				// 	}
				// 	localStorage.setItem('COLOR_CODES', JSON.stringify(color_codes));

				// }
			}
		}).catch(err => { console.log(err); });
	}
	// const fetchThemeData = (projectID, themeId = null) => {
	// 	let reqParam = {
	// 		type: REQUEST.POST,
	// 		url: 'project/getProjectThemeFilters',
	// 		data: JSON.stringify({
	// 			entity_type: "POI",
	// 			project_id: pro,
	// 			// theme_id: themeId,
	// 			lang: langId ? langId : '22'
	// 		})
	// 	}
	// 	makeAPICall(reqParam).then((result) => {
	// 		setLoading(!loading);
	// 		if (result && result.data && result.data.status !== "false") {
	// 			let resultThemes = null;

	// 			if(!Array.isArray(result.data)) {
	// 				resultThemes = result.data;
	// 				dispatch(actionCreators.landingPage.setThemeList([result.data]));
	// 			} else {
	// 				var status = false;
	// 				for (const key in result.data) {
	// 					if (result.data[key].Id === '0' && result.data[key].ThemeTitle === '') {
	// 						status = true
	// 					} 
	// 				}
	// 				if(status){
	// 					setInitialize(!initialize);
	// 				} else {
	// 				resultThemes = result.data[0];
	// 				if(result.data.length !== 1) {
	// 				dispatch(actionCreators.landingPage.setThemeList(result.data));
	// 				}
	// 			 }
	// 			}

	// 		}
	// 	}).catch(err => { console.log(err); });
	// }

	if(loading) {
		return <Loader />
	}

	return (
		<div className="wrapper landing-page-lp">
			<div>
				<ArticleCard />
				<NavigateFilter />
				<Header />
				<SecondaryHeader projectID={projectID} langId={langID} />

				<section className="mainPlaceholder themePlaceMain">
					<div className="shMapSec">
						<div className="shMapItem">
							<LandingFilter langId={langID}/>
							<SearchArea setSearchBox={setSearchBox} setSideBar={setSideBar} searchBox={searchBox} setInitialize = {setInitialize} langId={langID} projectID={projectID}  />
						</div>
						<LandingMap ProjectID={projectID} langId={langID}/>
					</div>
					<Sidebar setSideBar={setSideBar} setSearchBox={setSearchBox} sideBar={sideBar} langId={langID} projectID={projectID}  initialize={initialize} poiId = {keywordId} themeIds={themeID}/>
				</section>
			</div>
		</div>
	);
}