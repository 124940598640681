import moment from 'moment';
import React from 'react';
import renderHTML from 'react-render-html';
import { useWindowSize } from '../../Helpers/hooks/windows';







export default function Information({ information }) {
  const size = useWindowSize();
  const regex = /(<([^>]+)>)/ig;
  const regexSpace = /\s\s+/g;
const string="@internaldummyemail.com";
  return (
    <>
      <div class="more-section">
        <div class="title">More Information</div>
        {information.Location.getting_there.replace(regex,'') != '' ?
        <div class="fact-row">
          <div class="fact-row-left">Getting there:</div>
          <div class="fact-row-right">
             {renderHTML(information.Location.getting_there)}
          </div>
        </div>          :''}
        {information.museum.average_visit_duration ?
        <div class="fact-row">
          <div class="fact-row-left">Average duration of visit:</div>
          <div class="fact-row-right">{information.museum.average_visit_duration}</div>
        </div> : ''}
        {information.museum.best_time_to_visit.replace(regexSpace,'') != '' ?
       <div class="fact-row">
          <div class="fact-row-left">Best time of the day to visit:</div>
          <div class="fact-row-right">
            {renderHTML(information.museum.best_time_to_visit)}
          </div>
        </div>
:''}
        {information.museum.administered_by_other ?
        <div class="fact-row">
          <div class="fact-row-left">Administered by:</div>
          <div class="fact-row-right">{information.museum.administered_by_other}</div>
        </div>
        : ''}
        {information.Museum_incharge[0] ? 
        <div class="fact-row">
          <div class="fact-row-left">Person in charge:</div>
          <div class="fact-row-right">
            Name: {information.Museum_incharge[0].name}<br />
            <p>{information.Museum_incharge[0].designation != null ? 'Designation: ' + information.Museum_incharge[0].designation : ''}</p>
            {size.width < 768 ?
              <p>{information.Museum_incharge[0].mobile_number != '' ? <a href={`tel:${information.Museum_incharge[0].mobile_number}`}>Phone: <u class="highlightPhone">{information.Museum_incharge[0].mobile_number}</u></a> : ''}</p>
              : 
            <p>{information.Museum_incharge[0].mobile_number != '' ?  'Phone: ' + information.Museum_incharge[0].mobile_number : ''}</p>
            }
            {information.Museum_incharge[0].email.includes(string)?'':
            <p><a href={`mailto:${information.Museum_incharge[0].email}?subject=&body=`}>{information.Museum_incharge[0].email != '' ?  <>Email:  <u class="highlightEmail">{information.Museum_incharge[0].email}</u></> : ''}</a></p>
            }
            </div>
        </div> : ''}
        {information.Museum_docent[0] ? 
        <div class="fact-row">
          <div class="fact-row-left">Docent:</div>
          <div class="fact-row-right">
            Name: {information.Museum_docent[0].name}<br />
            {size.width < 768 ?
              <p>{information.Museum_docent[0].mobile_number != '' ? <a href={`tel:${information.Museum_docent[0].mobile_number}`}>Phone: <u class="highlightPhone">{information.Museum_docent[0].mobile_number}</u></a> : ''}</p>
                : 
            <p>{information.Museum_docent[0].mobile_number != '' ?  'Phone: ' + information.Museum_docent[0].mobile_number : ''}</p>
            }
            {information.Museum_docent[0].email.includes(string)?'':
            <p><a href={`mailto:${information.Museum_docent[0].email}?subject=&body=`}>{information.Museum_docent[0].email != '' ?  <>Email:  <u class="highlightEmail">{information.Museum_docent[0].email}</u></> : ''}</a></p>
            }
            </div>
        </div>
        : ''}
        {information.museum.is_currently_closed == 'yes' ?
          <div class="fact-row">
            <div class="fact-row-left">Currently closed:</div>
            <div class="fact-row-right"><p style={{float:"left",textTransform:"capitalize"}}>{information.museum.is_currently_closed}.&nbsp;</p> {renderHTML(information.museum.closed_reason)}</div>
          </div> : ''}
          {information.museum.website ?
        <div class="fact-row">
          <div class="fact-row-left">Website:</div>
          <div class="fact-row-right lineBottom">
            <a target="_blank" href={`${information.museum.website.includes('http') ? information.museum.website : 'https://'+information.museum.website}`}
            ><u>{information.museum.website}</u></a>
          </div>
        </div> :''}
        {information.museum.virtual_tour ?
        <div class="fact-row">
          <div class="fact-row-left">Virtual Tour:</div>
          <div class="fact-row-right lineBottom">
            <a target="_blank" href={`${information.museum.virtual_tour.includes('http') ? information.museum.virtual_tour : 'https://'+information.museum.virtual_tour}`}><u>Take the tour</u></a>
          </div>
        </div> :''}
        <div class="last-updated">Updated {moment(information.date_time_modified).format("MMMM YYYY")}{" "}</div>
      </div>
      {information.Credits.attribution.replace ? 
      <div class="supported-by">
        {information.Credits.attribution.replace(regex,'') == '' ? renderHTML(information.Credits.attribution) : ''}</div>
      :''}
    </>


  );
}