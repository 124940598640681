import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import LinesEllipsis from 'react-lines-ellipsis';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import apiURL from '../../components/url';
import { BASE_API_URL } from '../../services/apiService';
import editicon from '../images/edit-icon.png';
import icon1 from '../images/icon-1.png';
import icon from '../images/icon-2.png';
import '../library/libraryStyle.css';
import Social from '../socialicon';
export default class Content extends Component {
    constructor(props) {
		super(props);
		this.state={
            f:[],
            id:'',
            display: 6,
            visible:false,
            userId:localStorage.getItem('User'),
        }
        this.loadMore = this.loadMore.bind(this);
    }
    loadMore() {
        this.setState((prev) => {
            return {display: prev.display + 6};
        });
    }
    delete(id){
        this.setState({
            visible:true,
            id:id
        })
    }
    no(){
        this.setState({
            visible:false
        })
    }
    deleteSlide(){
        var t_id=this.state.id;
        var user_id=this.state.userId;
        if(t_id==''){
           
        }else{
            fetch(`${apiURL}RestApi/deleteTrail`,{
                method: 'POST',
                headers: new Headers({
                           'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                        }),
                body: JSON.stringify({
                            entity_id:t_id,
                            user_id:user_id
                        }) 
            })   
            .then(res => res.json())
            .then(json => {
                if (json){
                    this.setState({
                        visible:false
                    })
                    window.location.href=`/search/myTrails`
                }
                else{
                }
            });
        }
    }
    render() { 
        var entity = this.props.Entity;
        const shareUrl = window.location.href;
        const title = 'PCM';
        const regex = /<[^>]*>/g;
        var items = this.props.Data;
        return ( 
            <div>
                {items.status == 'false' ? '':
                <main class="main-library">
                    {
                    items.slice(0, this.state.display).map(item=>(
                    <div class="blog-card" data-toggle="tooltip" title="View">
                        <a href = {`${window.location.origin}/TrailView/${item.headline}/${item.id}/${this.state.userId}`}>
                        <img class="card-image2" src={item.media_file!=null? item.media_file : `${BASE_API_URL}/assets/images/dimage.jpg`} onError={(e)=>{e.target.onerror = null; e.target.src=`${BASE_API_URL}/assets/images/dimage.jpg`}}  width="100%" height="206px"/>
                        </a>
                        <div class="card-left2">
                            <a href = {`${window.location.origin}/TrailView/${item.headline}/${item.id}/${this.state.userId}`}>    
                                <div class="card-title-library" data-toggle="tooltip" title={item.headline}>
                                    <LinesEllipsis
                                    text={item.headline}
                                    onReflow={this.handleReflow}
                                    maxLine={2}
                                    basedOn='words'
                                    />
                                </div>
                            </a>
                            <div class="card-description-library">
                                {
                                    item.text==null?'':
                                    <LinesEllipsis
                                    text={item.text.replace(regex,'')}
                                    onReflow={this.handleReflow}
                                    maxLine={3}
                                    basedOn='words'
                                />}
                            </div>
                            <div class="card-author-library"></div>
                            <div class="card-icons-library">
                                <Popup  trigger={<img class="card-icon-img-library" data-toggle="tooltip" title="Share" src={icon1} alt="share" />} position="right center">
                                    <div>
                                        <Social type={'other'} title={item.headline} summary={item.text==null?'':(item.text.replace(/<[^>]+>/g, '')).replace(/((&nbsp;))*/gmi,'')} url={`${window.location.origin}/TrailView/${item.headline}/${item.id}/${this.state.userId}`} >
                                        </Social>
                                    </div>
                                </Popup>
                                <a href = {item.id ? '/editTrail?id='+item.id : '#'}>
                                <img class="card-icon-img-library" src={editicon} data-toggle="tooltip" title="Edit"/></a>
                                <img class="card-icon-img-library" src={icon} onClick={() => this.delete(item.id)} data-toggle="tooltip" title="Delete"/>
                            </div>
                        </div>
                    </div>
                    ))}
                    {
                    this.state.display < items.length &&
                        <div class="load-wrapper">
                            <div class="load-btn"  onClick={this.loadMore}>
                                Load More
                            </div>
                        </div>
                    }
                </main>
    }
                <Modal visible={this.state.visible} width="375" height="100" effect="fadeInUp">
                    <div style={{width:'100%',height:'100%'}}>
                        <p style={{fontSize:'20px',marginLeft:'5%',marginTop:'3%',marginRight:'5%'}}>Are you sure you want to delete?</p>
                        <br/>
                        <div align="center" style={{fontSize:'14px',marginTop:'-8%'}}>
                            <button onClick={()=>this.no()} style={{padding:' 10px 20px',borderRadius:'5px'}}>No</button><button style={{marginLeft:'20px',color:'#ffffff',backgroundColor:'#e65667',padding:' 10px 20px',borderRadius:'5px'}} onClick={() => this.deleteSlide()}>Yes</button>
                        </div>                        
                    </div>
                </Modal>
    
            </div>            
         );
    }
}
 
