import React from 'react';
import Flickity from 'react-flickity-component';
import 'flickity-fullscreen';
import 'flickity/dist/flickity.min.css';
import './carousel.css';
import ImageGallery from './imageGallery';
import { BASE_API_URL } from '../../services/apiService';

class Carousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullscreen: false,
            setGallerySize: false,
            draggable: true,
            wrapAround: true,
            pageDots: true,
        }
    }
    render() {
        var elements = [];
        let refIDD= this.props.refIDD;        
        let carouselArr = this.props.imageList;
        carouselArr.forEach((carouselImagList) => {
            let imageArray = carouselImagList.images;
            if(carouselImagList.id === refIDD){
                let list = document.querySelectorAll('embedImageCarousel');
                // // list[0].style.display = "none"; // Hides the first one
                for (let j = 0; j < list.length; j++) {
                      list[j].classList.add("noneD");
                  }
            for (var i = 0; i < imageArray.length; i++) {


                elements.push(<div key={imageArray[i].id} className="carousel-cell"> <ImageGallery onClickCarousel={this.props.onClickCarousel} image={`${BASE_API_URL}${imageArray[i].file_path}`} index={i} description={imageArray[i].description} /></div>);
            }
        }
        else{
         //   elements.push(<div></div>);
        }
        });
        return (
            <>
            {carouselArr.map(carouselImagList=>(
                <>
                {carouselImagList.id === refIDD ?
            <Flickity
                className={'carousel'} // default ''
                elementType={'div'} // default 'div'
                options={this.state} // takes flickity options {}
                disableImagesLoaded={false} // default false
                reloadOnUpdate // default false
            >
                {elements}
            </Flickity> : ''}
            </>
            ))}
            </>
        )
    }

}
export default Carousel;