import React from 'react';
import renderHTML from 'react-render-html';
import { useWindowSize } from '../../Helpers/hooks/windows';






export default function Quick({quick}) {
  const size = useWindowSize();
  const regex = /(<([^>]+)>)/ig;
  const string="@internaldummyemail.com";
    return (
        <div class="">
            <div class="title">Quick Facts</div>
            {quick.Location.museum_address ?
            <div class="fact-row">
              <div class="fact-row-left">Address:</div>
              <div class="fact-row-right">
                {quick.Location.museum_address}
              </div>
            </div> : ''}
            {quick.museum.contact_number ?
            <div class="fact-row">
              <div class="fact-row-left">Phone:</div>
              <div class="fact-row-right lineBottom">
              {size.width < 768 ?
                <a href={`tel:${quick.museum.contact_number}`}><u>{quick.museum.contact_number}</u></a>
                :
                <>{quick.museum.contact_number}</>
              }
              </div>
            </div> :''}
            {quick.museum.email ?
            quick.museum.email.includes(string)?'':
            <div class="fact-row">
              <div class="fact-row-left">Email:</div>
              <div class="fact-row-right lineBottom">
                <a href={`mailto:${quick.museum.email}?subject=&body=`}><u>{quick.museum.email}</u></a>
              </div>
            </div> :''}
            {quick.museum.timings.replace(regex,'') != '' ?
            <div class="fact-row">
              <div class="fact-row-left">Visiting Time:</div>
              <div class="fact-row-right">
              {renderHTML(quick.museum.timings)}
              </div>
            </div> :''}
            {quick.museum.entry_fee.replace(regex,'') != '' ?
            <div class="fact-row">
              <div class="fact-row-left">Entry Fees:</div>
              <div class="fact-row-right entry">
              {renderHTML(quick.museum.entry_fee)}
              </div>
            </div> :''}
            {quick.museum.year_established  != '0' ?
            <div class="fact-row">
              <div class="fact-row-left">Established:</div>
              <div class="fact-row-right">{quick.museum.year_established != '0' ? quick.museum.year_established : ''}</div>
            </div> :''}

            <div class="fact-row">
              {quick.museum.facility_parking == 'yes' || quick.museum.facility_photography =='yes' ?
              <>
              <div class="fact-row-left">Facilities & Services:</div>
              <div class="fact-row-right service-icons">
              <span class={`photography ${quick.museum.facility_photography}`} data-toggle="tooltip" title="Photography"></span>
              <span class={`videography ${quick.museum.facility_videography}`} data-toggle="tooltip" title="Videography"></span>
              <span class={`cafeteria ${quick.museum.facility_cafeteria}`} data-toggle="tooltip" title="Cafeteria"></span>
              <span class={`museumshop ${quick.museum.facility_shop}`} data-toggle="tooltip" title="Museum shop"></span>
              <span class={`wheelchair ${quick.museum.facility_wheelchair_friendly}`} data-toggle="tooltip" title="Wheelchair friendly"></span>
              <span class={`restroom ${quick.museum.facility_restroom}`} data-toggle="tooltip" title="Restroom"></span>
              <span class={`water ${quick.museum.facility_drinking_water}`} data-toggle="tooltip" title="Drinking water"></span>
              <span class={`lockerroom ${quick.museum.facility_locker_room}`} data-toggle="tooltip" title="Locker room"></span>
              <span class={`escalator ${quick.museum.facility_escalator}`} data-toggle="tooltip" title="Elevators/Escalators"></span>
              <span class={`seating ${quick.museum.facility_seating}`} data-toggle="tooltip" title="Seating"></span>
              <span class={`membership ${quick.museum.services_membership}`} data-toggle="tooltip" title="Membership"></span>
              <span class={`elderly ${quick.museum.facility_elderly_friendly}`} data-toggle="tooltip" title="Elderly Friendly"></span>
              <span class={`parking ${quick.museum.facility_parking}`} data-toggle="tooltip" title="Parking"></span>
              <span class={`infoinbraile ${quick.museum.facility_braille}`} data-toggle="tooltip" title="Information in Braile"></span>
              <span class={`garden ${quick.museum.facility_garden}`} data-toggle="tooltip" title="Garden/Picnic area"></span>
              <span class={`workshops ${quick.museum.facility_workshop_lecture_seminar}`} data-toggle="tooltip" title="Workshops/seminars/lectures"></span>
              <span class={`audioguide ${quick.museum.facility_audio_video_guide}`} data-toggle="tooltip" title="Audio/visual guides"></span>
              <span class={`guidedtour ${quick.museum.facility_guided_tours}`} data-toggle="tooltip" title="Guided tours"></span>
              <span class={`library ${quick.museum.facility_library}`} data-toggle="tooltip" title="Library and archives services"></span>
              <span class={`itfacilities ${quick.museum.facility_IT}`} data-toggle="tooltip" title="IT facilities ( Photocopying, printing, computer)"></span>
              <span class={`conference ${quick.museum.services_conference_room}`} data-toggle="tooltip" title="Conference/Seminar hall"></span>
              <span class={`conservation ${quick.museum.services_conversion_lab}`} data-toggle="tooltip" title="Conservation lab"></span>
              <span class={`research ${quick.museum.services_research_lab}`} data-toggle="tooltip" title="Research lab"></span>
              <span class={`auditorium ${quick.museum.services_auditorium}`} data-toggle="tooltip" title="Auditorium"></span>
              </div>
              </>
              :''}
              <div id="more-icon-title">
                <div class="arrow-bottom" data-popper-arrow></div>
                <div style={{position: "relative"}}>Photography</div>
              </div>
            </div>
          </div>


    );
}