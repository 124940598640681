import axios from 'axios';
import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'reactjs-popup/dist/index.css';
import Footer from "./footer";
import Header from './header';
import apiURL from './url';
export default class Sign extends Component {
    constructor(props) {
        super(props);
        this.state = {


            afterLoginUrl: this.props.afterLoginUrl,


            username: "",
            password: "",
            cpassword: "",
            message: '',
            uname: '',
            visible: true,
            visible1:false,
            message1: '',
            message2: '',
            message3: '',
            isChecked: false,
            date: '',
            userId: '',
            name:'',
            masg:'',


        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);
        var chk = localStorage.getItem('check');

        if (chk == 'true') {
            var sdate = localStorage.getItem('date');
            var date = Math.round(Date.now() / 1000);
            var diffDate = date - sdate;
            if (diffDate > 1800) {
                navigator.cookieEnabled && localStorage.clear();

            }

        }

    }

    openModal() {
        this.setState({


            afterLoginUrl: '/search/myTrails',
            afterLoginLibrary: '/search/libraries',


            visible: true
        });
    }
    
    openModal1() {
        this.setState({
            visible1: true
        });
    }



    closeModal() {
        // this.props.close();
        this.setState({
            visible: false,
            visible1: false
        });
        window.location.href="/inpune/1"
    }

signInModal(){
    this.setState({
        visible: false,
        visible1:false
    });
}

    handleEmail = event => {
        this.setState({ username: event.target.value });
    }
    handlePass = event => {
        this.setState({ password: event.target.value });
    }
    handlecPass = event => {
        this.setState({ cpassword: event.target.value });
    }
    handleSubmit(event) {
        var book = this.props.unbook;
        var id = this.props.bookID;
        var type = this.props.bookType;
        var relation = this.props.rel;
        var created = this.props.created;
        event.preventDefault();

        var count = 0;
        var username = this.state.username;
        var password = this.state.password;
        var cpassword = this.state.cpassword;
        const query = new URLSearchParams(this.props.location.search);
        const key = query.get('key');

        if (username == '') {
            count = count + 1;
            this.setState({

                message1: "Please enter valid email address."

            })
        }
        else {
            this.setState({
                message1: ""

            })
        }





        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(username)) {
            count = count + 1;
            this.setState({
                message1: "Please enter valid email address."
            })
        }
        else {
            this.setState({

                message1: ""

            })
        }
        if (password == '') {
            count = count + 1;
            this.setState({
                message2: "Please Enter Valid Password"
            })
        }
        else {
            this.setState({
                message2: ""
            })
        }
        if (cpassword != password) {
            count = count + 1;
            this.setState({
                message3: "The password and confirm password do not match"
            })
        }
        else {
            this.setState({
                message3: ""
            })
        }
        
        if (count === 0) {
            fetch(`${apiURL}master/saveFrontendPassword?email=${username}&passwd=${password}&key=${key}`)
                .then(res => res.json())
                .then(json => {
                    if (json[0].status === "true") {
                        this.setState({
                            masg: 'Your Password has been updated sucessfully',
                            //items: json,
                            //noData:false,
                        })

                    }
                    else {
                        this.setState({
                            masg: json[0].msg,

                        })
                    }
                });
            }        


    }
    test(id, type, relation, created) {
        var keyword = this.props.searchqry;
        var user = localStorage.getItem('User');

        fetch(`${apiURL}Api/addBookmark?id=${id}&entityType=${type}&relation=${relation}&user=${user}`)
            .then(res => res.json())
            .then(json => {
                if (json) {
                    // window.location.reload();
                    this.setState({
                        items: json,
                        noData: false,
                    })

                }
                else {
                    this.setState({
                    })
                }
            });

    }
    test2(id) {
        fetch(`${apiURL}Api/deleteBookmark?id=${id}`)
            .then(res => res.json())
            .then(json => {
                if (json) {
                    // window.location.reload();
                    this.setState({
                        f: json,
                        noData: false,
                    })
                }
                else {
                    this.setState({
                    })
                }
            });

    }
    componentWillUpdate(){
        if(this.state.name == 'true'){
            this.closeModal();
        }
    }

    postData(ev) {

        ev.preventDefault()
        const username = this.state.username
        const password = this.state.password
        this.setState({
            loading: true
        })

        // make API call
        const data = {
            username,
            password,
        }
        axios({
            method: 'post',
            url: `${apiURL}master/login_new`,
            data: "username=" + data.username + "&password=" + data.password,
        })
            .then(response => {
                console.log(response.data);
                this.setState({
                    loading: false,
                    result: response.data
                })
                this.alertboxShow();
            });


    }
    handleChangeCheckBox = event => {

        this.setState({ isChecked: event.target.checked });
        navigator.cookieEnabled &&   localStorage.setItem("check", event.target.checked);

    }



    render() {
        // alert('hii'+this.props.afterLoginUrl);
        const size = window.innerWidth;
        var user = this.state.uname;

        return (
            <div>
      <Header />
      <Modal visible={this.state.visible} width="800" height="550" effect="fadeInUp">
      <div class="row" style={{ marginLeft: "0px" }}>
                                        <div class="col-md-6" style={{ backgroundColor: "#fff",width:"800px"}}></div>
                                        <div class="col-md-6" align={`${size < 768 ? "center" : "right"}`} style={{ maxWidth:"48%",backgroundColor:"#e2e2e2",color: "grey", padding:"10px 30px", fontSize: "20px" }} >
                                            <a href="javascript:void(0);" style={{ color: "grey",marginLeft:`${size < 768 ?'320px':''}` }} onClick={() => this.closeModal()}>X</a>
                                        </div>
                                    </div>
                <div class="row" style={{padding: "0px 0px 0px 15px" }}>
                    <div class={`col-md-6 ${size < 768 ? 'signMobile' : ''}`} style={{ backgroundColor: "#fff" }} align="center">
                        <img src={`${apiURL}assets/logo/sahapediaHdlogo.png`} className="signinlogo" alt="logo" />
                        <div class="field" id="donate" style={{ marginTop: "47px" }}>
                            <a href=" https://www.sahapedia.org/donate" >     <button class="buttonStyl buttonStyll">Donate Now</button></a></div>

                            {/* <a href=" https://www.sahapedia.org/donate" >     <button class="buttonStyl buttonStyll">Donate Now</button></a></div> */}

                    </div>
                    <div class="col-md-6" style={{ color: "#000", padding: `${size < 768 ? "0px 5px 0px 0px" : "0px 15px 0px 0px"}` }}>

                        <div class="signInForm">
                            <div className="result" style={{ color: "#FF0000", fontSize: "14px" }}>{this.state.message}</div>
                            <p style={{ fontSize: "30px !important" }} align="left"><b class="signin-text">Reset Password</b></p>
                            <br/>
                            <p style={{ color: "#FF0000"}}>{this.state.masg}</p>
                             <div class="form-area">
                                <form >
                                    <div class="field"><input type="text" placeholder="Email Address" id="username" onChange={this.handleEmail} required /></div>
                                    <div className="result" style={{ color: "#FF0000", fontSize: "14px" }}>{this.state.message1}</div>
                                    <div class="field"><input type="password" placeholder="New Password" id="n_password" onChange={this.handlePass} required /></div>
                                    <div className="result" style={{ color: "#FF0000", fontSize: "14px" }}>{this.state.message2}</div>
                                    <div class="field"><input type="password" placeholder="Confirm Password" id="c_password" onChange={this.handlecPass} required /></div>
                                    <div className="result" style={{ color: "#FF0000", fontSize: "14px" }}>{this.state.message3}</div>
                                    
                                    
                                    <div class="field" id="field" style={{marginTop:'60px'}} onClick={this.handleSubmit}><button class="buttonStyl">Confirm Changes</button></div>
                                </form>
                                <div class="or-signin-with">
                                    <div class="line"></div>
                                    <div class="text"></div>
                                </div>

                                {/* <div class="social-login">
                                    <a href="#" class="google"><i class="fa fa-google" aria-hidden="true"></i></a>
                                    <a href="#" class="facebook"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                    <a href="#" class="twitter"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                </div> */}
                            </div>

                        </div>

                    </div>
                </div>
                </Modal>
                <Footer />
            </div>
        );
    }
}