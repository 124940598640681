import React, { Component } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Bookmark from "../../components/bookmark";
import defaultImg from "../../components/images/default-thumbnail.jpg";
import apiURL from "../../components/url";
import entityName from '../entityUrl';
import icon1 from "../images/icon-1.png";
import unBookmark from "../images/icon-4.png";
import Social from "../socialicon";

export default class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      display: 6,
    };
    this.loadMore = this.loadMore.bind(this);
  }
  loadMore() {
    this.setState((prev) => {
      return { display: prev.display + 6 };
    });
  }
  componentDidMount() {
    var Id = this.props.cardDetail;
    console.log(Id);
    fetch(`${apiURL}entity/getRecoList?entity_id=${Id}`)
      .then((res) => res.json())
      .then((json) => {
        if (json) {
          this.setState({
            isLoaded: true,
            items: json.data,
            noData: false,
          });
        } else {
          this.setState({
            isLoaded: true,
            items: [],
            noData: true,
          });
        }
      });
  }
  render() {
    var { items } = this.state;
    return (
      <>
        {items ? (
          <>
          {this.props.setReadMore && items.length > 0 && this.props.setReadMore("Read More")}
            {items.slice(0, this.state.display).map((cardDetail) => (
              <div className="col-md-4 mb-5 mobile-card-at">
                <div className="stThumbItem">
                  <div style={{ float: "left" }}>
                {console.log("cardDetail",cardDetail)}
                    <a
                      href={`/${entityName(cardDetail.f_master_meta_entity_type)}/${cardDetail.title.replace(" ", "-")}/${cardDetail.id}`}
                    >
                      <img
                        className="stThumbItemImg"
                            src={cardDetail.banner_image_url
                            ? apiURL +
                              cardDetail.banner_image_url
                            : defaultImg
                        }
                      />
                    </a>
                    <div className="stThumbItemDesc">
                      <p className="stThumbCat">{cardDetail.entity_type}</p>
                      <a
                        href={`/${entityName(cardDetail.f_master_meta_entity_type)}/${cardDetail.title.replace(" ", "-")}/${
                          cardDetail.id
                        }`}
                      >
                        <h2>
                          <LinesEllipsis
                            text={cardDetail.title}
                            onReflow={this.handleReflow}
                            maxLine={2}
                            basedOn="words"
                          />
                        </h2>
                      </a>
                      {/* <p className="stThumbSculp"> {cardDetail.domain_subdomain && concatMulti(cardDetail.domain_subdomain, "/", "subdomain_name")} </p> */}
                      <p className="stThumbSculp">
                        {cardDetail.subdomain_name.join(" / ")}
                      </p>
                      <p className="stThumbDesc" style={{ height: "64px" }}>
                        <LinesEllipsis
                          text={cardDetail.summary ? cardDetail.summary : ""}
                          onReflow={this.handleReflow}
                          maxLine={3}
                          basedOn="words"
                        />
                      </p>
                      <p className="stThumbOw">
                        {cardDetail.mapper_first_name
                          ? cardDetail.mapper_first_name
                          : cardDetail.first_name}
                        {cardDetail.mapper_middle_name
                          ? " " + cardDetail.mapper_middle_name
                          : cardDetail.middle_name}
                        {cardDetail.mapper_last_name
                          ? " " + cardDetail.mapper_last_name
                          : cardDetail.last_name}
                      </p>
                      <div
                        className="stThumbItemCtrl"
                        style={{ marginTop: "2%" }}
                      >
                        <a>
                          <Popup
                            trigger={
                              <img
                                class="card-icon-img-search"
                                src={icon1}
                                alt="share"
                              />
                            }
                            position="top center"
                          >
                            <div>
                              <Social
                                type={"other"}
                                title={cardDetail.title}
                                summary={
                                  cardDetail.summary ? cardDetail.summary : ""
                                }
                                url={
                                  cardDetail.type == "external"
                                    ? cardDetail.url
                                    // : `https://map.sahapedia.org/search/museum/${cardDetail.title.replace(
                                    //     " ",
                                    //     "-"
                                    //   )}/${cardDetail.id}`
                                    : `${window.location.origin}/search/${entityName(cardDetail.f_master_meta_entity_type)}/${cardDetail.title.replace(" ", "-")}/${
                                      cardDetail.id
                                    }`
                                }
                              ></Social>
                            </div>
                          </Popup>
                        </a>
                        <a>
                          {navigator.cookieEnabled && localStorage.getItem("User") ? (
                            <>
                              <Bookmark
                                id={cardDetail.id}
                                type={cardDetail.f_master_meta_entity_type}
                                relation={"6"}
                                user={localStorage.getItem("User")}
                              ></Bookmark>
                            </>
                          ) : (
                            <>
                              <img
                                class="card-icon-img-search"
                                value="Bookmark"
                                onClick={() =>
                                  this.openModal(
                                    cardDetail.linkage_id,
                                    cardDetail.id,
                                    cardDetail.f_master_meta_entity_type,
                                    6,
                                    localStorage.getItem("User")
                                  )
                                }
                                src={unBookmark}
                                alt="bookmark"
                              />
                            </>
                          )}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {this.state.display < items.length && (
              <div class="load-wrapper">
                <div class="load-btn" onClick={this.loadMore}>
                  Load More
                </div>
              </div>
            )}
          </>
        ) : (
          ""
        )}
      </>
    );
  }
}
