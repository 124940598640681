import axios from 'axios';
import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import LinesEllipsis from 'react-lines-ellipsis';
import Loader from "react-loader-spinner";
import renderHTML from 'react-render-html';
import { ReactSortable } from "react-sortablejs";
import { BASE_API_URL } from '../../services/apiService';
import search from '../images/ic_search@2x.png';
import {FiSearch} from 'react-icons/fi';
import Footer from '../footer';
import Header from '../header2';
import cameraicon from '../images/camera.png';
import icon from '../images/icon-2.png';
import '../search/searchStyle.css';
import Signin from '../signin2';
import apiURL from '../url';
import './trailsStyle.css';
export default class EditTrial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keyword : '',
            type: 'all',
            username:"",
            password:"",
            message: '',
            uname:'',
            items:[],
            searchData:[],
            View:false,
            t_items:[],
            e_items:[
                {
                    "id":"0",
                }
            ],
            visible:false,
            visible1:false,
            visible2:false,
            visible3:false,
            visib:false,
            message1:'',
            message2:'',
            isChecked:false,
            date:'',
            slideId:'',
            title:'',
            text:'',
            slide_title:'',
            slide_text:'',
            type:'15',
            message:'',
            entity_type:'',
            userId:localStorage.getItem('User'),
            media:'',
            media_type:'',
            lat:'',
            long:'',
            display:6,
            load:true,
            new:true,
            selectedFile:null,
            sendFile:null,
            slideView:false,
            slideNo:0,
            saveAlert:false,
            projectList:[],
            subdomainList:[],
            subdomainFilter:'',
            projectFilter:'',
            LangFilter:'',
            attached:[],
            attachedList:[],
            langList:[],
            attachedView:false,
            articleView:false,
            videoView:false,
            galleryView:false,
            museumView:false,
            head:'',
            s_type:'',
            s_id:'',
            controller:new AbortController()
        } 
        this.titleSubmit=this.titleSubmit.bind(this);
        this.titleSave=this.titleSave.bind(this);
        this.eventSave=this.eventSave.bind(this);
        this.titleslide=this.titleslide.bind(this);
        this.eventslide=this.eventslide.bind(this);
        this.loadMore = this.loadMore.bind(this);
    }
  
    updateInput = event=>{
        this.setState({keyword:event.target.value})
    }     
    loadMore() {
        this.setState((prev) => {
          return {display: prev.display + 6};
        });
      }
      getProject()
    {
        fetch(`${BASE_API_URL}master/getProjectList`)		
        .then(res => res.json())
        .then(json => {
            if (json){
                this.setState({
        
                    projectList: json,
            })
        }
        else{
            this.setState({
                projectList:[],
    
            })
        }
        });
    }
    getSubdomain()
    {
        var lang=this.state.LangFilter;
        fetch(`${BASE_API_URL}master/getSubdomainLangList?lang=${lang}`)		
        .then(res => res.json())
        .then(json => {
            if (json){
                this.setState({
        
                    subdomainList: json,
            })
        }
        else{
            this.setState({
                subdomainList:[],
    
            })
        }
        });
    }
    changeSubdomain(lang){
        fetch(`${BASE_API_URL}master/getSubdomainLangList?lang=${lang}`)		
        .then(res => res.json())
        .then(json => {
            if (json){
                this.setState({
        
                    subdomainList: json,
            })
        }
        else{
            this.setState({
                subdomainList:[],
    
            })
        }
        });
    }
    getLang()
    {
        var project=this.state.projectFilter;
        //fetch(`${apiURL}master/getLangData`)		
        fetch(`${apiURL}/Master/getProjectLangData?project=${project}`)
        .then(res => res.json())
        .then(json => {
            if (json){
                this.setState({
        
                    langList: json,
            })
        }
        else{
            this.setState({
                langList:[],
    
            })
        }
        });
    }
    changeLang(project)
    {
        
        //fetch(`${apiURL}master/getLangData`)		
        fetch(`${apiURL}/Master/getProjectLangData?project=${project}`)
        .then(res => res.json())
        .then(json => {
            if (json){
                this.setState({
        
                    langList: json,
            })
        }
        else{
            this.setState({
                langList:[],
    
            })
        }
        });
    }
      onFileChange(event){
    
        // Update the state
        console.log(event);
        this.setState({ 
            selectedFile:  URL.createObjectURL(event.target.files[0]),
            sendFile: event.target.files[0]
        });
      
      }
    titleSubmit(){
        var title=this.state.title;
        var userId=this.state.userId;
        if(title==''){
            this.setState({
                message:"Please Enter the Title",    
            })
        }else{
         fetch(`${apiURL}RestApi/createTrail?title=${title}&userID=${userId}`)   
         .then(res => res.json())
         .then(json => {
             if (json){
                 this.setState({
                     items: json,
                     visible:false
             })
             this.titleslide();
         }
         else{
             this.setState({
                 massage:"Something Went Worng",
     
             })
         }
         });
        }
    }
    titleSave(){
        var id=this.state.items;
        var title=this.state.title;
        var text=this.state.text;
        var userId=this.state.userId;
        if(title==''){
            this.setState({
                message:"Please Enter the Title",    
            })
        }else{
      const formData = new FormData();
    
      // Update the formData object
      formData.append('id',id);
      formData.append('title',title);
      formData.append('text',text);
      formData.append('userID',userId);
      formData.append('file',this.state.sendFile);
      axios.post(`${apiURL}RestApi/saveTitleTrail1`, formData)
      .then((response) => {
        this.titleslide(id);
        this.setState({
            saveAlert:true,
            slideView:false
        })
      }, (error) => {
        this.titleslide(id);
      });
        }
    }
    eventSave(){
        var t_id=this.state.items;
        var title=this.state.slide_title;
        var text=this.state.slide_text;
        var userId=this.state.userId;
        var media=this.state.media;
        var media_type=this.state.media_type;
        var lat=this.state.lat;
        var long=this.state.long;
        var slideNo=this.state.slideNo;
        if(title==''){
            this.setState({
                message:"Please Enter the Title",    
            })
        }else{
         //fetch(`${apiURL}RestApi/saveEventTrail?entity_id=${t_id}&title=${title}&text=${text}&userID=${userId}`)   
         fetch(`${apiURL}RestApi/saveEventTrail`,{
            method: 'POST',
            headers: new Headers({
                       'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
              }),
            body: JSON.stringify({
                entity_id:t_id,
                title:title,
                text:text,
                userID:userId,
                media_url:media,
                media_type:media_type,
                lat:lat,
                long:long,
                slideno:slideNo,
                slideid:this.state.s_id,
                slidetype:this.state.s_type
             }) 
         })   
         .then(res => res.json())
         .then(json => {
             if (json){
                this.setState({
                    slideId:json,  
                    new:false,
                    saveAlert:true,
                    slideView:false  ,
                    s_id:'',
                    s_type:''
                })
             this.eventslide(json);
         }
         else{
             this.setState({
                 massage:"Something Went Worng",
                 s_id:'',
                 s_type:''
             })
         }
         });
        }
    }
    titleslide(id){
        var userId=this.state.userId;
        fetch(`${apiURL}RestApi/titleSlide?id=${id}&userID=${userId}`)   
        .then(res => res.json())
        .then(json => {
            if (json){
                this.setState({
                    t_items: json,
                    title:json[0].headline,
                    selectedFile:json[0].media_file
            })
            if(json[0].text!=null){
                this.setState({
                    text:json[0].text.replace(/<[^>]+>/g, '').replace(/((&nbsp;))*/gmi,''),
                })
            }
            this.eventslide(id);
        }
        else{
            this.setState({
                massage:"Something Went Worng",
    
            })
        }
        });
    }
    eventslide(id){
        var id=this.state.items;
        var userId=this.state.userId;
        fetch(`${apiURL}RestApi/getSlideCs?id=${id}&userID=${userId}`)  
        .then(res => res.json())
        .then(json => {
            if (json){
                this.setState({
                    e_items: json,
                    visible:true
            })
            if(json[0].id!=0){
                this.setState({
                    slideNo:json.length
                })
            }else{
                this.setState({
                    slideNo:0
                })
            }
            
        }
        else{
            this.setState({
                massage:"Something Went Worng",
    
            })
        }
        });
    }
        openModal() {
            this.setState({
                visible : true
            });
        }
        
        closeModal() {
            this.setState({
                visible : false
            });
        }
        closeattachModal() {
            this.setState({
                attachedView : false
            });
        }
        closeAttached(){
            this.setState({
                attachedView:false,
                 visible1:true,
                head:''
            });
        }
        openModal1() {
            this.setState({
                visible1 : true,
                load:true,
                searchData:[]
            });
            window.history.pushState("object or string", "Title", `/editTrail?id=${this.state.items}#`);
            this.getProject();
            this.getSubdomain();
            this.getLang();
            this.searchData(this.state.projectFilter,this.state.subdomainFilter,this.state.LangFilter,this.state.keyword);
        }
        
        closeModal1() {
            this.setState({
                visible1 : false,
                visible2:false,
                slideView:false
            });
            window.location.reload();
    
        }   
        deleteAlert() {
            this.setState({
                visibleDelete : true
            });
        }  
        
        deleteClose() {
            window.location.href=`/editTrail?id=${this.state.items}`;
        }
   // if (isLoadedCount.current < 2) {
   //     return <LoaderScreen />
   // }
  
searchData(project,subdomain,lang,keyword){
    //const controller = new AbortController();
   
      fetch(`${BASE_API_URL}/entity/searchEntity?sta=publish&type=${this.state.type}&project=${project}&subdomain=${subdomain}&lang=${lang}&keyword=${keyword}`,{signal:this.state.controller.signal})   
    .then(res => res.json())
    .then(json => {
        if (json){
            this.setState({
                isLoaded: true,
                searchData: json.data,
                noData:false,
                load:false
        })
    }
    else{
        this.setState({
            isLoaded: true,
            searchData:[],
            noData:true,
            load:true

        })
    }
});		
}
   handleName = event => {
    this.setState({title: event.target.value });
}
handleLat = event => {
    this.setState({lat: event.target.value });
}
handleLong = event => {
    this.setState({long: event.target.value });
}
handleTitleText = event => {
    this.setState({text: event.target.value });
}
handleType = event => {
    this.setState({subdomainFilter: '' });
    this.setState({LangFilter: '' });
    var c='';
    var b = '';
    this.setState({projectFilter: event.target.value });
    this.setState({display:6,load:true});
    this.changeLang(event.target.value);
    this.state.controller.abort();
    this.state.controller=new AbortController();
    //this.setState({
   //    controller: new AbortController()
   // })
   
    this.searchData(event.target.value,c,b,this.state.keyword);
}
handleSubmit(){
    this.setState({LangFilter: '' });
    var d ='';
    this.setState({display:6,load:true});
    this.state.controller.abort();
    this.state.controller=new AbortController();
    //this.setState({
   //    controller: new AbortController()
   // })
   
    this.searchData(this.state.projectFilter,this.state.subdomainFilter,d,this.state.keyword);
}
handlesub = event => {
    this.setState({subdomainFilter: event.target.value });
    this.setState({display:6,load:true});
    this.state.controller.abort();
    this.state.controller=new AbortController();
   // this.setState({
  //      controller: new AbortController()
    // })
     this.searchData(this.state.projectFilter,event.target.value,this.state.LangFilter,this.state.keyword);
}
handlelang = event => {
    this.setState({subdomainFilter: '' });
    var a = '';
    this.setState({LangFilter: event.target.value });
    this.setState({display:6,load:true});
    this.changeSubdomain(event.target.value);
    this.state.controller.abort();
    this.state.controller=new AbortController();
    // this.setState({
   //     controller: new AbortController()
   //  })
  
     this.searchData(this.state.projectFilter,a,event.target.value,this.state.keyword);
}
componentDidMount(){
    var user = localStorage.getItem('User');
    if(user==null){
        this.setState({
            visible:true
        })
    }else{
    var a=this.state.type;
    const query = new URLSearchParams(this.props.location.search);
    var qryStr = query.get('id');
    this.setState({
        items:qryStr
    })
    this.titleslide(qryStr);
    window.onpopstate = e => {
        window.location.href=`/editTrail?id=${this.state.items}`;
    }
}
}

import(id,type,title,summary,latlong,thumb){
    var lat=latlong.split(',')[0];
    var long=latlong.split(',')[1];
    fetch(`${BASE_API_URL}project/importDetails?id=${id}&lang=${this.state.LangFilter}`)   
    .then(res => res.json())
    .then(json => {
        if (json){
    if(json[0].attached==''){
        this.importPOI(id,type,title,summary,lat,long,thumb);  
      this.importPOI(id,type,title,summary,lat,long,thumb);  
        this.importPOI(id,type,title,summary,lat,long,thumb);  
      this.importPOI(id,type,title,summary,lat,long,thumb);  
        this.importPOI(id,type,title,summary,lat,long,thumb);  
      this.importPOI(id,type,title,summary,lat,long,thumb);  
        this.importPOI(id,type,title,summary,lat,long,thumb);  
      this.importPOI(id,type,title,summary,lat,long,thumb);  
        this.importPOI(id,type,title,summary,lat,long,thumb);  
      this.importPOI(id,type,title,summary,lat,long,thumb);  
        this.importPOI(id,type,title,summary,lat,long,thumb);  
      this.importPOI(id,type,title,summary,lat,long,thumb);  
        this.importPOI(id,type,title,summary,lat,long,thumb);  
      this.importPOI(id,type,title,summary,lat,long,thumb);  
        this.importPOI(id,type,title,summary,lat,long,thumb);  
      this.importPOI(id,type,title,summary,lat,long,thumb);  
        this.importPOI(id,type,title,summary,lat,long,thumb);  
      }else{
      this.setState({
          visible1 : false,
          visible2 : true,
          attached:json[0].attached,
          attachedView:true,
          attachedList:[...new Set(json[0].attached.map(item =>item.entity_type))],
          entity_type:type,
          slide_title: title,
          head:title,
          slide_text: summary.replace(/\s{2,}/g,' ').trim(),
          lat:lat,
          long:long,
          media:apiURL+'assets/Map/map.html?token=jzp91qr6tdrkbol7qd35fonchhru23rk&lat='+lat+'&lng='+long+'&info='+title+'&popupOpen=true&iconUrl=https://maps.mapmyindia.com/images/to',
          media_type:1,
          new:true,
          slideView:true
      })
  }
    }
});	
}
importPOI(id,type,title,summary,lat,long,thumb){
    this.setState({
        visible1 : false,
        visible2 : true,
        entity_type:type,
        slide_title: title,
        slide_text: summary.replace(/\s{2,}/g,' ').trim(),
        lat:lat,
        s_id:id,
        s_type:type,
        long:long,
        media:thumb,
        media_type:1,
        new:true,
        slideView:true
    })
    
}

importArticle(id,type,title,summary,banner){
    this.setState({
        visible1 : false,
        visible2 : true,
        attachedView:false,
        head:'',
        s_id:id,
        s_type:type,
        entity_type:type,
        slide_title: title,
        slide_text: summary.replace(/\s{2,}/g,' ').trim(),
        media:banner,
        new:true,
        media_type:2,
        slideView:true,
        articleView:false
    })
    
}
importMuseum(id,type,title,summary,banner){
    this.setState({
        visible1 : false,
        visible2 : true,
        entity_type:type,
        attachedView:false,
        slide_title: title,
        slide_text: summary.replace(/\s{2,}/g,' ').trim(),
        media:banner,
        s_id:id,
        s_type:type,
        new:true,
        head:'',
        media_type:2,
        slideView:true,
        museumView:false
    })
    
}
importGallery(id,type,title,summary,banner){
    this.setState({
        visible1 : false,
        visible2 : true,
        entity_type:type,
        slide_title: title,
        slide_text: summary.replace(/\s{2,}/g,' ').trim(),
        media:banner,
        s_id:id,
        s_type:type,
        new:true,
        head:'',
        attachedView:false,
        media_type:1,
        slideView:true,
        galleryView:false
    })
    
}
importVideo(id,type,title,summary,banner){
    this.setState({
        visible1 : false,
        visible2 : true,
        entity_type:type,
        slide_title: title,
        slide_text: summary.replace(/\s{2,}/g,' ').trim(),
        media:banner,
        s_id:id,
        s_type:type,
        new:true,
        head:'',
        attachedView:false,
        media_type:1,
        slideView:true,
        videoView:false
    })
    
}
deletesideslide(id){
    this.setState({
        slideId:id,
        visibleDelete:true
    })
}
deleteSlide(id){
    var t_id=this.state.items;
    if(id==''){
        this.setState({
            message:"Please Enter the Title",    
        })
    }else{
     //fetch(`${apiURL}RestApi/saveEventTrail?entity_id=${t_id}&title=${title}&text=${text}&userID=${userId}`)   
     fetch(`${apiURL}RestApi/deleteTrailSlide`,{
        method: 'POST',
        headers: new Headers({
                   'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
          }),
        body: JSON.stringify({
            entity_id:t_id,
            slide_id:id
         }) 
     })   
     .then(res => res.json())
     .then(json => {
         if (json){
            window.location.href=`/editTrail?id=${this.state.items}`;   }
     else{
         this.setState({
             massage:"Something Went Worng",
 
         })
     }
     });
    }
}
llSubmit(){
    var lat=this.state.lat;
    var long=this.state.long;
    if(lat==''){

    }else if(long==''){

    }else{
    this.setState({
        visible3:false,
        visible2:true,
        slideView:true
    })
}
}
saveAlert(){
    this.setState({
        saveAlert:false
    })
}
titletab(){
    this.setState({
        visible2:false,
        slideView:true
    })
}
sort(items){
    var id=items.map(item=>(item.id));
    fetch(`${apiURL}RestApi/sortEventTrail`,{
        method: 'POST',
        headers: new Headers({
                   'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
          }),
        body: JSON.stringify({
            id:id,
         }) 
     })   
     .then(res => res.json())
     .then(json => {
         if (json){
         this.eventslide();
     }
     else{
         this.setState({
             massage:"Something Went Worng",
 
         })
     }
     });
}
slidetab(slide_id){
    var id=this.state.items;
        var userId=this.state.userId;
        fetch(`${apiURL}RestApi/getSlideCs?id=${id}&userID=${userId}&slide_id=${slide_id}`)  
        .then(res => res.json())
        .then(json => {
            if (json){
                this.setState({
                    visible:true,
                    visible2:true,
                    slideId:slide_id,
                    media_type:json[0].media_type,
                    media:json[0].media_file,
                    slide_title:json[0].headline,
                    slide_text:json[0].text,
                    new:false,
                    slideView:true

            })
            
        }
        else{
            this.setState({
                massage:"Something Went Worng",
    
            })
        }
        });
}
view(){
    var check=this.state.View;
    if(check==true){
        this.setState({
            View:false  
        })
    }
    else{
        this.setState({
            View:true  
        })
    }
}
POI(){
    this.setState({
        attachedView:false
    })
}
Article(){
    this.setState({
        articleView:true,
        attachedView:false
    })
}
Video(){
    this.setState({
        videoView:true,
        attachedView:false
    })
    console.log(this.state.attached);
}
Gallery(){
    this.setState({
        galleryView:true,
        attachedView:false
    })
    console.log(this.state.attached);
}
Museum(){
    this.setState({
        museumView:true,
        attachedView:false
    })
    console.log(this.state.attached);
}
enableModel(){
    this.setState({
        visib:true
    })

}
disableNo(){
    this.setState({
        visib:false
    })
}
deleteTrail(){
    var t_id=this.state.items;
    var user_id=this.state.userId;
    if(t_id==''){
       
    }else{
        fetch(`${apiURL}RestApi/deleteTrail`,{
            method: 'POST',
            headers: new Headers({
                       'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                    }),
            body: JSON.stringify({
                        entity_id:t_id,
                        user_id:user_id
                    }) 
        })   
        .then(res => res.json())
        .then(json => {
            if (json){
                this.setState({
                    visible:false
                })
                window.location.href=`/search/myTrails`
            }
            else{
            }
        });
    }
}

   render(){
       var items=this.state.items;
       var t_items=this.state.t_items;
       var e_items=this.state.e_items;
       var title=this.state.title;
       var keyword = this.props.keyword;
       const regex = /<[^>]*>/g;
       if (!this.state.visible){
        return  <div align="center"><Loader
        type="ThreeDots"
        color="#da3a50"
        height={100}
        width={100}
        
      //   timeout={10000} //3 secs   <Header></Header>

        align="center"
        
      /></div>;
      }
else {
    

    return (
    <div>
        <Modal visible={this.state.visib} width="375" height="100" effect="fadeInUp">
                    <div style={{width:'100%',height:'100%'}}>
                        <p style={{fontSize:'20px',marginLeft:'5%',marginTop:'3%',marginRight:'5%'}}>Are you sure you want to delete?</p>
                        <br/>
                        <div align="center" style={{fontSize:'14px',marginTop:'-8%'}}>
                        </div>  
                        <button onClick={()=>this.disableNo()} style={{padding:' 10px 20px',borderRadius:'5px',marginLeft:'28%'}}>No</button><button style={{marginLeft:'20px',color:'#ffffff',backgroundColor:'#e65667',padding:' 10px 20px',borderRadius:'5px'}} onClick={() => this.deleteTrail()}>Yes</button>                      
                    </div>
        </Modal>
        <Header></Header>
        {
             navigator.cookieEnabled &&   localStorage.getItem('User')?
              <section class="mainPlaceholder articlePlaceMain">
            {this.state.View==false?
                <section class="only-desktop articlePageTheme timelineText">
                    <div className="container" style={{marginTop:'-30px'}}>
                        <h3>Edit Trail  <a href={'/search/myTrails'} style={{color:'#e65667',fontSize:'15px'}}>My Trails</a> <a style={{fontSize:'15px'}}>|</a>  <a href={'/createTrail'} style={{color:'#e65667',fontSize:'15px'}}>Create New Trail</a></h3>
                        <div align="right" style={{width:'100%'}}>
                            <a style={{color:'#e65667'}} onClick={()=>this.view()}> Preview </a>
                            <img class="card-icon-img-library" src={icon} onClick={() => this.enableModel()} data-toggle="tooltip" title="Delete"/>
                        </div>
                        {items!=''?
                        <div class="row" style={{marginTop:'30px'}}>
                            <div class="col-md-3">
                            <label style={{color:'#a9a9a9',fontSize:'18px',marginTop:'5px'}}>
                                    <b>Title Slide</b>
                                </label><br/>
                                {
                                    t_items.map(t_item=>(
                                    <div  onClick={()=>this.titletab()} style={{listStyleType:"none",margin:'0',padding:'0',width:'70%',borderRight:'1px solid #a9a9a9'}}>
                                        <div id="title_slide" style={{width:'90%',borderWidth:'thick',borderStyle:'solid',backgroundColor:'#e65667',color:'#fbecec',height:'100px',borderRadius:'8px',overflow:'hidden',marginBottom:'20px'}}>
                                            <img style={{width:'100%',height:'100%'}} src={t_item.media_file} onError={(e)=>{e.target.onerror = null; e.target.src=`${BASE_API_URL}/assets/images/dimage.jpg`}} />
                                            <p style={{backgroundColor:'#000000',opacity:'0.8',marginTop:'-13%',overflow:'hidden'}}><a style={{color:'#ffffff',whiteSpace:'nowrap',textOverflow:'ellipsis',fontSize:'15px',width:'100%',overflow:'hidden'}}>{t_item.headline}</a></p>
                                        </div>
                                    </div>
                                    ))
                                }
                                <label style={{color:'#a9a9a9',fontSize:'18px',marginTop:'5px'}}>
                                    <b>Event Slides</b>
                                </label><br/>
                                <div class="row" style={{}}>
                                    <button className="form-control" onClick={() => this.openModal1()} style={{maxWidth:'60%',fontSize:'12px',backgroundColor:'#efefef',color:'#4a4a4a',borderColor:'#4a4a4a',borderStyle:'solid',borderWidth:'1px',height:'34px'}}> Import </button>
                                </div>
                                <div style={{borderColor:'#4a4a4a',borderStyle:'solid',borderRight:'1px',marginTop:'5px'}}>
                                {this.state.e_items[0].id==0?'':
                                <ReactSortable
        list={this.state.e_items}
        setList={(newState) => this.sort(newState)}
      >

                                    {
                                        e_items.map(e_item=>(
                                        e_item.id==0?'':
                                        <div key={e_item.id}>
                                        <div onClick={()=>this.slidetab(e_item.id)} style={{listStyleType:"none",margin:'0',padding:'0',width:'70%',borderRight:'1px solid #a9a9a9'}}>
                                            <img class="card-icon-img-library" src={icon} alt="Delete" align="right"  onClick={() => this.deletesideslide(e_item.id)} />
                                            <div id="title_slide" style={{width:'90%',borderWidth:'thick',borderStyle:'solid',backgroundColor:'#e65667',color:'#fbecec',height:'100px',borderRadius:'8px',overflow:'hidden',marginBottom:'20px'}}>
                                                {
                                                    e_item.media_type=='2'?
                                                    <img style={{width:'100%',height:'100%'}} src={e_item.media_file} onError={(e)=>{e.target.onerror = null; e.target.src=`${BASE_API_URL}/assets/images/dimage.jpg`}} />
                                                    :
                                                    <iframe src={e_item.media_file} scrolling="no" style={{width:'100%',height:'100%'}}></iframe>
                                                }
                                                {
                                                    e_item.media_type=='2'?   
                                                    <p style={{backgroundColor:'#000000',opacity:'0.8',marginTop:'-13%',overflow:'hidden'}}><a style={{color:'#ffffff',whiteSpace:'nowrap',textOverflow:'ellipsis',fontSize:'15px',width:'100%',overflow:'hidden'}}>{e_item.headline}</a></p>
                                                    :
                                                    <p style={{backgroundColor:'#000000',opacity:'0.8',marginTop:'-13%',overflow:'hidden'}}><a style={{color:'#ffffff',whiteSpace:'nowrap',textOverflow:'ellipsis',fontSize:'15px',width:'100%',overflow:'hidden'}}>{e_item.headline}</a></p>
                                                }        
                                            </div>
                                        </div>     
                                        </div>
                                        ))
                                    }
                                    </ReactSortable>
                                    }
                                </div>
                            </div>
                            {
                                this.state.visible2==false?
                                <div class="col-md-9" >
                                    <h5><b>Edit Title Slide</b></h5><br />
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label style={{color:'#a9a9a9',fontSize:'18px',marginTop:'10px'}}>
                                                <b>Media</b>
                                            </label>
                                            <div class="media-caps">
                                            <div class="media-container">   
                                                {
                                                    this.state.selectedFile==null?
                                                    <img class="media-image" src={`${BASE_API_URL}/assets/images/dimage.jpg`}/>
                                                    :
                                                    <img class="media-image" src={this.state.selectedFile}/>
                                                }
                                                <div class="media-middle">
                                                    <label style={{color:'#ffffff',fontSize:'13px',width:'100%'}} for="title_file-upload" class="custom-file-upload">
                                                        <img style={{width:'50%',marginLeft:'15%'}} src={cameraicon}/>
                                                        <br/>
                                                        Upload Image
                                                    </label>
                                                    <input style={{display:'none'}} type="file" accept="image/*" id="title_file-upload" name="title_file-upload" class="form-control" onChange={(event)=>this.onFileChange(event)} />
                                                </div>
                                            </div>
                                            </div>
                                        </div>        
                                        <div class="col-md-8">
                                            <label style={{color:'#a9a9a9',fontSize:'18px',marginTop:'5px'}}>
                                                <b>Headline</b>
                                            </label>
                                                {
                                                    t_items!=''?<input type="text" className="form-control" style={{width:'100%',marginTop:'5px',borderWidth:'thin',borderStyle:'solid',borderColor:'#ced4da'}} placeholder="" onChange={this.handleName} value={this.state.title}/>
                                                    :
                                                    <input type="text" className="form-control" style={{width:'100%',marginTop:'5px',borderWidth:'thin',borderStyle:'solid',borderColor:'#ced4da'}} placeholder="" onChange={this.handleName} value={this.state.title}/>
                                                }           
                                                <br />
                                                <label style={{color:'#a9a9a9',fontSize:'18px',marginTop:'10px'}}>
                                                    <b>Text</b>
                                                </label>
                                                <textarea className="form-control" style={{width:'100%'}} placeholder="" rows="7" value={this.state.text} onChange={this.handleTitleText}/>
                                                <br />
                                                <div className="mx-auto" style={{float:'right'}}>
                                                    <div style={{width:'110%',borderRadius:'10px'}} class="load-btn" onClick={() => this.titleSave()} >Save
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                                :
                                <div class="col-md-9" >
                                    <h5><b>Event Slide</b></h5><br />
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label style={{color:'#a9a9a9',fontSize:'18px',marginTop:'5px'}}>
                                                <b>Media</b>
                                            </label>    
                                                {
                                                    this.state.media!=''?
                                                    this.state.media_type=='1'?
                                                    <iframe src={this.state.media} scrolling="no" style={{width:'100%',height:'87%'}}></iframe>    
                                                    :
                                                    <img style={{height:'87%',width:'90%',position:'absolute'}} src={this.state.media} onError={(e)=>{e.target.onerror = null; e.target.src=`${BASE_API_URL}/assets/images/dimage.jpg`}} />
                                                    :
                                                    ''
                                                }
                                        </div>
                                        <div class="col-md-8">
                                            <label style={{color:'#a9a9a9',fontSize:'18px',marginTop:'5px'}}>
                                                <b>Headline</b>
                                            </label>
                                            {
                                                e_items!=''?<input type="text" className="form-control" disabled = "disabled" style={{width:'100%',marginTop:'5px',borderWidth:'thin',borderStyle:'solid',borderColor:'#ced4da'}} placeholder="" onChange={this.handleName} value={this.state.slide_title}/>
                                                :
                                                <input type="text" className="form-control"  disabled = "disabled" style={{width:'100%',marginTop:'5px',borderWidth:'thin',borderStyle:'solid',borderColor:'#ced4da'}} placeholder="" onChange={this.handleName} value={this.state.slide_title}/>
                                            }        
                                            <label style={{color:'#a9a9a9',fontSize:'18px',marginTop:'10px'}}>
                                                <b>Text</b>
                                            </label>
                                            {this.state.slide_text!=null?
                                                <textarea className="form-control" disabled="disabled" style={{width:'100%'}} placeholder="" rows="7" value={(this.state.slide_text.replace(/<[^>]+>/g, '')).replace(/((&nbsp;))*/gmi,'')} onChange={this.handleTitleText}/>
                                            :       <textarea className="form-control" disabled="disabled" style={{width:'100%'}} placeholder="" rows="7" value={this.state.slide_text} onChange={this.handleTitleText}/>
                                            }
                                            </div>
                                    </div>
                                    <br />
                                    <div className="mx-auto" style={{float:'right'}}>
                                        {
                                            this.state.new?
                                            <div style={{width:'110%',borderRadius:'10px'}} class="load-btn" onClick={() => this.eventSave()} >Save</div>
                                            :
                                            <div style={{width:'110%',borderRadius:'10px'}} class="load-btn" onClick={() => this.deleteAlert()} >Delete</div>
                                        }
                                    </div>
                                    <br />
                                </div>
                            }
                        </div>
                        :
                        " " 
                        }
                    </div>
                <Modal visible={this.state.visible3} width="400" height="120" effect="fadeInUp">
                    <div>
                        <div class="row">
                        <div style={{width:'10%'}}></div>
                        <input type="text" style={{width:'30%'}} placeholder="  Latitude" onChange={this.handleLat}/>
                        <div style={{width:'20%'}}></div>
                        <input type="text" style={{width:'30%'}} placeholder="  Longitude" onChange={this.handleLong}/>
                        </div>
                        <br/>
                        <div style={{marginLeft:'260px',fontSize:'14px',marginTop:'10px'}}><a style={{marginLeft:'30px',color:'#e65667'}} onClick={() => this.llSubmit()}>OK</a>
                        </div>
                        
                    </div>
                </Modal>
                
                <Modal visible={this.state.saveAlert} width="200" height="80" effect="fadeInUp">
                        <div>
                            <div class="" style={{marginTop:'5%'}} align="center" >
                            <p style={{fontSize:'18px'}}>Slide Saved</p>
                            </div>
                            <div style={{fontSize:'14px'}} align="center"><a style={{color:'#e65667'}} onClick={() => this.saveAlert()}>OK</a>
                            </div>
                            
                        </div>
                    </Modal>
                    <Modal visible={this.state.visibleDelete} width="350" height="120"  effect="fadeInUp"  onClickAway={() => this.deleteClose()}>
                        <div>
                            <div class="" style={{marginTop:'5%'}} align="center">
                            <p style={{fontSize:'18px'}}>Are you sure you want to delete? </p>
                            </div>
                            <div style={{fontSize:'14px'}} align="center">    
                            <a style={{borderRadius:'8px',padding:'1% 3%',backgroundColor:'#e65667',color:"#fff",fontSize:"16px"}} onClick={() => this.deleteSlide(this.state.slideId)}>Yes</a>&nbsp;&nbsp;&nbsp;    
                            <a style={{borderRadius:'8px',padding:'1% 3%',backgroundColor:'#e65667',color:"#fff",fontSize:"16px"}} onClick={() => this.deleteClose()}>No</a>    

                            </div>
                        </div>
                    </Modal>
                <Modal visible={this.state.visible1} width="90%" height="95%" effect="fadeInUp">
                <div class="col-md-12">
                <div style={{padding:'3%'}} align="right">
                        <a href="javascript:void(0);" style={{color:"grey"}} onClick={() => this.closeModal1()}>X</a>
                        </div>
                    <div className="row" style={{marginTop:'-9%'}}>
                    <h5 style={{margin:'3%'}}>Select Points for your Trail</h5>
                    <div>
                        
                    <div id="header-search" style={{marginLeft:'-5px',marginTop:'5.7%'}}>
                    <form id="search-form" onSubmit={e => { e.preventDefault(); }}>
                        <input type="text" name="keyword" placeholder='Search here...' onChange={this.updateInput} onKeyDown={(e) => { e.key === 'Enter' && this.handleSubmit() }}  defaultValue={keyword} />
                        <FiSearch id="search-form-icon" style={{display:"block",position: 
                        "absolute",top:"25px",right:"12px",fontsize: "20px  !important"}} onClick={() => this.handleSubmit()}></FiSearch>
                        {/* <img id="search-form-icon" src={search} alt="search"  /> */}
                    </form>
                    </div>
                    </div>
            <div className='row' style={{justifyContent:'center',width:'100%'}}>
                        <div class="filter-inputs-wrapper" style={{marginLeft:'-5px',marginTop:'0.7%'}}>
                        <div class="filter-select">
                            <div class="filter-label">Filter by</div>
                            <select class="import-select" value={this.state.projectFilter} onChange={this.handleType}>
                            <option value="">All Projects</option>
                            {this.state.projectList.map(project=>(
                                <option value={project.id}>{project.name}</option>
                            ))
                            }
                            </select>
                        </div>
                        </div>
                        <div class="filter-inputs-wrapper" style={{marginLeft:'-5px',marginTop:'0.7%',}}>
                        <div class="filter-select">
                        <div class="filter-label">  Language</div>
                            <select class="import-select" value={this.state.LangFilter} onChange={this.handlelang}>
                            <option value="">All Languages</option>
                            {this.state.langList.map(project=>(
                                <option value={project.id}>{project.language}</option>
                            ))
                            }
                            </select>
                            
                        </div>
                        </div>
                        <div class="filter-inputs-wrapper" style={{marginLeft:'-5px',marginTop:'0.7%'}}>
                        <div class="filter-select">
                            <select class="import-select" value={this.state.subdomainFilter} onChange={this.handlesub}>
                            <option value="">All Subdomains</option>
                            {this.state.subdomainList.map(project=>(
                                <option value={project.id}>{project.name}</option>
                            ))
                            }
                            </select>
                        </div>
                        </div>
                        </div>
                    </div>
                    <div >
                <main>
                    {this.state.load?
                    <div align="center" style={{width:'100%'}}><Loader
                    type="ThreeDots"
                    color="#da3a50"
                    height={100}
                    width={100}
                    align="center"
                  /></div>
                        :
                    <div className="row" style={{overflow:'scroll',height:'500px',width:'100%'}}>
                    {this.state.searchData.slice(0,this.state.display).map(it=>(
                <div class="blog-card" data-toggle="tooltip" title="Select" style={{cursor:'pointer'}} onClick={() => this.import(it.id,it.f_master_meta_entity_type,it.title,it.summary,it.lat_lng,it.thumbnail_file_path)}>
                    <img class="card-image2" src={it.thumbnail_file_path ? it.thumbnail_file_path : `${BASE_API_URL}/assets/images/dimage.jpg`} width="100%" height="236px" onError={(e)=>{e.target.onerror = null; e.target.src=`${BASE_API_URL}/assets/images/dimage.jpg`}}/>
                    <div class="card-left2">
                    <div class="card-title-library">
                    <LinesEllipsis
                            text={it.title.replace(regex,'').replace('&nbsp;',"").replace('&amp;apos;',"'").replace('&apos;',"'").replace('apos;',"'").replace('&amp;',"'")}
                            onReflow={this.handleReflow}
                            maxLine={2}
                            basedOn='words'
                        />
                    </div>
                    <div class="card-description-library">
                    {
                            it.summary==null?'':
                            <LinesEllipsis
                            text={it.summary.replace(regex,'').replace('&nbsp;',"").replace('&amp;apos;',"'").replace('&apos;',"'").replace('apos;',"'").replace('&amp;',"'")}
                            onReflow={this.handleReflow}
                            maxLine={3}
                            basedOn='words'
                        />}
                    </div>
                    </div>
                </div>
                ))}
                         {this.state.display < this.state.searchData.length &&
                        <div class="load-wrapper">
                        <div class="load-btn"  onClick={this.loadMore}>Load More</div>
                      </div>
            //  <button  type="button" className="load-more">Load more</button>
          }
                </div>
   }    
                </main>
            </div>
                    
                    </div>
                </Modal>
                    <Modal visible={this.state.attachedView} width="90%" height="95%" effect="fadeInUp">
                <div class="col-md-12">
                <div style={{padding:'3%'}} align="right">
                        <a href="javascript:void(0);" style={{color:"grey"}} onClick={() => this.closeAttached()}>X</a>
                        </div>
                    <div className="row" style={{marginTop:'-9%'}}>
                    <h5 style={{margin:'3%'}}>Import</h5>
                    </div>
                    <div class="row" style={{marginLeft:'3%',marginRight:'3%',width:'100%'}}>
                    <h5 style={{width:'15.5%'}}>Point of Interest (</h5>
                    <div style={{maxWidth:'30%',color:'#da3a50',whiteSpace:'nowrap',textOverflow:'ellipsis',overflow:'hidden'}}>{this.state.head.replace(regex,'').replace('&nbsp;',"").replace('&amp;apos;',"'").replace('&apos;',"'").replace('apos;',"'").replace('&amp;',"'")}</div>
                    <h5 style={{marginLeft:'0.5%'}}>) contains the following:</h5>
                    </div>
                    <p  style={{marginLeft:'3%',fontSize:'14px'}}>Select to attach to your trail</p>
                    <div class="searchList" >
                    <main style={{overflow:'auto',height:'70%'}}>
                    {
                    this.state.attached.map(its=>(
                    <div class="blog-card2" data-toggle="tooltip" title="Import" style={{cursor:'pointer'}} onClick={() => its.entity_type=='1'?this.importArticle(its.id,1,its.title,its.summary,its.banner_image[0]):its.entity_type=='7'?this.importMuseum(its.id,7,its.title,its.summary,its.banner_image[0]):its.entity_type=='23'?this.importVideo(its.id,23,its.title,its.summary,its.thumbnail_url[0]):its.entity_type=='29'?this.importGallery(its.id,29,its.title,its.summary,its.thumbnail_url[0]):''}>
                        <div class="card-left-search">
                            <div class="card-type-search">
                                {its.entity_type=='1'?<>Article</>
                                :
                                its.entity_type=='7'?<>Museum</>
                                :
                                its.entity_type=='23'?<>Video</>
                                :
                                its.entity_type=='29'?<>Image Gallery</>
                                :
                                ''}
                            </div>
                            <div class="card-title-search">
                                {its.title}
                            </div>
                            <div class="card-description-search">
                                {renderHTML(its.summary)}
                            </div>
                            <div class="card-icons-search">
                            
                            </div>
                        </div>
                        <div class="image-size" style={{width:"300px"}}>
                            {
                            its.entity_type=='23'? <img class="card-image-search" src={its.thumbnail_url? its.thumbnail_url[0] : `${BASE_API_URL}/assets/images/dimage.jpg`} />   
                            :
                            <img class="card-image-search" src={its.thumbnail_url? its.thumbnail_url[0] : `${BASE_API_URL}/assets/images/dimage.jpg`} />
                            }
                            </div>
                        </div>
                    ))}
                </main>
            </div>
                    
                    </div>
                </Modal>
                </section>
                :
                <section class="only-desktop articlePageTheme timelineText">
                <div className="container" style={{marginTop:'-30px'}}>
                    <h3>View Trail  <a href={'/search/myTrails'} style={{color:'#e65667',fontSize:'15px'}}>My Trails</a> <a style={{fontSize:'15px'}}>|</a>  <a href={'/createTrail'} style={{color:'#e65667',fontSize:'15px'}}>Create New Trail</a></h3>
                    <div align="right" style={{width:'100%'}}>
                    <a style={{width:'100%',color:'#e65667'}} onClick={()=>this.view()}>Back to Edit </a>
                    </div>
                    <iframe  style={{width:'100%',height:'550px',margin:'o auto'}} src={`${BASE_API_URL}blogs/crowdsource_trail?id=${this.state.items}&user_id=${this.state.userId}`} alt="" ></iframe>
                    </div>
                    </section>
                }
                {
                    this.state.View==false?
                    <section class="only-mobile articlePageTheme timelineText">
                        <div className="container" style={{marginTop:'-30px'}}>
                            <h3 style={{fontSize:'22px'}}>Edit Trail</h3>
                            <div align="right" style={{width:'100%',marginTop:'-10%'}}>
                                <a style={{color:'#e65667'}} onClick={()=>this.view()}>Preview </a>
                            </div>
                            {items!=''?
                            <div class="row" style={{marginTop:'40px'}}>
                                <div class="col-md-3" align="center" style={{overflow:'scroll'}}>
                                <label style={{color:'#a9a9a9',fontSize:'18px',marginTop:'5%'}}>
                                        <b>Title Slide</b>
                                    </label><br/>
                                 
                                    {
                                        t_items.map(t_item=>(
                                        <div  onClick={()=>this.titletab()}>
                                            <div style={{width:'90%',borderWidth:'thick',borderStyle:'solid',backgroundColor:'#fbecec',color:'#fbecec',height:'170px',borderRadius:'8px',overflow:'hidden',position:'relative',marginBottom:'8%'}}>
                                                <img style={{width:'100%',height:'100%'}} src={t_item.media_file} onError={(e)=>{e.target.onerror = null; e.target.src=`${BASE_API_URL}/assets/images/dimage.jpg`}} />
                                                <p style={{backgroundColor:'#000000',opacity:'0.8',width:'100%',position:'absolute',top:'85%',height:'20%',overflow:'hidden'}}><a style={{color:'#ffffff',whiteSpace:'nowrap',textOverflow:'ellipsis',fontSize:'18px',width:'100%',overflow:'hidden'}}>{t_item.headline}</a></p>
                                            </div>
                                        </div>
                                        ))
                                    }
                                    <div class="row" style={{width:'100%'}}>
                                    <label style={{color:'#a9a9a9',fontSize:'18px',marginTop:'5%'}}>
                                        <b> Slides</b>
                                    </label><br/>
                                        <button className="form-control" onClick={() => this.openModal1()} style={{maxWidth:'50%',fontSize:'18px',backgroundColor:'#efefef',marginLeft:'15%',color:'#4a4a4a',borderColor:'#4a4a4a',borderStyle:'solid',borderWidth:'1px',height:'10%',marginTop:'3%'}}> Import </button>
                                    </div>
                                    <div style={{marginTop:'5px'}}>
                                    {this.state.e_items[0].id==0?'':
                                    <ReactSortable
        list={this.state.e_items}
        setList={(newState) => this.sort(newState)}
      >
                                        {
                                            e_items.map(e_item=>(
                                            e_item.id==0?'':
                                            <div key={e_item.id}>
                                            <div  style={{listStyleType:"none",margin:'0',padding:'0',width:'100%',height:'20%'}}>
                                                <img class="card-icon-img-library"  src={icon} alt="" style={{position:'relative',left:'45%',width:'6%'}}   onClick={() => this.deletesideslide(e_item.id)} />
                                                <div onClick={()=>this.slidetab(e_item.id)} style={{width:'90%',borderWidth:'thick',borderStyle:'solid',backgroundColor:'#fbecec',color:'#fbecec',height:'170px',borderRadius:'8px',overflow:'hidden',marginBottom:'8%',position:'relative'}}>
                                                    {
                                                        e_item.media_type=='2'?
                                                        <img style={{width:'100%',height:'100%'}} src={e_item.media_file} onError={(e)=>{e.target.onerror = null; e.target.src=`${BASE_API_URL}/assets/images/dimage.jpg`}} />
                                                        :
                                                        <iframe src={e_item.media_file} scrolling="no" style={{width:'100%',height:'100%'}}></iframe>
                                                    }
                                                   <p onClick={()=>this.slidetab(e_item.id)} style={{backgroundColor:'#000000',opacity:'0.8',width:'100%',position:'absolute',overflow:'hidden',height:'20%',top:'85%'}}><a style={{color:'#ffffff',whiteSpace:'nowrap',textOverflow:'ellipsis',fontSize:'18px',width:'100%',overflow:'hidden'}}>{e_item.headline}</a></p>
                                                             
                                                </div>
                                               
                                            </div>  
                                            </div>  
                                            
                                              
                                            ))
                                        }
                                        </ReactSortable>
                                        }
                                    </div>
                                </div>
                                
                            </div>
                            :
                            " " 
                            }
                        </div>
                    <Modal visible={this.state.visible3} width="400" height="120" effect="fadeInUp">
                        <div>
                            <div class="row" style={{marginTop:'5%'}}>
                            <div style={{width:'10%'}}></div>
                            <input type="number" style={{width:'30%'}} placeholder="  Latitude" onChange={this.handleLat}/>
                            <div style={{width:'20%'}}></div>
                            <input type="number" style={{width:'30%'}} placeholder="  Longitude" onChange={this.handleLong}/>
                            </div>
                            <br/>
                            <div style={{marginLeft:'50%',fontSize:'14px',marginTop:'2%'}}><a style={{color:'#e65667'}} onClick={() => this.llSubmit()}>OK</a>
                            </div>
                            
                        </div>
                    </Modal>
                    <Modal visible={this.state.saveAlert} width="200" height="80"  effect="fadeInUp">
                        <div>
                            <div class="" style={{marginTop:'5%'}} align="center">
                            <p style={{fontSize:'18px'}}>Slide Saved</p>
                            </div>
                            <div style={{fontSize:'14px'}} align="center"><a style={{color:'#e65667'}} onClick={() => this.saveAlert()}>OK</a>
                            </div>
                            
                        </div>
                    </Modal>
                    <Modal visible={this.state.visibleDelete} width="350" height="120"  effect="fadeInUp"  onClickAway={() => this.deleteClose()}>
                        <div>
                            <div class="" style={{marginTop:'5%'}} align="center">
                            <p style={{fontSize:'18px'}}>Are you sure you want to delete? </p>
                            </div>
                            <div style={{fontSize:'14px'}} align="center">    
                            <a style={{borderRadius:'8px',padding:'1% 3%',backgroundColor:'#e65667',color:"#fff",fontSize:"16px"}} onClick={() => this.deleteSlide(this.state.slideId)}>Yes</a>&nbsp;&nbsp;&nbsp;    
                            <a style={{borderRadius:'8px',padding:'1% 3%',backgroundColor:'#e65667',color:"#fff",fontSize:"16px"}} onClick={() => this.deleteClose()}>No</a>    

                            </div>
                        </div>
                    </Modal>
                    
                    <Modal visible={this.state.slideView} width="90%" height="90%" effect="fadeInUp">
                        <div>
                            {
                                this.state.visible2==false?
                                <div style={{margin:'5%'}}>
                                    <h5><b>Edit Title Slide</b></h5>
                                    <div align="right" style={{width:'100%'}}>
                                        <a href="javascript:void(0);" onClick={() => this.closeModal1()} style={{color:"grey",marginTop:'-10%'}}>X</a>
                                    </div>
                                    <label style={{color:'#a9a9a9',fontSize:'18px',marginTop:'3%'}}>
                                        <b>Media</b>
                                    </label>
                                    <div style={{width:'100%',height:'30%',marginLeft:'20%',marginRight:'20%'}}> 
                                        <div class="media-caps">
                                        <div class="media-container">   
                                            {
                                                this.state.selectedFile==null?
                                                <img class="media-image" src={`${BASE_API_URL}/assets/images/dimage.jpg`}/>
                                                :
                                                <img class="media-image" src={this.state.selectedFile}/>
                                            }
                                            <div class="media-middle">
                                                <label style={{color:'#ffffff',fontSize:'13px'}} for="title_file-upload" class="custom-file-upload">
                                                    <img style={{width:'50%',marginLeft:'15%',marginTop:'20%'}} src={cameraicon}/>
                                                    <p style={{fontSize:'15px',width:'150%',marginLeft:'-20%',marginTop:'20%'}}>Upload Image</p>
                                                </label>
                                                <input style={{display:'none'}} type="file" accept="image/*" id="title_file-upload" name="title_file-upload" class="form-control" onChange={(event)=>this.onFileChange(event)} />
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <label style={{color:'#a9a9a9',fontSize:'18px',marginTop:'5%'}}>
                                        <b>Headline</b>
                                    </label>
                                    {
                                        t_items!=''?<input type="text" className="form-control" style={{width:'100%',marginTop:'1%',borderWidth:'thin',borderStyle:'solid',borderColor:'#ced4da'}} placeholder="" onChange={this.handleName} value={this.state.title}/>
                                        :
                                        <input type="text" className="form-control" style={{width:'100%',marginTop:'1%',borderWidth:'thin',borderStyle:'solid',borderColor:'#ced4da'}} placeholder="" onChange={this.handleName} value={this.state.title}/>
                                    }
                                    <label style={{color:'#a9a9a9',fontSize:'18px',marginTop:'5%'}}>
                                        <b>Text</b>
                                    </label>
                                    <textarea className="form-control" style={{width:'100%'}} placeholder="" rows="3" value={this.state.text} onChange={this.handleTitleText}/>
                                    <br />
                                    <div className="mx-auto" style={{float:'right'}}>
                                        <div style={{width:'110%',borderRadius:'15%'}} class="load-btn" onClick={() => this.titleSave()} >
                                            Save
                                        </div>
                                    </div>
                                </div>
                                :
                                <div style={{margin:'5%'}}>
                                    <h5><b>Event Slide</b></h5>
                                    <div align="right" style={{width:'100%'}}>
                                        <a href="javascript:void(0);" onClick={() => this.closeModal1()} style={{color:"grey",marginTop:'-10%'}}>X</a>
                                    </div>
                                    <label style={{color:'#a9a9a9',fontSize:'18px',marginTop:'3%'}}>
                                        <b>Media</b>
                                    </label>
                                    <div style={{width:'100%',height:'30%',marginLeft:'20%',marginRight:'20%'}}>
                                    <div class="media-container">
                                    {
                                        this.state.media!=''?
                                        this.state.media_type=='1'?
                                        <iframe src={this.state.media} scrolling="no" style={{width:'100%',height:'100%'}}></iframe>    
                                        :
                                        <img class="media-image1" src={this.state.media} onError={(e)=>{e.target.onerror = null; e.target.src=`${BASE_API_URL}/assets/images/dimage.jpg`}} />
                                        :
                                        ''
                                    }
                                    </div>
                                    </div>
                                    <label style={{color:'#a9a9a9',fontSize:'18px',marginTop:'5%'}}>
                                        <b>Headline</b>
                                    </label>   
                                    {
                                        e_items!=''?<input type="text" className="form-control" disabled = "disabled" style={{width:'100%',marginTop:'1%',borderWidth:'thin',borderStyle:'solid',borderColor:'#ced4da'}} placeholder="" onChange={this.handleName} value={this.state.slide_title}/>
                                        :
                                        <input type="text" className="form-control"  disabled = "disabled" style={{width:'100%',marginTop:'1%',borderWidth:'thin',borderStyle:'solid',borderColor:'#ced4da'}} placeholder="" onChange={this.handleName} value={this.state.slide_title}/>
                                    } 
                                    <label style={{color:'#a9a9a9',fontSize:'18px',marginTop:'5%'}}>
                                        <b>Text</b>
                                    </label>
                                    {this.state.slide_text!=null?
                                        <textarea className="form-control" disabled="disabled" style={{width:'100%'}} placeholder="" rows="3" value={(this.state.slide_text.replace(/<[^>]+>/g, '')).replace(/((&nbsp;))*/gmi,'')} onChange={this.handleTitleText}/>
                                        :          <textarea className="form-control" disabled="disabled" style={{width:'100%'}} placeholder="" rows="3" value={this.state.slide_text} onChange={this.handleTitleText}/>
                                    }
                                    <br />
                                    <div className="mx-auto" style={{float:'right'}}>
                                        {
                                            this.state.new?
                                            <div style={{width:'110%',borderRadius:'15%'}} class="load-btn" onClick={() => this.eventSave()} >Save</div>
                                            :
                                            <div style={{width:'110%',borderRadius:'15%'}} class="load-btn" onClick={() => this.deleteAlert()} >Delete</div>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </Modal>
                    <Modal visible={this.state.visible1} width="90%" height="95%" effect="fadeInUp">
                    <div class="col-md-12">
                    <div style={{padding:'3%'}} align="right">
                            <a href="javascript:void(0);" style={{color:"grey",marginTop:'5%'}} onClick={() => this.closeModal1()}>X</a>
                            </div>
                        <div style={{marginTop:'-14%'}}>
                        <h6 style={{margin:'3.8%'}}>Select Points for your Trail</h6>
                        <div style={{width:'100%'}}>
                        <form id="search-form" onSubmit={e => { e.preventDefault(); }}>
                        <input type="text" name="keyword" placeholder='Search here...' onChange={this.updateInput} onKeyDown={(e) => { e.key === 'Enter' && this.handleSubmit() }}  defaultValue={keyword} />
                        <FiSearch id="search-form-icon" style={{display:"block",position: "absolute",top:"25px",right:"12px",fontsize: "20px  !important"}} onClick={() => this.handleSubmit()}></FiSearch>
                        {/* <img id="search-form-icon" src={search} alt="search"  /> */}
                    </form>
                        <div class="filter-label" style={{marginLeft:'3.8%'}}>Filter by</div>
                            <div class="row"  style={{width:'100%',marginLeft:'3.8%',marginRight:'3.8%'}}>
                            <div class="filter-inputs-wrapper" style={{width:'40%',marginTop:'0.7%'}}>
                            <div class="filter-select" style={{}}>              
                                <select class="import-select" value={this.state.projectFilter} onChange={this.handleType}>
                            <option value="">All Projects</option>
                            {this.state.projectList.map(project=>(
                                <option value={project.id}>{project.name}</option>
                            ))
                            }      </select>
                            </div>
                            </div>
                            <div style={{width:'10%'}}></div>
                            <div class="filter-inputs-wrapper" style={{width:'40%',marginTop:'0.7%'}}>
                            <div class="filter-select" style={{}}>
                            <select class="import-select" value={this.state.subdomainFilter} onChange={this.handlesub}>
                            <option value="">All Subdomains</option>
                            {this.state.subdomainList.map(project=>(
                                <option value={project.id}>{project.name}</option>
                            ))
                            }
                            </select>
                            </div>
                            </div>
                            </div>
                            <div class="row"  style={{width:'100%',marginLeft:'3.8%',marginRight:'3.8%'}}>
                            <div class="filter-label" style={{marginLeft:'3.8%',marginTop:'1.5%'}}>Language</div>
                            <div style={{width:'10%'}}></div>
                            <div class="filter-inputs-wrapper" style={{width:'40%',marginLeft:'-15px',marginTop:'0.7%'}}>
                        <div class="filter-select">
                            <select class="import-select" value={this.state.LangFilter} onChange={this.handlelang}>
                                <option value="">All Languages</option>
                            {this.state.langList.map(project=>(
                                <option value={project.id}>{project.language}</option>
                            ))
                            }
                            </select>
                        </div>
                        </div>
                        </div>
                            </div> 
                        </div>
                        <div >
                    <main>
                        {this.state.load?
                        <div align="center" style={{width:'100%'}}><Loader
                        type="ThreeDots"
                        color="#da3a50"
                        height={100}
                        width={100}
                        align="center"
                      /></div>
                            :
                        <div className="row" style={{overflow:'auto',height:'360px',width:'100%',marginRight:'5%',marginLeft:'5%'}}>
                        {this.state.searchData.slice(0,this.state.display).map(it=>(
                    <div class="blog-card" data-toggle="tooltip" title="Select" style={{cursor:'pointer'}} onClick={() => this.import(it.id,it.f_master_meta_entity_type,it.title,it.summary,it.lat_lng,it.thumbnail_file_path)}>
                        <img class="card-image2" src={it.thumbnail_file_path ? it.thumbnail_file_path  : `${BASE_API_URL}/assets/images/dimage.jpg`} onError={(e)=>{e.target.onerror = null; e.target.src=`${BASE_API_URL}/assets/images/dimage.jpg`}} width="100%" height="230px"/>
                        <div class="card-left2">
                        <div class="card-title-library">
                            <LinesEllipsis
                            text={it.title.replace(regex,'').replace('&nbsp;',"").replace('&amp;apos;',"'").replace('&apos;',"'").replace('apos;',"'").replace('&amp;',"'")}
                            onReflow={this.handleReflow}
                            maxLine={2}
                            basedOn='words'
                        />
                        </div>
                        <div class="card-description-library">
                            {
                            it.summary==null?'':
                            <LinesEllipsis
                            text={it.summary.replace(regex,'').replace('&nbsp;',"").replace('&amp;apos;',"'").replace('&apos;',"'").replace('apos;',"'").replace('&amp;',"'")}
                            onReflow={this.handleReflow}
                            maxLine={3}
                            basedOn='words'
                        />}
                        </div>
                        </div>
                    </div>
                    ))}
                             {this.state.display < this.state.searchData.length &&
                            <div class="load-wrapper">
                            <div class="load-btn"  onClick={this.loadMore}>Load More</div>
                          </div>
                //  <button  type="button" className="load-more">Load more</button>
              }
                    </div>
       }    
                    </main>
                </div>
                        
                        </div>
                    </Modal>
                    <Modal visible={false} width="90%" height="160"  effect="fadeInUp"  onClickAway={() => this.closeModal()}>
                        <div>
                        <div style={{padding:'3%'}} align="right">
                        <a href="javascript:void(0);" style={{color:"grey"}} onClick={() => this.closeattachModal()}>X</a>
                        </div>
                            <div class="" style={{marginTop:'-7%'}} align="center">
                            <p style={{fontSize:'18px'}}>Import </p>
                            </div>
                            <h6 style={{width:'15.5%'}}>Point of Interest (</h6>
                            
                            <div class="row" style={{fontSize:'14px',marginTop:'7%',margin:'3%'}} align="center">    
                            <div style={{marginLeft:'2%',display:'block'}}>
                            <a class="btn btn-info shadow" style={{borderColor:'#e65667',backgroundColor:"#e65667",color:'#fff'}} onClick={() => this.POI()}>POI</a>
                            </div>
                            {
                                this.state.attachedList.map(i=>
                                <div style={{marginLeft:'2%',display:'block'}}>
                                    {
                                    i=='1'?<a class="btn btn-info shadow" style={{borderColor:'#e65667',backgroundColor:"#e65667",color:'#fff'}} onClick={() => this.Article()}>Article</a>
                                    :
                                    i=='7'?<a class="btn btn-info shadow" style={{borderColor:'#e65667',backgroundColor:"#e65667",color:'#fff'}} onClick={() => this.Museum()}>Museum</a>
                                    :
                                    i=='23'?<a class="btn btn-info shadow" style={{borderColor:'#e65667',backgroundColor:"#e65667",color:'#fff'}} onClick={() => this.Video()}>Video</a>
                                    :
                                    i=='29'?<a class="btn btn-info shadow" style={{borderColor:'#e65667',backgroundColor:"#e65667",color:'#fff'}} onClick={() => this.Gallery()}>Image Gallery</a>
                                    :
                                    ''
                                    }
                                </div>)
                            }
                            </div>
                        </div>
                    </Modal>
                    <Modal visible={this.state.attachedView} width="90%" height="95%" effect="fadeInUp">
                    <div class="col-md-12">
                <div style={{padding:'3%'}} align="right">
                        <a href="javascript:void(0);" style={{color:"grey"}} onClick={() => this.closeAttached()}>X</a>
                        </div>
                    <div className="row" style={{marginTop:'-9%'}}>
                    <h5 style={{margin:'3%'}}>Import</h5>
                    </div>
                    <div class="row" style={{marginLeft:'3%',marginRight:'3%',width:'100%',fontSize:'12px'}}>
                    <h5 style={{width:'38%',fontSize:'12px'}}>Point of Interest (</h5>
                    <div style={{maxWidth:'50%',color:'#da3a50',whiteSpace:'nowrap',textOverflow:'ellipsis',overflow:'hidden',fontSize:'12px'}}>{this.state.head.replace(regex,'').replace('&nbsp;',"").replace('&amp;apos;',"'").replace('&apos;',"'").replace('apos;',"'").replace('&amp;',"'")}</div>)
                    <h5 style={{fontSize:'12px',marginLeft:'0.5%'}}> contains the following:</h5>
                    </div>
                    <p  style={{marginLeft:'3%',fontSize:'10px'}}>Select to attach to your trail</p>
                    <div class="searchList" >
                    <main style={{overflow:'auto',height:'450px'}}>
                    {
                    this.state.attached.map(its=>(
                    <div class="blog-card2" data-toggle="tooltip" title="Import" style={{cursor:'pointer'}} onClick={() => its.entity_type=='1'?this.importArticle(its.id,1,its.title,its.summary,its.banner_image[0]):its.entity_type=='7'?this.importMuseum(its.id,7,its.title,its.summary,its.banner_image[0]):its.entity_type=='23'?this.importVideo(its.id,23,its.title,its.summary,its.thumbnail_url[0]):its.entity_type=='29'?this.importGallery(its.id,29,its.title,its.summary,its.thumbnail_url[0]):''}>
                        <div class="card-left-search">
                            <div class="card-type-search">
                                {its.entity_type=='1'?<>Article</>
                                :
                                its.entity_type=='7'?<>Museum</>
                                :
                                its.entity_type=='23'?<>Video</>
                                :
                                its.entity_type=='29'?<>Image Gallery</>
                                :
                                ''}
                            </div>
                            <div class="card-title-search">
                                {its.title}
                            </div>
                            <div class="card-description-search">
                                {renderHTML(its.summary)}
                            </div>
                            <div class="card-icons-search">
                            
                            </div>
                        </div>
                        <div class="image-size" style={{width:"300px"}}>
                            {
                            its.entity_type=='23'? <img class="card-image-search" src={its.thumbnail_url? its.thumbnail_url : `${BASE_API_URL}/assets/images/dimage.jpg`} />   
                            :
                            <img class="card-image-search" src={its.thumbnail_url? its.thumbnail_url : `${BASE_API_URL}/assets/images/dimage.jpg`} />
                            }
                            </div>
                        </div>
                    ))}
                </main>
            </div>
                    
                    </div>
                </Modal>
                    </section>
                    :
                    <section class="only-mobile articlePageTheme timelineText">
                    <div className="container" style={{marginTop:'-30px'}}>
                        <h3 style={{fontSize:'22px'}}>View Trail</h3>
                        <div align="right" style={{width:'100%',marginTop:'-10%',marginBottom:'2%'}}>
                        <a style={{color:'#e65667'}} onClick={()=>this.view()}>Back to Edit </a>
                        </div>
                        <iframe  style={{width:'100%',height:'100%',margin:'o auto'}} src={`${BASE_API_URL}blogs/crowdsource_trail?id=${this.state.items}&user_id=${this.state.userId}`} alt="" ></iframe>
                        </div>
                        </section>
                }
                <Footer />
            </section>
                :
                <Modal visible={true} width="800" height="550" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                    <div  style={{backgroundColor:"#e2e2e2"}}>
                        <div class="row" style={{marginLeft:"0px"}}>
                            <div class="col-md-6" style={{backgroundColor:"#fff"}}>
                            </div>
                            <div class="col-md-6" align="right" style={{color:"grey",padding:"10px 30px",fontSize:"20px"}}>
                                <a href="javascript:void(0);" style={{color:"grey"}} onClick={() => this.closeModal()}>X</a>
                            </div>
                        </div>
                        <Signin bookID="" bookType="" rel="" unbook="" created="" close={() => this.closeModal()}></Signin>
                    </div>
                </Modal>
}

        </div>
    );
            }
}
    }
