import { actionTypes } from '../actions/action';

const INITIAL_STATE = {
    savedPost: []
}

const userReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case actionTypes.user.SET_POST: return {
            ...state,
            savedPost: action.payload.allPosts
        };

        case actionTypes.user.ADD_POST:  return {
            ...state,
            savedPost: [...state.savedPost, action.payload.savedPost]
        };
        
        case actionTypes.user.REMOVE_POST: return {
            ...state,
            savedPost:  state.savedPost.filter(post => post.Id !== action.payload.postId)
        };
        default: return state;
    }
}

export default userReducer;