import axios from 'axios';
import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import Loader from "react-loader-spinner";
import renderHTML from 'react-render-html';
import { ReactSortable } from "react-sortablejs";
import apiURL from '../../components/url';
import { BASE_API_URL } from '../../services/apiService';
//import Footer from '../footer';
import Header from '../header2';
import cameraicon from '../images/camera.png';
import icon2 from '../images/icon-1.png';
import icon from '../images/icon-2.png';
import Signin from '../signin2';
import './trailsStyle.css';
export default class CreateTrial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username:"",
            password:"",
            message: '',
            uname:'',
            items:[],
            signModal:false,
            searchData:[],
            View:false,
            t_items:[],
            e_items:[
                {
                    "id":"0",
                }
            ],
            visible:true,
            visible1:false,
            visible2:false,
            visible3:false,
            message1:'',
            message2:'',
            isChecked:false,
            date:'',
            slideId:'',
            title:'',
            text:'',
            slide_title:'',
            slide_text:'',
            type:15,
            message:'',
            entity_type:'',
            userId:localStorage.getItem('User'),
            media:'',
            media_type:'',
            lat:'',
            long:'',
            display:6,
            load:true,
            new:true,
            selectedFile:null,
            sendFile:null,
            slideView:false
                } 
        this.titleSubmit=this.titleSubmit.bind(this);
        this.titleSave=this.titleSave.bind(this);
        this.eventSave=this.eventSave.bind(this);
        this.titleslide=this.titleslide.bind(this);
        this.eventslide=this.eventslide.bind(this);
        this.loadMore = this.loadMore.bind(this);
    }
    loadMore() {
        this.setState((prev) => {
          return {display: prev.display + 6};
        });
      }
      onFileChange(event){
    
        // Update the state
        console.log(event);
        this.setState({ 
            selectedFile:  URL.createObjectURL(event.target.files[0]),
            sendFile: event.target.files[0]
        });
      
      }
    titleSubmit(){
        var title=this.state.title;
        var userId=this.state.userId;
        if(title==''){
            this.setState({
                message:"Please Enter the Title",    
            })
        }else{
         fetch(`${apiURL}RestApi/createTrail?title=${title}&userID=${userId}`)   
         .then(res => res.json())
         .then(json => {
             if (json){
                 this.setState({
                     items: json,
                     visible:false
             })
             this.titleslide();
             window.location.href=`/editTrail?id=${json}`;
         }
         else{
             this.setState({
                 massage:"Something Went Worng",
     
             })
         }
         });
        }
    }
    titleSave(){
        var id=this.state.items;
        var title=this.state.title;
        var text=this.state.text;
        var userId=this.state.userId;
        if(title==''){
            this.setState({
                message:"Please Enter the Title",    
            })
        }else{
      const formData = new FormData();
    
      // Update the formData object
      formData.append('id',id);
      formData.append('title',title);
      formData.append('text',text);
      formData.append('userID',userId);
      formData.append('file',this.state.sendFile);
      axios.post(`${apiURL}RestApi/saveTitleTrail1`, formData)
      .then((response) => {
        this.titleslide();
        

      }, (error) => {
        this.titleslide();
      });
        }
    }
    eventSave(){
        var t_id=this.state.items;
        var title=this.state.slide_title;
        var text=this.state.slide_text;
        var userId=this.state.userId;
        var media=this.state.media;
        var media_type=this.state.media_type;
        var lat=this.state.lat;
        var long=this.state.long;
        if(title==''){
            this.setState({
                message:"Please Enter the Title",    
            })
        }else{
         //fetch(`${apiURL}RestApi/saveEventTrail?entity_id=${t_id}&title=${title}&text=${text}&userID=${userId}`)   
         fetch(`${apiURL}RestApi/saveEventTrail`,{
            method: 'POST',
            headers: new Headers({
                       'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
              }),
            body: JSON.stringify({
                entity_id:t_id,
                title:title,
                text:text,
                userID:userId,
                media_url:media,
                media_type:media_type,
                lat:lat,
                long:long
             }) 
         })   
         .then(res => res.json())
         .then(json => {
             if (json){
                this.setState({
                    slideId:json,  
                    new:false  
                })
             this.eventslide();
         }
         else{
             this.setState({
                 massage:"Something Went Worng",
     
             })
         }
         });
        }
    }
    titleslide(){
        var id=this.state.items;
        var userId=this.state.userId;
        fetch(`${apiURL}RestApi/titleSlide?id=${id}&userID=${userId}`)   
        .then(res => res.json())
        .then(json => {
            if (json){
                this.setState({
                    t_items: json,
                    title:json[0].headline,
                    selectedFile:json[0].media_file
            })
            if(json[0].text!=null){
                this.setState({
                    text:json[0].text.replace(/<[^>]+>/g, '').replace(/((&nbsp;))*/gmi,''),
                })
            }
            this.eventslide(id);
            
        }
        else{
            this.setState({
                massage:"Something Went Worng",
    
            })
        }
        });
    }
    eventslide(id){
        var id=this.state.items;
        var userId=this.state.userId;
        fetch(`${apiURL}RestApi/getSlideCs?id=${id}&userID=${userId}`)  
        .then(res => res.json())
        .then(json => {
            if (json){
                this.setState({
                    e_items: json,
                    visible:true
            })
            
        }
        else{
            this.setState({
                massage:"Something Went Worng",
    
            })
        }
        });
    }
        openModal() {
            this.setState({
                visible : true
            });
        }
        
        closeModal() {
            this.setState({
                visible : false
            });
        }
        openModal1() {
            this.setState({
                visible1 : true
            });
            var a="15";
            this.searchData(a);
        }
        
        closeModal1() {
            this.setState({
                visible1 : false
            });
        }   
   // if (isLoadedCount.current < 2) {
   //     return <LoaderScreen />
   // }
  
searchData(type){
   
      fetch(`${BASE_API_URL}project/importDetails?type=${type}`)   
    .then(res => res.json())
    .then(json => {
        if (json){
            this.setState({
                isLoaded: true,
                searchData: json,
                noData:false,
                load:false
        })
    }
    else{
        this.setState({
            isLoaded: true,
            searchData:[],
            noData:true,
            load:true

        })
    }
});		
}
   handleName = event => {
    this.setState({title: event.target.value });
}
handleLat = event => {
    this.setState({lat: event.target.value });
}
handleLong = event => {
    this.setState({long: event.target.value });
}
handleTitleText = event => {
    this.setState({text: event.target.value });
}
handleType = event => {
    this.setState({type: event.target.value });
    this.setState({display:6,load:true});
     this.searchData(event.target.value);
}
componentDidMount(){
    var a=this.state.type;
}
importPOI(id,type,title,summary,lat,long){
    this.setState({
        visible1 : false,
        visible2 : true,
        entity_type:type,
        slide_title: title,
        slide_text: summary,
        lat:lat,
        long:long,
        media:apiURL+'assets/Map/map.html?token=jzp91qr6tdrkbol7qd35fonchhru23rk&lat='+lat+'&lng='+long+'&info='+title+'&popupOpen=true&iconUrl=https://maps.mapmyindia.com/images/to.png',
        media_type:1,
        new:true
    })
    
}
importArticle(id,type,title,summary,banner){
    this.setState({
        visible1 : false,
        visible2 : true,
        visible3 : true,
        entity_type:type,
        slide_title: title,
        slide_text: summary,
        media:banner,
        new:true,
        media_type:2
    })
    
}
importMuseum(id,type,title,summary,banner){
    this.setState({
        visible1 : false,
        visible2 : true,
        visible3 : true,
        entity_type:type,
        slide_title: title,
        slide_text: summary,
        media:banner,
        new:true,
        media_type:2
    })
    
}
importGallery(id,type,title,summary,banner){
    this.setState({
        visible1 : false,
        visible2 : true,
        visible3 : true,
        entity_type:type,
        slide_title: title,
        slide_text: summary,
        media:banner,
        new:true,
        media_type:1
    })
    
}
importVideo(id,type,title,summary,banner){
    this.setState({
        visible1 : false,
        visible2 : true,
        visible3 : true,
        entity_type:type,
        slide_title: title,
        slide_text: summary,
        media:banner,
        new:true,
        media_type:1
    })
    
}
deleteSlide(id){
    var t_id=this.state.items;
    if(id==''){
        this.setState({
            message:"Please Enter the Title",    
        })
    }else{
     //fetch(`${apiURL}RestApi/saveEventTrail?entity_id=${t_id}&title=${title}&text=${text}&userID=${userId}`)   
     fetch(`${apiURL}RestApi/deleteTrailSlide`,{
        method: 'POST',
        headers: new Headers({
                   'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
          }),
        body: JSON.stringify({
            entity_id:t_id,
            slide_id:id
         }) 
     })   
     .then(res => res.json())
     .then(json => {
         if (json){
            this.setState({
            })
            this.titletab();
         this.eventslide();
     }
     else{
         this.setState({
             massage:"Something Went Worng",
 
         })
     }
     });
    }
}
llSubmit(){
    var lat=this.state.lat;
    var long=this.state.long;
    if(lat==''){

    }else if(long==''){

    }else{
    this.setState({
        visible3:false
    })
}
}
titletab(){
    this.setState({
        visible2:false,
        slideView:true
    })
}
sort(items){
    console.log(items);
    console.log(this.state.e_items);
}
slidetab(slide_id){
    var id=this.state.items;
        var userId=this.state.userId;
        fetch(`${apiURL}RestApi/getSlideCs?id=${id}&userID=${userId}&slide_id=${slide_id}`)  
        .then(res => res.json())
        .then(json => {
            if (json){
                this.setState({
                    visible:true,
                    visible2:true,
                    slideId:slide_id,
                    media_type:json[0].media_type,
                    media:json[0].media_file,
                    slide_title:json[0].headline,
                    slide_text:json[0].text,
                    new:false

            })
            
        }
        else{
            this.setState({
                massage:"Something Went Worng",
    
            })
        }
        });
}
view(){
    var check=this.state.View;
    if(check==true){
        this.setState({
            View:false  
        })
    }
    else{
        this.setState({
            View:true  
        })
    }
}
   render(){
       var items=this.state.items;
       var t_items=this.state.t_items;
       var e_items=this.state.e_items;
       var title=this.state.title;
       if (!this.state.visible){
        return  <div align="center"><Loader
        type="ThreeDots"
        color="#da3a50"
        height={100}
        width={100}
      //   timeout={10000} //3 secs
        align="center"
      /></div>;
      }
else {
    return (

        <div>
            <Header></Header>
           {
              navigator.cookieEnabled &&  localStorage.getItem('User')?
            <section class="mainPlaceholder articlePlaceMain">
            {this.state.View==false?
                <section class="only-desktop articlePageTheme timelineText">
                    <div className="container" style={{marginTop:'-130px'}}>
                        <h3>Create Trail</h3>
                        <div align="right" style={{width:'100%'}}>
                        <a style={{color:'#e65667'}} onClick={()=>this.view()}> Preview </a>
                        </div>
                        {items!=''?
                        <div class="row" style={{marginTop:'30px'}}>
                            <div class="col-md-3">
                                {
                                    t_items.map(t_item=>(
                                        <div  onClick={()=>this.titletab()} style={{listStyleType:"none",margin:'0',padding:'0',width:'70%',borderRight:'1px solid #a9a9a9'}}>
                                            <div class="column" id="title_slide" style={{width:'90%',borderWidth:'thick',borderStyle:'solid',backgroundColor:'#e65667',color:'#fbecec',height:'100px',borderRadius:'8px',overflow:'hidden',marginBottom:'20px'}}>
                                                <img style={{width:'100%',height:'100%'}} src={t_item.media_file} onError={(e)=>{e.target.onerror = null; e.target.src=`${BASE_API_URL}/assets/images/dimage.jpg`}} />
                                                <p style={{backgroundColor:'#000000',opacity:'0.8',marginTop:'-13%',overflow:'hidden'}}><a style={{color:'#ffffff',whiteSpace:'nowrap',textOverflow:'ellipsis',fontSize:'15px',width:'100%',overflow:'hidden'}}>{t_item.headline}</a></p>
                                            </div>
                                        </div>
                                        ))
                                }
                                <label style={{color:'#a9a9a9',fontSize:'18px',marginTop:'5px'}}>
                                    <b>Slides</b>
                                </label><br/>
                                <div class="row" style={{}}>
                                    <button className="form-control" onClick={() => this.openModal1()} style={{maxWidth:'53%',fontSize:'12px',backgroundColor:'#efefef',color:'#4a4a4a',borderColor:'#4a4a4a',borderStyle:'solid',borderWidth:'1px',height:'34px',marginLeft:'20px'}}> Import </button>
                                </div>
                                <div id="sortable" style={{borderColor:'#4a4a4a',borderStyle:'solid',borderRight:'1px',marginTop:'5px'}}>
                                <ReactSortable
        list={this.state.e_items}
        setList={(newState) => this.setState({ e_items: newState })}
      >

                                    {
                                        e_items.map(e_item=>(
                                        e_item.id==0?'':
                                        <div key={e_item.id}>
                                        <div onClick={()=>this.slidetab(e_item.id)} style={{listStyleType:"none",margin:'0',padding:'0',width:'70%',borderRight:'1px solid #a9a9a9'}}>
                                            <img class="card-icon-img-library" src={icon} alt="Delete" align="right"  onClick={() => this.deleteSlide(e_item.id)} />
                                            <div class="column" id="title_slide" style={{width:'90%',borderWidth:'thick',borderStyle:'solid',backgroundColor:'#e65667',color:'#fbecec',height:'100px',borderRadius:'8px',overflow:'hidden',marginBottom:'20px'}}>
                                                {
                                                    e_item.media_type=='2'?
                                                    <img style={{width:'100%',height:'100%'}} src={e_item.media_file} onError={(e)=>{e.target.onerror = null; e.target.src=`${BASE_API_URL}/assets/images/dimage.jpg`}} />
                                                    :
                                                    <iframe src={e_item.media_file} scrolling="no" style={{width:'100%',height:'100%'}}></iframe>
                                                }
                                                {
                                                    e_item.media_type=='2'?   
                                                    <p style={{backgroundColor:'#000000',opacity:'0.8',marginTop:'-13%',overflow:'hidden'}}><a style={{color:'#ffffff',whiteSpace:'nowrap',textOverflow:'ellipsis',fontSize:'15px',width:'100%',overflow:'hidden'}}>{e_item.headline}</a></p>
                                                    :
                                                    <p style={{backgroundColor:'#000000',opacity:'0.8',marginTop:'-13%',overflow:'hidden'}}><a style={{color:'#ffffff',whiteSpace:'nowrap',textOverflow:'ellipsis',fontSize:'15px',width:'100%',overflow:'hidden'}}>{e_item.headline}</a></p>
                                                }        
                                            </div>
                                        </div>     
                                        </div>
                                        ))
                                    }
                                    </ReactSortable>
                                </div>
                            </div>
                            {
                            this.state.visible2==false?
                            <div class="col-md-9" >
                                <h5><b>Edit Title Slide</b></h5><br />
                                <div class="row">
                                <div class="col-md-4">
                                <label style={{color:'#a9a9a9',fontSize:'18px',marginTop:'10px'}}>
                                    <b>Media</b>
                                </label>
                                <div class="media-container">   
                                                {
                                                    this.state.selectedFile==null?
                                                    <img class="media-image" src={`${BASE_API_URL}/assets/images/dimage.jpg`}/>
                                                    :
                                                    <img class="media-image" src={this.state.selectedFile}/>
                                                }
                                                <div class="media-middle">
                                                    <label style={{color:'#ffffff',fontSize:'15px'}} for="title_file-upload" class="custom-file-upload">
                                                        <img style={{width:'50%',marginLeft:'15%'}} src={cameraicon}/>
                                                        <br/>
                                                        Upload Image
                                                    </label>
                                                    <input style={{display:'none'}} type="file" accept="image/*" id="title_file-upload" name="title_file-upload" class="form-control" onChange={(event)=>this.onFileChange(event)} />
                                                </div>
                                            </div>
                                        </div>                
                                        <div class="col-md-8">
                                            <label style={{color:'#a9a9a9',fontSize:'18px',marginTop:'5px'}}>
                                                <b>Headline</b>
                                            </label>
                                                {
                                                    t_items!=''?<input type="text" className="form-control" style={{width:'100%',marginTop:'5px',borderWidth:'thin',borderStyle:'solid',borderColor:'#ced4da'}} placeholder="" onChange={this.handleName} value={this.state.title}/>
                                                    :
                                                    <input type="text" className="form-control" style={{width:'100%',marginTop:'5px',borderWidth:'thin',borderStyle:'solid',borderColor:'#ced4da'}} placeholder="" onChange={this.handleName} value={this.state.title}/>
                                                }           
                                                <br />
                                                <label style={{color:'#a9a9a9',fontSize:'18px',marginTop:'10px'}}>
                                                    <b>Text</b>
                                                </label>
                                                <textarea className="form-control" style={{width:'100%'}} placeholder="" rows="7" value={this.state.text} onChange={this.handleTitleText}/>
                                                <br />
                                                <div className="mx-auto" style={{float:'right'}}>
                                                    <div style={{width:'110%',borderRadius:'10px'}} class="load-btn" onClick={() => this.titleSave()} >Save
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            :
                            <div class="col-md-9" >
                                    <h5><b>Event Slide</b></h5><br />
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label style={{color:'#a9a9a9',fontSize:'18px',marginTop:'5px'}}>
                                                <b>Media</b>
                                            </label>    
                                                {
                                                    this.state.media!=''?
                                                    this.state.media_type=='1'?
                                                    <iframe src={this.state.media} scrolling="no" style={{width:'100%',height:'87%'}}></iframe>    
                                                    :
                                                    <img style={{height:'87%',width:'90%',position:'absolute'}} src={this.state.media} onError={(e)=>{e.target.onerror = null; e.target.src=`${BASE_API_URL}/assets/images/dimage.jpg`}} />
                                                    :
                                                    ''
                                                }
                                        </div>
                                        <div class="col-md-8">
                                            <label style={{color:'#a9a9a9',fontSize:'18px',marginTop:'5px'}}>
                                                <b>Headline</b>
                                            </label>
                                            {
                                                e_items!=''?<input type="text" className="form-control" disabled = "disabled" style={{width:'100%',marginTop:'5px',borderWidth:'thin',borderStyle:'solid',borderColor:'#ced4da'}} placeholder="" onChange={this.handleName} value={this.state.slide_title}/>
                                                :
                                                <input type="text" className="form-control"  disabled = "disabled" style={{width:'100%',marginTop:'5px',borderWidth:'thin',borderStyle:'solid',borderColor:'#ced4da'}} placeholder="" onChange={this.handleName} value={this.state.slide_title}/>
                                            }        
                                            <label style={{color:'#a9a9a9',fontSize:'18px',marginTop:'10px'}}>
                                                <b>Text</b>
                                            </label>
                                            {this.state.slide_text!=''?
                                                <textarea className="form-control" disabled="disabled" style={{width:'100%'}} placeholder="" rows="7" value={(this.state.slide_text.replace(/<[^>]+>/g, '')).replace(/((&nbsp;))*/gmi,'')} onChange={this.handleTitleText}/>
                                            :''
                                            }
                                            </div>
                                    </div>
                                    <br />
                                    <div className="mx-auto" style={{float:'right'}}>
                                        {
                                            this.state.new?
                                            <div style={{width:'110%',borderRadius:'10px'}} class="load-btn" onClick={() => this.eventSave()} >Save</div>
                                            :
                                            <div style={{width:'110%',borderRadius:'10px'}} class="load-btn" onClick={() => this.deleteSlide(this.state.slideId)} >Delete</div>
                                        }
                                    </div>
                                    <br />
                                </div>
                            }
                        </div>
                        :
                        " " 
                        }
                    </div>
                    <Modal visible={this.state.visible} width="470" height="150" effect="fadeInUp">
                    <div style={{width:'100%',height:'100%'}}>
                        <p style={{fontSize:'14px',marginLeft:'5%',marginTop:'3%',marginRight:'5%'}}>Great! Let's make a Trail. What do you want to call it?</p>
                        <input type="text" style={{marginTop:'-1%',borderWidth:'thin',borderStyle:'solid',borderColor:'#ced4da',marginLeft:'5%',marginRight:'5%',width:'90%'}}  onChange={this.handleName}/>
                        <br/>
                        <div align="right" style={{fontSize:'14px',marginRight:'5%',marginTop:'10px'}}><a href={navigator.cookieEnabled && localStorage.getItem('User') ? '/search/myTrails' : '/search/trails/'}><button style={{padding:' 10px 20px',borderRadius:'5px'}}>Back</button></a><button style={{marginLeft:'20px',color:'#ffffff',backgroundColor:'#e65667',padding:' 10px 20px',borderRadius:'5px'}} onClick={() => this.titleSubmit()}>Save</button>
                        </div>
                        
                    </div>
                </Modal>
                <Modal visible={this.state.visible3} width="400" height="120" effect="fadeInUp">
                    <div>
                        <div class="row">
                        <div style={{width:'10%'}}></div>
                        <input type="text" style={{width:'30%'}} placeholder="  Latitude" onChange={this.handleLat}/>
                        <div style={{width:'20%'}}></div>
                        <input type="text" style={{width:'30%'}} placeholder="  Longitude" onChange={this.handleLong}/>
                        </div>
                        <br/>
                        <div style={{marginLeft:'260px',fontSize:'14px',marginTop:'10px'}}><a onClick={() => this.closeModal()}>Close</a><a style={{marginLeft:'30px',color:'#e65667'}} onClick={() => this.llSubmit()}>Save</a>
                        </div>
                        
                    </div>
                </Modal>
                <Modal visible={this.state.visible1} width="90%" height="95%" effect="fadeInUp">
                <div class="col-md-12">
                <div style={{padding:'3%'}} align="right">
                        <a href="javascript:void(0);" style={{color:"grey"}} onClick={() => this.closeModal1()}>X</a>
                        </div>
                    <div className="row" style={{marginTop:'-9%'}}>
                    <h5 style={{margin:'3%'}}>Import</h5>
                    
                        <div class="filter-inputs-wrapper" style={{marginLeft:'-5px',marginTop:'0.7%'}}>
                        <div class="filter-select">
                            <div class="filter-label">Filter by</div>
                            <select class="filterImport" value={this.state.type} onChange={this.handleType}>
                            <option value="15">POI</option>
                            <option value="1">Article</option>
                            <option value="7">Museum</option>
                            <option value="29">Image_Gallery</option>
                            <option value="23">Video</option>
                            </select>
                        </div>
                        </div>
                     
                    </div>
                    <div >
                <main>
                    {this.state.load?
                    <div align="center" style={{width:'100%'}}><Loader
                    type="ThreeDots"
                    color="#da3a50"
                    height={100}
                    width={100}
                    align="center"
                  /></div>
                        :
                    <div className="row" style={{overflow:'scroll',height:'500px',width:'100%'}}>
                    {this.state.searchData.slice(0,this.state.display).map(it=>(
                <div class="blog-card">
                    <a href = {it.id ? '/search/trailDetail/'+it.title+'/'+it.id : '#'}>
                    <img class="card-image2" src={it.thumbnail_url ? it.thumbnail_url : `${BASE_API_URL}/assets/images/dimage.jpg`} width="100%" height="180px"/>
                    </a>
                    <div class="card-left2">
                    <div class="card-title-library">{it.title ? renderHTML(it.title.slice(0,40)) :' '}</div>
                    <div class="card-description-library">
                        {it.summary ? renderHTML(it.summary.slice(0,100)) : ''}...
                    </div>
                    {it.Author && it.Author.map(i => (
                    <div class="card-author-library">{i.name ? i.name : ''}</div>
                    ))}
           
                    <div class="card-icons-library">
                    {
                        it.f_master_meta_entity_type=='15'?
                        <img class="card-icon-img-library" src={icon2} alt="bookmark"  onClick={() => this.importPOI(it.id,it.f_master_meta_entity_type,it.title,it.summary,it.lat[0],it.long[0])} />
                        :
                        it.f_master_meta_entity_type=='1'?
                        <img class="card-icon-img-library" src={icon2} alt="bookmark"  onClick={() => this.importArticle(it.id,it.f_master_meta_entity_type,it.title,it.summary,it.banner_image[0])} />
                        :
                        it.f_master_meta_entity_type=='7'?
                        <img class="card-icon-img-library" src={icon2} alt="bookmark"  onClick={() => this.importMuseum(it.id,it.f_master_meta_entity_type,it.title,it.summary,it.banner_image[0])} />
                        :
                        it.f_master_meta_entity_type=='29'?
                        <img class="card-icon-img-library" src={icon2} alt="bookmark"  onClick={() => this.importGallery(it.id,it.f_master_meta_entity_type,it.title,it.summary,it.carousel[0])} />
                        :
                        it.f_master_meta_entity_type=='23'?
                        <img class="card-icon-img-library" src={icon2} alt="bookmark"  onClick={() => this.importVideo(it.id,it.f_master_meta_entity_type,it.title,it.summary,it.video_url)} />
                        :
                        ''

                    }
                        </div>
                    </div>
                </div>
                ))}
                         {this.state.display < this.state.searchData.length &&
                        <div class="load-wrapper">
                        <div class="load-btn"  onClick={this.loadMore}>Load More</div>
                      </div>
            //  <button  type="button" className="load-more">Load more</button>
          }
                </div>
   }    
                </main>
            </div>
                    
                    </div>
                </Modal>
                </section>
                :
                <section class="articlePageTheme timelineText">
                <div className="container" style={{marginTop:'-130px'}}>
                    <h3>View Trail</h3>
                    <div align="right" style={{width:'100%'}}>
                    <a style={{width:'100%',color:'#e65667'}} onClick={()=>this.view()}>Back to Edit </a>
                    </div>
                    <iframe  style={{width:'100%',height:'550px',margin:'o auto'}} src={`${BASE_API_URL}blogs/crowdsource_trail?id=${this.state.items}&user_id=${this.state.userId}`} alt="" ></iframe>
                    </div>
                    </section>
                }
                {this.state.View==false?
                <section class="only-mobile articlePageTheme timelineText">
                    <div className="container" style={{marginTop:'-130px'}}>
                        <h3>Create Trail</h3>
                        <div align="right" style={{width:'100%'}}>
                        <a style={{color:'#e65667'}} onClick={()=>this.view()}> Preview </a>
                        </div>
                        {items!=''?
                        <div class="row" style={{marginTop:'30px'}}>
                            <div class="col-md-3">
                                {
                                    t_items.map(t_item=>(
                                        <div  onClick={()=>this.titletab()} style={{listStyleType:"none",margin:'0',padding:'0',width:'70%',borderRight:'1px solid #a9a9a9'}}>
                                            <div class="column" id="title_slide" style={{width:'90%',borderWidth:'thick',borderStyle:'solid',backgroundColor:'#e65667',color:'#fbecec',height:'100px',borderRadius:'8px',overflow:'hidden',marginBottom:'20px'}}>
                                                <img style={{width:'100%',height:'100%'}} src={t_item.media_file} onError={(e)=>{e.target.onerror = null; e.target.src=`${BASE_API_URL}/assets/images/dimage.jpg`}} />
                                                <p style={{backgroundColor:'#000000',opacity:'0.8',marginTop:'-13%',overflow:'hidden'}}><a style={{color:'#ffffff',whiteSpace:'nowrap',textOverflow:'ellipsis',fontSize:'15px',width:'100%',overflow:'hidden'}}>{t_item.headline}</a></p>
                                            </div>
                                        </div>
                                        ))
                                }
                                <label style={{color:'#a9a9a9',fontSize:'18px',marginTop:'5px'}}>
                                    <b>Slides</b>
                                </label><br/>
                                <div class="row" style={{}}>
                                    <button className="form-control" onClick={() => this.openModal1()} style={{maxWidth:'53%',fontSize:'12px',backgroundColor:'#efefef',color:'#4a4a4a',borderColor:'#4a4a4a',borderStyle:'solid',borderWidth:'1px',height:'34px',marginLeft:'20px'}}> Import </button>
                                </div>
                                <div id="sortable" style={{borderColor:'#4a4a4a',borderStyle:'solid',borderRight:'1px',marginTop:'5px'}}>
                                <ReactSortable
        list={this.state.e_items}
        setList={(newState) => this.setState({ e_items: newState })}
      >

                                    {
                                        e_items.map(e_item=>(
                                        e_item.id==0?'':
                                        <div key={e_item.id}>
                                        <div onClick={()=>this.slidetab(e_item.id)} style={{listStyleType:"none",margin:'0',padding:'0',width:'70%',borderRight:'1px solid #a9a9a9'}}>
                                            <img class="card-icon-img-library" src={icon} alt="Delete" align="right"  onClick={() => this.deleteSlide(e_item.id)} />
                                            <div class="column" id="title_slide" style={{width:'90%',borderWidth:'thick',borderStyle:'solid',backgroundColor:'#e65667',color:'#fbecec',height:'100px',borderRadius:'8px',overflow:'hidden',marginBottom:'20px'}}>
                                                {
                                                    e_item.media_type=='2'?
                                                    <img style={{width:'100%',height:'100%'}} src={e_item.media_file} onError={(e)=>{e.target.onerror = null; e.target.src=`${BASE_API_URL}/assets/images/dimage.jpg`}} />
                                                    :
                                                    <iframe src={e_item.media_file} scrolling="no" style={{width:'100%',height:'100%'}}></iframe>
                                                }
                                                {
                                                    e_item.media_type=='2'?   
                                                    <p style={{backgroundColor:'#000000',opacity:'0.8',marginTop:'-13%',overflow:'hidden'}}><a style={{color:'#ffffff',whiteSpace:'nowrap',textOverflow:'ellipsis',fontSize:'15px',width:'100%',overflow:'hidden'}}>{e_item.headline}</a></p>
                                                    :
                                                    <p style={{backgroundColor:'#000000',opacity:'0.8',marginTop:'-13%',overflow:'hidden'}}><a style={{color:'#ffffff',whiteSpace:'nowrap',textOverflow:'ellipsis',fontSize:'15px',width:'100%',overflow:'hidden'}}>{e_item.headline}</a></p>
                                                }        
                                            </div>
                                        </div>     
                                        </div>
                                        ))
                                    }
                                    </ReactSortable>
                                </div>
                            </div>
                            {
                            this.state.visible2==false?
                            <div class="col-md-9" >
                                <h5><b>Edit Title Slide</b></h5><br />
                                <div class="row">
                                <div class="col-md-4">
                                <label style={{color:'#a9a9a9',fontSize:'18px',marginTop:'10px'}}>
                                    <b>Media</b>
                                </label>
                                <div class="media-container">   
                                                {
                                                    this.state.selectedFile==null?
                                                    <img class="media-image" src={`${BASE_API_URL}/assets/images/dimage.jpg`}/>
                                                    :
                                                    <img class="media-image" src={this.state.selectedFile}/>
                                                }
                                                <div class="media-middle">
                                                    <label style={{color:'#ffffff',fontSize:'15px'}} for="title_file-upload" class="custom-file-upload">
                                                        <img style={{width:'50%',marginLeft:'15%'}} src={cameraicon}/>
                                                        <br/>
                                                        Upload Image
                                                    </label>
                                                    <input style={{display:'none'}} type="file" accept="image/*" id="title_file-upload" name="title_file-upload" class="form-control" onChange={(event)=>this.onFileChange(event)} />
                                                </div>
                                            </div>
                                        </div>                
                                        <div class="col-md-8">
                                            <label style={{color:'#a9a9a9',fontSize:'18px',marginTop:'5px'}}>
                                                <b>Headline</b>
                                            </label>
                                                {
                                                    t_items!=''?<input type="text" className="form-control" style={{width:'100%',marginTop:'5px',borderWidth:'thin',borderStyle:'solid',borderColor:'#ced4da'}} placeholder="" onChange={this.handleName} value={this.state.title}/>
                                                    :
                                                    <input type="text" className="form-control" style={{width:'100%',marginTop:'5px',borderWidth:'thin',borderStyle:'solid',borderColor:'#ced4da'}} placeholder="" onChange={this.handleName} value={this.state.title}/>
                                                }           
                                                <br />
                                                <label style={{color:'#a9a9a9',fontSize:'18px',marginTop:'10px'}}>
                                                    <b>Text</b>
                                                </label>
                                                <textarea className="form-control" style={{width:'100%'}} placeholder="" rows="7" value={this.state.text} onChange={this.handleTitleText}/>
                                                <br />
                                                <div className="mx-auto" style={{float:'right'}}>
                                                    <div style={{width:'110%',borderRadius:'10px'}} class="load-btn" onClick={() => this.titleSave()} >Save
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            :
                            <div class="col-md-9" >
                                    <h5><b>Event Slide</b></h5><br />
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label style={{color:'#a9a9a9',fontSize:'18px',marginTop:'5px'}}>
                                                <b>Media</b>
                                            </label>    
                                                {
                                                    this.state.media!=''?
                                                    this.state.media_type=='1'?
                                                    <iframe src={this.state.media} scrolling="no" style={{width:'100%',height:'87%'}}></iframe>    
                                                    :
                                                    <img style={{height:'87%',width:'90%',position:'absolute'}} src={this.state.media} onError={(e)=>{e.target.onerror = null; e.target.src=`${BASE_API_URL}/assets/images/dimage.jpg`}} />
                                                    :
                                                    ''
                                                }
                                        </div>
                                        <div class="col-md-8">
                                            <label style={{color:'#a9a9a9',fontSize:'18px',marginTop:'5px'}}>
                                                <b>Headline</b>
                                            </label>
                                            {
                                                e_items!=''?<input type="text" className="form-control" disabled = "disabled" style={{width:'100%',marginTop:'5px',borderWidth:'thin',borderStyle:'solid',borderColor:'#ced4da'}} placeholder="" onChange={this.handleName} value={this.state.slide_title}/>
                                                :
                                                <input type="text" className="form-control"  disabled = "disabled" style={{width:'100%',marginTop:'5px',borderWidth:'thin',borderStyle:'solid',borderColor:'#ced4da'}} placeholder="" onChange={this.handleName} value={this.state.slide_title}/>
                                            }        
                                            <label style={{color:'#a9a9a9',fontSize:'18px',marginTop:'10px'}}>
                                                <b>Text</b>
                                            </label>
                                            {this.state.slide_text!=''?
                                                <textarea className="form-control" disabled="disabled" style={{width:'100%'}} placeholder="" rows="7" value={(this.state.slide_text.replace(/<[^>]+>/g, '')).replace(/((&nbsp;))*/gmi,'')} onChange={this.handleTitleText}/>
                                            :''
                                            }
                                            </div>
                                    </div>
                                    <br />
                                    <div className="mx-auto" style={{float:'right'}}>
                                        {
                                            this.state.new?
                                            <div style={{width:'110%',borderRadius:'10px'}} class="load-btn" onClick={() => this.eventSave()} >Save</div>
                                            :
                                            <div style={{width:'110%',borderRadius:'10px'}} class="load-btn" onClick={() => this.deleteSlide(this.state.slideId)} >Delete</div>
                                        }
                                    </div>
                                    <br />
                                </div>
                            }
                        </div>
                        :
                        " " 
                        }
                    </div>
                    <Modal visible={this.state.visible} width="90%" height="28%" effect="fadeInUp">
                    <div style={{width:'100%',height:'100%'}}>
                        <p style={{fontSize:'13px',marginLeft:'5%',marginTop:'3%',marginRight:'5%'}}>Great! Let's make a Trail. What do you want to call it?</p>
                        <input type="text" style={{marginTop:'-1%',borderWidth:'thin',borderStyle:'solid',borderColor:'#ced4da',marginLeft:'5%',marginRight:'5%',width:'90%'}}  onChange={this.handleName}/>
                        <br/>
                        <div align="right" style={{fontSize:'14px',marginRight:'5%',marginTop:'5%'}}><a href={navigator.cookieEnabled && localStorage.getItem('User') ? '/search/myTrails' : '/search/trails/'}><button style={{padding:' 10px 20px',borderRadius:'5px'}}>Back</button></a><button style={{marginLeft:'20px',color:'#ffffff',backgroundColor:'#e65667',padding:' 10px 20px',borderRadius:'5px'}} onClick={() => this.titleSubmit()}>Save</button>
                        </div>
                        
                    </div>
                </Modal>
                <Modal visible={this.state.visible3} width="90%" height="35%" effect="fadeInUp">
                    <div>
                        <div class="row">
                        <div style={{width:'10%'}}></div>
                        <input type="text" style={{width:'30%'}} placeholder="  Latitude" onChange={this.handleLat}/>
                        <div style={{width:'20%'}}></div>
                        <input type="text" style={{width:'30%'}} placeholder="  Longitude" onChange={this.handleLong}/>
                        </div>
                        <br/>
                        <div style={{marginLeft:'50%',fontSize:'14px',marginTop:'10px'}}><a onClick={() => this.closeModal()}>Close</a><a style={{marginLeft:'30px',color:'#e65667'}} onClick={() => this.llSubmit()}>Save</a>
                        </div>
                        
                    </div>
                </Modal>
                <Modal visible={this.state.visible1} width="90%" height="95%" effect="fadeInUp">
                <div class="col-md-12">
                <div style={{padding:'3%'}} align="right">
                        <a href="javascript:void(0);" style={{color:"grey"}} onClick={() => this.closeModal1()}>X</a>
                        </div>
                    <div className="row" style={{marginTop:'-9%'}}>
                    <h5 style={{margin:'3%'}}>Import</h5>
                    
                        <div class="filter-inputs-wrapper" style={{marginLeft:'-5px',marginTop:'0.7%'}}>
                        <div class="filter-select">
                            <div class="filter-label">Filter by</div>
                            <select value={this.state.type} onChange={this.handleType}>
                            <option value="15">POI</option>
                            <option value="1">Article</option>
                            <option value="7">Museum</option>
                            <option value="29">Image_Gallery</option>
                            <option value="23">Video</option>
                            </select>
                        </div>
                        </div>
                     
                    </div>
                    <div >
                <main>
                    {this.state.load?
                    <div align="center" style={{width:'100%'}}><Loader
                    type="ThreeDots"
                    color="#da3a50"
                    height={100}
                    width={100}
                    align="center"
                  /></div>
                        :
                    <div className="row" style={{overflow:'scroll',height:'500px',width:'100%'}}>
                    {this.state.searchData.slice(0,this.state.display).map(it=>(
                <div class="blog-card">
                    <a href = {it.id ? '/search/trailDetail/'+it.title+'/'+it.id : '#'}>
                    <img class="card-image2" src={it.thumbnail_url ? it.thumbnail_url : `${BASE_API_URL}/assets/images/dimage.jpg`} width="100%" height="180px"/>
                    </a>
                    <div class="card-left2">
                    <div class="card-title-library">{it.title ? renderHTML(it.title.slice(0,40)) :' '}</div>
                    <div class="card-description-library">
                        {it.summary ? renderHTML(it.summary.slice(0,100)) : ''}...
                    </div>
                    {it.Author && it.Author.map(i => (
                    <div class="card-author-library">{i.name ? i.name : ''}</div>
                    ))}
           
                    <div class="card-icons-library">
                    {
                        it.f_master_meta_entity_type=='15'?
                        <img class="card-icon-img-library" src={icon2} alt="bookmark"  onClick={() => this.importPOI(it.id,it.f_master_meta_entity_type,it.title,it.summary,it.lat[0],it.long[0])} />
                        :
                        it.f_master_meta_entity_type=='1'?
                        <img class="card-icon-img-library" src={icon2} alt="bookmark"  onClick={() => this.importArticle(it.id,it.f_master_meta_entity_type,it.title,it.summary,it.banner_image[0])} />
                        :
                        it.f_master_meta_entity_type=='7'?
                        <img class="card-icon-img-library" src={icon2} alt="bookmark"  onClick={() => this.importMuseum(it.id,it.f_master_meta_entity_type,it.title,it.summary,it.banner_image[0])} />
                        :
                        it.f_master_meta_entity_type=='29'?
                        <img class="card-icon-img-library" src={icon2} alt="bookmark"  onClick={() => this.importGallery(it.id,it.f_master_meta_entity_type,it.title,it.summary,it.carousel[0])} />
                        :
                        it.f_master_meta_entity_type=='23'?
                        <img class="card-icon-img-library" src={icon2} alt="bookmark"  onClick={() => this.importVideo(it.id,it.f_master_meta_entity_type,it.title,it.summary,it.video_url)} />
                        :
                        ''

                    }
                        </div>
                    </div>
                </div>
                ))}
                         {this.state.display < this.state.searchData.length &&
                        <div class="load-wrapper">
                        <div class="load-btn"  onClick={this.loadMore}>Load More</div>
                      </div>
            //  <button  type="button" className="load-more">Load more</button>
          }
                </div>
   }    
                </main>
            </div>
                    
                    </div>
                </Modal>
                </section>
                :
                <section class="articlePageTheme timelineText">
                <div className="container" style={{marginTop:'-130px'}}>
                    <h3>View Trail</h3>
                    <div align="right" style={{width:'100%'}}>
                    <a style={{width:'100%',color:'#e65667'}} onClick={()=>this.view()}>Back to Edit </a>
                    </div>
                    <iframe  style={{width:'100%',height:'550px',margin:'o auto'}} src={`${BASE_API_URL}blogs/crowdsource_trail?id=${this.state.items}&user_id=${this.state.userId}`} alt="" ></iframe>
                    </div>
                    </section>
                }

              
            </section>
            :
            <Modal visible={true} width="800" height="550" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                    <div  style={{backgroundColor:"#e2e2e2"}}>
                        <div class="row" style={{marginLeft:"0px"}}>
                            <div class="col-md-6" style={{backgroundColor:"#fff"}}>
                            </div>
                            <div class="col-md-6" align="right" style={{color:"grey",padding:"10px 30px",fontSize:"20px"}}>
                                <a href="javascript:void(0);" style={{color:"grey"}} onClick={() => this.closeModal()}>X</a>
                            </div>
                        </div>
                        <Signin bookID="" bookType="" rel="" unbook="" created="" close={() => this.closeModal()}></Signin>
                    </div>
                </Modal>
}

        </div>
    );
            }
}
    }
