import { useEffect, useState } from 'react';
import { formatDate, importAll } from '../../Helpers/utility';
import { makeAPICall, REQUEST } from '../../services/apiService';


const images = importAll(require.context('../../assets/img', false, /\.(png|jpe?g|svg)$/));

/*  global $ */
export default function Authors({ authors, createdAt }) {
    useEffect(() => {
        console.log("authors : ", authors);
        $.each(authors, function (index, value) {
            $('#author_entity_' + index + ', .shToolCl').on('click', function () {
                var auth_desc = $('#author_description .shAuthBio');

                $.each(auth_desc, function (i, node) {
                    $(node).removeClass("active");
                });
            });
            $('#author_entity_' + index).on('click', function () {
                $('#author_description_' + index).addClass("active");
            });
        });
    });


    return (
        <div className="shDateSec clearfix">
            <div id="author_entity">
                {authors && authors.map((author, index) => (
                    <h3 className="float-left" key={index} id={`author_entity_${index}`}>{author.name}</h3>
                ))}
            </div>
            <p className="float-left" id="date_entity">{formatDate(createdAt)}</p>
            <div id="author_description">
                {authors && authors.map((author, index) => (
                    <AuthorDetail author={author} index={index} />
                    // <div className="shAuthBio" id={`author_description_${index}`}>
                    //     <a href="javascript:void(0);" className="shToolCl">
                    //         <img src={images['ic_tool_cl@2x.png'].default} alt="" />
                    //     </a>
                    //     <div className="shAuthImg">
                    //         <img src={images['david.png'].default} alt="" />
                    //     </div>
                    //     <div className="shAuthDesc">
                    //         Naomi Fry became a staff writer at The New Yorker in 2018. Her writing on culture, books and art has appeared in the Times Book Review, the Times Magazine and Artforum among other publications.
                    //     </div>
                    // </div>
                ))}
            </div>
        </div>
    );
}


const AuthorDetail = ({ author, index }) => {
    const [authorPop, setAuthorPop] = useState(null);

    useEffect(() => {
        let reqParam = {
            type: REQUEST.GET,
            params: {
                id: author.id
            }
        }
        if (author.resource_type === 'Individual') {
            reqParam['url'] = 'Project/getResourceDetailInd';
        } else {
            reqParam['url'] = 'Project/getResourceDetailOrg';
        }
        makeAPICall(reqParam).then((result) => {
            console.log("Author Detail : ", result);
            if (result.data.length > 0) {
                setAuthorPop(result.data[0])
            }
        })
    }, []);

    return (
        <div style={{position:'relative'}}>
            {authorPop && (<div className="shAuthBio" id={`author_description_${index}`}>
                <a href="javascript:void(0);" className="shToolCl">
                    <img src={images['ic_tool_cl@2x.png'].default} alt="" />
                </a>
                <div className="shAuthImg">
                    {authorPop && (<img src={authorPop.thumbnail_url} alt="" />)}
                </div>
                {authorPop && (<div className="shAuthDesc">{authorPop.description}</div>)}
            </div>)}
        </div>
    );
}