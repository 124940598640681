import React, { Component } from "react";
import Modal from "react-awesome-modal";
import LinesEllipsis from "react-lines-ellipsis";
import Loader from "react-loader-spinner";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import renderHTML from "react-render-html";
import Popup from "reactjs-popup";
import apiURL from "../../components/url";
import Bookmark from "../bookmark";
import entityName from "../entityUrl";
import icon1 from "../images/icon-1.png";
import unBookmark from "../images/icon-4.png";
import Signin from "../signin2";
import Social from "../socialicon";
import img from "./images/lib-1.png";

export default class blogContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      display: 7,
      noData: true,
      bookmarkId: "",
      bookmarkType: "",
      relation: "",
      unbookmarkId: "",
      created: "",
    };
    this.loadMore = this.loadMore.bind(this);
  }
  loadMore() {
    this.setState((prev) => {
      return { display: prev.display + 6 };
    });
  }

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    var project = query.get("project");
    fetch(`${apiURL}Api/getBlogsList?type=33&project=${project}`)
      .then((res) => res.json())
      .then((json) => {
        if (json) {
          this.setState({
            isLoaded: true,
            items: json,
            noData: false,
          });
        } else {
          this.setState({
            isLoaded: true,
            items: [],
            noData: true,
          });
        }
      });
  }

  openModal(a, b, c, d ) {
      this.setState({
        bookmarkId: a,
        bookmarkType: b,
        relation: c,
        created: d,
      });
    this.setState({
      visible: true,
    });
  }

  closeModal() {
    this.setState({
      visible: false,
    });
  }

  render() {
    const size = window.innerWidth;
    const regex = /<[^>]*>/g;
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 3,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        partialVisibilityGutter: 50,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        partialVisibilityGutter: 20,
      },
    };
    var { isLoaded, entityType, items } = this.state;
    if (!isLoaded) {
      return (
        <div align="center">
          <Loader
            type="ThreeDots"
            color="#da3a50"
            height={100}
            width={100}
            //   timeout={10000} //3 secs
            align="center"
          />
        </div>
      );
    } else {
      return (
        <>
          {/* <Helmet>
                    <meta property="og:caption" content="testing" data-react-helmet="true"  />
                    <meta property="og:keywords" content="testing" data-react-helmet="true" />
                    <meta property="og:description" content="testing" data-react-helmet="true"/>
                    <meta name="og:image" content="https://map.sahapedia.org/admin/assets/logo/sahapedialogo.png" data-react-helmet="true"/>
                    </Helmet> */}

          {this.state.noData ? (
            ""
          ) : (
            <main class="mainBlog">
              {items.slice(0, this.state.display).map((item) => (
                <>
                  <div class="slider-header slider-header-width">
                    <div class="slider-title">{item.title}</div>
                    <div class="slider-subtitle">
                      {renderHTML(item.summary ? item.summary : "")}
                    </div>
                    <div class="slider-view-all">
                      <a href={`/blogDetail?id=${item.id}`}>View All</a>
                    </div>
                  </div>
                  <div id="slider-one" class="splide">
                    <div class="slide__track">
                      <Carousel
                        responsive={responsive}
                        centerMode={false}
                        partialVisbile={
                          item.attached_entity.length == 1 ? false : true
                        }
                        removeArrowOnDeviceType={["mobile"]}
                      >
                        {item.attached_entity.map((data) => (
                          <>
                            <div class="blog-card-bl slide__slide">
                              <a
                                href={`/${entityName(data.f_master_meta_entity_type)}/${data.title.replace(" ", "-")}/${
                                  data.link2_entity_id
                                }`}
                                style={{width:'100%'}}
                              >
                                <img
                                  class="card-image-bl"
                                  src={
                                    data.thumbnail_url
                                      ? data.thumbnail_url
                                      : img
                                  }
                                />
                              </a>
                              <div class="card-details-bl">
                                <div class="card-title-bl">
                                  <a
                                    href={`/${entityName(data.f_master_meta_entity_type)}/${data.title.replace(" ", "-")}/${
                                      data.link2_entity_id
                                    }`}
                                  >
                                    <LinesEllipsis
                                      text={data.title}
                                      onReflow={this.handleReflow}
                                      maxLine={2}
                                      basedOn="words"
                                    />
                                  </a>
                                </div>
                                <div class="card-description-bl">
                                <LinesEllipsis
                    text={data.summary.replace(regex,'')}
                    onReflow={this.handleReflow}
                    maxLine={3}
                    basedOn='words'
                  />

                                  {/* {data.summary.length ? data.summary.replace(regex,''): data.summary.replace(regex,'')} */}
                                </div>
                                <div class="card-icons-bl">
                                  <Popup
                                    trigger={
                                      <img
                                        class="card-icon-img"
                                        src={icon1}
                                        alt="share"
                                      />
                                    }
                                    position={
                                      size > 768
                                        ? "right center"
                                        : "left center"
                                    }
                                  >
                                    <div>
                                        <Social
                                          type={"other"}
                                          title={data.title}
                                          img={
                                            data.banner_url
                                              ? data.banner_url
                                              : img
                                          }
                                          summary={data.summary}
                                          url={`${window.location.origin}/${entityName(data.f_master_meta_entity_type)}/${data.title.replace(
                                            " ",
                                            "-"
                                          )}/${data.link2_entity_id}`}
                                        ></Social>
                                    </div>
                                  </Popup>
                                  {navigator.cookieEnabled && localStorage.getItem("myData") ? (
                                    <>
                                      <Bookmark
                                        id={data.link2_entity_id}
                                        type={data.f_master_meta_entity_type}
                                        relation={"6"}
                                        user={localStorage.getItem("User")}
                                      ></Bookmark>
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        class="card-icon-img"
                                        value="Bookmark"
                                        onClick={() =>
                                          this.openModal(
                                            data.link2_entity_id,
                                            data.f_master_meta_entity_type,
                                            6,
                                            localStorage.getItem("User")
                                          )
                                        }
                                        src={unBookmark}
                                        alt="bookmark"
                                      />
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      </Carousel>
                      {
                        this.state.display < items.length && (
                          <div class="load-wrapper">
                            <div class="load-btn" onClick={this.loadMore}>
                              Load More
                            </div>
                          </div>
                        )
                        //  <button  type="button" className="load-more">Load more</button>
                      }
                    </div>
                  </div>
                  {this.state.visible?
                  <Modal
                    visible={this.state.visible}
                    width="800"
                    height="550"
                    effect="fadeInUp"
                    onClickAway={() => this.closeModal()}
                  >
                    <div style={{ backgroundColor: "#e2e2e2" }}>
                      <div class="row" style={{ marginLeft: "0px" }}>
                        <div
                          class="col-md-6"
                          style={{ backgroundColor: "#fff" }}
                        ></div>
                        <div
                          class="col-md-6"
                          align="right"
                          style={{
                            color: "grey",
                            padding: "10px 30px",
                            fontSize: "20px",
                          }}
                        >
                          <a
                            href="javascript:void(0);"
                            style={{ color: "grey" }}
                            onClick={() => this.closeModal()}
                          >
                            X
                          </a>
                        </div>
                      </div>
                      <Signin
                         bookID={this.state.bookmarkId}
                         bookType={this.state.bookmarkType}
                         rel={this.state.relation}
                         unbook=""
                         created={this.state.created}
                         close={() => this.closeModal()}
                      ></Signin>
                    </div>
                  </Modal>
                  :""}
                </>
              ))}
            </main>
          )}
        </>
      );
    }
  }
}
