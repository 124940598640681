import React, { Component } from "react";
import { BASE_API_URL } from "../../services/apiService";

export default class Heading extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "all",
      trailTimeCount: [],
    };
    this.entityType = this.entityType.bind(this);
  }

  componentDidMount() {
    fetch(`${BASE_API_URL}entity/trailTimeCount?type=trails`)
      .then((res) => res.json())
      .then((json) => {
        if (json) {
          this.setState({
            trailTimeCount: Object.keys(json),
          });
        } else {
          this.setState({
            trailTimeCount: [],
          });
        }
      });
  }
  entityType(ev) {
    this.setState({ type: ev.target.value });
    var url = "";
    url = ev.target.value
      ? `/search/trails?type=${ev.target.value}`
      : `/search/trails`;
    window.location.href = url;
  }
  render() {
    var items = this.props.Data;
    var type = this.props.Data2;
    var entity = this.props.Entity;
    return (
      <div>
        <div id="header">
          <h1 class="header-h1" align="center">
            All Trails
          </h1>
          <div class="sub-title" style={{ textAlign: "center" }}>
            Explore the cultural vibrancy of India’s cities & towns.
            <br />
            Create your own trails and share them
          </div>
          <div id="filters2" justify-content="center">
            <div id="search-result-count">{items.length} Items</div>
            <div class="filter-inputs-wrapper">
              <div class="filter-select">
                <div class="filter-label">Filter by</div>
                <select
                  class="library-select"
                  onChange={this.entityType}
                  value={entity ? entity : this.state.type}
                >
                  <option value="">All</option>
                  {type.map(
                    (entity) =>
                      this.state.trailTimeCount.includes(entity.id) && (
                        <option value={entity.id}>{entity.name}</option>
                      )
                  )}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
