import { useEffect } from 'react';
import { hideMap, mapUtils } from '../../services/mapService';

/* global $ */
export default function Map({ pagetype, Locations }) {
	useEffect(() => {
		/**
		 * If got new locations
		 * Clear the existing Plot new locations
		 */
		if ((pagetype === 'article' || pagetype === 'imgGallery' || pagetype === 'museum' || pagetype === 'timeline' || pagetype === 'story' ) && Locations) {
			mapUtils.locations = [];

			Locations.forEach((place) => {
				if (place.Location && place.Location.length > 0) {
					place.Location.forEach((poi_place) => {
						mapUtils.locations.push({ ...poi_place, title: place.title });
					})
				}
			});
		}

		else if (pagetype === 'theme' && !mapUtils.map) {
			mapUtils.initMap("map");
			$('#map').css({
				'z-index': 1
			});

			mapUtils.locations = [];

			if (Locations) {
				Locations.forEach((place, index) => {
					if (place.Location)
						mapUtils.locations.push(place.Location);
				});
			}
			setTimeout(() => {
				mapUtils.removeMarker();
				let plottedMarker = mapUtils.addMarkers();
				mapUtils.markers = plottedMarker;
				mapUtils.boundMarkers();
			}, 1500);
		}
	})


	return (
		<div className="locMapPopup hidden">
			<div className="locMapItem">
				<a href="javascript:void(0);" id="exit-map" onClick={() => hideMap()}>&#10005;</a>
				<div className="locMapMain">
					<div id="map"></div>
				</div>
			</div>
		</div>
	);
}