import React, { Component } from 'react';
import Loader from "react-loader-spinner";
import { BASE_API_URL, makeAPICall, REQUEST } from '../../services/apiService';
import Footer from '../footer';
import Header from '../header2';
import apiURL from '../url';
import Heading from './blogTitle';
import Content from './blogView';

export default class Blog extends Component {
    constructor(props) {
		super(props);
		this.state={
      items:[],
      entityType:[],
      entityProjects:[]
        }
    }
    getEntity()
    {
        fetch(`${BASE_API_URL}master/getProjectList`)		
        .then(res => res.json())
        .then(json => {
            if (json){
                this.setState({
        
                    entityType: json,
            })
        }
        else{
            this.setState({
                entityType:[],
    
            })
        }
        });
    }
    getEntityProjects(article_id){
        let reqParam = {
            type: REQUEST.POST,
            url: 'Entity/getEntityProjects',
            data: JSON.stringify({
                id: article_id
            })
        }
        makeAPICall(reqParam)
            .then((result) => {
                if (result) {
                    let res = result.data;
                    this.setState({
                    entityProjects:res['primary_projects'].concat(res['secondary_projects'])
                })
                }
            })
    }
    componentDidMount(){
            var user = localStorage.getItem('User');
            const query = new URLSearchParams(this.props.location.search);
            var id = query.get('id');
            this.getEntityProjects(id);
			fetch(`${apiURL}Api/getBlogsList?type=33&id=${id}`)		
			.then(res => res.json())
			.then(json => {
				if (json){
					this.setState({
						isLoaded: true,
						items: json,
						noData:false,
				})
			}
			else{
				this.setState({
					isLoaded: true,
					items:[],
					noData:true,
		
				})
			}
      });		
    }
    render() { 
        const query = new URLSearchParams(this.props.location.search);
        var qryStr = query.get('type');
        if ( qryStr == undefined ) {qryStr = ''}
        var type = qryStr;
        var { isLoaded, entityType, items} = this.state;
        if (!isLoaded){
          return  <div align="center"><Loader
          type="ThreeDots"
          color="#da3a50"
          height={100}
          width={100}
        //   timeout={10000} //3 secs
          align="center"
        /></div>;
		}
        else {
        return ( 
            <div>
                <Header projectDirectory={this.state.entityProjects}></Header>
                <Heading Data = {items} Data2={entityType} Entity = {type}></Heading>
                <Content Data = {items} Entity = {type}></Content>
                <Footer></Footer>
            </div>
         );
    }
}
 
}
