import React, { Component } from 'react';
import "react-image-lightbox/style.css";
import { Link, withRouter } from 'react-router-dom';
import EmbedImage from './EmbedImage';
import './imageGallery.css'

let myRef = React.createRef();
class ImageGallery extends Component {
    constructor(props) {
        super(props);
    }
    onClickEmbed = () => {
        var index = this.props.index;
        this.props.onClickCarousel(index);
    }
    render() {
        return (
            <div className='center1'>
                <EmbedImage onClickEmbed={this.onClickEmbed} index={this.props.index} id={this.props.id} image={this.props.image} caption={this.props.caption ? this.props.caption : ''} description = {this.props.description ? this.props.description : ''}  />
            </div>

        )
    }
}

export default ImageGallery;




// import React, { useEffect, useState, useRef } from 'react';
// import LightBox from 'react-image-lightbox';
// import 'react-image-lightbox/style.css';

// /* global $ */
// export default function ImageGallery() {
//     const photoIndex = useRef(0);
//     const images = useRef([]);
//     const [open, setOpen] = useState(false);


//     useEffect(() => {
        // $(function () {
        //     let imgs = document.querySelectorAll('.articlePage img');
        //     images.current = [];
        //     imgs.forEach((img, index) => {
        //         // Setting Cursor to pointer on article images only
        //         $(img).css({ 'cursor': 'pointer' });
        //         // Setting image links in array
        //         images.current = [...images.current, img.src];

        //         // Click Listener to open Gallery
        //         $(img).on('click', function () {
        //             console.log('clicked');
        //             setOpen(true);
        //             photoIndex.current = index;
        //             console.log(photoIndex);
        //         })
        //     });
//             // setImages(imgs);
//             // images.current = imgs;
//             console.log("ImageList :", images.current);
//         }, [])
//     });

//     return (
//         <div>
//             {open && <LightBox
//                 mainSrc={images.current[photoIndex]}
//                 nextSrc={images.current[(photoIndex + 1) % images.current.length]}
//                 prevSrc={images.current[(photoIndex - 1) % images.current.length]}
//                 onCloseRequest={() => setOpen(false)}
//                 onMovePrevRequest={() => photoIndex.current = (photoIndex.current + images.current.length - 1) % images.current.length}
//                 onMoveNextRequest={() => photoIndex.current = (photoIndex.current + 1) % images.current.length}
//             />}
//         </div>
//     )
// }