import { importAll } from "../../Helpers/utility";

export default function ArticleCard() {
    const images = importAll(require.context('../../assets/img', false, /\.(png|jpe?g|svg)$/));

    return (
        <div className="shPoiSec" style={{ display: 'none' }}>
            <div className="shPoiHead">
                <h2>Pune Okayama Friendship Garden</h2>
                <a href="#" className="shToolCl">
                    <img src={images['ic_tool_cl@2x.png'].default} alt />
                </a>
            </div>
            <div className="shPoiImg">
                <img src="img/banner.png" alt />
            </div>
            <div className="shPoiBody">
                <p>Distinct areas in Pune have their own identities, a fact that becomes most evident when one looks.</p>
                <div className="shPoiBreadcrumb">
                    <nav aria-label="breadcrumb" className="shBreadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="#">Parks and Gardens</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Trees</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="shPoiFoot clearfix">
                <button className="btn btn-more">More</button>
                <div className="shPoiFtRt">
                    <a href="#">
                        <img src={images['ic_su_p_g@2x.png'].default} alt />
                    </a>
                    <a href="#">
                        <img src={images['ic_tag_g@2x.png'].default} alt />
                    </a>
                </div>
            </div>
        </div>
    );
}