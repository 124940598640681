import React, { Component } from 'react';
// import saha from '../images/saha.png';
import 'reactjs-popup/dist/index.css';
import apiURL from './url';

export default class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            name: '',
            pass: '',
            cpass: '',
            uname: '',
            mobile: '',
            dob: '',
            org_or_ind: 'Individual',
            messemail: '',
            massage: '',
            massage1: '',
            massage2: '',
            massage3: '',
            massage4: '',
            masg:'Enter your email address and get a link to reset your password',
        }
        this.dataSubmit = this.dataSubmit.bind(this);
    }
    dataSubmit() {
        var count = 0;
        var email = this.state.email;
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (email === '' || !pattern.test(email)) {
            count = count + 1;
            this.setState({

                massage2: "Please Enter Valid Email",

            })
        }
        else {
            this.setState({

                massage2: " ",

            })
        }
        if (count === 0) {
            fetch(`${apiURL}master/fgPassword?email=${email}`)
                .then(res => res.json())
                .then(json => {
                    if (json[0].status === "true") {
                        this.setState({
                            masg: 'We have sent a link on your email address. Click it to reset your password',
                            //items: json,
                            //noData:false,
                        })

                    }
                    else {
                        this.setState({
                            masg: 'Something went wrong',

                        })
                    }
                });
        }
    }

    openModal() {
        this.setState({
            visible: true
        });
    }


    closeModal() {
        this.props.close();
        this.setState({
            visible: false
        });
    }

    handleEmail = event => {
        this.setState({ email: event.target.value });
    }

    handlePass = event => {
        this.setState({ pass: event.target.value });
    }
    handleName = event => {
        this.setState({ name: event.target.value });
    }
    handleCpass = event => {
        this.setState({ cpass: event.target.value });
    }
    handleUname = event => {
        this.setState({ uname: event.target.value });
    }

     onPass(params) {
 
        this.props.closePopup(params);
        // event.preventDefault();
    }
    render() {
        const size = window.innerWidth;

        return (
            <>
            
                <div class="row" style={{ padding: "0px 0px 0px 15px" }}>
                    <div class={`col-md-6 ${size < 768 ? 'signMobile' : ''}`} style={{ backgroundColor: "#fff" }} align="center">
                        <img src={`${apiURL}assets/logo/sahapediaHdlogo.png`} className="" alt="logo" style={{width:"72%", marginTop: "15%" }} />
                        <div class="field"  id="donate1" style={{ marginTop: "38px"}}>                        
                            <a href=" https://www.sahapedia.org/donate" >  <button class="buttonStyl">Donate Now</button></a></div>
                            
                    </div>
                    <div class="col-md-6" style={{ color: "#000", padding: "0px 15px 0px 0px" }}>
                        <div class={`${size < 768 ? 'signInForm3' : 'signInForm'}`}>
                            <div className="result" style={{ color: "#008000", fontSize: "14px" }}>{this.state.massage}</div>
                            <p style={{ fontSize: "30px !important" }} align="left"><b class="signin-text">Forgot Password</b></p>
                            <br/>
        <p class="BoldTexts" id="BoldTexts1" style={{height:"14%",overflowY:"hidden"}} ><span class="Colortexts" onClick={() => this.props.signIn()} value="Open">{this.state.masg}</span></p>
                            
                            <div class="form-area">
                                <div class="field">
                                    <div class="left-field"><input type="text" placeholder="Email Address" onChange={this.handleEmail} /></div>
                                    <div className="result" style={{ color: "#FF0000", fontSize: "14px" }}>{this.state.massage2}</div>
                                </div>
                                
                                <br/>
                                <br></br><div class="field" id="field2" style={{marginTop:'8px'}}><button class="buttonStyl" onClick={this.dataSubmit}>Submit</button></div>
                                <p class="BoldTexts" style={{textAlign:'center',width:'100%'}}><span  style={{color:"#444444"}} onClick={() => this.props.signIn()} value="Open"  >Remember Password? <span class="Colortexts">Sign In</span></span></p>
                                <div class="or-signin-with">
                                    <div class="line"></div>
                                    <div class="text"></div>
                                </div>
                                {/* <div class="social-login">

											<a href="#" class="google"><i class="fa fa-google" aria-hidden="true"></i></a>
											<a href="#" class="facebook"><i class="fa fa-facebook" aria-hidden="true"></i></a>
											<a href="#" class="twitter"><i class="fa fa-twitter" aria-hidden="true"></i></a>
										</div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}