import axios from 'axios';
import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'reactjs-popup/dist/index.css';
// import saha from '../images/saha.png';
import ForgotPassword from './forgotPassword';
import Signup from './signup3';
import apiURL from './url';

export default class Sign extends Component {
    constructor(props) {
        super(props);
        this.state = {


            afterLoginUrl: this.props.afterLoginUrl,


            username: "",
            password: "",
            message: '',
            uname: '',
            visible: false,

            message1: '',
            message2: '',
            isChecked: false,
            date: '',
            userId: '',
            name:'',


        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);
        var chk = localStorage.getItem('check');

        if (chk == 'true') {
            var sdate = localStorage.getItem('date');
            var date = Math.round(Date.now() / 1000);
            var diffDate = date - sdate;
            if (diffDate > 1800) {
                navigator.cookieEnabled && localStorage.clear();

            }

        }

    }

    openModal() {
        this.setState({


            afterLoginUrl: '/search/myTrails',
            afterLoginLibrary: '/search/libraries',


            visible: true
        });
    }

openModal1(){
    this.setState({
        visible1:true
    })
}

    closeModal() {
        this.props.close();
        this.setState({
            visible: false,
            visible1:false
        });
    }

signInModal(){
    this.setState({
        visible: false,
        visible1:false
    });
}

    handleEmail = event => {
        this.setState({ username: event.target.value });
    }
    handlePass = event => {
        this.setState({ password: event.target.value });
    }
    handleSubmit(event) {
        var book = this.props.unbook;
        var id = this.props.bookID;
        var type = this.props.bookType;
        var relation = this.props.rel;
        var created = this.props.created;
        event.preventDefault();

        var count = 0;
        var username = this.state.username;
        var password = this.state.password;

        if (username == '') {
            count = count + 1;
            this.setState({

                message1: "Please enter valid email address."

            })
        }
        else {
            this.setState({
                message1: ""

            })
        }





        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(username)) {
            count = count + 1;
            this.setState({
                message1: "Please enter valid email address."
            })
        }
        else {
            this.setState({

                message1: ""

            })
        }
        if (password == '') {
            count = count + 1;
            this.setState({
                message2: "Please Enter Valid Password"
            })
        }
        else {
            this.setState({
                message2: ""
            })
        }
        if (count == 0) {
            fetch(`${apiURL}Api/login_new?username=${username}&password=${password}`)
                .then(res => res.json())
                .then(json => {
                    if (json) {
                        if (json.status == 200) {

                            this.setState({
                                message: json.msg,
                                uname: username,
                                userId: json.id

                            });
                            navigator.cookieEnabled && localStorage.setItem("date", Math.round(Date.now() / 1000));
                            var user2 = username.slice(0, 1);
                            navigator.cookieEnabled && localStorage.setItem('myData', user2);
                            navigator.cookieEnabled && localStorage.setItem('User', this.state.userId);
                            if (book == '') {
                                this.test(id, type, relation, created);
                            }
                            else {
                                this.test2(book);
                            }

                            if (this.props.afterLoginUrl) {
                                window.location.href = this.props.afterLoginUrl
                            }
                            else {
                                window.location.reload();
                            }




                        }
                        else {
                            this.setState({

                                message: 'Enter Valid Username Or Password',

                            })
                        }


                    }
                    else {
                        this.setState({
                            message: 'Something Went Wrong',

                        })
                    }

                });
        }


    }
    test(id, type, relation, created) {
        var keyword = this.props.searchqry;
        var user = localStorage.getItem('User');

        fetch(`${apiURL}Api/addBookmark?id=${id}&entityType=${type}&relation=${relation}&user=${user}`)
            .then(res => res.json())
            .then(json => {
                if (json) {
                    // window.location.reload();
                    this.setState({
                        items: json,
                        noData: false,
                    })

                }
                else {
                    this.setState({
                    })
                }
            });

    }
    test2(id) {
        fetch(`${apiURL}Api/deleteBookmark?id=${id}`)
            .then(res => res.json())
            .then(json => {
                if (json) {
                    // window.location.reload();
                    this.setState({
                        f: json,
                        noData: false,
                    })
                }
                else {
                    this.setState({
                    })
                }
            });

    }
    componentWillUpdate(){
        if(this.state.name == 'true'){
            this.closeModal();
        }
    }

    postData(ev) {

        ev.preventDefault()
        const username = this.state.username
        const password = this.state.password
        this.setState({
            loading: true
        })

        // make API call
        const data = {
            username,
            password,
        }
        axios({
            method: 'post',
            url: `${apiURL}master/login_new`,
            data: "username=" + data.username + "&password=" + data.password,
        })
            .then(response => {
                console.log(response.data);
                this.setState({
                    loading: false,
                    result: response.data
                })
                this.alertboxShow();
            });


    }
    handleChangeCheckBox = event => {

        this.setState({ isChecked: event.target.checked });
        navigator.cookieEnabled && localStorage.setItem("check", event.target.checked);

    }



    render() {
        // alert('hii'+this.props.afterLoginUrl);
        const size = window.innerWidth;
        var user = this.state.uname;

        return (
            <>

                <div class="row" style={{ padding: "0px 0px 0px 15px" }}>
                    <div class={`col-md-6 ${size < 768 ? 'signMobile' : ''}`} style={{ backgroundColor: "#fff" }} align="center">
                    
                        <img src={`${apiURL}assets/logo/sahapediaHdlogo.png`} className="signinlogo1" alt="logo" style={{width:"64%"}} />
                        <div class="field" id="donate" style={{ marginTop: "50px" }}>
                            <a href=" https://www.sahapedia.org/donate" >     <button class="buttonStyl">Donate Now</button></a></div>

                            
                    </div>
                    <div class="col-md-6" style={{ color: "#000", padding: "0px 15px 0px 0px" }}>

                        <div class="signInForm">
                            <div className="result" style={{ color: "#FF0000", fontSize: "14px" }}>{this.state.message}</div>
                            <p style={{ fontSize: "30px !important" }} align="left"><b class="signin-text">Sign In</b></p>

                            <p class="BoldTexts" id="BoldTexts">New User?<span class="Colortexts" onClick={() => this.openModal()} value="Open"  > Create an account</span></p>

                            <Modal visible={this.state.visible} width="800" height="450" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                                <div style={{ backgroundColor: "#e2e2e2" }}>
                                    <div class="row" style={{ marginLeft: "0px" }}>
                                        <div class="col-md-6" style={{ backgroundColor: "#fff",width:"800px"}}></div>
                                        <div class="col-md-6" style={{ color: "grey", padding: "10px 30px", fontSize: "20px" }} align="right">
                                            <a href="javascript:void(0);" style={{ color: "grey" }} onClick={() => this.closeModal()}>X</a>
                                        </div>
                                    </div>

                                    <Signup signIn={() => this.signInModal()}></Signup>


                                </div>
                            </Modal>
                            <Modal visible={this.state.visible1} width="800" height="550" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                                <div style={{ backgroundColor: "#e2e2e2" }}>
                                    <div class="row" style={{ marginLeft: "0px" }}>
                                        <div class="col-md-6" style={{ backgroundColor: "#fff",width:"800px"}}></div>
                                        <div class="col-md-6" align={`${size < 768 ? "center" : "right"}`} style={{ color: "grey", padding:"10px 30px", fontSize: "20px" }} >
                                            <a href="javascript:void(0);" style={{ color: "grey",marginLeft:`${size < 768 ?'320px':''}` }} onClick={() => this.closeModal()}>X</a>
                                        </div>
                                    </div>

                                    <ForgotPassword signIn={() => this.signInModal()}></ForgotPassword>


                                </div>
                            </Modal>

                            <div class="form-area">
                                <form >
                                    <div class="field"><input type="text" placeholder="Username or email" id="username" onChange={this.handleEmail} required /></div>
                                    <div className="result" style={{ color: "#FF0000", fontSize: "14px" }}>{this.state.message1}</div>
                                    <div class="field"><input type="password" placeholder="Password" id="password" onChange={this.handlePass} required /></div>
                                    <div className="result" style={{ color: "#FF0000", fontSize: "14px" }}>{this.state.message2}</div>
                                    <div class="keeplogin" id="keeplogin"><label><input type="checkbox" checked={this.state.checked} onChange={this.handleChangeCheckBox} />Keep me signed in</label></div>

                                    <input type="hidden" value={this.state.afterLoginUrl} />

                                    <div class="field" id="field"  onClick={this.handleSubmit}><button style={{marginTop:'-10px'}} class="buttonStyl">Sign In</button></div>
                                    <p class="BoldTexts" style={{textAlign:'center',width:'100%'}}><span class="Colortexts" onClick={() => this.openModal1()} value="Open"  > Forgot Password ?</span></p>

                                </form>
                            </div>

                        </div>

                    </div>
                </div>
            </>
        );
    }
}