import React, { Component } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { BASE_API_URL } from '../../services/apiService';
import Bookmark from '../bookmark4';
import Footer from '../footer';
import icon1 from '../images/icon-1.png';
import '../library/libraryStyle.css';
import Social from '../socialicon';

export default class Content extends Component {
    constructor(props) {
		super(props);
		this.state={
            f:[],
            id:'',
            display: 6,
        }
        this.loadMore = this.loadMore.bind(this);
    }
    loadMore() {
        this.setState((prev) => {
          return {display: prev.display + 6};
        });
      }
    test(ev){
			fetch(`${BASE_API_URL}Api/deleteBookmark?id=${ev}`)		
			.then(res => res.json())
			.then(json => {
				if (json){
                    // window.location.reload();
					this.setState({
						f: json,
						noData:false,
				})
			}
			else{
				this.setState({		
				})
			}
			});
		
        }

    render() { 
        var entity = this.props.Entity;
        const regex = /<[^>]*>/g;
        const shareUrl = window.location.href;
        const title = 'PCM';
        var items = this.props.Data;
        return ( 
            <div>
                {items.status == 'false' ? '':
                <main>
                    {items.slice(0, this.state.display).map(item=>(
                <div class="blog-card">
                    <a href = {item.id ? '/search/timelineDetail/'+item.title+'/'+item.id : '#'}>
                    {/* <a href = {item.id ? '/search/timelineDetail/'+item.title+'/'+item.id : '#'}> */}
                    <img class="card-image2" onError={(e)=>{e.target.onerror = null; e.target.src=`${BASE_API_URL}/assets/images/dimage.jpg`}} src={item.thumbnail_url ? item.thumbnail_url : `${BASE_API_URL}/assets/images/dimage.jpg`} width="100%" height="206px"/>
                    </a>
                    <div class="card-left2">
                    <a href = {item.id ? '/search/timelineDetail/'+item.title+'/'+item.id : '#'}>    
                    <div class="card-title-library">
                    <LinesEllipsis
                            text={item.title.replace(regex,'').replace(/((&nbsp;))*/gmi,'')}
                            onReflow={this.handleReflow}
                            maxLine={2}
                            basedOn='words'
                        />
                        {/* {item.title ? renderHTML(item.title.slice(0,40)) :' '} */}
                        </div>
                    <div class="card-description-library">
                    <LinesEllipsis
                            text={item.summary.replace(regex,'').replace(/((&nbsp;))*/gmi,'')}
                            onReflow={this.handleReflow}
                            maxLine={3}
                            basedOn='words'
                        />
                        {/* {item.summary ? renderHTML(item.summary) : ''}... */}
                    </div>
                    </a>

                    {item.Author && item.Author.map(items => (
                    <div class="card-author-library">{items.name ? items.name : ''}</div>
                    ))}
                    <div class="card-icons-library">
                    <Popup trigger={<img class="card-icon-img-library" src={icon1} alt="share" />} position="left center">
                            <div><Social type={'other'} title={item.title} summary={item.summary} url={`${window.location.origin}/search/timelineDetail/${item.title}/${item.id}`}></Social></div>
                        </Popup>
                        <Bookmark style={{height:"40px", width:"50px"}}  id={item.id} type={item.f_master_meta_entity_type} relation={'6'} user={navigator.cookieEnabled && localStorage.getItem('User')}></Bookmark>
                        {/*<img class="card-icon-img-library" src={icon2} alt="bookmark"  onClick={() => this.test(item.id)} />*/}
                    </div>
                    </div>
                </div>

                ))}
                {this.state.display < items.length &&
                        <div class="load-wrapper">
                        <div class="load-btn"  onClick={this.loadMore}>Load More</div>
                      </div>
            //  <button  type="button" className="load-more">Load more</button>
          }
                </main>
    } <Footer></Footer>
            </div>
         );
    }
}
 
