import React, { Component } from 'react';
// import saha from '../images/saha.png';
import 'reactjs-popup/dist/index.css';
import apiURL from './url';

export default class Cookiedetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            name: '',
            pass: '',
            cpass: '',
            uname: '',
            mobile: '',
            dob: '',
            org_or_ind: 'Individual',
            messemail: '',
            massage: '',
            massage1: '',
            massage2: '',
            massage3: '',
            massage4: '',
            masg:'Enter your email address and get a link to reset your password',
        }
      
        
    }
    refresh(){
        window.location.reload();
    }
    openModal() {
        this.setState({
            visible: true
        });
    }


    closeModal() {
        this.props.close();
        this.setState({
            visible: false
        });
    }

    render() {
        const size = window.innerWidth;

        return (
            <>
            
                <div class="row" style={{ padding: "0px 0px 0px 15px" }}>
                    <div class={`col-md-6 ${size < 768 ? 'signMobile' : ''}`} style={{ backgroundColor: "#fff" }} align="center">
                        <img src={`${apiURL}assets/logo/sahapediaHdlogo.png`} className="" alt="logo" style={{width:"72%", marginTop: "15%" }} />
                        <div class="field"  id="donate1" style={{ marginTop: "38px"}}>                        
                        <a href=" https://www.sahapedia.org/donate" >  <button class="buttonStyl">Donate Now</button></a></div>        
                    </div>
                    <div class="col-md-6" style={{ color: "#000", padding: "0px 15px 31px 0px" }}>
                    <div class={`${size < 768 ? 'signInForm3' : 'signInForm'}`}>
                    <div className="result" style={{ color: "#008000", fontSize: "14px" }}>{this.state.massage}</div>
                    <p style={{ fontSize: "30px !important" }} align="left"><b class="signin-text">Cookies denied</b></p><br/>
                    <div class="form-area"><br/>
                    <div>
                        Your browser has cookies disabled.Make sure that your cookies are enabled and try again.
                    </div>
                    
                    <div class="field" id="field" style={{marginTop:'90px'}} onClick={() => this.refresh()} value="Open" ><button class="buttonStyl">Refresh</button></div>
                    <div class="or-signin-with">
                        <div class="line"></div>
                        <div class="text"></div>
                    </div>
                    </div>
                    </div>
                    </div>
                </div>
            </>
        );
    }
}