import { actionTypes } from './action';

export const actionCreators = {
    landingPage : {
        setThemeList: (themes) => ({
            type: actionTypes.landingPage.THEME_FILTERS,
            payload: {
                themeList: themes
            }
        }),
        selectAllTheme: (themeList) => ({
            type: actionTypes.landingPage.SELECT_ALL_THEMES,
            payload: {
                themeList
            }
        }),
        deselectAllTheme: () => ({
            type:actionTypes.landingPage.DESELECT_ALL_THEMES
        }),
        themeItemClick: (themeId) => ({
            type: actionTypes.landingPage.THEME_ARTICLE_FILTER,
            payload: {
                themeId: themeId
            }
        }),
        themeFilterItemClick: (filter) => ({
            type: actionTypes.landingPage.CUSTOM_DD_THEME_FILTER,
            payload: {
                filter
            }
        }),
        setProjectId: (projectId) => ({
            type: actionTypes.landingPage.SET_PROJECT_ID,
            payload: {
                projectId
            }
        }),
        setProjectThemeId: (themeId) => ({
            type: actionTypes.landingPage.SET_PROJECT_THEME_ID,
            payload: {
                themeId
            }
        }),
        setThemePois: (themePOI) => ({
            type: actionTypes.landingPage.SET_POIS,
            payload: {
                themePOI
            }
        }),
        removeThemePois: (identifier_id) => ({
            type: actionTypes.landingPage.REMOVE_POIS,
            payload: {
                themePoiId: identifier_id
            }
        })
    }
}