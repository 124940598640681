import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { importAll } from "../../Helpers/utility";
import { BASE_API_URL, makeAPICall, REQUEST } from "../../services/apiService";
import CardListing from "../common/card-listing";
import LoaderScreen from "../common/loader";
import RecommendList from "../common/recommendList";
import RecommendProjects from "../common/recommendProjects";
import References from "../common/references";
import Social from "../common/socialArticle";
import Tags from "../common/tags";
import Text from "../common/text";
import Footer from "../footer";
import GalleryImg from "../gallery/galleryImg";
import Header from "../header";
import Map from "../Map/map";
import Banner from "./banner";

/* global $ */
export default function TrialDetail({ Article = null }) {
  const [entityDetail, setEntityDetail] = useState(null);
  const [visibleReadmore, setVisible] = useState(false);
  const [entityProjects, setEntityProjects] = useState([]);
  const [entityRelation, setEntityRelation] = useState({
    curated: null,
    automated: null,
  });
  const [slider, setSlider] = useState(null);
  const isLoadedCount = useRef(0);
  const autoSuggestion = useRef(6);
  const pageType = useRef("story");
  const { articleId } = useParams();
  const { articleName } = useParams();
  const article = useRef(null);
  const [readMore, setReadMore] = useState("");

  const images = importAll(
    require.context("../../assets/img", false, /\.(png|jpe?g|svg)$/)
  );

  useEffect(() => {
    let unique_article_id = articleId;
    if (unique_article_id == undefined) {
      unique_article_id = Article.article.id;
      pageType.current = "theme";
    }

    article.current = unique_article_id;
    if (unique_article_id !== undefined) {
      getEntityDetails(unique_article_id);
      getEntityProjects(unique_article_id);
      getEntityRelations(unique_article_id);
    }

    $(window).on("scroll", function () {
      if ($(window).scrollTop() > 100) {
        $(".shHeader ").addClass("smHeader");
      } else {
        //remove the background property so it comes transparent again (defined in your css)
        $(".shHeader ").removeClass("smHeader");
        $(".shHeader ").removeClass("card-icon-img");
      }
    });
  }, []);

  const getEntityDetails = (article_id) => {
    let reqParam = {
      type: REQUEST.POST,
      url: "Entity/getEntityDetail",
      data: JSON.stringify({
        entityType: "story",
        id: article_id,
      }),
    };
    makeAPICall(reqParam)
      .then((result) => {
        if (result && result.data && result.data.status !== "false") {
          let res = result.data[0];
          setEntityDetail(res);

          let img = [];
          res.embedded_ImageGallery?.forEach((carouselImgList) => {
            carouselImgList.images.forEach((image) => {
              img.push({
                description: image.description,
                image: BASE_API_URL + image.file_path,
                caption: image.label,
              });
            });
          });
          res.standalone_images?.forEach((standaloneImg) => {
            img.push({
              description: standaloneImg.description
                ? standaloneImg.description
                : "",
              image: BASE_API_URL + standaloneImg.file_path,
              caption: standaloneImg.description ? standaloneImg.label : "",
            });
          });
          console.log("from article", img);
          setSlider(img);
        }
        isLoadedCount.current++;
      })
      .catch((err) => {
        console.log(err);
        isLoadedCount.current++;
      });
  };

  const getEntityRelations = (article_id) => {
    let reqParam = {
      type: REQUEST.POST,
      url: "Entity/getEntityRelations",
      data: JSON.stringify({
        relation: "curatedAndAutomated_recommendation",
        entity_id: article_id,
        backend:''
      }),
    };
    makeAPICall(reqParam)
      .then((result) => {
        if (result) {
          let res = result.data;
          let autoRec = [];
          let curatedRec = [];
          res?.forEach((recommendation) => {
            if (
              recommendation.recommendation_type === "automated_recommendation"
            )
              autoRec.push(recommendation);
            else if (
              recommendation.recommendation_type === "curated_recommendation"
            )
              curatedRec.push(recommendation);
          });
          setEntityRelation({
            automated: autoRec,
            curated: curatedRec,
          });
          if (autoRec) {
            setVisible(true);
          }
        }
        isLoadedCount.current++;
      })
      .catch((err) => {
        console.log(err);
        isLoadedCount.current++;
      });
  };

  const getEntityProjects = (article_id) => {
    let reqParam = {
      type: REQUEST.POST,
      url: "Entity/getEntityProjects",
      data: JSON.stringify({
        id: article_id,
      }),
    };
    makeAPICall(reqParam)
      .then((result) => {
        if (result) {
          let res = result.data;
          setEntityProjects(
            res["primary_projects"].concat(res["secondary_projects"])
          );
        }
        isLoadedCount.current++;
      })
      .catch((err) => {
        console.log(err);
        isLoadedCount.current++;
      });
  };

  if (isLoadedCount.current < 2) {
    return <LoaderScreen />;
  }

  return (
    <div className="wrapper Timeline-Page">
      {entityProjects.length > 0 && (
        <Header projectDirectory={entityProjects} entityDet={entityDetail} pagename={"trails"} />
      )}
      <section className="mainPlaceholder articlePlaceMain">
        {entityDetail && (
          <Banner
            bannerDetail={entityDetail}
            articleName={articleName}
            articleID={article.current}
            type={pageType.current}
          />
        )}

        <section className="articlePageTheme museumPageTheme">
          <div className="container MuseumText">
            <div className="row">
              <div className="col-md-7 textAlign1">
                {entityDetail && pageType.current === "article" ? (
                  <Text text={entityDetail} />
                ) : (
                  <Text
                    text={entityDetail}
                    themePOI={Article?.theme_poi}
                    type={pageType.current}
                  />
                )}
                {entityDetail && entityDetail.tags && (
                  <Tags taglist={entityDetail.tags} />
                )}

                {entityDetail && (
                  <References
                    notes={entityDetail.notes}
                    bibliography={entityDetail.bibliography}
                    explore={entityDetail.exploremore}
                  />
                )}

                <div className="artNotesTabSocial shSocialSecMuseum clearfix">
                  <ul className="socialMuseum socialNew2">
                    <Social
                      url={`${window.location.origin}/search/trailDetail/${entityDetail.title}/${entityDetail.id}`}
                      title={entityDetail.title}
                      summary={entityDetail.summary}
                    ></Social>
                  </ul>
                  {entityDetail?.License?.length > 0 && (
                    <a
                      href="/search/license"
                      className="someRight"
                      style={{ display: "flex" }}
                    >
                      <img src={images["ic_rights@2x.png"].default} alt="" />{" "}
                      <span>{entityDetail?.License[0]?.title}</span>
                    </a>
                  )}
                </div>
              </div>

              <div className="col-md-4 textAlign3">
                <div className="moreMainSec museumMoreSec">
                  {entityRelation.curated && (
                    <RecommendList recommendations={entityRelation.curated} />
                  )}

                  {entityProjects.length > 0 && (
                    <RecommendProjects projects={entityProjects} />
                  )}
                </div>
              </div>
            </div>
            {visibleReadmore == true ? (
              <div className="row">
                <div className="col-md-12">
                  <div className="read-more-text">{readMore}</div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </section>
        <section class="">
          <div class="container museumRelation">
            <div class="row">
              {entityRelation &&
                entityRelation.automated?.map((relation, index) => (
                  <>
                    {autoSuggestion.current > index && (
                      <CardListing
                        setReadMore={setReadMore}
                        cardDetail={entityDetail.id}
                        type={pageType.current}
                      />
                    )}
                  </>
                ))}
            </div>
            {autoSuggestion.current < entityRelation.automated.length - 1 ? (
              <div class="row">
                <div class="col-md-12 text-center">
                  <button
                    class="btn btn-load"
                    onClick={() => (autoSuggestion.current += 6)}
                  >
                    Load more
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </section>
        {/* image Gallery */}
        {/* {slider?.length > 0 && <ReactFullscreenSlideshow displayOverlay={false} images={slider} currentSlideIndex={2} BannerImgIndex={2} cycle={true} />} */}
        <Footer />
      </section>

      {entityDetail && pageType.current === "story" && (
        <Map pagetype={pageType.current} Locations={entityDetail.attachPoi} />
      )}

      {entityDetail && slider?.length > 0 && (
        <GalleryImg
          carouselImg={entityDetail.embedded_Carousel}
          imageGallery={entityDetail.embedded_ImageGallery}
          slideImg={slider}
        />
      )}
    </div>
  );
}
