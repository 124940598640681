import React, { useEffect, useState } from 'react';
import { importAll } from '../../Helpers/utility';
import { BASE_API_URL, makeAPICall, REQUEST } from '../../services/apiService';

export default function Bannerfull({ bannerDetail, articleName,articleID, type = 'article' }) {
    const [entityLang, setEntityLang] = useState([]);
    const images = importAll(require.context('../../assets/img', false, /\.(png|jpe?g|svg)$/));

    useEffect(() => {
        // Language
        console.log(bannerDetail);
        let data = { "entityType": "timeline", "entity_id": articleID };
        let reqParams = {
            type: REQUEST.POST,
            url: 'Entity/getEntityLanguages',
            data: JSON.stringify(data)
        };
        makeAPICall(reqParams)
            .then((result) => {
                if (result.data)
                    setEntityLang(result.data);
            })
            .catch(err => console.log(err));
    }, []);

    return (
        <section className="shBanner">
            <div className="shBnIfr">
            <a style={{marginTop:"-30px",color:"#444444"}} href= {articleID ? '/search/timelineDetail/'+articleName+'/'+articleID : '#'} className="resize1 row" ><img src={images['resize.png'].default} alt="" />Exit Fullscreen</a>
                <iframe className="banner-image" src={`${BASE_API_URL}blogs/timeline?id=${bannerDetail.id}`} alt="" ></iframe>
            </div>
        </section>

    );
}