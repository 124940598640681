import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Article from '../article/article';

export default function GalleryLanding() {
    const { id } = useParams();
    const imgGallery = { id };
    useEffect(() => {
        console.log(imgGallery);
    })

    return (
        <div class="img_galleries">
            { id && <Article ImgGallery={imgGallery} />}
        </div>
    );
}