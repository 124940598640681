import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Social from '../components/common/socialArticle';


export default class Static extends Component {
    state = {  }
    render() { 
        return ( 
            <>
            <Helmet>
                <meta property="og:caption" content="testing" data-react-helmet="true"  />
                <meta property="og:keywords" content="testing" data-react-helmet="true" />
                <meta property="og:description" content="testing" data-react-helmet="true"/>
                <meta property="og:image" content="https://map.sahapedia.org/admin/assets/images/2021032614493469804_Banner.jpeg" data-react-helmet="true"/>
                {/* <meta property="fb:pages" content="143902715620459" data-react-helmet="true"/> */}
            </Helmet>
            <h1>
                This is testing page
            </h1>
            <div class="staticSocial">
                <Social url = "http://65.0.167.59/search/entity" summary = ""></Social>
            </div>

            <header style={{ backgroundImage: `url("https://map.sahapedia.org/admin/assets/images/2021032614493469804_Banner.jpeg")` }}/>


            </>
         );
    }
}
 
