import React, { Component } from 'react';
import {FiSearch} from 'react-icons/fi';

export default class HeaderSearch extends Component {
    constructor(props){
        super(props);
        
        this.state = {
          keyword : '',
          type: 'all',
          lang: ''

        }
        this.updateInput = this.updateInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        }
        updateInput(event){
            this.setState({keyword : event.target.value})
            var lang = this.props.language;
            var type = this.props.entity;
                if(event.key === 'Enter'){
                    window.open(
                        `/search?keyword=${event.target.value}&type=${type ? type : this.state.type}&lang=${lang ? lang : this.state.lang}`, "_blank");
                }
            }
        handleSubmit(event){
            var lang = this.props.language;
            var type = this.props.entity;
            window.open(
                `/search?keyword=${this.state.keyword}&type=${type ? type : this.state.type}&lang=${lang ? lang : this.state.lang}`, "_blank");
        }
    render() { 
        var keyword = this.props.keyword;

        return ( 
            <>

                <input type="text" class="search_mobile" name="keyword" id="headSearch" onKeyPress={this.updateInput} onChange={this.updateInput} defaultValue={keyword} style={{borderBottom:"1px solid #e6e6e6",marginTop:"4px",marginLeft:"-270px",height:"25px" }} />
                <FiSearch id="search-form-icon" class="search-conMobile" style={{top:"12px",right:"120px"}} type="submit" onClick={this.handleSubmit}></FiSearch>

            </>
         );
    }
}
 
