import React from 'react';
import './EmbedImage.css';

const EmbedImage = (props) => {
    return (
        <div id={props.id} className="embed-image">
            <img onClick={props.onClickEmbed} src={props.image} alt="image" />
            <div className="banner-details">
                {/* <div className="left-des">
                    {props.caption}
                </div> */}
                <div className="right-des">
                    {props.description}
                </div>
            </div>
        </div>
    )
}
export default EmbedImage;