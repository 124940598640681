import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Article from './components/article/article';
import Articlenew from './components/article/articleNew';
import Blogs from './components/blog/bannerBlog';
import Blog from './components/blog/blog';
import Home from './components/Build/home';
import About from './components/ContentMenu/about';
import CreateTrail from './components/crowdsource_trails/createTrail';
import EditTrail from './components/crowdsource_trails/editTrail';
import CrowdsourceTrails from './components/crowdsource_trails/trails';
import TrailView from './components/crowdsource_trails/trailsView';
import TrailViewFull from './components/crowdsource_trails/trailViewFull';
import Demo from './components/demo';
import unsubscribe from './components/unsubscribe';
import GalleryLanding from './components/landing-gallery/gallery-landing';
import VideoLanding from './components/landing-video/video-landing';
import LandingPage from './components/landingpage/landingPage';
import LandingPagePOI from './components/landingpage/landingPagePOI';

// import './index.css';
import Library from './components/library/library';
import TimelineListing from './components/listing-page/timeline-listing';
import TrailsListing from './components/listing-page/trails-listing';
import Museum from './components/museum/museum';
import Timer from './components/pop/Timer';
import ResetPassword from './components/resetPassword';
import Search from './components/search/search';
import License from './components/static/license';
import Theme from './components/theme/theme';
import ThemeMapFull from './components/theme/ThemeMapFull';
import Timeline from './components/timeline/timeline';
import TimelineDetail from './components/timeline/timelineDetail';
import TimelineDetailFull from './components/timeline/timelineDetailFullScreen';
import TrailDetailFull from './components/trails/trailDetailFullScreen';
import Trails from './components/trails/trails';
import TrialDetail from './components/trails/trialDetail';
import './css/common-module.css';
import './css/style_museum.css';
import store from './redux/store';
import NavigateVideo from '../src/components/landingpage/navigateVideo';
// import './css/style.css';
import * as serviceWorker from './serviceWorker';
import Subscribe from './components/subscribe';    






class Sahapedia extends React.Component {
    render() {
        return (
            <Router>
                <div>
                    <Switch>
                        {/* Remove Search from url */}
                        <Route exact path={`/`} component={Home} />
                        <Route exact path={`/timelines`} component={Timeline} />
                        {/* Remove Search from url */}


                        <Route exact path="//:langId" component={About} />
                        <Route exact path="/about" component={About} />
                        <Route exact path="/user/unsubscribe" component={About} />

                        <Route exact path="/about/:projectId" component={About} />
                        <Route exact path="/about/:projectId/:langId" component={About} />
                        <Route exact path="/home/:projectID" component={LandingPage} />
                        <Route exact path="/home/:projectID" component={LandingPage} />
                        <Route exact path="/home/POI/:projectID/:keywordId" component={LandingPagePOI} />
                        {/* <Route exact path="/home/:projectID/poi/:keywordId/:langId" component={LandingPage} /> */}
                        <Route exact path="/home/:projectID/:langId" component={LandingPage} />
                        <Route exact path="/home/:projectID/:langId/:themeID" component={LandingPage} />
                        <Route exact path="/article/:articleName/:articleId" component={Article} />
                        <Route exact path="/theme/:themeName/:themeId" component={Theme} />
                        <Route exact path="/themeMap/:themeName/:themeId" component={ThemeMapFull} />
                        <Route exact path="/gallery/:galleryName/:id" component={GalleryLanding} />
                        <Route exact path="/video/:videoName/:id" component={VideoLanding} />
                        <Route exact path="/list/timelines" component={TimelineListing} />
                        <Route exact path="/list/trails" component={TrailsListing} />
                        <Route exact path="/libraries" component={Library} />
                        <Route exact path="/timelines" component={Timeline} />
                        <Route exact path="/trails" component={Trails} />
                        <Route exact path="/trailDetail/:articleName/:articleId" component={TrialDetail} />
                        <Route exact path="/timelineDetail/:articleName/:articleId" component={TimelineDetail} />
                        <Route exact path="/museum/:museumName/:articleId" component={Museum} />
                        <Route exact path="/blogs" component={Blogs} />
                        <Route exact path ="/Timer" component={Timer}/>
                        <Route exact path ="/navigate/:projectId" component={NavigateVideo}/>
                        <Route exact path="/home" component={Home} />
                        <Route exact path="/license" component={License} />
                        <Route exact path="/unsubscribe/:data" component={Subscribe} />

                        <Route exact path="/blogDetail" component={Blog} />
                        
                        
                        

                        <Route exact path="search/gallery/:id" component={GalleryLanding} />
                        <Route exact path="search/video/:id" component={VideoLanding} />
                        <Route exact path={`/search/trailDetailFullScreen/:articleName/:articleId`} component={TrailDetailFull} />
                        <Route exact path={`/search/timelineDetailFullScreen/:articleName/:articleId`} component={TimelineDetailFull} />
                        <Route exact path={`/search/libraries`} component={Library} />
                        <Route exact path={`/search/timelines`} component={Timeline} />
                        <Route exact path={`/search/trails`} component={Trails} />
                        <Route exact path={`/search/trailDetail/:articleName/:articleId`} component={TrialDetail} />
                        <Route exact path={`/search/timelineDetail/:articleName/:articleId`} component={TimelineDetail} />
                        <Route exact path={`/search`} component={Search} />
                        <Route exact path={`/search/entity`} component={Demo} />
                        <Route exact path={`/search/article/:articleName/:articleId`} component={Article} />
                        <Route exact path={`/search/articlenew/:articleName/:articleId`} component={Articlenew} />

                        <Route exact path={`/search/museum/:museumName/:articleId`} component={Museum} />
                        <Route exact path={`/search/theme/:themeName/:themeId`} component={Theme} />
                        <Route exact path={`/search/blogs`} component={Blogs} />
                        <Route exact path={`/blogDetail`} component={Blog} />
                        <Route exact path={'/search/Timer'} component={Timer}/>
            
                        <Route exact path={`/search/home`} component={Home} />
                        
                        <Route exact path={`/search/blogDetail`} component={Blog} />
                        <Route exact path={`/search/license`} component={License} />
                        <Route exact path={`/search/home`} component={Home} />
                        <Route exact path={`/user/unsubscribe/:data`} component={Subscribe} />


                        <Route exact path={`/search/myTrails`} component={CrowdsourceTrails} />
                        <Route exact path={`/createTrail`} component={CreateTrail} />
                        <Route exact path={`/editTrail`} component={EditTrail} />
                        <Route exact path={`/TrailView/:name/:id/:user`} component={TrailView} />
                        <Route exact path={`/TrailViewFull/:name/:id/:user`} component={TrailViewFull} />
                        <Route exact path={`/ResetPassword`} component={ResetPassword} />

                        <Route exact path="/:projectDirectory/about/:projectId/:langId" component={About} />
                        <Route exact path="/:projectDirectory/:projectID" component={LandingPage} />
                        <Route exact path="/:projectDirectory/POI/:projectID/:keywordId" component={LandingPagePOI} />
                        <Route exact path="/:projectDirectory/theme/:themeName/:themeId" component={Theme} />
                        <Route exact path="/:projectDirectory/:projectID/:langId" component={LandingPage} />
                        <Route exact path="/:projectDirectory/:projectID/:langId/:themeID" component={LandingPage} />
                        
                    </Switch>
                </div>
            </Router>
        )
    };
}

ReactDOM.render(
    <Provider store={store}>
        <Sahapedia />
    </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
