import { useParams } from 'react-router-dom';
import Article from '../article/article';

export default function VideoLanding() {
    const { id } = useParams();
    const videoGallery = { id };

    return (
        <div class="video-content">
            { id && <Article VideoGallery={videoGallery} />}
        </div>
    );
}