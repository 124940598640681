export const actionTypes = {
    landingPage : {
        SELECT_ALL_THEMES: 'select_all_themes',
        DESELECT_ALL_THEMES: 'deselect_all_themes',
        THEME_FILTERS: 'get_theme_filters',
        CUSTOM_DD_THEME_FILTER: 'get_dd_theme_filter',
        THEME_ARTICLE_FILTER: 'get_theme_article_filter',
        REMOVE_ARTICLE_FILTER: 'remove_aricle_filter',
        SET_PROJECT_ID: 'set_project_id',
        SET_PROJECT_THEME_ID: 'set_project_theme_id',
        SET_POIS: 'set_pois',
        REMOVE_POIS: 'remove_pois'
    },
    user: {
        SET_POST: 'set_post',
        ADD_POST: 'add_post',
        REMOVE_POST: 'remove_post'
    }
}