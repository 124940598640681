import React, { useState } from 'react';
import Modal from 'react-awesome-modal';
import LOGO from '../assets/img/ic_arrow_down.svg';
import Signin from '../components/signin2';
import { useWindowSize } from '../Helpers/hooks/windows';
import { hamburgerSchema } from '../Helpers/schema';
import Timer from "../components/pop/Timer";
export default function HamburgerManu() {
	const logout = () => {
		localStorage.clear();
		window.location.reload();
	}
	const closeModal=()=>{
		setVisible(false);
	}
	const openModal=(url)=>{
		setVisible(true);
		setUrl(url);
	}
	const windowSize = useWindowSize();
	const [loginUrl, setUrl] = useState();
	const [visiblee, setVisible] = useState(false);


	return (
		<><Timer></Timer>
		<section className="shAllMenu" style={{ paddingTop: '0' }} >
			< div className="container">
				{
					hamburgerSchema && hamburgerSchema.map((menu, index) => (
						<div className="row" key={menu._id}>
							{menu.items.map((menuItem) => (
								<div className={`${menu.selector}`} key={menuItem._id}>
									{
										windowSize.width > 768
											? (<div className="stFtTopItem">
												<h5>{menuItem.title} <img src={LOGO} alt="" /></h5>
												<ul className="stFtTopList">
													{menuItem.listItem.map((item) => (
														<li key={item._id}>
															<a href={item.urlTo}>{item.title}</a>
														</li>
													))}
												</ul>
											</div>)
											: <MenuItem menuItem={menuItem} />
									}

								</div>
							))}
						</div>
					))
				}
				{windowSize.width < 768 && (
					<div className="row">
						<div className="col-md-12">
							<ul className="shMenuAccountList">
								<li>
								{navigator.cookieEnabled && localStorage.getItem("User") ?
									<a target="_blank" href="#">My Account</a>
								:''
									}
						</li>
								<li>
								{navigator.cookieEnabled && localStorage.getItem("User") ? 
									<a 
									target="_blank" 
									href={
										localStorage.getItem('User') ? 
										'/search/myTrails' : '#'}>My Trails
									</a>:
									<a 
									target="_blank" 
									onClick={() => openModal("/search/myTrails")}
									>My Trails
									</a>
									}
								</li>
								<li>
								{navigator.cookieEnabled && localStorage.getItem("User") ?
									<a 
									target="_blank" 
									href={localStorage.getItem('User') 
									? '/search/libraries' : '#'}>My Library
									</a>
								: <a
								class="dropdown-item"
								target="_blank"
								onClick={() => openModal(`/search/libraries`)}
							  >
								My Library
							  </a>
								}
								</li>
								<li className="shMenuSignOut">
									{navigator.cookieEnabled && localStorage.getItem('myData') ?
										<a onClick={() => logout()} >Sign Out</a>
										:
										<a onClick={() => openModal()} >Sign In</a>
									}
								</li>
							</ul>
						</div>
					</div>)}
				
			</div >
		</section >
		{visiblee?
		<Modal
		visible={visiblee}
		width="800"
		height="550"
		effect="fadeInUp"
		onClickAway={() => openModal()}
	  >
		<div style={{ backgroundColor: "#e2e2e2" }}>
		  <div class="row" style={{ marginLeft: "0px" }}>
			<div class="col-md-6" style={{ backgroundColor: "#fff" }}></div>
			<div
			  class="col-md-6"
			  align="right"
			  style={{
				color: "grey",
				padding: "10px 30px",
				fontSize: "20px",
			  }}
			>
			  <a
				href="javascript:void(0);"
				style={{ color: "grey" }}
				onClick={() => closeModal()}
			  >
				X
			  </a>
			</div>
		  </div>
		  <Signin
			afterLoginUrl={loginUrl}
			id="signIN"
			close={()=>closeModal()}
		  ></Signin>
		  {/* <Signup afterLoginUrl={this.state.afterLoginUrl} id = "signUP"></Signup> */}
		</div>
	  </Modal>
	  :""}
	  </>
	);
}

const MenuItem = ({ menuItem }) => {
	const [active, setActive] = useState(false);

	return (
		<div className="stFtTopItem">
			<h5 onClick={e => setActive(!active)} className={active ? 'active' : ''}>{menuItem.title} <img src={LOGO} alt="" /></h5>
			<ul className="stFtTopList" style={{ display: active ? 'block' : 'none' }}>
				{menuItem.listItem.map((item) => (
					<li key={item._id}>
						<a href={item.urlTo}>{item.title}</a>
					</li>
				))}
			</ul>
		</div >
	)
}