import React, { useState } from 'react';
import { importAll } from '../../Helpers/utility';
import { BASE_API_URL } from '../../services/apiService';

export default function Bannerfull({ bannerDetail, articleName, articleID, type = 'article' }) {
    const [entityLang, setEntityLang] = useState([]);
    const images = importAll(require.context('../../assets/img', false, /\.(png|jpe?g|svg)$/));

    return (
        <section className="shBanner">
            
            <div className="shBnIfr">
            <a style={{marginTop:"-30px",color:"#444444"}} href= {articleID ? '/search/trailDetail/'+articleName+'/'+articleID : '#'} className="resize1 row" ><img src={images['resize.png'].default} alt="" />Exit Fullscreen</a>
                <iframe className="banner-image" style={{marginTop:"-30px"}} src={`${BASE_API_URL}blogs/trail?id=${bannerDetail.id}`} alt="" ></iframe>
            </div>
        </section>

    );
}