import React, { useState } from 'react';
import { MdCopyright } from 'react-icons/md';
import renderHTML from 'react-render-html';
import logo from '../assets/img/logo@2x.png';
import { useWindowSize } from '../Helpers/hooks/windows';
import { footerSchema } from '../Helpers/schema';
import {GetProjectDirectory} from '../Helpers/utility';

/* global $ */
export default function Footer() {
    const size = useWindowSize();
    const [projectDir, setProjectDir] = useState('');
    GetProjectDirectory().then(d => {
        setProjectDir(d);
    });
    return (
        <footer className="stFooter">
            
            <div className="stFootLogo">
                <img src={logo} alt="" />
            </div>

            <div className="stFootTop">
                <div className="container">
                    <div className="row">
                        {footerSchema?.elements.map((footerItem, index) => (
                            <div className={footerItem.selector} key={`footer_section_${index}`}>
                                <div className="container">
                                    <div className="row">
                                        {footerItem.data.map((footerLinks, index) => (
                                            <div className={footerLinks.selector} key={`footer_${index}`}>
                                                {footerLinks.items.map((footerLink, i) => (
                                                    <div>
                                                        {
                                                            size.width > 768 ? (
                                                                <div className="stFtTopItem" key={footerLink.title}>
                                                                    <h5>{footerLink.title}</h5>
                                                                    <ul className="stFtTopList">
                                                                        {footerLink.listItem.map(link => (
                                                                            <li key={link._id}>
                                                                                <a href={link.urlTo}>{link.title}</a>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            ) : <FooterItem footerListItem={footerLink} />
                                                        }
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="stFootBtm text-center">
                <a href={footerSchema.bottomTabs[0].urlTo}>
                    <MdCopyright style={{verticalAlign:"-1.5px",display: "inline-block"}}></MdCopyright>&nbsp;{renderHTML(footerSchema.bottomTabs[0].title)}
                    </a>
                {footerSchema?.bottomTabs.slice(1).map(tab => (
                    <>
                    <a key={tab._id} href={tab.urlTo.indexOf("DIRNAME") >= 0 ? tab.urlTo.replace('DIRNAME', projectDir) : tab.urlTo}>{renderHTML(tab.title)}</a>
                    </>
                ))}
            </div>
        </footer>

    );
}


const FooterItem = ({ footerListItem }) => {
    const [active, setActive] = useState(false);

    return (
        <div className="stFtTopItem" key={footerListItem.title}>
            <h5 onClick={e => setActive(!active)}>{footerListItem.title}</h5>
            <ul className="stFtTopList" style={{ display: active ? 'block' : 'none' }}>
                {footerListItem.listItem.map(link => (
                    <li key={link._id}>
                        <a href={link.urlTo}>{link.title}</a>
                    </li>
                ))}
            </ul>
        </div>
    );
}