import { navigateFilterSchema } from '../../Helpers/schema';
import $ from 'jquery';

$( ".stFtTopItem:has(ul)" ).click(function(){ // When a li that has a ul is clicked ...
	$(this).toggleClass('active'); // then toggle (add/remove) the class 'active' on it. 
});

export default function NavigateFilter() {
    return (
        <section className="shAllMenu" id="navigate-menu">
            <div className="container">
                {navigateFilterSchema?.map(navigationFilterRow => (
                    <div key={navigationFilterRow._id} className={navigationFilterRow.selector}>
                        {navigationFilterRow.listItems?.map(navigationFilters => (
                            <div className={navigationFilters.selector}>
                                <details className="stFtTopItem">
                                    <summary class="Navigate-mobile">{navigationFilters.title}</summary>
                                    <ul className="stFtTopList">
                                        {navigationFilters.sublistItems?.map(subNavFilter => (
                                            <li key={subNavFilter._id}>
                                                <a href={subNavFilter.urlTo}>{subNavFilter.title}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </details>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </section>
    );
}