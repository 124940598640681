import { useEffect, useState } from 'react';
import { formatDate, importAll } from '../../Helpers/utility';
import { makeAPICall, REQUEST } from '../../services/apiService';
import moment from 'moment';



const images = importAll(require.context('../../assets/img', false, /\.(png|jpe?g|svg)$/));

/*  global $ */
export default function Authors({ authors, createdAt,type }) {
    useEffect(() => {
        $.each(authors, function (index, value) {
            $('#author_entity_' + index + ', .shToolCl').on('click', function () {
                var auth_desc = $('#author_description .shAuthBio');

                $.each(auth_desc, function (i, node) {
                    $(node).removeClass("active");
                });
            });
            $('#author_entity_' + index).on('click', function () {
                $('#author_description_' + index).addClass("active");
                $(".authorDess").show();
                $(".authorDess").removeClass('hide');
            });
            // $('#closepop').on('click', function () {
            //     $('.shAuthBio').removeClass("active");
            //     $('.shAuthBio').addClass("inActive");
            // });
            $("#closepop").click(function(){
                $(".authorDess").hide();
                $(".authorDess").addClass('hide');
            });
        });
        // If an event gets to the body
        $(".shBanner ").click(function(e){
            //$("[id^=author_description_]").fadeOut().removeClass("active");
            if (e.target.className.indexOf("clicked-show-author") > 0) return;
            if (e.target.className.indexOf("popup-closeit") < 0 || e.target.className.indexOf("popup-close-x") > 0) {
              $(".authorDess").hide();
              $(".authorDess").addClass('hide');
            } else {
                $('[id^=author_description_]').addClass("active");
                $(".authorDess").show();
                $(".authorDess").removeClass('hide');
            }
            console.log(e.target.className);
        });

        // Prevent events from getting pass .popup
        // $(".authorDess").click(function(e){
        //     e.stopPropagation();
        // });
    });


    return (
        <div className="shDateSec clearfix">
            <div>
                <div className="float-left clicked-show-author">
                {authors && authors.map((author, index) => (
                    // <h3 key={index} id={`author_entity_${index}`}>{author.name}</h3>
                    <h3 className="float-left clicked-show-author" key={index} id={`author_entity_${index}`} style={{paddingRight: "2px"}}>{author.name}<span>{index < authors.length - 1 && ", "}</span></h3>

                    ))}
                </div>
            </div>
            <p className="float-left" id="entity_date" style={{margin:"4px 0px 0px 70px",color:type==='videoGallery'?"#444444":"#fff"}}>
                {createdAt ? moment(createdAt).format("MMM YYYY") : ''}
            </p>

            <div id="author_description clicked-show-author">
                {authors && authors.map((author, index) => (
                    <AuthorDetail author={author} index={index} />
                ))}
            </div>
        </div>
    );
}


const AuthorDetail = ({ author, index }) => {
    const [authorPop, setAuthorPop] = useState(null);
    const [active, setActive] = useState(true);

    useEffect(() => {
        let reqParam = {
            type: REQUEST.GET,
            params: {
                id: author.id
            }
        }
        if (author.resource_type === 'Individual') {
            reqParam['url'] = 'Project/getResourceDetailInd';
        } else {
            reqParam['url'] = 'Project/getResourceDetailOrg';
        }
        makeAPICall(reqParam).then((result) => {
            console.log("Author Detail : ", result);
            if (result.data.length > 0) {
                setAuthorPop(result.data[0])
            }
        })
    }, []);

    function onClosePopup() {
        $(".authorDess").hide();
        $(".authorDess").addClass('hide');
        $(".authorDess .shAuthBio").removeClass("active");
    }

    return (
        <h3 class="authorDess">
            {authorPop && (<div className="shAuthBio popup-closeit" id={`author_description_${index}`}>
                <a  className="shToolCl popup-closeit" onClick={() => {onClosePopup()}}>
                    <img src={images['ic_tool_cl@2x.png'].default} id = "closepop" alt="" className="popup-closeit popup-close-x" />
                </a>
                <div className="shAuthImg popup-closeit">
                    {authorPop && (<img src={authorPop.thumbnail_url} alt=""className="popup-closeit" />)}
                </div>
                {authorPop && (<div className="shAuthDesc popup-closeit">{authorPop.description}</div>)}
            </div>)}
        </h3>
    );
}