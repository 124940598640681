import { importAll } from "../../Helpers/utility";
import CardListing from "../common/card-listing";
import Footer from "../footer";
import Header from "../header";

export default function TimelineListing() {
    const images = importAll(require.context('../../assets/img', false, /\.(png|jpe?g|svg)$/));

    return (
        <div className="wrapper">
            <Header />

            <section className="mainPlaceholder libraryPlaceMain">
                <section className="shSearhTypeView">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="shSearchInputSec text-center">
                                    <h2 className="myLib">All Trails</h2>
                                    <p>Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="shSearchResFound">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="shSearchResFoundBlk clearfix">
                                    <div className="shResultCount">84 Items</div>
                                    <div className="shSearhResFoundFilter clearfix">
                                        <div className="shSearhResFoundFilterItem">
                                            <label>Filter by</label>
                                            <div className="shSearhResFoundFilterItemSel">
                                                <div className="dropdown">
                                                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        All <img src={images['sel_down@2x.png'].default} alt />
                                                    </button>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <a className="dropdown-item" href="#">Action</a>
                                                        <a className="dropdown-item" href="#">Another action</a>
                                                        <a className="dropdown-item" href="#">Something else here</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="shSearchResListSec shSearchLibSec">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <a href="#" className="createNewTrig">
                                    <i />
                                    Create New Trail
                                </a>
                            </div>
                            {trails?.map(trail => (
                                <div className="col-md-4">
                                    <CardListing />
                                </div>
                            ))}
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <button className="btn btn-load">Load more</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
            <Footer />
        </div>
    );
}

const trails = [{}, {}, {}, {}, {}, {}];