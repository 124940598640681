import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import { HiOutlinePlusCircle } from 'react-icons/hi';
import Loader from "react-loader-spinner";
import { BASE_API_URL } from '../../services/apiService';
import Header from '../header2';
import Signin from '../signin2';
import Content from './myTrails';
import Heading from './myTrailsSearchParams';
import Footer from '../footer';

// import './trailsStyle.css';
export default class CrowdsourceTrails extends Component {
    constructor(props) {
		super(props);
		this.state={
      items:[],
      entityType:[],
        }
    }
    getEntity()
    {
        fetch(`${BASE_API_URL}master/getProjectList`)		
        .then(res => res.json())
        .then(json => {
            if (json){
                this.setState({
        
                    entityType: json,
            })
        }
        else{
            this.setState({
                entityType:[],
    
            })
        }
        });
    }
    
    componentDidMount(){
            //this.getEntity();
            var user = localStorage.getItem('User');
            const query = new URLSearchParams(this.props.location.search);
            var qryStr = query.get('type');
            if ( qryStr == undefined ) {qryStr = ''}
            var type = qryStr;

			var keyword = this.props.searchqry;
			fetch(`${BASE_API_URL}Api/getMyTrailsList?user=${user}`)
            .then(res => res.json())
			.then(json => {
				if (json){
					this.setState({
						isLoaded: true,
						items: json,
						noData:false,
				})
			}
			else{
				this.setState({
					isLoaded: true,
					items:[],
					noData:true,
		
				})
			}
      });		
      
    }
    openModal() {
        this.setState({
            visible : true
        });
    }
    
    closeModal() {
        this.setState({
            visible : false
        });
    }
    render() { 
        const size = window.innerWidth;
        const query = new URLSearchParams(this.props.location.search);
        var qryStr = query.get('type');
        var user = localStorage.getItem('User');
        if ( qryStr == undefined ) {qryStr = ''}
        var type = qryStr;
        var { isLoaded, entityType, items} = this.state;
        if (!isLoaded){
          return  <div align="center"><Loader
          type="ThreeDots"
          color="#da3a50"
          height={100}
          width={100}
        //   timeout={10000} //3 secs
          align="center"
        /></div>;
		}
        else {
        return ( 
            <div>
                <Header></Header>
                <Heading ></Heading>
                <div class="row" style={{width:'100%',padding:'2%'}}>
                    <div class="col-md-5" style={{marginLeft:'-35px'}}>
                    </div>
                    {
                 navigator.cookieEnabled &&   localStorage.getItem('User')?
                    <div class="col-md-1 create1">
                        <a href={localStorage.getItem('User')?'/createTrail':'#'}>
                            <HiOutlinePlusCircle style={{fontSize:"34px",color:"#6b6b6b"}}/>
                        </a>
                    </div>
                    :
                    <div class="col-md-1 create1">
                        <a href="javascript:void(0);" onClick={() => this.openModal()}>
                            <HiOutlinePlusCircle style={{fontSize:"34px",color:"#6b6b6b"}}/>
                        </a>
                    </div>
                    }
                    {
                navigator.cookieEnabled &&    localStorage.getItem('User')?
                    <div class="col-md-2 create2"  style={{margin:"7px 0px 1px 0px",padding:"0px",color:"#6b6b6b",fontWeight:"600",fontFamily:"sans-serif"}}>
                        <a href={localStorage.getItem('User')?'/createTrail':'#'}>Create New Trail</a>
                    </div>
                    :
                    <div class="col-md-2 create2"  style={{margin:"7px 0px 1px 0px",padding:"0px",color:"#6b6b6b",fontWeight:"600",fontFamily:"sans-serif"}}>
                        <a href="javascript:void(0);" onClick={() => this.openModal()}>Create New Trail</a>
                    </div>
                    }
                    <div class="col-md-4">
                    </div>
                </div>
                {
            navigator.cookieEnabled &&    localStorage.getItem('User')?   
                <Content Data = {items} Entity = {type}></Content>
                :''
                }
                {
                this.state.visible?
                <Modal visible={this.state.visible} width="800" height="550" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                    <div  style={{backgroundColor:"#e2e2e2"}}>
                        <div class="row" style={{marginLeft:"0px"}}>
                            <div class="col-md-6" style={{backgroundColor:"#fff"}}>
                            </div>
                            <div class="col-md-6" align="right" style={{color:"grey",padding:"10px 30px",fontSize:"20px"}}>
                                <a href="javascript:void(0);" style={{color:"grey"}} onClick={() => this.closeModal()}>X</a>
                            </div>
                        </div>
                        <Signin bookID="" bookType="" rel="" unbook="" created="" close={() => this.closeModal()}></Signin>
                    </div>
                </Modal>
                :""
                }
                 <Footer></Footer>
            </div>
         );
    }
}
 
}
