import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { entityType, importAll } from '../../Helpers/utility';
import { BASE_API_URL, makeAPICall, REQUEST } from '../../services/apiService';
import LoaderScreen from '../common/loader';
import Header from '../header';
import LandingMap from '../Map/landingMap';
import Map from '../Map/map';





const License = [
    {
        title: 'License 1',
        url: 'http://localhost/license-1'
    },
    {
        title: 'License 2',
        url: 'http://localhost/license-2'
    },
    {
        title: 'License 3',
        url: 'http://localhost/license-3'
    },
]


/* global $ */
export default function ThemeFull({ Article = null, ImgGallery = null, VideoGallery = null }) {
    const [entityDetail, setEntityDetail] = useState(null);
    const [entityProjects, setEntityProjects] = useState([]);
    const [entityRelation, setEntityRelation] = useState({ "curated": null, "automated": null });
    const [slider, setSlider] = useState(null);
    const autoSuggestion = useRef(6);
    const isLoadedCount = useRef(0);
    const pageType = useRef('article');
    const { articleId } = useParams();
    const article = useRef(null);
    const [readMore, setReadMore] = useState("");

    const images = importAll(require.context('../../assets/img', false, /\.(png|jpe?g|svg)$/));

    useEffect(() => {
        let unique_article_id = articleId;
        if (unique_article_id == undefined && Article) {
            unique_article_id = Article.article.id;
            pageType.current = 'theme';
        } else if (unique_article_id == undefined && ImgGallery) {
            unique_article_id = ImgGallery.id;
            pageType.current = 'imgGallery';
        } else if (unique_article_id == undefined && VideoGallery) {
            unique_article_id = VideoGallery.id;
            pageType.current = 'videoGallery';
        }

        article.current = unique_article_id;
        if (unique_article_id !== undefined) {
            getEntityDetails(unique_article_id);
            getEntityProjects(unique_article_id);
            getEntityRelations(unique_article_id);
        }

        $(window).on("scroll", function () {
            if ($(window).scrollTop() > 100) {
                $(".shHeader ").addClass("smHeader");
            } else {
                //remove the background property so it comes transparent again (defined in your css)
                $(".shHeader ").removeClass("smHeader");
                $(".shHeader ").removeClass("card-icon-img");
            }
        });

    }, []);


    const getEntityDetails = (article_id) => {
        let reqParam = {
            type: REQUEST.POST,
            url: 'Entity/getEntityDetail',
            data: JSON.stringify({
                entityType: entityType[pageType.current],
                id: article_id
            })
        }

        makeAPICall(reqParam)
            .then((result) => {
                console.log({ "entity-details": result });
                if (result && result.data && result.data.status !== "false") {
                    let res = result.data[0];
                    setEntityDetail(res);

                    let img = [];
                    console.log("IMAGES PUSH start 1", img);
                    res.embedded_Carousel?.forEach((carouselImgList) => {
                        carouselImgList.images.forEach(image => {
                            img.push({ description: image.description, image: BASE_API_URL + image.file_path, caption: '' });
                        })
                    })

                    console.log("IMAGES PUSH start 2", img);

                    res.standalone_images?.forEach((standaloneImg) => {
                        console.log("standaloneImg", standaloneImg);
                        let a  = {description: pageType.current == 'imgGallery' ? standaloneImg.description : "",
                        image:  BASE_API_URL + standaloneImg.file_path ,
                        caption:  "", };
                        console.log("a : ", a);
                        // if(pageType.current == 'imgGallery'){
                            img.push(a);
                        // }
                        // else{
                        //     img.push();
                        // }
                        // console.log("IMAGES PUSH", img);
                    })
                    console.log("Slider", img);
                    setSlider(img)

                }
                isLoadedCount.current++;
            })
            .catch(err => { console.log(err); isLoadedCount.current++ });
    }

    // const getEntityRelations_curated = (article_id) => {
    //     let reqParam = {
    //         type: REQUEST.POST,
    //         url: 'Entity/getEntityRelations',
    //         data: JSON.stringify({
    //             relation: "curated_recommendation",
    //             entity_id: article_id
    //         })
    //     }
    //     makeAPICall(reqParam)
    //         .then((result) => {
    //             if (result) {
    //                 let res = result.data;
    //                 setEntityRelation({ ...entityRelation, "curated": res });
    //             }
    //             isLoadedCount.current++;
    //         })
    //         .catch(err => { console.log(err); isLoadedCount.current++ });
    // }

    const getEntityRelations = (article_id) => {
        let reqParam = {
            type: REQUEST.POST,
            url: 'Entity/getEntityRelations',
            data: JSON.stringify({
                relation: "curatedAndAutomated_recommendation",
                entity_id: article_id,
                backend:''
            })
        }
        makeAPICall(reqParam)
            .then((result) => {
                if (result && result.data && result.data.status !== "false") {
                    let res = result.data;
                    let autoRec = [];
                    let curatedRec = [];
                    res?.forEach((recommendation) => {
                        if (recommendation.recommendation_type === 'automated_recommendation')
                            autoRec.push(recommendation);
                        else if (recommendation.recommendation_type === 'curated_recommendation')
                            curatedRec.push(recommendation);
                    })
                    setEntityRelation({
                        "automated": autoRec,
                        "curated": curatedRec
                    });
                }
                isLoadedCount.current++;
            })
            .catch(err => { console.log(err); isLoadedCount.current++ });
    }

    const getEntityProjects = (article_id) => {
        let reqParam = {
            type: REQUEST.POST,
            url: 'Entity/getEntityProjects',
            data: JSON.stringify({
                id: article_id
            })
        }
        makeAPICall(reqParam)
            .then((result) => {
                if (result && result.data && result.data.status !== "false") {
                    let res = result.data;
                    setEntityProjects(res['primary_projects'].concat(res['secondary_projects']));
                }
                isLoadedCount.current++;
            })
            .catch(err => { console.log(err); isLoadedCount.current++ });
    }


    if (isLoadedCount.current < 2) {
        return <LoaderScreen />
    }

    return (
        
        <div className="wrapper"style={{marginTop:'-100px',maxWidth:'100%',overflowX:'hidden'}}>
            {entityProjects.length > 0 && <Header projectDirectory={entityProjects} entityDet = {entityDetail} />}

            <section className="mainPlaceholder articlePlaceMain">
                                <LandingMap pageType={'themeMap'} themeName={entityDetail.theme[0] ? entityDetail.theme[0].theme : ''} themeId={entityDetail.theme[0] ? entityDetail.theme[0].f_master_theme_id : ''} />
            </section>

            {entityDetail && pageType.current !== 'theme' && (<Map pagetype={pageType.current} Locations={entityDetail.attachPoi} />)}
</div>
    );
}
