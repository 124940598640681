import React, { Component } from 'react';
import Loader from "react-loader-spinner";
import apiURL from '../../components/url';
import Footer from '../footer';
import Header from '../header2';
import Content from './myLibrary';
import Heading from './myLibrarySearchParams';


export default class Library extends Component {
    constructor(props) {
		super(props);
		this.state={
      items:[],
      entityType:[],
        }
    }
    getEntity()
    {
        fetch(`${apiURL}master/getEntityTypeData`)		
        .then(res => res.json())
        .then(json => {
            if (json){
                this.setState({
        
                    entityType: json,
            })
        }
        else{
            this.setState({
                entityType:[],
    
            })
        }
        });
    }
    componentDidMount(){
            this.getEntity();
            var user = navigator.cookieEnabled && localStorage.getItem('User');
            const query = new URLSearchParams(this.props.location.search);
            var qryStr = query.get('type');
            if ( qryStr == undefined ) {qryStr = ''}
            var type = qryStr;

			var keyword = this.props.searchqry;
			fetch(`${apiURL}Api/getBookmarkList?relation=6&type=${type ? type : ''}&user=${user}`)		
			.then(res => res.json())
			.then(json => {
				if (json){
					this.setState({
						isLoaded: true,
						items: json,
						noData:false,
				})
			}
			else{
				this.setState({
					isLoaded: true,
					items:[],
					noData:true,
		
				})
			}
      });		
    }
    render() { 
        const query = new URLSearchParams(this.props.location.search);
        var qryStr = query.get('type');
        if ( qryStr == undefined ) {qryStr = ''}
        var type = qryStr;
        var { isLoaded, entityType, items} = this.state;
        if (!isLoaded){
          return  <div align="center"><Loader
          type="ThreeDots"
          color="#da3a50"
          height={100}
          width={100}
        //   timeout={10000} //3 secs
          align="center"
        /></div>;
		}
        else {
        return ( 
            <div>
                <Header></Header>
                <Heading Data = {items} Data2={entityType} Entity = {type}></Heading>
                <Content Data = {items} Entity = {type}></Content>
                <Footer></Footer>
            </div>
         );
    }
}
 
}
