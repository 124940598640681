import React, { Component } from 'react';
export default class Heading extends Component {
    constructor(props){
        super(props);
        }
    render() { 
        return ( 
            <div>
                    <div id="header">
                    <h1 class="header-h1">My Trails</h1>
                    <div class="sub-title"></div>
                    </div>
            </div>
         );
    }
}