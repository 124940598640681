import { concatMulti } from '../../Helpers/utility';

export default function RecommendList({ recommendations }) {
    return (
        <>
        {recommendations.length > 0 &&(
        <div className="moreTopicSec">
            <h2>More on this topic</h2>
            <ul className="moreList" id="moreList">
                {recommendations.length > 0 && recommendations.map((recommendation, index) => (
                    <li key={recommendation.title}>
                        {(() => {
                            if (recommendation.f_master_meta_entity_type == '15') {
                            return (
                                <a target="_blank" href={`/search/POI/${recommendation.title}/${recommendation.id}`}>
                                    <h3>{recommendation.title}</h3>
                                    <p>{concatMulti(recommendation.Author, ", ", "name")}</p>
                                </a>
                            )
                            } else if (recommendation.f_master_meta_entity_type == '1') {
                     var ttl= recommendation.title + ""
                            return (
                                <a target="_blank" href={`/search/article/${ttl.replace("?","-")}/${recommendation.id}`}>
                                    <h3>{recommendation.title}</h3>
                                    <p>{concatMulti(recommendation.Author, ", ", "name")}</p>
                                </a>
                            )
                            } else if (recommendation.f_master_meta_entity_type == '7') {
                                return (
                                    <a target="_blank" href={`/search/museum/${recommendation.title}/${recommendation.id}`}>
                                        <h3>{recommendation.title}</h3>
                                        <p>{recommendation.Author[0] ? concatMulti(recommendation.Author, ", ", "name") : recommendation.Mapper[0] ? recommendation.Mapper[0].name : ''}</p>
                                    </a>
                                )
                                } 
                                else if (recommendation.f_master_meta_entity_type == '29') {
                                    return (
                                        <a target="_blank" href={`/gallery/${recommendation.title}/${recommendation.id}`}>
                                            <h3>{recommendation.title}</h3>
                                            <p>{recommendation.Author[0] ? concatMulti(recommendation.Author, ", ", "name") : recommendation.Mapper[0] ? recommendation.Mapper[0].name : ''}</p>
                                        </a>
                                    )
                                    } 
                                else {
                            return (
                                <a target="_blank" href={`/video/${recommendation.title}/${recommendation.id}`}>
                                    <h3>{recommendation.title}</h3>
                                    <p>{concatMulti(recommendation.Author, ", ", "name")}</p>
                                </a>
                            )
                            }
                        })()}
                        {/* <a href={recommendation.url}>
                        </a> */}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    );
}
