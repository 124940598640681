import { useEffect, useState } from 'react';
import { formatDate, importAll } from '../../Helpers/utility';
import { makeAPICall, REQUEST } from '../../services/apiService';
import moment from 'moment';



const images = importAll(require.context('../../assets/img', false, /\.(png|jpe?g|svg)$/));

/*  global $ */
export default function Mapper({ authors, createdAt }) {
    useEffect(() => {
        console.log("mappers : ", authors);
        $.each(authors, function (index, value) {
            $('#mapper_entity_' + index ).on('click', function () {
                var auth_desc = $('#mapper_description .shAuthBio');

                $.each(auth_desc, function (i, node) {
                    $(node).removeClass("active");
                });
            });
            $('#mapper_entity_' + index).on('click', function () {
                $('#mapper_description_' + index).addClass("active");

            });
        });
        // $("#root").click(function(e){
        //     if (e.target.className.indexOf("clicked-show-author") > 0) return;
        //     if (e.target.className.indexOf("popup-closeit") < 0 || e.target.className.indexOf("popup-close-x") > 0) {
        //       $('[id^=mapper_description_]').removeClass("active");
        //     } else {
        //         $('[id^=mapper_description_]').addClass("active");
        //     }
        // });
    });


    return (
        <div className="shDateSec clearfix">
            <div id="mapper_entity" style={{float:"left"}} className="clicked-show-author">
                {authors && authors.map((author, index) => (
                    <div className="float-left clicked-show-author" key={index} id={`mapper_entity_${index}`} style={{paddingRight: "2px"}}>{author.name}<span>{index < authors.length - 1 && ", "}</span></div>
                ))}
            </div>
            <div className="float-left" id="entity_date" style={{ marginLeft: '15px' }}>
                {createdAt ? moment(createdAt).format("MMM YYYY") : ''}
            </div>

            <div id="mapper_description">
                {authors && authors.map((author, index) => (
                    <AuthorDetail author={author} index={index} />
                ))}
            </div>
        </div>
    );
}


const AuthorDetail = ({ author, index }) => {
    const [authorPop, setAuthorPop] = useState(null);

    useEffect(() => {
        let reqParam = {
            type: REQUEST.GET,
            params: {
                id: author.id
            }
        }
        if (author.resource_type === 'Individual') {
            reqParam['url'] = 'Project/getResourceDetailInd';
        } else {
            reqParam['url'] = 'Project/getResourceDetailOrg';
        }
        makeAPICall(reqParam).then((result) => {
            console.log("Author Detail : ", result);
            if (result.data.length > 0) {
                setAuthorPop(result.data[0])
            }
        })
    }, []);

    return (
        <h3>
            {authorPop && (<div className="shAuthBio  popup-closeit" id={`mapper_description_${index}`}>
                <a href="javascript:void(0);" className="shToolCl  popup-closeit">
                    <img src={images['ic_tool_cl@2x.png'].default} alt="" className="popup-closeit popup-close-x"/>
                </a>
                <div className="shAuthImg popup-closeit">
                    {authorPop && (<img src={authorPop.thumbnail_url} alt="" className="popup-closeit" />)}
                </div>
                {authorPop && (<div className="shAuthDesc popup-closeit">{authorPop.description}</div>)}
            </div>)}
        </h3>
    );
}