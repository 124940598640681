import $ from 'jquery';
import React, { useEffect } from 'react';
import renderHTML from 'react-render-html';
import LandingMap from '../Map/landingMap';
import Information from './museumInformation';
import Quick from './quickFacts';

export default function Text({ text, themePOI, type = 'article' ,themeId,themeName}) {
    useEffect(() => {
          let sideToggle = document.querySelector("a");
          let sideToggle2 = document.querySelector(".notes");

          if(sideToggle2 == ''){
          sideToggle.classList.add("shSidemenuCol");
          console.log(themePOI);
          $('.articleMainTxt a').attr('target', '_blank');
          }
          });
    return (
        <div className="articlePage">
            <div className="articleMainTxt">
                {/* <div ref="caron" /> */}
                {text?.f_master_meta_entity_type != '25' &&
                <>
                {text?.f_master_meta_entity_type != '9' &&
                    <div className="paraItalic timeline-summary">{text?.summary ? renderHTML(text.summary) : ''}</div>
                }       
                </>
    }
{/* {text?.f_master_meta_entity_type != '9' &&
                <div className="paraItalic timeline-summary">{text?.summary ? renderHTML(text.summary) : ''}</div>
    } */}
                {type === 'theme' && <LandingMap pageType={'theme'} themeId={themeId} themeName={themeName} />}
                {text?.f_master_meta_entity_type == '7' &&
                    <div className="more-section">
                        <Quick quick={text} />
                    </div>
                }
                {/* {mapSection && (<div style={{ height: '350px', marginBottom: '30px' }}><div id="map"></div></div>)} */}
                {text?.f_master_meta_entity_type != '25' &&
                <>
                {text?.f_master_meta_entity_type != '9' &&
                <div className="museumMainTxt">{text?.body_text_html ? renderHTML(text.body_text_html) : ''}</div>
            }       
            </>
}
                {text?.f_master_meta_entity_type == '7' && <Information information={text} />}
                {text?.f_master_meta_entity_type != '7' &&
                    <div class="article-supported-by">{text.Credits.attribution ? renderHTML(text.Credits.attribution):''}</div>
                }

            </div>
            {text?.Credit ?
            <p className="supportSec">{text?.Credit ? renderHTML(text.Credit) : ''}</p>
            : ''}
        </div>
    );
}