import React from 'react';
import { importAll } from '../../Helpers/utility';
import { BASE_API_URL } from '../../services/apiService';

export default function Trailfull({ id,user,name}) {
    const images = importAll(require.context('../../assets/img', false, /\.(png|jpe?g|svg)$/));
    console.log(id);
    console.log(user);
    console.log(name);
    return (
        <section className="shBanner">
            
            <div className="shBnIfr">
            <a style={{marginTop:"-30px",color:"#444444"}} href= {`${window.location.origin}/TrailView/${name}/${id}/${user}`} className="resize1 row" ><img src={images['resize.png'].default} alt="" />Exit Fullscreen</a>
                <iframe className="banner-image" style={{marginTop:"-30px"}} src={`${BASE_API_URL}blogs/crowdsource_trail?id=${id}&user_id=${user}`} alt="" ></iframe>
            </div>
        </section>

    );
}