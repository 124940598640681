export const footerSchema = {
  elements: [
    {
      _id: "yrKYBxGY",
      selector: "col-md-8",
      data: [
        {
          _id: "lGtDSSjE",
          selector: "col-md-4",
          items: [
            {
              title: "Type",
              listItem: [
                {
                  _id: "nf95ew0iOV",
                  urlTo:
                    "https://map.sahapedia.org/search/?keyword=&type=Article&lang=",
                  title: "Article",
                },
                {
                  _id: "nf95ewti14",
                  urlTo:
                    "https://map.sahapedia.org/search/?keyword=&type=Video&lang=",
                  title: "Video",
                },
                {
                  _id: "nf95ewna6G",
                  urlTo:
                    "https://map.sahapedia.org/search/?keyword=&type=Image gallery&lang=",
                  title: "Image Gallery",
                },
          
                {
                  _id: "nf95ewVBXJ",
                  urlTo:
                    "https://map.sahapedia.org/search/?keyword=&type=POI&lang=",
                  title: "Map",
                },
                {
                  _id: "nf95ewDgPx",
                  urlTo:
                    "https://map.sahapedia.org/search/?keyword=&type=Timeline&lang=",
                  title: "Timeline",
                },
                {
                  _id: "nf95ew07bG",
                  urlTo:
                    "https://map.sahapedia.org/search/trails",
                  title: "Trail",
                },
            
              ],
            },
          ],
        },
        {
          selector: "col-md-4",
          items: [
            {
              title: "Cultural Mapping",
              listItem: [
                {
                  _id: "nog66vdCEs",
                  urlTo: "https://map.sahapedia.org/inkochi/20",
                  title: "Kochi",
                },
                {
                  _id: "nog66v0Zxh",
                  urlTo: "https://map.sahapedia.org/inpune/1",
                  title: "Pune",
                },
                {
                  _id: "nog66vjgCA",
                  urlTo: "https://map.sahapedia.org/inindore/13",
                  title: "Indore",
                },
                {
                  _id: "nog66vn24l",
                  urlTo: "https://map.sahapedia.org/inahmedabad/16",
                  title: "Ahmedabad",
                },
                {
                  _id: "nog66vUGRC",
                  urlTo: "https://map.sahapedia.org/inprayagraj/17",
                  title: "Prayagraj",
                },
                {
                  _id: "nog66veNJi",
                  urlTo: "https://map.sahapedia.org/inshillong/14",
                  title: "Shillong",
                },
                {
                  _id: "nog66vJfiZ",
                  urlTo: "https://map.sahapedia.org/ingoa/15",
                  title: "Goa",
                },
                {
                  _id: "nog66vnHIU",
                  urlTo:
                    "https://www.sahapedia.org/mapping-indian-handcrafted-textiles",
                  title: "Textiles",
                },
                {
                  _id: "nog66vFMtX",
                  urlTo: "https://www.sahapedia.org/bhakti-sufi-traditions",
                  title: "Bhakti",
                },
                {
                  _id: "nog66vlS4n",
                  urlTo: "https://map.sahapedia.org/museums/18",
                  title: "Museums",
                },
              ],
            },
          ],
        },
        {
          selector: "col-md-4",
          items: [
            {
              title: "Programmes",
              listItem: [
                {
                  _id: "S99a7xi1PB",
                  urlTo:
                    "https://www.sahapedia.org/sahapedia-unesco-fellowship",
                  title: "Sahapedia UNESCO Fellowship",
                },
                {
                  _id: "msp46a2ctH",
                  urlTo:
                    "https://www.sahapedia.org/sahapedia-explore-fellowship",
                  title: "EXPLORE Fellowship",
                },
                {
                  _id: "msp46aATjh",
                  urlTo: "https://www.sahapedia.org/sahapedia-frames",
                  title: "FRAMES Photography Grant",
                },
                {
                  _id: "msp46ac89M",
                  urlTo:
                    "https://www.sahapedia.org/sahapedia-film-fellowship-apply",
                  title: "Film Fellowship",
                },
              ],
            },
            {
              title: "Activities",
              listItem: [
                {
                  _id: "msp46aCPl7",
                  urlTo: "https://www.sahapedia.org/heritage-experiences",
                  title: "Heritage Experiences",
                },
                {
                  _id: "msp46a3vVK",
                  urlTo: "https://www.sahapedia.org/baithak",
                  title: "Baithaks",
                },
                {
                  _id: "msp46ajI8K",
                  urlTo: "https://www.sahapedia.org/conferences",
                  title: "Conferences",
                },
                {
                  _id: "msp46ak8mt",
                  urlTo: "https://www.sahapedia.org/workshops",
                  title: "Workshops",
                },
                {
                  _id: "msp46aSDd5",
                  urlTo:
                    "https://www.sahapedia.org/india-heritage-walk-festival",
                  title: "India Heritage Walk Festival",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      selector: "col-md-4",
      data: [
        {
          selector: "col-md-6",
          items: [
            {
              title: "In Focus",
              listItem: [
                {
                  _id: "msp46anizC",
                  urlTo: "https://www.sahapedia.org/samvad",
                  title: "Samvad",
                },
                {
                  _id: "msp46aOUWu",
                  urlTo: "https://www.sahapedia.org/saha-sutra",
                  title: "Saha Sutra",
                },
                {
                  _id: "msp46aRxyV",
                  urlTo: "https://www.museumsofindia.org/",
                  title: "Museums of India",
                },
              ],
            },
            {
              title: "More",
              listItem: [
                {
                  _id: "msp46a1SP4",
                  urlTo: "https://map.sahapedia.org/search/license",
                  title: "Copyright",
                },
                {
                  _id: "msp46ac60U",
                  urlTo: "https://www.sahapedia.org/donation-now",
                  title: "Donate",
                },
  
              ],
            },
          ],
        },
        {
          selector: "col-md-6",
          items: [
            {
              title: "Follow Us",
              listItem: [
                {
                  _id: "msp46agzmQ",
                  urlTo: "https://www.linkedin.com/company/sahapedia.org/mycompany/?viewAsMember=true",
                  title: "LinkedIn",
                },
                {
                  _id: "msp46actgT",
                  urlTo: "https://www.facebook.com/Sahapedia",
                  title: "Facebook",
                },
                {
                  _id: "msp46arYUk",
                  urlTo: "https://www.instagram.com/sahapedia/",
                  title: "Instagram",
                },
                {
                  _id: "msp46aYCYp",
                  urlTo: "https://twitter.com/Sahapedia",
                  title: "Twitter",
                },
                {
                  _id: "msp46aRxnY",
                  urlTo: "https://wa.me/+919810826158",
                  title: "Whatsapp",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  bottomTabs: [
    {
      _id: "apoj1a6kzZY",
      urlTo: "#",
      title: "2021 Sahapedia",
    },
    {
      _id: "pbgyi35zFps",
      urlTo: "https://map.sahapedia.org/DIRNAME/about/1/22",
      title: "About",
    },
    {
      _id: "p849vghhz9u",
      urlTo: "https://www.sahapedia.org/contact-us",
      title: "Contact",
    },
    {
      _id: "okvt5v6dXSQ",
      urlTo: "https://www.sahapedia.org/sahapedia-team",
      title: "Team",
    },
    {
      _id: "mkgi36b1GfZ",
      urlTo: "https://www.sahapedia.org/terms-use",
      title: "Terms of Use",
    },
    {
      _id: "a165ia6Dn6o",
      urlTo: "https://www.sahapedia.org/privacy-policy",
      title: "Privacy Policy",
    },
  ],
};

export const hamburgerSchema = [
  {
    _id: "KB3Md5",
    selector: "col-md-3",
    items: [
      {
        _id: "2IQtKD",
        title: "Domains",
        listItem: [
          {
            _id: "BY1GRN",
            title: "Knowledge Traditions",
            urlTo: "https://www.sahapedia.org/domains/knowledge-traditions",
          },
          {
            _id: "iSV1SC",
            title: "Visual & Material Arts",
            urlTo: "https://www.sahapedia.org/domains/visual-and-material-arts",
          },
          {
            _id: "tagjqg",
            title: "Performing Arts",
            urlTo: "https://www.sahapedia.org/domains/performing-arts",
          },
          {
            _id: "984Acz",
            title: "Literature & Language",
            urlTo: "https://www.sahapedia.org/domains/literature-and-languages",
          },
          {
            _id: "xfR8yK",
            title: "Practices & Rituals",
            urlTo: "https://www.sahapedia.org/domains/practices-and-rituals",
          },
          {
            _id: "PplNe2",
            title: "Histories",
            urlTo: "https://www.sahapedia.org/domains/histories",
          },
          {
            _id: "JgfEmZ",
            title: "Institutions",
            urlTo: "https://www.sahapedia.org/domains/institutions",
          },
          {
            _id: "wpY0PT",
            title: "People",
            urlTo: "https://www.sahapedia.org/domains/people",
          },
          {
            _id: "iM8VW8",
            title: "Built Spaces",
            urlTo: "https://www.sahapedia.org/domains/built-spaces",
          },
          {
            _id: "nMfNFh",
            title: "Natural Environment",
            urlTo: "https://www.sahapedia.org/domains/natural-environment",
          },
        ],
      },
      {
        _id: "3RPkvN",
        title: "Cultural Mapping",
        listItem: [
          {
            _id: "UtMiuk",
            urlTo: "https://map.sahapedia.org/inkochi/20",
            title: "Kochi",
          },
          {
            _id: "TTadFi",
            urlTo: "https://map.sahapedia.org/inpune/1",
            title: "Pune",
          },
          {
            _id: "uUumnh",
            urlTo: "https://map.sahapedia.org/inindore/13",
            title: "Indore",
          },
          {
            _id: "5jGiWO",
            urlTo: "https://map.sahapedia.org/inahmedabad/16",
            title: "Ahmedabad",
          },
          {
            _id: "Kv0qUe",
            urlTo: "https://map.sahapedia.org/inprayagraj/17",
            title: "Prayagraj",
          },
          {
            _id: "FErWet",
            urlTo: "https://map.sahapedia.org/inshillong/14",
            title: "Shillong",
          },
          {
            _id: "IN0f4U",
            urlTo: "https://map.sahapedia.org/ingoa/15",
            title: "Goa",
          },
          {
            _id: "5YmhnN",
            urlTo:
              "https://www.sahapedia.org/mapping-indian-handcrafted-textiles	",
            title: "Textiles",
          },
          {
            _id: "Ut4iEg",
            urlTo: "https://www.sahapedia.org/bhakti-sufi-traditions",
            title: "Bhakti",
          },
          {
            _id: "ThAAWa",
            urlTo: "https://map.sahapedia.org/museums/18",
            title: "Museums",
          },
        ],
      },
      {
        _id: "XFna8M",
        title: "Type",
        listItem: [
          {
            _id: "UfYOwx",
            urlTo:
              "https://map.sahapedia.org/search/?keyword=&type=article&lang=",
            title: "Article",
          },
          {
            _id: "5G7KJV",
            urlTo:
              "https://map.sahapedia.org/search/?keyword=&type=video&lang=",
            title: "Video",
          },
          {
            _id: "k9tVP9",
            urlTo:
              "https://map.sahapedia.org/search/?keyword=&type=image_gallery&lang=",
            title: "Image Gallery",
          },
          {
            _id: "kOTOxi",
            urlTo: "https://www.sahapedia.org/sahapedia-frames",
            title: "Photo Essay",
          },
          {
            _id: "8wEADb",
            urlTo: "https://map.sahapedia.org/search/?keyword=&type=POI&lang=",
            title: "Map",
          },
          {
            _id: "V4E4z9",
            urlTo:
              "https://map.sahapedia.org/search/?keyword=&type=timeline&lang=",
            title: "Timeline",
          },
          {
            _id: "e3p1rr",
            urlTo:
              "https://map.sahapedia.org/search/trails",
            title: "Trail",
          },
          {
            _id: "Q2fupg",
            urlTo: "#",
            title: "Library Artefacts",
          },
        ],
      },
      {
        _id: "ywaFxD",
        title: "Language",
        listItem: [
          {
            _id: "2XXO1b",
            urlTo: "https://map.sahapedia.org/search/?keyword=&type=0&lang=22",
            title: "English",
          },
          {
            _id: "oBNm0d",
            urlTo: "https://map.sahapedia.org/search/?keyword=&type=0&lang=7",
            title: "Hindi",
          },
          {
            _id: "Letliq",
            urlTo: "https://map.sahapedia.org/search/?keyword=&type=0&lang=12",
            title: "Marathi",
          },
          {
            _id: "T0bsTg",
            urlTo: "https://map.sahapedia.org/search/?keyword=&type=0&lang=18",
            title: "Tamil",
          },
          {
            _id: "tv3cm8",
            urlTo: "https://map.sahapedia.org/search/?keyword=&type=0&lang=11",
            title: "Malayalam",
          },
          {
            _id: "q9UEzx",
            urlTo: "https://map.sahapedia.org/search/?keyword=&type=0&lang=3",
            title: "Bengali",
          },
          {
            _id: "VtxAN4",
            urlTo: "https://www.sahapedia.org/lang/urdu",
            title: "Urdu",
          },
          {
            _id: "ssNWD9",
            urlTo: "https://www.sahapedia.org/lang/chhattisgarhi",
            title: "Chhattisgarhi",
          },
        ],
      },
    ],
  },
  {
    _id: "HzGfau",
    selector: "col-md-3 col-xs-12",
    items: [
      {
        _id: "s3dEr7",
        title: "Programmes",
        listItem: [
          {
            _id: "uMwATq",
            title: "Sahapedia UNESCO Fellowship",
            urlTo: "https://www.sahapedia.org/sahapedia-unesco-fellowship",
          },
          {
            _id: "CKUpx8",
            title: "EXPLORE Fellowship",
            urlTo: "https://www.sahapedia.org/sahapedia-explore-fellowship",
          },
          {
            _id: "r1TI5W",
            title: "FRAMES Photography Grant",
            urlTo: "https://www.sahapedia.org/sahapedia-frames",
          },
          {
            _id: "dPloDQ",
            title: "Film Fellowship",
            urlTo: "https://www.sahapedia.org/sahapedia-film-fellowship-apply",
          },
        ],
      },
      {
        _id: "JCW93Z",
        title: "In Focus",
        listItem: [
          {
            _id: "HpHG2r",
            title: "Samvad",
            urlTo: "https://www.sahapedia.org/samvad",
          },
          {
            _id: "PYSpgg",
            title: "Saha Sutras",
            urlTo: "https://www.sahapedia.org/saha-sutra",
          },
          {
            _id: "J6daUH",
            title: "Museums of India",
            urlTo: "https://www.museumsofindia.org/",
          },
        ],
      },
      // {
      //   _id: "xznfHy",
      //   title: "Activities",
      //   listItem: [
      //     {
      //       _id: "f3nq79",
      //       title: "Heritage Experiences",
      //       urlTo: "https://www.sahapedia.org/heritage-experiences",
      //     },
      //     {
      //       _id: "jZWIMI",
      //       title: "Baithaks",
      //       urlTo: "https://www.sahapedia.org/baithak",
      //     },
      //     {
      //       _id: "xtYaI3",
      //       title: "Conferences",
      //       urlTo: "https://www.sahapedia.org/conferences",
      //     },
      //     {
      //       _id: "CuKHEX",
      //       title: "Workshops",
      //       urlTo: "https://www.sahapedia.org/workshops",
      //     },
      //     {
      //       _id: "8h6dus",
      //       title: "India Heritage Walk Festival",
      //       urlTo: "https://www.sahapedia.org/india-heritage-walk-festival",
      //     },
      //   ],
      // },
      {
        _id: "X9opeU",
        title: "More",
        listItem: [
          {
            _id: "pFatbz",
            title: "About",
            urlTo: "#",
          },
          {
            _id: "5fDeiZ",
            title: "Team",
            urlTo: "#",
          },
          {
            _id: "UtpG2M",
            title: "Contact",
            urlTo: "#",
          },
          {
            _id: "yRgBVp",
            title: "Donate",
            urlTo: "https://www.sahapedia.org/donation-now",
          },
          {
            _id: "Sk8eQw",
            title: "Copyright",
            urlTo: "http://map.sahapedia.org/search/license",
          },
        ],
      },
    ],
  },
];

export const navigateFilterSchema = [
  {
    _id: "IJsXzRfS",
    selector: "row",
    listItems: [
      {
        _id: "X03KSuB9",
        title: "Domains",
        selector: "col-md-3",
        sublistItems: [
          {
            _id: "M4VSs6VA",
            urlTo: "https://www.sahapedia.org/domains/built-spaces",
            title: "Built Spaces",
          },
          {
            _id: "vLyuvVe9",
            urlTo: "https://www.sahapedia.org/domains/histories",
            title: "Histories",
          },
          {
            _id: "kA71wq2J",
            urlTo: "https://www.sahapedia.org/domains/institutions",
            title: "Institutions",
          },
          {
            _id: "iOBKEeNa",
            urlTo: "https://www.sahapedia.org/domains/knowledge-traditions",
            title: "Knowledge Traditions",
          },
          {
            _id: "aHTlXc9M",
            urlTo: "https://www.sahapedia.org/domains/literature-and-languages",
            title: "Literature & Language",
          },
          {
            _id: "NZDcnJaR",
            urlTo: "https://www.sahapedia.org/domains/natural-environment",
            title: "Natural Environment",
          },
          {
            _id: "fWE60tyz",
            urlTo: "https://www.sahapedia.org/domains/people",
            title: "People",
          },
          {
            _id: "ivKAXCrn",
            urlTo: "https://www.sahapedia.org/domains/performing-arts",
            title: "Performing Arts",
          },
          {
            _id: "knqxjLVI",
            urlTo: "https://www.sahapedia.org/domains/practices-and-rituals",
            title: "Practices & Rituals",
          },
          {
            _id: "5wUDP10b",
            urlTo: "https://www.sahapedia.org/domains/visual-and-material-arts",
            title: "Visual & Material Arts",
          },
        ],
      },
      {
        _id: "0Z2tuvda",
        title: "Cultural Mapping",
        selector: "col-md-3",
        sublistItems: [
          {
            _id: "pLlagzUk",
            urlTo: "https://map.sahapedia.org/inkochi/20",
            title: "Kochi",
          },
          {
            _id: "FhfKscMP",
            urlTo: "https://map.sahapedia.org/inpune/1",
            title: "Pune",
          },
          {
            _id: "NbXREmgI",
            urlTo: "https://map.sahapedia.org/inindore/13",
            title: "Indore",
          },
          {
            _id: "XXRZ94sA",
            urlTo: "https://map.sahapedia.org/inahmedabad/16",
            title: "Ahmedabad",
          },
          {
            _id: "AyDsZFcP",
            urlTo: "https://map.sahapedia.org/inprayagraj/17",
            title: "Prayagraj",
          },
          {
            _id: "AyDsZFcS",
            urlTo: "https://map.sahapedia.org/inshillong/14",
            title: "Shillong",
          },
          {
            _id: "AyDsZFcg",
            urlTo: "https://map.sahapedia.org/ingoa/15",
            title: "Goa",
          },
          {
            _id: "AyDsZFcR",
            urlTo: "https://map.sahapedia.org/inrajasthan/100001",
            title: "Rajasthan",
          },
          {
            _id: "AyDsZFct",
            urlTo: "https://www.sahapedia.org/mapping-indian-handcrafted-textiles",
            title: "Textiles",
          },
          {
            _id: "AyDsZFcB",
            urlTo: "https://www.sahapedia.org/bhakti-sufi-traditions",
            title: "Bhakti",
          },
          {
            _id: "AyDsZFcm",
            urlTo: "https://map.sahapedia.org/museums/18",
            title: "Museums",
          },
        ],
      },
      {
        _id: "5a877IVA",
        title: "In Focus",
        selector: "col-md-3",
        sublistItems: [
          {
            _id: "C0RLusS3",
            urlTo: "#",
            title: "Cultural Mapping",
          },
          {
            _id: "KgQlFsZA",
            urlTo: "#",
            title: "Fellowships & Grants",
          },
          {
            _id: "tN7bJDvN",
            urlTo: "#",
            title: "Museums of India",
          },
          {
            _id: "s1aHCE87",
            urlTo: "#",
            title: "Rashtrapati Bhavan",
          },
          {
            _id: "qM1wU2py",
            urlTo: "#",
            title: "Saha Sutra",
          },
          {
            _id: "6LVJWinS",
            urlTo: "#",
            title: "Samvad",
          },
          {
            _id: "BB4jyvRH",
            urlTo: "#",
            title: "World Heritage Sites",
          },
        ],
      },
      {
        _id: "0Z2tuvda",
        title: "Programmes",
        selector: "col-md-3",
        sublistItems: [
          {
            _id: "pLlagzUU",
            urlTo: "https://www.sahapedia.org/baithak",
            title: "Baithaks",
          },
          {
            _id: "FhfKscMY",
            urlTo: "https://www.sahapedia.org/conferences",
            title: "Conferences",
          },
          {
            _id: "NbXREmgm",
            urlTo: "https://www.sahapedia.org/india-heritage-walk-festival",
            title: "Festivals",
          },
          {
            _id: "XXRZ94sE",
            urlTo: "https://www.sahapedia.org/heritage-experiences",
            title: "Heritage Experiences",
          },
          {
            _id: "AyDsZFch",
            urlTo: "https://www.sahapedia.org/workshops",
            title: "Workshops",
          },
        ],
      },
      // {
      //   _id: "0Z2tuvda",
      //   title: "Activities",
      //   selector: "col-md-3",
      //   sublistItems: [
      //     {
      //       _id: "pLlagzUU",
      //       urlTo: "#",
      //       title: "Baithaks",
      //     },
      //     {
      //       _id: "FhfKscMY",
      //       urlTo: "#",
      //       title: "Conferences",
      //     },
      //     {
      //       _id: "NbXREmgm",
      //       urlTo: "#",
      //       title: "Festivals",
      //     },
      //     {
      //       _id: "XXRZ94sE",
      //       urlTo: "#",
      //       title: "Heritage Experiences",
      //     },
      //     {
      //       _id: "AyDsZFch",
      //       urlTo: "#",
      //       title: "Workshops",
      //     },
      //   ],
      // },
      {
        _id: "ESjJMdun",
        title: "Type",
        selector: "col-md-3",
        sublistItems: [
          {
            _id: "6Dryn8Zy",
            urlTo: "#",
            title: "Article",
          },
          {
            _id: "bLQPtLYX",
            urlTo: "#",
            title: "Video",
          },
          {
            _id: "vJI1awaX",
            urlTo: "#",
            title: "Image Gallery",
          },
          // {
          //   _id: "zxVV2MvI",
          //   urlTo: "#",
          //   title: "Photo Essay",
          // },
          // {
          //   _id: "8SbALUW5",
          //   urlTo: "#",
          //   title: "Map",
          // },
        ],
      },
      {
        _id: "ESjJMdun",
        title: "Projects",
        selector: "col-md-3",
        sublistItems: [
          {
            _id: "M4VSs6VA",
            urlTo: "https://www.sahapedia.org/domains/built-spaces",
            title: "Built Spaces",
          },
          {
            _id: "vLyuvVe9",
            urlTo: "https://www.sahapedia.org/domains/histories",
            title: "Histories",
          },
          {
            _id: "kA71wq2J",
            urlTo: "https://www.sahapedia.org/domains/institutions",
            title: "Institutions",
          },
          {
            _id: "iOBKEeNa",
            urlTo: "https://www.sahapedia.org/domains/knowledge-traditions",
            title: "Knowledge Traditions",
          },
          {
            _id: "aHTlXc9M",
            urlTo: "https://www.sahapedia.org/domains/literature-and-languages",
            title: "Literature & Language",
          },
          {
            _id: "NZDcnJaR",
            urlTo: "https://www.sahapedia.org/domains/natural-environment",
            title: "Natural Environment",
          },
          {
            _id: "fWE60tyz",
            urlTo: "https://www.sahapedia.org/domains/people",
            title: "People",
          },
          {
            _id: "ivKAXCrn",
            urlTo: "https://www.sahapedia.org/domains/performing-arts",
            title: "Performing Arts",
          },
          {
            _id: "knqxjLVI",
            urlTo: "https://www.sahapedia.org/domains/practices-and-rituals",
            title: "Practices & Rituals",
          },
          {
            _id: "5wUDP10b",
            urlTo: "https://www.sahapedia.org/domains/visual-and-material-arts",
            title: "Visual & Material Arts",
          },
        ],
      },
    ],
  },
  {
    _id: "G0CgKKDf",
    selector: "row",
    listItems: [
      // {
      //   _id: "3qmTh5ID",
      //   title: "Projects",
      //   selector: "col-md-3",
      //   sublistItems: [
      //     {
      //       _id: "M4VSs6VA",
      //       urlTo: "#",
      //       title: "Built Spaces",
      //     },
      //     {
      //       _id: "vLyuvVe9",
      //       urlTo: "#",
      //       title: "Histories",
      //     },
      //     {
      //       _id: "kA71wq2J",
      //       urlTo: "#",
      //       title: "Institutions",
      //     },
      //     {
      //       _id: "iOBKEeNa",
      //       urlTo: "#",
      //       title: "Knowledge Traditions",
      //     },
      //     {
      //       _id: "aHTlXc9M",
      //       urlTo: "#",
      //       title: "Literature & Language",
      //     },
      //     {
      //       _id: "NZDcnJaR",
      //       urlTo: "#",
      //       title: "Natural Environment",
      //     },
      //     {
      //       _id: "fWE60tyz",
      //       urlTo: "#",
      //       title: "People",
      //     },
      //     {
      //       _id: "ivKAXCrn",
      //       urlTo: "#",
      //       title: "Performing Arts",
      //     },
      //     {
      //       _id: "knqxjLVI",
      //       urlTo: "#",
      //       title: "Practices & Rituals",
      //     },
      //     {
      //       _id: "5wUDP10b",
      //       urlTo: "#",
      //       title: "Visual & Material Arts",
      //     },
      //   ],
      // },
      // {
      //   _id: "PME35ejd",
      //   title: "Projects",
      //   selector: "col-md-3",
      //   sublistItems: [
      //     {
      //       _id: "LbklspJu",
      //       urlTo: "#",
      //       title: "Cultural Mapping",
      //     },
      //     {
      //       _id: "iO2TiiUB",
      //       urlTo: "#",
      //       title: "Fellowships & Grants",
      //     },
      //     {
      //       _id: "BL1rbgNT",
      //       urlTo: "#",
      //       title: "Museums of India",
      //     },
      //     {
      //       _id: "rOiOv9wF",
      //       urlTo: "#",
      //       title: "Rashtrapati Bhavan",
      //     },
      //     {
      //       _id: "776BgCYA",
      //       urlTo: "#",
      //       title: "Saha Sutra",
      //     },
      //     {
      //       _id: "xYdhjitb",
      //       urlTo: "#",
      //       title: "Samvad",
      //     },
      //     {
      //       _id: "UkabdHSH",
      //       urlTo: "#",
      //       title: "World Heritage Sites",
      //     },
      //   ],
      // },
      // {
      //   _id: "gW8KF7Ig",
      //   title: "Projects",
      //   selector: "col-md-3",
      //   sublistItems: [
      //     {
      //       _id: "uE8xSoIA",
      //       urlTo: "#",
      //       title: "Baithaks",
      //     },
      //     {
      //       _id: "bOzXIu2K",
      //       urlTo: "#",
      //       title: "Conferences",
      //     },
      //     {
      //       _id: "1cK2AmIH",
      //       urlTo: "#",
      //       title: "Festivals",
      //     },
      //     {
      //       _id: "X7JoM7cq",
      //       urlTo: "#",
      //       title: "Heritage Experiences",
      //     },
      //     {
      //       _id: "000DxHeJ",
      //       urlTo: "#",
      //       title: "Workshops",
      //     },
      //   ],
      // },
      // {
      //   _id: "uU2sCD1J",
      //   title: "Projects",
      //   selector: "col-md-3",
      //   sublistItems: [
      //     {
      //       _id: "LGGbZX9v",
      //       urlTo: "#",
      //       title: "Baithaks",
      //     },
      //     {
      //       _id: "wctTBVy4",
      //       urlTo: "#",
      //       title: "Conferences",
      //     },
      //     {
      //       _id: "TXcXpYvM",
      //       urlTo: "#",
      //       title: "Festivals",
      //     },
      //     {
      //       _id: "fmxG83OR",
      //       urlTo: "#",
      //       title: "Heritage Experiences",
      //     },
      //     {
      //       _id: "3GgYqlU7",
      //       urlTo: "#",
      //       title: "Workshops",
      //     },
      //   ],
      // },
    ],
  },
];

