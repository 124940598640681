import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaFacebookSquare, FaLink, FaLinkedin, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import {
    EmailShareButton,
    FacebookShareButton, LinkedinShareButton,
    TwitterShareButton, WhatsappShareButton
} from "react-share";
import { stripHtml } from "string-strip-html";
import apiURL from "./url";


export default class Social extends Component {
    state = {
        value: '',
        copied: false,
      };
    render() { 
        const regex = /(<([^>]+)>)/ig;
        var re = /,?[ ]/g;
        var type = this.props.type;
        //var title = this.props.title?.replace(regex, '');
        var entityUrl = this.props.url.replace(re,'-');
        const button={
            width:"95px"
        }
        var summary2 = stripHtml(this.props.summary || '').result;
        var summary = summary2?.replace(/^\s+|\s+$/g, '');
        var title = stripHtml(this.props.title || '').result;
        // const entityUrl = this.props.url;
       
       
       
            return (<div>
                <div class="social-icon">
                <a class="SocialShare" style={{color:"#686868",lineHeight:"3"}}>Share this page</a>
                {type != 'entity' ? 
                <>
                <FacebookShareButton
                            url={entityUrl}
                            hashtag = {title}
                            quote={title+' - '+summary +' - '+entityUrl}
                            media={window.location.origin+"/assets/logo/sahapedialogo.png"}
                            style={button}
                            separator=" - "
                            className="Demo__some-network__share-button"
                        >
                            <a href="#"><FaFacebookSquare style={{float:"left"}}></FaFacebookSquare><p class="socialDropdown">Facebook</p></a>
                        </FacebookShareButton>
                <TwitterShareButton
                            url={entityUrl}
                            title = {title}
                            via = {summary}
                            style={button}
                            separator=" - "
                            className="Demo__some-network__share-button"
                        >
                            <a href="#"><FaTwitter style={{float:"left"}}></FaTwitter><p class="socialDropdown">Twitter</p></a>
                        </TwitterShareButton>
                    
                
            <WhatsappShareButton
                            url={entityUrl}
                            media={`${apiURL}assets/logo/sahapedialogo.png`}
                            title= {title+' - '+summary}
                            style={button}
                            separator=" - "
                            className="Demo__some-network__share-button"
                        >
                            <a href="#"><FaWhatsapp style={{float:"left"}}></FaWhatsapp><p class="socialDropdown">Whatsapp</p></a>
                        </WhatsappShareButton>
                        <LinkedinShareButton
                            // mini='true'
                            summary = {title}
                            url={entityUrl}
                            style={button}
                            separator=" - "
                            className="Demo__some-network__share-button"
                        >
                        <a href="#"><FaLinkedin style={{float:"left"}}></FaLinkedin><p class="socialDropdown">LinkedIn</p></a>

                        </LinkedinShareButton>
                        <EmailShareButton
                        url={entityUrl}
                        subject = {title}
                        body={title+' - '+summary}
                        style={button}
                        separator=" - "
                        className="Demo__some-network__share-button"
                    >
                            <a><MdEmail style={{float:"left"}}></MdEmail><p class="socialDropdown">Email</p></a>
                        </EmailShareButton>
                        <CopyToClipboard text={entityUrl}
                        onCopy={() => this.setState({copied: true})}>
                            <a><FaLink style={{float:"left"}}></FaLink><p class="socialDropdown">Copy Link</p>{this.state.copied ? <><br></br><p style={{color: 'red',marginLeft:"32%",float:"left"}}>Copied</p></> : null}</a>
                        </CopyToClipboard>
                        </>
                        :
                        <>
                        <LinkedinShareButton
                            // mini='true'
                            summary = {title}
                            url={entityUrl}
                            style={button}
                            separator=" - "
                            className="Demo__some-network__share-button"
                        >
                        <a href="#"><FaLinkedin style={{float:"left"}}></FaLinkedin><p class="socialDropdown">LinkedIn</p></a>

                        </LinkedinShareButton>
                        <EmailShareButton
                        url={entityUrl}
                        subject = {title}
                        body={title+' - '+summary}
                        style={button}
                        separator=" - "
                        className="Demo__some-network__share-button"
                    >
                            <a><MdEmail style={{float:"left"}}></MdEmail><p class="socialDropdown">Email</p></a>
                        </EmailShareButton>
                        {console.log("entityUrl*******", entityUrl)}
                        <CopyToClipboard text={entityUrl}
                        onCopy={() => this.setState({copied: true})}>
                            <a><FaLink style={{float:"left"}}></FaLink><p class="socialDropdown">Copy Link</p>{this.state.copied ? <><br></br><p style={{color: 'red',marginLeft:"32%",float:"left"}}>Copied</p></> : null}</a>
                        </CopyToClipboard>
                        </>
                        }

            </div>
            </div>
    );
        
        
        
    }
}

