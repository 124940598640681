import React, { Component } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import renderHTML from "react-render-html";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import apiURL from "../../components/url";
import entityName from "../entityUrl";
import icon1 from "../images/icon-1.png";
import icon2 from "../images/icon-2.png";
import lib1 from "../images/lib-1.png";
import Social from "../socialicon";
import "./libraryStyle.css";

export default class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      f: [],
      id: "",
      display: 6,
    };
    this.loadMore = this.loadMore.bind(this);
  }
  loadMore() {
    this.setState((prev) => {
      return { display: prev.display + 6 };
    });
  }
  test(ev) {
    fetch(
      `${apiURL}Api/deleteBookmark?id=${ev}&user=${navigator.cookieEnabled && localStorage.getItem(
        "User"
      )}`
    )
      .then((res) => res.json())
      .then((json) => {
        if (json) {
          // window.location.reload();
          this.setState({
            f: json,
            noData: false,
          });
          window.location.reload();
        } else {
          this.setState({});
        }
      });
  }

  render() {
    var entity = this.props.Entity;
    const shareUrl = window.location.href;
    const title = "PCM";
    var items = this.props.Data;
    const regex = /<[^>]*>/g;
    return (
      <div>
        {items.status == "false" ? (
          ""
        ) : (
          <main class="main-library">
            {items.slice(0, this.state.display).map((item) => (
              <div class="blog-card">
                <a
                  target="_blank"
                  href={item.entityDetail[0].f_master_meta_entity_type == '15' ? `/${item.directory}/POI/${item.project_id}/${item.link2_entity_id}` : `/${entityName(item.entityDetail[0].f_master_meta_entity_type)}/${
                    item.entityDetail[0]
                      ? renderHTML(item.entityDetail[0].title.replace(" ", "-"))
                      : ""
                  }/${item.link2_entity_id}`}
                >
                  <img
                    class="card-image2"
                    src={item.thumbnail_url[0] ? item.thumbnail_url[0] : lib1}
                    width="100%"
                    height="206px"
                  />
                </a>
                <div class="card-left2">
                  <div
                    class="card-title-library"
                    data-toggle="tooltip"
                    title={
                      item.entityDetail[0]
                        ? renderHTML(item.entityDetail[0].title)
                        : ""
                    }
                  >
                    <a
                      target="_blank"
                      href={item.entityDetail[0].f_master_meta_entity_type == '15' ? `/${item.directory}/POI/${item.project_id}/${item.link2_entity_id}` : `/${entityName(item.entityDetail[0].f_master_meta_entity_type)}/${
                        item.entityDetail[0]
                          ? renderHTML(
                              item.entityDetail[0].title.replace(" ", "-")
                            )
                          : ""
                      }/${item.link2_entity_id}`}
                    >
                      <LinesEllipsis
                        text={
                          item.entityDetail[0]
                            ? item.entityDetail[0].title.replace(regex, "")
                            : ""
                        }
                        onReflow={this.handleReflow}
                        maxLine={2}
                        basedOn="words"
                      />
                    </a>
                  </div>
                  <div class="card-description-library">
                    <LinesEllipsis
                      text={
                        item.entityDetail[0].summary
                          ? item.entityDetail[0].summary.replace(regex, "")
                          : ""
                      }
                      onReflow={this.handleReflow}
                      maxLine={3}
                      basedOn="words"
                    />
                    {/* {item.entityDetail[0] ? item.entityDetail[0].summary ? renderHTML(item.entityDetail[0].summary) :'' : ''}... */}
                  </div>
                  {item.Author[0] ? (
                    <div class="card-author-library">
                      {item.Author[0] ? item.Author[0].name : " "}
                    </div>
                  ) : (
                    <div class="card-author-library">
                      {item.Mapper[0] ? item.Mapper[0].name : " "}
                    </div>
                  )}

                  <div class="card-icons-library">
                    <Popup
                      trigger={
                        <img
                          class="card-icon-img-library"
                          src={icon1}
                          alt="share"
                        />
                      }
                      position="right center"
                    >
                      <div>
                        <Social
                          type={"other"}
                          url={
                            item.f_link2_master_meta_entity_type_id == "1"
                              ? `${window.location.origin}/article/${
                                  item.entityDetail[0]
                                    ? renderHTML(item.entityDetail[0].title)
                                    : ""
                                }/${
                                  item.entityDetail[0]
                                    ? item.entityDetail[0].id
                                    : ""
                                }`
                              : item.f_link2_master_meta_entity_type_id == "7"
                              ? `${window.location.origin}/museum/${
                                  item.entityDetail[0]
                                    ? renderHTML(item.entityDetail[0].title)
                                    : ""
                                }/${
                                  item.entityDetail[0]
                                    ? item.entityDetail[0].id
                                    : ""
                                }`
                              : item.f_link2_master_meta_entity_type_id == "23"
                              ? `${window.location.origin}/video/${
                                  item.entityDetail[0]
                                    ? item.entityDetail[0].id
                                    : ""
                                }`
                              : item.f_link2_master_meta_entity_type_id == "9"
                              ? `${window.location.origin}/trailDetail/${
                                  item.entityDetail[0]
                                    ? renderHTML(item.entityDetail[0].title)
                                    : ""
                                }/${
                                  item.entityDetail[0]
                                    ? item.entityDetail[0].id
                                    : ""
                                }`
                              : item.f_link2_master_meta_entity_type_id == "25"
                              ? `${window.location.origin}/timelineDetail/${
                                  item.entityDetail[0]
                                    ? renderHTML(item.entityDetail[0].title)
                                    : ""
                                }/${
                                  item.entityDetail[0]
                                    ? item.entityDetail[0].id
                                    : ""
                                }`
                              : item.f_link2_master_meta_entity_type_id == "29"
                              ? `${window.location.origin}/gallery/${
                                  item.entityDetail[0]
                                    ? item.entityDetail[0].id
                                    : ""
                                }`
                              : `${window.location.origin}/home/${
                                  item.entityDetail[0]
                                    ? item.entityDetail[0].id
                                    : ""
                                }`
                          }
                          summary={
                            item.entityDetail[0]
                              ? item.entityDetail[0].summary
                              : ""
                          }
                        ></Social>
                      </div>
                    </Popup>
                    <img
                      class="card-icon-img-library"
                      src={icon2}
                      alt="bookmark"
                      onClick={() => this.test(item.link2_entity_id)}
                    />
                  </div>
                </div>
              </div>
            ))}
            {
              this.state.display < items.length && (
                <div class="load-wrapper">
                  <div class="load-btn" onClick={this.loadMore}>
                    Load More
                  </div>
                </div>
              )
              //  <button  type="button" className="load-more">Load more</button>
            }
          </main>
        )}
      </div>
    );
  }
}
