import { useEffect, useRef, useState } from "react";
import { BiFullscreen } from 'react-icons/bi';
import { shallowEqual, useSelector } from 'react-redux';
import { makeAPICall, REQUEST } from "../../services/apiService";
import { mapUtils } from "../../services/mapService";
import LandingFilter from "../landingpage/landingFilter";

export default function LandingMap({ ProjectID, pageType, langId ,themeId,themeName}) {
    const { articleFilter, secArticleFilter } = useSelector(state => state.landingPage, shallowEqual);
    const flag = useRef(0);

    /**
     * 
     * @param filterIdList : Total Filter Selected 
     * @param filters : Filter for a current POI
     * @returns if exist of not 
     */
    const isExists = (filterIdList, filters) => {
        if (!filterIdList || filterIdList.length === 0)
            return true;
        for (let i = 0; i < filters.length; ++i) {
            for (let j = 0; j < filterIdList.length; ++j) {
                if (filters[i] == filterIdList[j]) {
                    return true;
                }
            }
        }
        return false;
    }
    const [isOpen, setIsOpen] = useState(false);
 
    const togglePopup = () => {
        // alert('kk');
        setIsOpen(!isOpen);
    }

    const getPoiLocations = () => {
        let filterList = articleFilter;
        let pointsToFetch = [];

        for (const plottedMarker in mapUtils.mapLocations) {
            deletePlotted(plottedMarker);
        }

        filterList?.forEach((filter) => {
            pointsToFetch.push(filter.Id);
        });

        if (pointsToFetch.length > 0) {
            flag.current = pointsToFetch.length;
            fetchToPlot(pointsToFetch);
        }else {
            if(mapUtils.map) {
                if(!navigator.geolocation){
                    navigator.geolocation.getCurrentPosition((position)=>{
                        mapUtils.map.setView([position.coords.latitude, position.coords.longitude], 4);
                    });
                }else{
                    mapUtils.map.setView([28.046, 77.65], 4);
                }
            }
        }
    }

    const deletePlotted = (id) => {
        debugger
        mapUtils.removeConditionalClustorMarker(mapUtils.mapMarkers[id]);
        delete (mapUtils.mapLocations[id]);
        delete (mapUtils.mapMarkers[id]);
    }

    /**
     * 
     * @param  reqParams 
     * @param  key : theme key
     * @returns theme location data
     */
    const getThemeLocations = async (reqParams, key) => {
        let ARTICLE_FILTER = sessionStorage.getItem('ARTICLE_FILTER');
        let updatedData = {};

        if (ARTICLE_FILTER) {
            updatedData = JSON.parse(ARTICLE_FILTER);
            let expiration = updatedData.expiration;
            updatedData = updatedData.data;
            if (updatedData && updatedData[key] && expiration && expiration >= Math.floor(Date.now() / 1000)) {
                return updatedData[key];
            }
        }
        try {
            let response = await makeAPICall(reqParams);
            if (response && response.data.data.status !== "false") {
                let result = response.data.data;
                let updatedData = {};
                const ARTICLE_FILTER = sessionStorage.getItem('ARTICLE_FILTER');
                if (ARTICLE_FILTER && ARTICLE_FILTER !== "") {
                    updatedData = JSON.parse(ARTICLE_FILTER);
                    updatedData = updatedData.data;
                }
                updatedData[key] = result;
                sessionStorage.setItem('ARTICLE_FILTER', JSON.stringify({
                    data: updatedData,
                    expiration: Math.floor(Date.now() / 1000) + 300
                }));
                return result;
            }
            return null;
        } catch (err) {
            console.log(err);
        }
    }
// alert(sessionStorage.getItem('ARTICLE_FILTER'));
    /**
     * @param themeIdList : All selected theme ids
     * Fetch and plot on the map
     */
    const fetchToPlot = (themeIdList) => {
        themeIdList.forEach((themeId) => {
            let reqParam = {
                type: REQUEST.GET,
                url: "entity/searchEntity",
                params: {
                    type: "POI",
                    outputFormat: "landing_map",
                    theme: themeId,
                    sta: "publish",
                    lang: langId,
                    project: ProjectID

                }
            }

            getThemeLocations(reqParam, themeId).then((result) => {
                if (result) {



                    let locations = result;
                    let poiList = [];
                    locations?.forEach((poi) => {
                        if (poi.lat_lng && poi.lat_lng !== "" && isExists(secArticleFilter, poi.filter_id)) {
                            let loc = {};
                            let loc_arr = poi.lat_lng.split(",");
                            loc['meta_latitude'] = loc_arr[0];
                            loc['meta_longitude'] = loc_arr[1];
                            loc['id'] = poi.id;
                            loc['title'] = poi.title;
                            loc['theme_id'] = poi.theme_id
                            loc['filter_id'] = poi.filter_id
                            poiList.push(loc);
                        }
                    });
                    flag.current--;

                    mapUtils.mapLocations[themeId] = poiList;

                    if (flag.current === 0) {
                        
                        mapUtils.plotConditionalClustorMarker();
                        mapUtils.boundMarkers();
                    }
                }

            }).catch((err) => {
                console.log(err)
                flag.current--;
                if (flag.current === 0) {

                    mapUtils.plotConditionalClustorMarker();
                    mapUtils.boundMarkers();
                }
            });
        });
    }

    useEffect(() => {
        if (!mapUtils.map) {
            mapUtils.initMap('map');
            setTimeout(() => {
                mapUtils.generateClustor();
            }, 1000);
        }
        if (articleFilter) {
            getPoiLocations();
        }
    }, [articleFilter, secArticleFilter]);

    const themeMap=()=>{
        window.open('/themeMap/'+themeName+'/'+themeId,'_self');
    };
    const themeMapClose=()=>{
        window.open('/theme/'+themeName+'/'+themeId,'_self');
    };
    if (pageType === 'theme') {
        return (
            <div className="inner-map-container">
                <LandingFilter />
                <div class="theme-fullscreen" onClick={themeMap}>  <BiFullscreen style={{fontSize:"24px"}}></BiFullscreen>
                </div>
                <div id="map" style={{ zIndex: '1' }}></div>
            </div>
        );
    }
    else if (pageType === 'themeMap') {
        return (
            <div className="inner-map-container">
                <LandingFilter />
                <div onClick={themeMapClose} class="theme-fullscreen">  <BiFullscreen style={{fontSize:"24px"}}></BiFullscreen>
                </div>
                <div id="map" style={{ zIndex: '1' }}></div>
            </div>
        );
    }

    return (
        <div className="landing-map-wrapper">
            <div id="map" style={{ 'zIndex': '1' }}></div>
        </div>
    );
}