import $ from 'jquery';
import React, { Component } from 'react';
import Loader from "react-loader-spinner";
import { BASE_API_URL, makeAPICall, REQUEST } from '../../services/apiService';
import Header from '../header2';
import Content from './myTimeline';
import Heading from './myTimelineSearchParams';

export default class Timeline extends Component {
    constructor(props) {
		super(props);
		this.state={
      items:[],
      entityType:[],
      project:[],
        }
    }
    getEntity()
    {
        fetch(`${BASE_API_URL}master/getProjectList`)		
        .then(res => res.json())
        .then(json => {
            if (json){
                this.setState({
        
                    entityType: json,
            })
        }
        else{
            this.setState({
                entityType:[],
    
            })
        }
        });
    }
    componentDidMount(){
            this.getEntity();
            this.projectDetail();
            var user = navigator.cookieEnabled && localStorage.getItem('User');
            const query = new URLSearchParams(this.props.location.search);
            var qryStr = query.get('type');
            if ( qryStr == undefined ) {qryStr = ''}
            var type = qryStr;

			var keyword = this.props.searchqry;
            fetch(`${BASE_API_URL}entity/getEntityDetail`,{
                method: 'POST',
                headers: new Headers({
                           'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                  }),
                body: JSON.stringify({
                    entityType: 'timeline',
                    project_id:type,
                    published:'true',
                    status:'enabled'
                 }) 
              })	
            .then(res => res.json())
			.then(json => {
				if (json){
					this.setState({
						isLoaded: true,
						items: json,
						noData:false,
				})
			}
			else{
				this.setState({
					isLoaded: true,
					items:[],
					noData:true,
		
				})
			}
      });	
      $(window).on("scroll", function () {
        if ($(window).scrollTop() > 100) {
            $(".shHeader ").addClass("smHeader");
        } else {
            //remove the background property so it comes transparent again (defined in your css)
            $(".shHeader ").removeClass("smHeader");
            $(".shHeader ").removeClass("card-icon-img");
        }
    });	
    }
    projectDetail(){
        const query = new URLSearchParams(window.location.search);
        var project = query.get("type");
        let reqParam = {
            type: REQUEST.POST,
            url: 'Entity/getProjects',
            data: JSON.stringify({
                id: project ? project : '1'
            })
        }
        makeAPICall(reqParam)
            .then((result) => {
                if (result) {
                    let res = result.data;
                this.setState({
                    project:res['primary_projects']
                })
                }
                console.log(this.state.project);
            })
    }
    render() { 
        const query = new URLSearchParams(this.props.location.search);
        var qryStr = query.get('type');
        if ( qryStr == undefined ) {qryStr = ''}
        var type = qryStr;
        var { isLoaded, entityType, items} = this.state;
        if (!isLoaded){
          return  <div align="center"><Loader
          type="ThreeDots"
          color="#da3a50"
          height={100}
          width={100}
        //   timeout={10000} //3 secs
          align="center"
        /></div>;
		}
        else {
        return ( 
            <div>
                <Header></Header>
                <Heading Data = {items} Data2={entityType} Entity = {type}></Heading>
                <Content Data = {items} Entity = {type}></Content>
            </div>
         );
    }
}
 
}
