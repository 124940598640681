import { BASE_API_URL } from "../services/apiService";

export const socialMediaBright = [
  {
    _id: "ns59gr3",
    urlTo: "#",
    icon: "ic_tw@2x.png",
  },
  {
    _id: "naei326",
    urlTo: "#",
    icon: "ic_em@2x.png",
  },
  {
    _id: "ome54ea",
    urlTo: "#",
    icon: "ic_fb@2x.png",
  },
  {
    _id: "pl1e26a",
    urlTo: "#",
    icon: "ic_su@2x.png",
  },
];

export const socialMediaDark = [
  {
    _id: "n959pr3",
    urlTo: "#",
    icon: "ic_tw_b@2x.png",
  },
  {
    _id: "n4iep26",
    urlTo: "#",
    icon: "ic_em_b@2x.png",
  },
  {
    _id: "oomegb6",
    urlTo: "#",
    icon: "ic_fb_b@2x.png",
  },
  {
    _id: "pl1evgn",
    urlTo: "#",
    icon: "ic_su_b@2x.png",
  },
];

export const socialLinkPopup = [
  {
    _id: "",
    class: "bx bxl-whatsapp",
    urlTo: "",
    title: "Whatsapp",
  },
];

export const importAll = (r) => {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

export const concatMulti = (resource, separator, identifier) => {
  let result = [];
  resource?.forEach((item) => {
    result.push(item[identifier]);
  });
  return result.join(separator);
};

export const formatDate = (dateString) => {
  const monthName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var date = new Date(dateString);
  return monthName[date.getMonth() + 1] + " " + date.getFullYear();
};

export const joinArray = (resource, separator) => {
  return resource.join(separator);
};

export const trimPara = (para, length) => {
  return para;
};

export const makeURLPath = (string) => {
  return string.split(" ").join("-");
};

export const removeDuplicate = (array, key) => {
  let unique = [];

  for (let i = 0; i < array.length; ++i) {
    let id = array[i][key];
    let flag = false;
    for (let j = 0; j < unique.length; ++j) {
      if (unique[j][key] === id) {
        flag = true;
        break;
      }
    }
    if (!flag) {
      unique.push(array[i]);
    }
  }

  return unique;
};

export const getColorCode = (key) => {
  let colors =navigator.cookieEnabled && localStorage.getItem("COLOR_CODES");
  if (colors) {
    let colorsJson = JSON.parse(colors);
    if (colorsJson && colorsJson[key]) return colorsJson[key];
  }
  return "#000";
};

export const entityType = {
  article: "article",
  theme: "article",
  imgGallery: "image_gallery",
  videoGallery: "video",
  museum: "museum",
};

export function isUserComingFromExternalWebsite() {
  var ref = document.referrer;
  var refurl =
    ref !== null && ref !== undefined && ref !== ""
      ? ref.split("/")[2].split(":")[0]
      : "";
  //console.log("ref", ref);
  var myhostname = window.location.hostname;
  //console.log('myhostname', myhostname);
  if (refurl !== "" && refurl !== myhostname) {
    console.log("Current page domain is different from referrer page domain.");
  }
  return ref !== null && ref !== undefined && ref !== "";
}

export function setCookie(cname, cvalue) {
  const d = new Date();
  d.setTime(d.getTime() + 30 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  console.log(cname + "=" + cvalue + ";" + expires + ";path=/");
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function GetProjectDirectory() {
  var projectdir = "";
  if (getCookie("PROJECT_DTL") === "" || isUserComingFromExternalWebsite()) {
    var currenturl = window.location.href;
    return fetch(
      `${BASE_API_URL}/Api/getProjDtlForSess?scriptURL=${currenturl}`
    )
      .then((response) => response.text())
      .then((res) => {
        var data = res.replace(/(^[ \t]*\n)/gm, "");
        //console.log("data", data);
        projectdir = data.split('|')[0];
        setCookie('PROJECT_DTL', data);
        return projectdir;
      });
  }
  else{
    projectdir = getCookie("PROJECT_DTL").split('|')[0];
    return Promise.resolve(projectdir);
  }
}
