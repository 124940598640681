import { useEffect, useState } from "react";
import { REQUEST, makeAPICall } from "../../services/apiService";

/* global $ */
export default function SecondaryHeader({ projectID, langId,pageName}) {
    const [lang, setLang] = useState(null);
    const [projectName, setProjectName] = useState(null);
    const [directory, setDirectory] = useState('inpune');

    useEffect(() => {
        // getProjectLanguages(projectID);
        getProjectNames(projectID);
    }, []);
    const langIdd = langId ? langId : '22';


    // const getProjectLanguages = (projectID) => {
    //     let reqParam = {
    //         type: REQUEST.GET,
    //         url: 'project/getProjectLang',
    //         params: {
    //             entity_id: projectID
    //         }
    //     }
    //     makeAPICall(reqParam).then((result) => {
    //         console.log({ result });
    //         if (result && result.data.status !== "false") {
    //             setLang(result.data);
    //         }
    //     }).catch((err) => console.log(err));
    // }

    const getProjectNames = () => {
        let reqParam = {
            type: REQUEST.GET,
            url: 'Master/getProjectDetail',
            params: {
                key: "KIOPLI27JSUSJIO",
                id: projectID
            }
        }
        makeAPICall(reqParam).then((result) => {
            if (result && result.data.status !== "false") {
                setProjectName(result.data);
                console.log(result.data[0]);
                setDirectory(result.data[0].directory);
                setLang(result.data[0].available_languages);
            }
        }).catch(err => console.log(err));
    }

    const showNavigateMenu = () => {
        $('#navigate-menu').toggleClass('active');
    }
    return (
        <div className="shSubHeader">
            <a href="/home" target="_blank" className="shAllMaps">
                <i />All Maps
            </a>
            <div className="shSubHdRt clearfix">
                <div className="shSubNav float-left">
                    {projectName && projectName?.map((project) => {
                        return(
                        <>
                            {project.directory && <a key={project.id} className="shSubLoc" href={`/${directory}/${projectID}`}>{`/${project.directory}`}</a>}
                        </>
                        )
                    })}
                    <a href={`/${directory}/about/${projectID}/${langId ? langId : '22'}`} target="_blank">About</a>
                    <a href={`/navigate/${projectID}`} class="navigateButton" role="button">Navigate</a>
                </div>
                {lang && <div aria-label="breadcrumb" className="shBreadcrumb float-right">
                    {pageName == "About"?
                    <ol className="breadcrumb">
                    {lang && lang?.map(l => (
                        <>
                            {langIdd != l.language_id ?
                            (<li key={l.language_id} className="breadcrumb-item"><a href={`/about/${projectID}/${l.language_id}`} style={{color : '#8a8383'}}>{l.language}</a></li>)
                            : (<li key={l.language_id} className="breadcrumb-item"><a style={{cursor : 'text'}}>{l.language}</a></li>)}
                        </>
                    ))}
                </ol>
                    :
                    <ol className="breadcrumb">
                        {lang && lang?.map(l => (
                            <>
                                {langIdd != l.language_id ?
                                (<li key={l.language_id} className="breadcrumb-item"><a href={`/${directory}/${projectID}/${l.language_id}`} style={{color : '#8a8383'}}>{l.language}</a></li>)
                                : (<li key={l.language_id} className="breadcrumb-item"><a style={{cursor : 'text'}}>{l.language}</a></li>)}
                            </>
                        ))}
                    </ol>
                }
                </div>}
            </div>
        </div>
    );
}