export default function entityName(entityId) {
  const RETURN_ENTITY_URL = `${
    entityId === "1"
      ? "article"
      : entityId === "7"
      ? "museum"
      : entityId === "15"
      ? "POI"
      : entityId === "29"
      ? "gallery"
      : entityId === "23"
      ? "video"
      : entityId === "25"
      ? "timelineDetail"
      : entityId === "9"
      ? "trailDetail"
      : ""
  }`;

  return RETURN_ENTITY_URL;
}
