import React, { useEffect, useState } from "react";
import Header from "../header";
import Footer from "../footer";
import "./about.css";
import { useParams } from "react-router-dom";
import { BASE_API_URL } from "../../services/apiService";
import Team from "./team";
import SecondaryHeader from "../landingpage/secondaryHeader";

function About() {
  const [pageData, setPageData] = useState(null);
  const [displayData, setDisplayData] = useState(null);
  const [apiName, setApiName] = useState(null);
  const [activeMenu, setActiveMenu] = useState(null);
  const [proId, setProId] = useState(1);
  const [languageId, setLanguageId] = useState(null);
  const [loadTeam, setLoadTeam] = useState(null);
  const [teamExist, setTeamExist] = useState(false);
  const [collaboratorExist, setCollaboratorExist] = useState(false);
  const [partnerExist, setPartnerExist] = useState(false);
  const { projectId, langId } = useParams();

  useEffect(() => {
    let pId = projectId;
    let lId = langId;

    if (
      (pId == null || pId === undefined || pId === "") &&
      langId !== null &&
      langId !== undefined
    ) {
      pId = 100000;
    }
    if (pId == null || pId === undefined || pId === "") {
      pId = 1;
    }
    if (lId == null || lId === undefined || lId === "") {
      lId = 22;
    }
    setProId(pId);
    setLanguageId(lId);
    fetch(
      `${BASE_API_URL}/Api/getProjectDetailContent?projectId=${pId}&langId=${lId}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data !== null && data !== undefined && data.length > 0) {
          setDisplayData(data[0].BODY);
          setActiveMenu(data[0].TITLE);
        } else {
          displayTeamData("teams");
        }
        setPageData(data);
      });
    fetch(
      `${BASE_API_URL}/Api/getProjectDetailTeam?projectId=${pId}&type=teams&langId=${lId}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data !== null && data !== undefined && data.length > 0) {
          setTeamExist(true);
        }
      });
    fetch(
      `${BASE_API_URL}/Api/getProjectDetailTeam?projectId=${pId}&type=collaborators&langId=${lId}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data !== null && data !== undefined && data.length > 0) {
          setCollaboratorExist(true);
        }
      });
    fetch(
      `${BASE_API_URL}/Api/getProjectDetailTeam?projectId=${pId}&type=sponsors&langId=${lId}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data !== null && data !== undefined && data.length > 0) {
          setPartnerExist(true);
        }
      });
  }, [projectId, langId]);
  const langID = langId ? langId : '22';
  const projectID = projectId;
  let h = "";

  function displayTeamData(param) {
    setDisplayData(null);
    setApiName(param);
    setActiveMenu(param);
    setLoadTeam(true);
  }

  return (
    <div class="about-ab">
      <Header></Header>
      <SecondaryHeader projectID={projectID} langId={langID} pageName={'About'}/>
      <div className="contentFont"style={{marginTop:'0px',width:"96%"}}>
        <div className="row" >
          <div className="col-sm-10 pt-5 m-auto">
            <div className="menu">
              <ul className="d-flex flex-wrap align-items-center justify-content-center p-0 ">
                {pageData &&
                  pageData.map((d) => (
                    <li className="d-inline-block px-4 " >
                      <a
                        className={activeMenu == d.TITLE ? "active" : null}
                        onClick={() => {
                          setDisplayData(d.BODY);
                          setActiveMenu(d.TITLE);
                        }}
                      >
                        {d.TITLE}
                      </a>
                    </li>
                  ))}
                  { teamExist &&
                <li className="d-inline-block px-4">
                  <a
                    className={activeMenu == "teams" ? "active" : null}
                    onClick={() => displayTeamData("teams")}
                  >
                    Team
                  </a>
                </li>}
                { collaboratorExist &&
                <li className="d-inline-block px-4">
                  <a
                    className={activeMenu == "collaborators" ? "active" : null}
                    onClick={() => displayTeamData("collaborators")}
                  >
                    Collaborators
                  </a>
                </li>}
                { partnerExist &&
                <li className="d-inline-block px-4">
                  <a
                    className={activeMenu == "sponsors" ? "active" : null}
                    onClick={() => displayTeamData("sponsors")}
                  >
                    Partners
                  </a>
                </li>}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5 mb-5 contentFont">
        <div className="row">
          <div className="col-sm-10 m-auto">
            <div className="menu">
              {displayData && (
                <div dangerouslySetInnerHTML={{ __html: displayData }} />
              )}
              {!displayData && loadTeam && (
                <Team apiName={apiName} projectId={proId} langId={languageId}></Team>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default About;
