import { useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { makeURLPath, removeDuplicate } from '../../Helpers/utility';
import { actionCreators } from "../../redux/actions/actionCreators";

export default function LandingFilter({langId}) {

    const [filters, setFilters] = useState(null);
    const dispatch = useDispatch();
    const {themeList, articleFilter } = useSelector(state => state.landingPage, shallowEqual);

    useEffect(() => {
        setTimeout(() => {
            let filters = [];
            articleFilter?.forEach((filterTheme) => {
                if (filterTheme.FILTERS) {
                    filters = filters.concat(filterTheme.FILTERS);
                } else if (filterTheme.Filters) {
                    filters = filters.concat(filterTheme.Filters);
                }
            });
    
            if (articleFilter?.length > 1 && filters.length > 1) {
                filters = removeDuplicate(filters, "id");
            }
            setFilters(filters);
          }, 2000);
    }, [articleFilter]);

    const singleFilterClickHandler = (e, filter) => {
        e.preventDefault();
        dispatch(actionCreators.landingPage.themeFilterItemClick(filter.id));
        let clickedFilter = document.querySelector('a i.inbuilt.filter-item.' + makeURLPath(filter.filter));
        console.log(filter.filter, clickedFilter);
        if (clickedFilter) {
            clickedFilter.classList.toggle('selected');
        }
    }
    const regExp = /^[^a-zA-Z]*$/;
    const lang = langId ? langId : '22';

    return (
        <div className="shMapSel" style={{ 'zIndex': '2', height: '32px' }}>
            <div className="dropdown">
            {filters == '' ?
            <a className="btn btn-secondary dropdown-toggle disableDrop" href="javascript:void(0);" role="button" id="" data-toggle="" aria-haspopup="" aria-expanded="false" style={{cursor:"text"}}>Select <div className="ic_acc" /></a>
            :
            <a className="btn btn-secondary dropdown-toggle" href="javascript:void(0);" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >Select <div className="ic_acc" /></a>
            }
                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <div className="shSidemenuScrl">
                        <ul className="shSidemenuList">
                            {filters && filters?.map(filter => (
                                <>
                                {lang == '22' ? 
                                <>
                                {filter.filter.replace(regExp,'') != '' ?
                                <li key={filter.id}>
                                    <a href="javascript:void(0);" role="button" onClick={(e) => singleFilterClickHandler(e, filter)}>
                                        <div style={{float:"left",height:"17px"}}><i className={`inbuilt filter-item ${makeURLPath(filter.filter)}`} /></div>
                                        <div>{filter.filter}</div>
                                    </a>
                                </li>
                                :''}
                                </>
                                :
                                <>
                                 {filter.filter.replace(regExp,'') == '' ?
                                <li key={filter.id}>
                                    <a href="javascript:void(0);" role="button" onClick={(e) => singleFilterClickHandler(e, filter)}>
                                        <div style={{float:"left",height:"17px"}}><i className={`inbuilt filter-item ${makeURLPath(filter.filter)}`} /></div>
                                        <div>{filter.filter}</div>
                                    </a>
                                </li>
                                :''}
                                </>

                            }
                            </>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
