import React, { createRef, useEffect, useRef, useState } from 'react';
import museumBook from '../../assets/img/ic_tag@2x.png';
import museumunBook from '../../assets/img/ic_tag_b@2x.png';
import Bookmark from '../../components/bookmark2';
import apiURL from '../../components/url';
import { useWindowSize } from '../../Helpers/hooks/windows';
import { importAll } from '../../Helpers/utility';
import { makeAPICall, REQUEST } from '../../services/apiService';
import { mapUtils, scrollToMap, showMap } from '../../services/mapService';
import Social from '../common/socialArticle';
import Authors from './authors';
import Mapper from './museumMapper';




/* global YTC */
let bannerRef = createRef();
export default function Banner({ bannerDetail, articleID, slideImg, type = 'article' }) {
    const [entityLang, setEntityLang] = useState([]);
    const [activeLang, setActiveLang] = useState(null);
    const currentSlide = useRef(0);
    // const activeLang = useRef(null);

    const size = useWindowSize();

    const images = importAll(require.context('../../assets/img', false, /\.(png|jpe?g|svg)$/));

    useEffect(() => {
        // Language
        console.log("Toal Loc", mapUtils.locations);
        console.log({ bannerDetail });
        let data = { "entityType": "museum", "entity_id": articleID };
        let reqParams = {
            type: REQUEST.POST,
            url: 'Entity/getEntityLanguages',
            data: JSON.stringify(data)
        };
        makeAPICall(reqParams)
            .then((result) => {
                if (result && result.data && result.data.status !== "false") {
                    setEntityLang(result.data);

                    result.data?.forEach((lang) => {
                        if (lang.entity_id == articleID) {
                            setActiveLang(lang.language);
                        }
                    });
                }
            })
            .catch(err => console.log(err));

        if (type === 'videoGallery') {
            YTC('#player', {
                youtubeId: 'pCewaWYNnu4',
                chapters: [
                    {
                        time: '0m 0s',
                        title: '01 - Chapter 1',
                        id: 'id1',
                        text: '01 - Chapter 1',
                    },
                    {
                        time: '03:43',
                        title: '02 - Chapter 2',
                        id: 'id2',
                        text: '02 - Chapter 2',
                    },
                    {
                        time: '06:43',
                        title: '03 - Chapter 3',
                        id: 'id3',
                        text: '03 - Chapter 3',
                    }]
            });
        }
    }, []);

    const onOpenSlideShow = (index) => {
        if (slideImg?.length > 0) {
            bannerRef.current.viewCurrentThumbnail(index);
            bannerRef.current.openModal();
        }
    }

    return (
        <>
        <div class="header-mobile only-mobile">
      <div class="sub-title">
      {bannerDetail?.domain_subdomain?.length == 0 ? 
                <a className="" target="_blank" href={`${window.location.origin}/search?keyword=${bannerDetail.domain_subdomain[0].subdomain_name.replace(/\s+$/,'')}&type=all&lang=`}>{bannerDetail.domain_subdomain[0].subdomain_name}</a>
            : 
            <>
            <a className="" target="_blank" href={`${window.location.origin}/search?keyword=${bannerDetail?.domain_subdomain[0].subdomain_name.replace(/\s+$/,'')}&type=all&lang=`}>{bannerDetail?.domain_subdomain[0].subdomain_name}&nbsp;</a>
            {bannerDetail?.domain_subdomain.slice(1).map(subdomain => (
                <>
            <span>&nbsp;/&nbsp;</span><a className="" target="_blank" href={`${window.location.origin}/search?keyword=${subdomain.subdomain_name.replace(/\s+$/,'')}&type=all&lang=`}>{subdomain.subdomain_name} </a>
            </>
            ))}
            </>
            }          </div>
      <div class="sub-title highlights">
      {bannerDetail?.categories?.length == 0 ? 
                <a className="" target="_blank" href={`${window.location.origin}/search?keyword=${bannerDetail.categories[0].museum_categories.replace(/\s+$/,'')}&type=Museum&lang=`}>{bannerDetail.categories[0].museum_categories}</a>
            : 
            <>
            <a className="" target="_blank" href={`${window.location.origin}/search?keyword=${bannerDetail?.categories[0].museum_categories.replace(/\s+$/,'')}&type=Museum&lang=`}>{bannerDetail?.categories[0].museum_categories}</a>
            {bannerDetail?.categories.slice(1).map(category => (
                <>
            <span>&nbsp;/&nbsp;</span><a className="" target="_blank" href={`${window.location.origin}/search?keyword=${category.museum_categories.replace(/\s+$/,'')}&type=Museum&lang=`}>{category.museum_categories} </a>
            </>
            ))}
            </>
            }      </div>
      <div class="title">{bannerDetail.title}</div>
      <div class="header-details">
      <Mapper authors={bannerDetail.Museum_mapper} createdAt={bannerDetail.date_time_published} />
      </div>

      <div class="header-bottom">
      <div class="location">
            <a href="javascript:void(0);" style={{ width: "100%" }}>
                <img id="trigger-map" onClick={type === 'theme' ? () => scrollToMap() : () => showMap("museum")} src={images[`${size.width > 768 ? 'ic_loc@2x.png' : 'ic_loc_b@2x.png'}`].default} alt="" class="location_banner" />
                <div class="location_text">{bannerDetail.Location?.city}</div>
            </a>
            </div>
        <ul class="social socialNew bannerSocialView">
                <Social url = {`${apiURL}search/museum/${bannerDetail.id}`} title={bannerDetail.title} summary = {bannerDetail.summary}></Social>
        </ul>
        <a class="bookmarkList">
        {/* {
            localStorage.getItem('User') ? 
            <> */}
            <Bookmark style={{height:"40px", width:"50px"}} deleteId = {bannerDetail.id ? bannerDetail.id : ''} id={bannerDetail.id}  type={bannerDetail.f_master_meta_entity_type} relation={'6'} user={navigator.cookieEnabled && localStorage.getItem('User')} logo = {museumBook} logo2 = {museumunBook}></Bookmark>
            {/* </>
            :
            <>
            <img class="card-icon-img" value="Bookmark" src={museumBook} alt="bookmark" />   
            </>
        }     */}
        </a>
        </div>
        <div href="#" className="shLang muLang">
                            {entityLang.length > 0 &&
                                (
                                    <div className="dropdown">
                                        <a className="btn btn-secondary dropdown-toggle languageMuseum" target="_blank" style={{cursor:"text"}} role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {bannerDetail.language ? bannerDetail.language : entityLang[0].language}&nbsp;
                                        {entityLang.length > 1 && (<img style={{cursor:"pointer"}} src={images[`${size.width > 768 ? 'ic_drop@2x.png' : 'ic_drop_b@2x.png'}`].default} alt="" />)}
                                        </a>
                                        {entityLang.length > 1 && (
                                            <div class="dropdown-menu dropdownLang" aria-labelledby="dropdownMenuLink">
                                                {entityLang.map((lang, index) => (<a key={index} class="dropdown-item" target="_blank" style={{fontSize:"12px",fontFamily:"'open Sans', Sans-serif"}} href={`/search/museum/${lang.title}/${lang.entity_id}`}>{bannerDetail.language == lang.language ? lang.language : lang.language}</a>))}
                                            </div>
                                        )}
                                    </div>
                                )}
                        </div>
    </div>
    <img
      class="header-image-moblie only-mobile"
      src={bannerDetail.bannerimage_url}
    />
    <header class="mobileBann" style={{ backgroundImage: `url(${bannerDetail.bannerimage_url})` }}>
      <div class="header-overlay">
        <div class="header-wrapper ClickableEvent">
        {bannerDetail?.domain_subdomain?.length == 0 ? 
                <a className="sub-title" target="_blank" href={`${window.location.origin}/search?keyword=${bannerDetail.domain_subdomain[0].subdomain_name.replace(/\s+$/,'')}&type=all&lang=`}>{bannerDetail.domain_subdomain[0].subdomain_name}</a>
            : 
            <>
            <a className="sub-title" target="_blank" href={`${window.location.origin}/search?keyword=${bannerDetail?.domain_subdomain[0].subdomain_name.replace(/\s+$/,'')}&type=all&lang=`}>{bannerDetail?.domain_subdomain[0].subdomain_name}&nbsp;</a>
            {bannerDetail?.domain_subdomain.slice(1).map(subdomain => (
                <>
            <span>&nbsp;/&nbsp;</span><a className="sub-title" target="_blank" href={`${window.location.origin}/search?keyword=${subdomain.subdomain_name.replace(/\s+$/,'')}&type=all&lang=`}>{subdomain.subdomain_name} </a>
            </>
            ))}
            </>
            }
          {/* {bannerDetail?.domain_subdomain?.map(subdomain => (
            <>
            <a className="sub-title" href={`${window.location.origin}/search?keyword=${subdomain.subdomain_name.replace(/\s+$/,'')}&type=Museum&lang=`}>{subdomain.subdomain_name}</a>
          </>))} */}
          <br></br>
          {/* {bannerDetail?.categories?.map(category => (
              <> */}
            {bannerDetail?.categories?.length == 0 ? 
                <a className="sub-title" target="_blank" href={`${window.location.origin}/search?keyword=&type=Museum&lang=&category=${bannerDetail.categories[0].f_category_id}`}>{bannerDetail.categories[0].museum_categories}</a>
            : 
            <>
            <a className="sub-title" target="_blank" href={`${window.location.origin}/search?keyword=&type=Museum&lang=&category=${bannerDetail.categories[0].f_category_id}`}>{bannerDetail?.categories[0].museum_categories}</a>
            {bannerDetail?.categories.slice(1).map(category => (
                <>
            <span>&nbsp;/&nbsp;</span><a className="sub-title" target="_blank" href={`${window.location.origin}/search?keyword=&type=Museum&lang=&category=${category.f_category_id}`}>{category.museum_categories} </a>
            </>
            ))}
            </>
            }
            {/* </>
          ))} */}
          <div class="title">{bannerDetail.title}</div>
          <div class="header-details">
        <Authors authors={bannerDetail.Museum_mapper} createdAt={bannerDetail.date_time_published} />
          </div>

          <div class="header-bottom">
            <div class="location">
            <a href="javascript:void(0);" style={{ width: "100%" }}>
                <img onClick={type === 'theme' ? () => scrollToMap() : () => showMap("museum")} src={images[`${size.width > 768 ? 'ic_loc@2x.png' : 'ic_loc_b@2x.png'}`].default} alt="" class="location_banner" />
                <div class="location_text" onClick={type === 'theme' ? () => scrollToMap() : () => showMap("museum")}>{bannerDetail.Location?.city}</div>
            </a>
            </div>
            <ul class="social socialNew bannerSocialView">
                <Social title = {bannerDetail.title} img={bannerDetail.bannerimage_url} url = {`${window.location.origin}/search/museum/${bannerDetail.title.replace(/\s+/g,'-')}/${bannerDetail.id}`} summary = {bannerDetail.summary}></Social>
            </ul>
            <a class="bookmarkList">
                                {/* {
                                    localStorage.getItem('User') ? 
                                    <> */}
                                    <Bookmark style={{height:"40px", width:"50px"}} deleteId = {bannerDetail.id ? bannerDetail.id : ''} id={bannerDetail.id}  type={bannerDetail.f_master_meta_entity_type} relation={'6'} user={navigator.cookieEnabled && localStorage.getItem('User')} logo = {museumBook} logo2 = {museumunBook}></Bookmark>
                                    {/* </>
                                    :
                                    <>
                                     <img class="card-icon-img" value="Bookmark" src={museumBook} alt="bookmark" />   
                                    </>
                                }     */}
                            </a>
                            <div href="#" className="shLang museumLanguage">
                            {entityLang.length > 0 &&
                                (
                                    <div className="dropdown">
                                        <a className="btn btn-secondary dropdown-toggle languageMuseum" style={{cursor:"text"}} target="_blank" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {bannerDetail.language ? bannerDetail.language : entityLang[0].language}&nbsp;
                                        {entityLang.length > 1 && (<img style={{cursor:"pointer"}} src={images[`${size.width > 768 ? 'ic_drop@2x.png' : 'ic_drop_b@2x.png'}`].default} alt="" />)}
                                        </a>
                                        {entityLang.length > 1 && (
                                            <div class="dropdown-menu dropdownLang" aria-labelledby="dropdownMenuLink">
                                                {entityLang.map((lang, index) => (
                                                <a key={index} class="dropdown-item" target="_blank" style={{fontSize:"12px",fontFamily:"'open Sans', Sans-serif"}} href={`/search/museum/${lang.title}/${lang.entity_id}`}>
                                                    {bannerDetail.language == lang.language ? lang.language : lang.language}
                                                </a>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                )}
                        </div>
          </div>
        </div>
      </div>
    </header>
    </>

    );
}