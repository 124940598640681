import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import IdleTimer from 'react-idle-timer';
import Drop from './Drop.jsx';

export default class Timer extends Component {
  constructor(props) {
    super(props)
    this.state={
      showDonationPopup:false,
      Popup:true
    }
    this.idleTimer = null
    this.handleOnIdle = this.handleOnIdle.bind(this)
  }
 closeModal(){
   this.setState({
     showDonationPopup:false,
     Popup:false
   })
   console.log(this.state.showDonationPopup);
 }
  render() {
    var { showDonationPopup,Popup } = this.state;
    if(Popup){
    if(showDonationPopup){
      return(
      <Modal visible={true} height={"0%"} width={"100%"}>
        <div>
        <div class="outer_circle">
        <div class="inner_circle">
        <div class="cls">
        <a href="javascript:void(0);" style={{color:"#fff"}} onClick={() => this.closeModal()}>X</a>
        </div>
        </div>
        </div>
        </div>
      <Drop></Drop>
      </Modal>
      )} 
      else{
    return (
      <div>  
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          timeout={30 * 1000}
          onIdle={this.handleOnIdle}
          debounce={250}
        />
      </div>
    )}
  }
  else{
    return(
      <div></div>
    )}
  }
  handleOnIdle (event) {
    console.log('user is idle', event)
    console.log('last active', this.idleTimer.getLastActiveTime())
    this.setState({
      showDonationPopup: true,
    })
  }
}