import React, { createRef, useEffect, useRef, useState } from 'react';
import ReactFullscreenSlideshow from 'react-fullscreen-slideshow';
import { IoIosArrowDropupCircle } from 'react-icons/io';
import museumBook from '../../assets/img/ic_tag@2x.png';
import museumunBook from '../../assets/img/ic_tag_b@2x.png';
import museumBook1 from '../../assets/img/ic_tag_g1@2x.png';
import museumunBook1 from '../../assets/img/redBookmark.png';
import Bookmark from '../../components/bookmark2';
import Bookmark2 from '../../components/bookmark3';
import { useWindowSize } from '../../Helpers/hooks/windows';
import { importAll } from '../../Helpers/utility';
import { makeAPICall, REQUEST } from '../../services/apiService';
import { mapUtils, scrollToMap, showMap } from '../../services/mapService';
import Social from '../common/socialArticle';
import Authors from './authors';





/* global YTC */
let bannerRef = createRef();
export default function Banner({ bannerDetail, articleID, slideImg, type = 'article' }) {
    const [entityLang, setEntityLang] = useState([]);
    const [activeLang, setActiveLang] = useState(null);
    const currentSlide = useRef(0);
    // const activeLang = useRef(null);

    const size = useWindowSize();

    const images = importAll(require.context('../../assets/img', false, /\.(png|jpe?g|svg)$/));

    useEffect(() => {
        // Language
        console.log("Toal Loc", mapUtils.locations);
        console.log({ bannerDetail });
        if(type == 'imgGallery'){
            var entityType = 'imageGallery';
        }
        else{
            var entityType = type;
        }

        let data = { "entityType": entityType ==="theme"?"article":entityType, "entity_id": articleID };
        let reqParams = {
            type: REQUEST.POST,
            url: 'Entity/getEntityLanguages',
            data: JSON.stringify(data)
        };
        makeAPICall(reqParams)
            .then((result) => {
                if (result && result.data && result.data.status !== "false") {
                    setEntityLang(result.data);

                    result.data?.forEach((lang) => {
                        if (lang.entity_id == articleID) {
                            setActiveLang(lang.language);
                        }
                    });
                }
            })
            .catch(err => console.log(err));

        if (type === 'videoGallery') {
            let videoTimes = bannerDetail.Video_start_time || undefined;
            let videoTimeline = [];
            if (videoTimes) {
                videoTimes.forEach((vtime) => {
                    videoTimeline.push({
                        time: vtime.start_time,
                        title: vtime.theme,
                        id: vtime.id,
                        text: vtime.theme
                    });
                });
            }

            let videoFilePath = bannerDetail.video_url?.file_path || undefined;

            if (videoFilePath && videoTimes) {
                let videoPathSplit = videoFilePath.split('/');

                YTC('#player', {
                    youtubeId: videoPathSplit[videoPathSplit.length - 1],
                    chapters: videoTimeline
                })
                YTC('#playerMobile', {
                    youtubeId: videoPathSplit[videoPathSplit.length - 1],
                    chapters: videoTimeline
                })
            }
        }
    }, [bannerDetail, type, articleID]);

    const onOpenSlideShow = (index) => {
        if (slideImg?.length > 0) {
            bannerRef.current.viewCurrentThumbnail(index);
            bannerRef.current.openModal();
        }
    }

    return (
        <section className={`shBanner ${type === 'videoGallery' ? 'shVidBanner' : ''}`}>

            <div class="container articleContainer">
            {type === 'videoGallery' && <div id="player"></div>}

                <div className={`shBnText ${type === 'videoGallery' ? 'shVidBannerText' : ''}`}>
                    {/* <p style={{ fontWeight: 600 }} id="subDomain_p">
                        {concatMulti(bannerDetail.domain_subdomain, ", ", "subdomain_name")}
                    </p> */}
                    <p id="subDomain_p">
                    {bannerDetail?.domain_subdomain?.length == 0 ?
                        <div></div>
                    :
                        <>
                        <span className="sub-title" target="_blank" >{bannerDetail?.domain_subdomain[0].subdomain_name}&nbsp;</span>
                        {bannerDetail?.domain_subdomain.slice(1).map(subdomain => (
                            // <a className="sub-title"  target="_blank" href={`${window.location.origin}/search?keyword=${subdomain.subdomain_name.replace(/\s+$/,'')}&type=Article&lang=`}>/&nbsp;{subdomain.subdomain_name} </a>
                            `/ ${subdomain.subdomain_name}`
                        ))}
                        </>
                    }
                    </p>

                    {/* {bannerDetail?.theme?.map(theme => (
                        <a className="shBnCat" href={`${window.location.origin}/theme/${theme.theme}/${theme.f_master_theme_id}`} key={theme.f_master_theme_id}>&nbsp;{theme.theme}&nbsp;</a>
                    ))} */}
                    {bannerDetail?.theme?.length == 0 ?
                        <a className="shBnCat" target="_blank" href={`${window.location.origin}/theme/${bannerDetail.theme[0] ? bannerDetail.theme[0].theme : ''}/${bannerDetail.theme[0] ? bannerDetail.theme[0].f_master_theme_id : ''}`}>{bannerDetail.theme[0] ? bannerDetail.theme[0].theme : ''}</a>
                    :
                        <>
                        <a className="shBnCat" target="_blank" href={`${window.location.origin}/theme/${bannerDetail.theme ? bannerDetail.theme[0].theme : ''}/${bannerDetail.theme ? bannerDetail.theme[0].f_master_theme_id : ''}`}>{bannerDetail.theme ? bannerDetail.theme[0].theme : ''}&nbsp;</a>
                        {bannerDetail?.theme.slice(1).map(theme => (
                            <a className="shBnCat" target="_blank" href={`${window.location.origin}/theme/${theme.theme}/${theme.f_master_theme_id}`}>/&nbsp;{theme.theme} </a>
                        ))}
                        </>
                    }
                    <h2 id="title_heading_entity">{bannerDetail.title}</h2>

                    <Authors authors={bannerDetail.Author} createdAt={bannerDetail.date_time_modified} type={type} />

                    <div className="shSocialSec clearfix">
                        <ul className="shSocialList">
                            {(type !== 'theme') && mapUtils.locations && mapUtils.locations.length > 0 && (<li className="shLoc" id="trigger-map" onClick={type === 'theme' ? () => scrollToMap() : () => showMap(type)}>
                                <a href="javascript:void(0);" style={{ width: "100%" }}>
                                    <img src={images[`${size.width > 768 ? 'ic_loc@2x.png' : 'ic_loc_b@2x.png'}`].default} alt="" className="location_banner" />
                                    <div className="location_text">{bannerDetail.Location?.museum_address}</div>
                                </a>
                            </li>)}
                            {(type === 'theme') && (<li className="shLoc" id="trigger-map" onClick={type === 'theme' ? () => scrollToMap() : () => showMap(type)}>
                                <a href="javascript:void(0);" style={{ width: "100%" }}>
                                    <img src={images[`${size.width > 768 ? 'ic_loc@2x.png' : 'ic_loc_b@2x.png'}`].default} alt="" className="location_banner" />
                                    <div className="location_text">{bannerDetail.Location?.museum_address}</div>
                                </a>
                            </li>)}
                            {/* <ul class="social socialNew bannerSocialView"> */}
                                <Social title = {bannerDetail.title} url = {type === 'theme' ? `${window.location.origin}/${type}/${bannerDetail.theme[0] ? bannerDetail.theme[0].theme : ''}/${bannerDetail.theme[0] ? bannerDetail.theme[0].f_master_theme_id : ''}` :type === 'imgGallery' ? `${window.location.origin}/gallery/${bannerDetail.id}`: type === 'videoGallery' ?`${window.location.origin}/video/${bannerDetail.id}`: `${window.location.origin}/article/${bannerDetail.title}/${bannerDetail.id}`}  summary = {bannerDetail.summary}></Social>
                            {/* </ul> */}
                            {/* {size.width > 768
                                        ? socialMediaBright.map(socialIcon => <SocialMedia key={socialIcon._id} socialIcon={socialIcon} />)
                                        : socialMediaDark.map(socialIcon => <SocialMedia key={socialIcon._id} socialIcon={socialIcon} />)
                                    } */}

                            {(type !== 'videoGallery') && <li className="shTag bookmarkList article-book">
                            <Bookmark style={{height:"40px", width:"50px"}} deleteId = {bannerDetail.id ? bannerDetail.id : ''} id={bannerDetail.id}  type={bannerDetail.f_master_meta_entity_type} relation={'6'} user={navigator.cookieEnabled && localStorage.getItem('User')} logo = {museumBook} logo2 = {museumunBook}></Bookmark>
                            </li>}
                            {(type === 'videoGallery') && <li className="shTag bookmarkList article-book">
                            <Bookmark2 style={{height:"40px", width:"50px"}} deleteId = {bannerDetail.id ? bannerDetail.id : ''} id={bannerDetail.id}  type={bannerDetail.f_master_meta_entity_type} relation={'6'} user={navigator.cookieEnabled && localStorage.getItem('User')} logo = {museumBook1} logo2 = {museumunBook1}></Bookmark2>
                            </li>}
                        </ul>
                        <div href="#" className="shLang">
                            {entityLang.length > 0 &&
                                (
                                    <div className="dropdown">
                                        <a aria-disabled={entityLang.length === 1 ? true : false} className="btn btn-secondary dropdown-toggle" target="_blank" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ paddingTop: '10px',cursor:"text" }}>
                                        {bannerDetail.language ? bannerDetail.language : entityLang[0].language}&nbsp;
                                                        {entityLang.length > 1 && (<img style={{cursor:"pointer"}} src={images[`${size.width > 768 ? 'ic_drop@2x.png' : 'ic_drop_b@2x.png'}`].default} alt="" />)}
                                        </a>
                                        {entityLang.length > 1 && (
                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                {entityLang.map((lang, index) => (
                                                <>
                                                {lang.language == bannerDetail.language &&
                                                <a style={{cursor:"pointer"}} className="dropdown-item">

                                                    {lang.language == bannerDetail.language && lang.language } {lang.language == bannerDetail.language && <IoIosArrowDropupCircle style={{margin: "-2% 35%"}}></IoIosArrowDropupCircle>}

                                                </a>}

                                            </>
                                            ))}
                                                {entityLang.map((lang, index) => (
                                                    lang.language != bannerDetail.language ?
                                            <a style={{cursor:"pointer"}} className="dropdown-item" target="_blank" href={`/${type === 'imgGallery' ? 'gallery' : type==='theme'?'article':type}/${lang.language != bannerDetail.id ? lang.title.replace("?","-"):''}/${lang.language != bannerDetail.id ?lang.entity_id :''}`}>
                                                    {/*lang.language != bannerDetail.language ? lang.language :''*/}
                                                {lang.language}
                                                </a>
                                                :''
                                            ))}
                                            </div>
                                        )}
                                    </div>
                                )}
                        </div>
                    </div>
                    {type === 'imgGallery' && (
                        <div className="shGalSec">
                            <a href="#" className="shTotalImg" role="button" onClick={() => onOpenSlideShow(currentSlide.current)}>
                                <img src={images['ic_img_all.svg'].default} alt="" />
                                {slideImg?.length} photos
                                        </a>
                            <a href="#" role="button" className="shViewGal" onClick={() => onOpenSlideShow(currentSlide.current)}>
                                View Gallery
                                            <img src={images['ic_view_caret.svg'].default} alt="" />
                            </a>
                        </div>
                    )}
                </div>

                {type !== 'videoGallery' && <div className="shBnImg" style={{ backgroundImage: `linear-gradient(180deg,transparent 0,rgba(0,0,0,.75)), url(${bannerDetail.bannerimage_url})` }}></div>}
                {type === 'videoGallery' && <div id="playerMobile"></div>}

            </div>
            {slideImg?.length > 0 &&
                <ReactFullscreenSlideshow
                    ref={bannerRef}
                    displayOverlay={false}
                    displayPreviewBanner={false}
                    images={slideImg}
                    currentSlideIndex={currentSlide.current}
                    BannerImgIndex={currentSlide.current}
                    cycle={true}
                    thumbnailsToBeDisplayed={10}
                />}
        </section>

    );
}
