import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Footer from "../footer";
import Header from "../header";
import apiURL from "../url";
import "./home.css";
export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      isLoaded: false,
      show: false,
    };
  }
  handleModal() {
    this.setState({ show: !this.state.show });
  }
  componentDidMount() {
    fetch(`${apiURL}Master/getProjectListingDisplay?langId=22`)
      .then((res) => res.json())

      .then((json) => {
        if (json) {
          this.setState({
            isLoaded: true,
            items: json,
            slideIndex: [],
            noData: false,
          });
        } else {
          this.setState({
            isLoaded: true,
            items: [],
            noData: true,
          });
        }
      });
  }
  renderTableRows = () => {
    return this.state.items.map((item) => {
      return (
        <tr key={item.id}>
          <td>{item.id}</td>
          <td>{item.project}</td>
          <td>{item.order_by}</td>
          <td>
            <a href project_url={item.project_image}></a>
          </td>
        </tr>
      );
    });
  };
  render() {
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 3,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3,
      },
    };
    var { isLoaded, items } = this.state;
    if (!isLoaded) {
      return <div>loading</div>;
    }
  }
  render() {
    const width = window.innerWidth;
    const height = window.innerheight;
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 3,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3,
      },
    };
    var { isLoaded, items } = this.state;
    if (!isLoaded) {
      return <div>loading</div>;
    } else {
      return (
        <div style={{ maxWidth: "100%", overflow: "hidden" }}>
          <Header></Header>
          <div
            class="only-desktop"
            style={{
              marginTop: "-20px",
              maxWidth: "100%",
              position: "relative",
            }}
          >
            <Carousel
              responsive={responsive}
              removeArrowOnDeviceType={["mobile"]}
            >
              {items.map((data) => (
                <div style={{ height: "92vh", display: "grid" }}>
                  <div class="centered"><a href={window.location.origin+'/' + data.directory + '/' + data.id}>{data.project}</a></div>
                  <a
                    class="img-hover-zoom"
                    style={{ height: "92vh" }}
                    href={
                      (data.projecturl =
                        window.location.origin+'/' + data.directory + '/' + data.id)
                    }
                  >
                    <img
                      style={{
                        height: "92vh",
                        overflow: "hidden",
                        width: "100%",
                      }}
                      src={data.project_image}
                    />
                  </a>
                </div>
              ))}
            </Carousel>
            <div class="logo">
              <img
                class="center"
                style={{ width: "110px" }}
                src={`${apiURL}assets/images/2021052619313026SAHAPEDIA_white.png`}
              />
            </div>
          </div>
          <div class="only-mobile">
            {items.map(data => (
              data.project_image2?
              <>
              <div class="img1" style={{marginBottom:'-5%',overflow:'hidden'}}>
                <a style={{width:'100%',height:'100%'}} href={data.projecturl = window.location.origin+'/' +data.directory + '/' + data.id}>
                <div class="centered">{data.project}</div>
                  <img style={{width:'100%',height:'100%',objectFit:'cover'}} src={data.project_image2} /></a>
              </div>
              <div style={{height:'58px'}}></div>
              </>
              :''
            ))}
          </div>
          <Footer></Footer>
        </div>
      );
    }
  }
}
