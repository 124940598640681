import { actionTypes, actionTypesLanding } from '../actions/action';

const INITIAL_STATE = {
    articleFilter: [], //seleted themes
    secArticleFilter: [],
    projectID: '',
    projectThemeId: '',
    themeList: [],
    themePoiList: {} //all markers
};

function landingReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionTypes.landingPage.THEME_FILTERS: return {
            ...state,
            themeList: action.payload.themeList
        }

        case actionTypes.landingPage.THEME_ARTICLE_FILTER:
            let isExist = state.articleFilter.findIndex(article => article.Id === action.payload.themeId);
            let themeToAdd = state.themeList.filter(theme=>theme.Id === action.payload.themeId);
            if (isExist !== -1) {
                return {
                    ...state,
                    articleFilter: state.articleFilter.filter(article =>  article.Id !== action.payload.themeId)
                }
            }else{
                return {
                    ...state,
                    articleFilter: state.articleFilter.concat(themeToAdd)
                }
            }

        case actionTypes.landingPage.SELECT_ALL_THEMES: return {
            ...state,
            articleFilter: action.payload.themeList
        }

        case actionTypes.landingPage.DESELECT_ALL_THEMES: return {
            ...state,
            articleFilter: []
        }
        
        case actionTypes.landingPage.CUSTOM_DD_THEME_FILTER:
            let isExistSec = state.secArticleFilter.findIndex(filter=>filter === action.payload.filter);
            let secArticleFilter = state.secArticleFilter;
            if(isExistSec !== -1) {
                secArticleFilter = secArticleFilter.filter((filter)=> filter !== action.payload.filter);
            }
            else{
                secArticleFilter = [...secArticleFilter, action.payload.filter];
            }
            return {
                ...state, 
                secArticleFilter
            };
        // return {
        //     ...state,
        //     articleFilter: state.themeList.filter(theme=>theme.Id === action.payload.themeId)
        // }

        case actionTypes.landingPage.SET_PROJECT_ID: return {
            ...state,
            projectID: action.payload.projectId
        }

        case actionTypes.landingPage.SET_PROJECT_THEME_ID: return {
            ...state,
            projectThemeId: action.payload.themeId
        }

        case actionTypes.landingPage.SET_POIS:
            // console.log("From reducer :" ,action.payload);
            return {
                ...state,
                themePoiList: action.payload.themePOI
            }

        case actionTypes.landingPage.REMOVE_POIS:
            let themePois = state.themePoiList;
            delete themePois[action.payload.themePoiId];
            return {
                ...state,
                themePoiList: themePois
            }

        case actionTypes.landingPage.GET_PROJECT_THEME_FILTERS: return {
            ...state,
            projectThemeFilter: action.payload.projectThemeFilters
        }

        default: return state;
    }
}


export default landingReducer;