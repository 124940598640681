import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import blog from "../../assets/img/ic_blog.svg";
import timeline from "../../assets/img/ic_timelines.svg";
import trail from "../../assets/img/ic_trails.svg";
import { getColorCode, importAll, makeURLPath } from "../../Helpers/utility";
import { actionCreators } from "../../redux/actions/actionCreators";
import { makeAPICall, REQUEST } from "../../services/apiService";
import { mapUtils } from "../../services/mapService";

/* global $ */

export default function Sidebar({
  setSearchBox,
  sideBar,
  setSideBar,
  langId,
  projectID,
  initialize,
  poiId,
  themeIds
}) {
  const [count, setCount] = useState(0);
  const [showAll, setShowAll] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const dispatch = useDispatch();
  const { themeID } = useParams();
  const [searchText, setSearchText] = useState("");
  const [articles, setArticles] = useState([]);
  const lang = langId ? langId : "22";
  const { themeList, articleFilter } = useSelector(
    (state) => state.landingPage
  );

  const images = importAll(
    require.context("../../assets/img", false, /\.(png|jpe?g|svg)$/)
  );
  const size = window.innerWidth;

  useEffect(() => {
    if (size < 768) {
      let sideToggle = document.querySelector("#expand-bar");
      sideToggle.classList.add("shSidemenuCol");
      document.querySelector("#expand").classList.remove("active");
      document.querySelector("#colapse").classList.add("active");
    }

    if (sideBar === false) {
      let sideToggle = document.querySelector("#expand-bar");
      sideToggle.classList.add("shSidemenuCol");
      document.querySelector("#expand").classList.add("active");
      document.querySelector("#colapse").classList.remove("active");

      document
        .querySelectorAll("i.bx.bx-check.sub-theme-item.selected")
        .forEach(function (item) {
          item.classList.remove("show");
        });

      document
        .querySelector("i.bx.bxs-circle.unselected")
        .classList.add("show");

      document
        .querySelector("i.bx.bxs-check-circle.selected")
        .classList.remove("show");

      if (document.querySelector("div.shMapSel")) {
        document.querySelector("div.shMapSel").classList.add("contract");
      }
    }

    // setTimeout(() => {
    //   if(!poiId && !themeID && count === 0 && initialize && themeList.length > 0){
    //     toggleAllThemeSelection()
    //   } else if(themeList.length === 0 && count === 0 && !initialize){
    //     suggestArticle()
    //   }  
    //   else if (poiId){
    //     suggestArticle()
    //   }
    // });

    setTimeout(() => {
      if(count == 0){
        const blockElement = document.querySelector("#eventBlock");
        blockElement.style.pointerEvents = "none";
      }
      if(poiId){
        suggestArticle();
      } 
      if(!themeIds &&  count === 0 && initialize === 0 && themeList.length > 0 && !poiId){
        toggleAllThemeSelection()
      } else if(themeList.length === 0 && count === 0 && initialize === 1){
        suggestArticle();
      } 
      else if(themeIds &&  count === 0 && initialize === 2 && themeList.length > 0){
        toggleSingleThemeIdSelection(themeIds);
      }  
       
    }, 2000 );

    // toggleAllThemeSelection();
   }, [sideBar, themeList,initialize,themeIds]);
  // $(document).ready(function(){
  //   toggleAllThemeSelection();
  // });

  const suggestArticle = () => {
    mapUtils.removeMarker(mapUtils.markers);
    setCount(2);
    // if (searchText?.length > 2) {

      let reqBody = {
        type: REQUEST.GET,
        url: "entity/searchEntity",
        params: {
          keyword: poiId ? poiId : '',
          type: "POI",
          lang: poiId ?"":lang,
          project:projectID,
          sta: "publish",
        },
      };
      makeAPICall(reqBody)
        .then((result) => {
          console.log(result);
          if (result) {
            let data = result.data.data;
            if(mapUtils.markers.length >0){
              mapUtils.removeMarker(mapUtils.markers)
            }
            let marker = [];
            for (let i = 0; i < data.length; i++) {
              if(data[i].lat_lng !==  undefined && i < 100){
                // alert('hj');
                var coord = data[i].lat_lng.split(",")
                var obj ={
                  'id': data[i].id,
                  'meta_latitude':parseFloat(coord[0]),
                  'meta_longitude':parseFloat(coord[1]),
                  
                }
                marker.push(obj);
              }
          }  
            if (data.length > 10) {
              data.length = 10;
            }
            mapUtils.addMarkers(marker)
            setArticles(data);
          }
        })
        .catch((err) => console.log(err));
    // } else {
    //   setArticles([]);
    // }
  };
  const toggleSidebar = (e) => {
    let sideToggle = document.querySelector("#expand-bar");
    $(".shMapSel").toggleClass("contract");
    if (sideToggle.classList.contains("shSidemenuCol")) {
      sideToggle.classList.remove("shSidemenuCol");
      document.querySelector("#expand").classList.remove("active");
      document.querySelector("#colapse").classList.add("active");
      setSideBar(true);
      setSearchBox(false);
    } else {
      sideToggle.classList.add("shSidemenuCol");
      document.querySelector("#expand").classList.add("active");
      document.querySelector("#colapse").classList.remove("active");
      setSideBar(true);
      setSearchBox(true);
    }
  };

  const toggleSingleThemeIdSelection= (theme_id) =>{
    // if user click theme in dom ready state the code execution false;
    //if(count == 0 && theme_id != null){return false;}
    // get theme list ;
    let _theme;
    for (let i = 0; i < themeList.length; i++) {
        if(themeList[i].Id == theme_id){_theme = themeList[i];break;}
    }
    // get theme list ;
    dispatch(actionCreators.landingPage.themeItemClick(_theme.Id));
    setCount(2);
    setSearchBox(false);
    setSideBar(true);

    let totalSelection = articleFilter.length;
    let idx = selectedFilter.findIndex((filter) => filter === _theme.Id);

    let iTheme = document.querySelectorAll("." + makeURLPath(_theme.ThemeTitle) + " i.sub-theme-item");
    for (let i = 0; i < iTheme.length; i++) {
      iTheme[i].classList.toggle("show");
    }

    if (idx === -1) {
       setSelectedFilter([...selectedFilter, _theme.Id]);
       totalSelection += 1;
    } else {
      setSelectedFilter(selectedFilter.filter((filter) => filter !== _theme.Id));
      totalSelection -= 1;
    }

    let selectFil = [];
    themeList?.forEach((theme) => {
      if (theme.Id == _theme.Id) {
        selectFil.push(theme.Id);
      }
    });
    setSelectedFilter(selectFil);

    if (articleFilter.length === totalSelection) {
      document.querySelector("a.all-theme-select i.unselected").classList.remove("show");
      document.querySelector("a.all-theme-select i.selected").classList.add("show");
    } else {
      document.querySelector("a.all-theme-select i.selected").classList.remove("show");
      document.querySelector("a.all-theme-select i.unselected").classList.add("show");
    }

  }


  const toggleAllThemeSelection = () => {

    // remove marker 
    mapUtils.removeMarker(mapUtils.markers);

    let selectAll = document.querySelectorAll("a.all-theme-select i");
    setSearchBox(false);
    // setSideBar(true);
    setCount(2);
    for (let i = 0; i < selectAll.length; i++) {
      selectAll[i].classList.toggle("show");
    }
    let selected = document.querySelectorAll(
      "a.all-theme-select i.selected.show"
    );

    if (selected.length > 0) {
      dispatch(actionCreators.landingPage.selectAllTheme(themeList));

      let subListItems = document.querySelectorAll(
        "a.sub-theme i.sub-theme-item"
      );

      for (let j = 0; j < subListItems.length; j++) {
        if (subListItems[j].classList.contains("selected")) {
          subListItems[j].classList.add("show");
        }
      }

      let selectFil = [];
      themeList?.forEach((theme) => {
        if (theme.Id) {
          selectFil.push(theme.Id);
        }
      });
      setSelectedFilter(selectFil);
    } else {
      dispatch(actionCreators.landingPage.deselectAllTheme());

      let subListItems = document.querySelectorAll(
        "a.sub-theme i.sub-theme-item"
      );

      for (let j = 0; j < subListItems.length; j++) {
        if (subListItems[j].classList.contains("selected")) {
          subListItems[j].classList.remove("show");
        }
      }
      setSelectedFilter([]);
    }
    const blockElement = document.querySelector("#eventBlock");
    if(blockElement.style.pointerEvents == "none"){
      setTimeout(() => {
        blockElement.style.pointerEvents = "auto";
      }, 6000);
    }
  };

  const toggleSingleThemeSelection = (e, _theme) => {
    // remove marker 
    mapUtils.removeMarker(mapUtils.markers);

    dispatch(actionCreators.landingPage.themeItemClick(_theme.Id));
    

    let totalSelection = articleFilter.length;
    let idx = selectedFilter.findIndex((filter) => filter === _theme.Id);
    setSearchBox(false);
    setSideBar(true);

    if (idx === -1) {
      setSelectedFilter([...selectedFilter, _theme.Id]);
      totalSelection += 1;
    } else {
      setSelectedFilter(
        selectedFilter.filter((filter) => filter !== _theme.Id)
      );
      totalSelection -= 1;
    }

    let iTheme = document.querySelectorAll(
      "." + makeURLPath(_theme.ThemeTitle) + " i.sub-theme-item"
    );
    for (let i = 0; i < iTheme.length; i++) {
      iTheme[i].classList.toggle("show");
    }

    if (themeList.length === totalSelection) {
      document
        .querySelector("a.all-theme-select i.unselected")
        .classList.remove("show");
      document
        .querySelector("a.all-theme-select i.selected")
        .classList.add("show");
    } else {
      document
        .querySelector("a.all-theme-select i.selected")
        .classList.remove("show");
      document
        .querySelector("a.all-theme-select i.unselected")
        .classList.add("show");
    }
  };
  const regExp = /^[^a-zA-Z]*$/;

  return (
    <div id="expand-bar" className="shSidemenu">
      <a
        className="shSideMenuTrig"
        role="button"
        onClick={(e) => toggleSidebar(e)}
      >
        <img
          id="colapse"
          className="active"
          src={images["ic_side_draw_col@2x.png"].default}
          alt=""
        />
        <img
          id="expand"
          src={images["ic_side_draw_exp@2x.png"].default}
          alt=""
        />
      </a>
      <div className="shSidemenuScrl" id="eventBlock">
        <ul className="shSidemenuList">
          <li className={showAll ? "active" : ""}>
            {themeList[0]?.Id ? (
              <span style={{ display: "flex" }}>
                {themeList && themeList[0]?.Id == "0" && (
                  <a href="javascript:void(0);">
                    <i class="bx bxs-circle"></i>
                  </a>
                )}
                {themeList && themeList[0]?.Id != "0" && (
                  <a
                    href="#"
                    id="allTheme"
                    className="all-theme-select"
                    role="button"
                    onClick={() => toggleAllThemeSelection()}
                  >
                    <i class="bx bxs-circle unselected show"></i>
                    <i
                      className="bx bxs-check-circle selected"
                      style={{ color: getColorCode("ALL_COLOR") }}
                    ></i>
                  </a>
                )}
                <a
                  href="#"
                  style={{
                    padding: "20px 0 0 0",
                    position: "relative",
                    left: "-5px",
                  }}
                  role="button"
                  onClick={() => setShowAll(!showAll)}
                >
                  <span>Themes</span>
                  {themeList && themeList[0] && themeList[0]?.Id != "0" && (
                    <i className="ic_acc" />
                  )}
                </a>
              </span>
            ) : (
              ""
            )}
            { themeList && themeList[0]?.Id != "0" && (
              <ul className="shSidemenuList sub-menu">
                {themeList
                  .sort((a, b) => (a.ThemeTitle > b.ThemeTitle ? 1 : -1))
                  ?.map((_theme) => (
                    <>
                      {lang == "22" ? (
                        <>
                          {_theme?.ThemeTitle?.replace(regExp, "") != "" ? (
                            <li style={{ display: "flex" }} key={_theme.Id}>
                              <a
                                href="javascript:void(0);"
                                className={`sub-theme ${makeURLPath(
                                  _theme.ThemeTitle
                                )}`}
                                role="button"
                                onClick={(e) =>
                                  toggleSingleThemeSelection(e, _theme)
                                }
                              >
                                <i
                                
                                  className="bx bxs-circle unselected"
                                  style={{
                                    background:
                                      _theme.color != "#FFFF0"
                                        ? _theme?.color?.replace(
                                            "#White",
                                            _theme["DEFAULT_COLOR"]
                                          )
                                        : _theme["DEFAULT_COLOR"],
                                    color: "transparent",
                                    borderRadius: "50%",
                                  }}
                                />
                                <i
                                id = {_theme.Id}
                                  className={`bx bx-check sub-theme-item selected`}
                                  style={{
                                    position: "absolute",
                                    top: "7px",
                                    color: "#fff",
                                  }}
                                />
                              </a>
                              <a href={_theme.url} className="theme-item">
                                <span
                                  onClick={(e) =>
                                    toggleSingleThemeSelection(e, _theme)
                                  }
                                >
                                  {_theme.ThemeTitle}
                                </span>
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        <>
                          {_theme?.ThemeTitle?.replace(regExp, "") == "" ? (
                            <li style={{ display: "flex" }} key={_theme.Id}>
                              <a
                                href="javascript:void(0);"
                                className={`sub-theme ${makeURLPath(
                                  _theme.ThemeTitle
                                )}`}
                                role="button"
                                onClick={(e) =>
                                  toggleSingleThemeSelection(e, _theme)
                                }
                              >
                                <i
                                  className="bx bxs-circle unselected"
                                  style={{
                                    background: _theme.color
                                      ? _theme?.color?.replace(
                                          "#White",
                                          _theme["DEFAULT_COLOR"]
                                        )
                                      : _theme["DEFAULT_COLOR"],
                                    color: "transparent",
                                    borderRadius: "50%",
                                  }}
                                />
                                <i
                                  className={`bx bx-check sub-theme-item selected ${_theme.Id == themeID ? 'show': ''}`}
                                  style={{
                                    position: "absolute",
                                    top: "7px",
                                    color: "#fff",
                                  }}
                                />
                              </a>
                              <a href={_theme.url} className="theme-item">
                                <span
                                  onClick={(e) =>
                                    toggleSingleThemeSelection(e, _theme)
                                  }
                                >
                                  {_theme.ThemeTitle}
                                </span>
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </>
                  ))}
              </ul>
            )}
            {/* {themeList && themeList[0]?.Id != "0" &&
                            <ul className="shSidemenuList sub-menu">
                                {themeList?.map(_theme => (
                                    <li style={{ display: 'flex' }} key={_theme.Id}>
                                        <a href="javascript:void(0);" className={`sub-theme ${makeURLPath(_theme.ThemeTitle)}`} role="button" onClick={(e) => toggleSingleThemeSelection(e, _theme)}>
                                            <i className='bx bxs-circle sub-theme-item unselected show' style={{ color: _theme.color ? _theme.color : getColorCode('DEFAULT_COLOR') }}></i>
                                            <i className='bx bxs-check-circle sub-theme-item selected' style={{ color: _theme.color ? _theme.color : getColorCode('DEFAULT_COLOR') }}></i>
                                        </a>
                                        <a href={_theme.url} style={{ padding: '20px 0 0 0', position: 'relative', left: '-5px' }}><span>{_theme.ThemeTitle}</span></a>
                                    </li>
                                ))}
                            </ul>} */}
          </li>
          {/* {linksToOtherPage.map(link => ( */}
          <li>
            <a target="_blank" href={`/trails?type=${projectID}`}>
              <i className="inbuilt extra">
                <img src={trail} alt="" />
              </i>
              <span>Trails</span>
            </a>
          </li>
          <li>
            <a target="_blank" href={`/timelines?type=${projectID}`}>
              <i className="inbuilt extra">
                <img src={timeline} alt="" />
              </i>
              <span>Timelines</span>
            </a>
          </li>
          {/* <li>
            <a target="_blank" href="https://map.sahapedia.org/trails">
              <i className="inbuilt extra">
                <img src={map} alt="" />
              </i>
              <span>Maps</span>
            </a>
          </li>
          <li>
            <a target="_blank" href="">
              <i className="inbuilt extra">
                <img src={directory} alt="" />
              </i>
              <span>Directory</span>
            </a>
          </li> */}
          <li>
            <a target="_blank" href={`/blogs?project=${projectID}`}>
              <i className="inbuilt extra">
                <img src={blog} alt="" />
              </i>
              <span>Blog</span>
            </a>
          </li>
          {/* ))} */}
        </ul>
      </div>
    </div>
  );
}

const linksToOtherPage = [
  {
    _id: "eHouWT",
    linkTo: window.location.origin+"/trails",
    icon: "ic_trails.svg",
    title: "Trails",
  },
  {
    _id: "DXEuNs",
    linkTo: window.location.origin+"/timelines",
    icon: "ic_timelines.svg",
    title: "Timelines",
  },
  {
    _id: "XoN4u8",
    linkTo: "#",
    icon: "ic_maps.svg",
    title: "Maps",
  },
  {
    _id: "eRXCl6",
    linkTo: "#",
    icon: "ic_directory.svg",
    title: "Directory",
  },
  {
    _id: "IppU6f",
    linkTo: window.location.origin+"/blogs?project=18",
    icon: "ic_blog.svg",
    title: "Blog",
  },
];
