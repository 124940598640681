import React, { useEffect, useState } from "react";
import Modal from "react-awesome-modal";
import { BASE_API_URL } from "../../services/apiService";
import "./about.css";

function Team({ apiName, projectId, langId }) {
  let hrefValue = "#";
  const [pageData, setPageData] = useState(null);
  const [popWindow, setPopWindow] = useState(false);
  const [popData, setPopData] = useState(null);

  useEffect(() => {
    fetch(
      `${BASE_API_URL}/Api/getProjectDetailTeam?projectId=${projectId}&type=${apiName}&langId=${langId}`
    )
      .then((response) => response.json())
      .then((data) => {
        setPageData(data);
      });
  }, [projectId, apiName, langId]);

  function openModal(param) {
    setPopWindow(true);
    setPopData(param);
  }

  function closeModal() {
    setPopWindow(false);
  }

  return (
    <>
        <div >
          <div class="row">
            <div className="col-md-12 ">
              <div className="menu row">
                {pageData &&
                  pageData.map((d) => (
                    <div className="col-md-3 mb-4">
                      <div className="team">
                        {apiName==='sponsors'?<div style={{height:'21vh',display:'table-cell',verticalAlign:'middle'}}><img
                          onClick={() => openModal(d)}
                          src={d.IMG}
                          className="img-fluid"
                          alt=" "
                        /></div>:<img
                          onClick={() => openModal(d)}
                          src={d.IMG}
                          className="img-fluid"
                          alt=" "
                        />}
                        <h5 className="imgLink">
                          {" "}
                          <a 
                            onClick={() => openModal(d)}
                            href={hrefValue}
                            data-toggle="modal"
                            data-target="#largeModal"
                          >
                            {d.NAME}
                          </a>
                        </h5>
                        <span>{d.Role}</span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
  
      <Modal
        visible={popWindow}
        width="800"
        height="550"
        effect="fadeInUp"
        onClickAway={() => closeModal()}
      >
        <div className="articlesign" >
          <div className="row" >
            <div className="col-md-6"></div>
            <div
              className="col-md-6"
              align="right"
              style={{ padding: "10px 30px", fontSize: "20px" }}
            >
              <a
                href="javascript:void(0);"
                style={{ color: "grey" }}
                onClick={() => closeModal()}
              >
                X
              </a>
            </div>
          </div>
          <hr></hr>
          {popData && (
            <div className="row" >
              <div style={{ padding: "1px 35px" }} >
                <div className="team">
                  <img src={popData.IMG} className="img-fluid mb-4" alt=" " />
                  <h5> {popData.NAME}</h5>
                  <h6 style={{fontSize:"14px",fontWeight:"bold"}}> {popData.Role} </h6>
                  <span> {popData.AboutResource} </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default Team;
