import React from 'react';
import Header from "./header";
import Footer from "./footer";

export default class Subscribe extends React.Component{ 
   render(){
      if(this.props.match.params.data == 1){
         return(
            <>
            <Header></Header><br/><br/><br/><br/>
            <div style={{ fontSize: "30px", textAlign: "center", color:"Green" }}>You have been unsubscribed successfully.</div>
            <br/><br/><br/><br/>
            <Footer></Footer>
            </>  
      )} else{
         return(
            <>  
            <Header></Header><br/><br/><br/><br/>
            <div style={{ fontSize: "30px", textAlign: "center",color:"Red" }}>Something went wrong while processing.</div>
            <br/><br/><br/><br/>
            <Footer></Footer>
            </>  
      )}
   }; 
}
