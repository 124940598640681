import React, { Component } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { BASE_API_URL } from '../../services/apiService';
import Bookmark from '../bookmark4';
import icon1 from '../images/icon-1.png';
import '../library/libraryStyle.css';
import Social from '../socialicon';
import img from './images/lib-1.png';

export default class Content extends Component {
    constructor(props) {
		super(props);
		this.state={
            f:[],
            id:'',
            display: 6,
        }
        this.loadMore = this.loadMore.bind(this);
    }
    loadMore() {
        this.setState((prev) => {
          return {display: prev.display + 6};
        });
      }
    test(ev){
			fetch(`${BASE_API_URL}Api/deleteBookmark?id=${ev}`)		
			.then(res => res.json())
			.then(json => {
				if (json){
                    // window.location.reload();
					this.setState({
						f: json,
						noData:false,
				})
			}
			else{
				this.setState({		
				})
			}
			});
		
        }

    render() { 
        const size = window.innerWidth;
        const regex = /<[^>]*>/g;
        var entity = this.props.Entity;
        const shareUrl = window.location.href;
        const title = 'PCM';
        var items = this.props.Data[0].attached_entity;
        return ( 
            <div>
                <main>
                    {items.slice(0, this.state.display).map(item=>(
                <div class="blog-card" style={{display:'table'}}>
                    
                {
                  item.f_master_meta_entity_type=='1'?
                <a target="_blank" href={`/article/${item.title.replace(" ","-")}/${item.link2_entity_id}`}>
                  <img class="card-image-bl" src={item.thumbnail_url ? item.thumbnail_url : img} />
                </a>:
                  item.f_master_meta_entity_type=='7'?
                  <a target="_blank" href={`/museum/${item.title.replace(" ","-")}/${item.link2_entity_id}`}>
                  <img class="card-image-bl" src={item.thumbnail_url ? item.thumbnail_url : img} />
                </a>:
                item.f_master_meta_entity_type=='9'?
                <a target="_blank" href={`/trailDetail/${item.title.replace(" ","-")}/${item.link2_entity_id}`}>
                <img class="card-image-bl" src={item.thumbnail_url ? item.thumbnail_url : img} />
              </a>: item.f_master_meta_entity_type=='25'?
                <a target="_blank" href={`/timelineDetail/${item.title.replace(" ","-")}/${item.link2_entity_id}`}>
                <img class="card-image-bl" src={item.thumbnail_url ? item.thumbnail_url : img} />
              </a>:item.f_master_meta_entity_type=='23'?
                <a target="_blank" href={`/video/${item.link2_entity_id}`}>
                <img class="card-image-bl" src={item.thumbnail_url ? item.thumbnail_url : img} />
              </a>:item.f_master_meta_entity_type=='29'?
                <a  target="_blank" href={`/gallery/${item.link2_entity_id}`}>
                <img class="card-image-bl" src={item.thumbnail_url ? item.thumbnail_url : img} />
              </a>:
                <a target="_blank" href={`/home/${item.link2_entity_id}`}>
                  <img class="card-image-bl" src={item.thumbnail_url ? item.thumbnail_url : img} />
                </a>
                }
                    <div class="card-left2">
                    <a target="_blank" href={`/${item.f_master_meta_entity_type=='1'?'article':item.f_master_meta_entity_type=='7'?'museum':item.f_master_meta_entity_type=='15'?'POI':item.f_master_meta_entity_type=='29'?'gallery':item.f_master_meta_entity_type=='23'?'video':item.f_master_meta_entity_type=='25'?'timelineDetail':item.f_master_meta_entity_type=='9'?'trailDetail':''}/${item.title.replace(" ","-")}/${item.link2_entity_id}`}>
                    <div class="card-title-library">
                    <LinesEllipsis
                      text={item.title}
                      onReflow={this.handleReflow}
                      maxLine={2}
                      basedOn='words'
                    />
                      </div>
                    </a>
                    <div class="card-description-library ">
                    <LinesEllipsis
                      text={item.summary.replace(regex,'')}
                      onReflow={this.handleReflow}
                      maxLine={3}
                      basedOn='words'
                    />
                    </div>
                    <div class="card-icons-library">
                    <Popup trigger={<img class="card-icon-img-library" src={icon1} alt="share" />} position="right center">
                           
                            <div>          
                                {
                  item.f_master_meta_entity_type=='1'?
                    <Social type={'other'} title={item.title} summary={item.summary} url={`${window.location.origin}/article/${item.title.replace(" ","-")}/${item.link2_entity_id}`}></Social>
                :
                  item.f_master_meta_entity_type=='7'?
                  <Social type={'other'} title={item.title} summary={item.summary} url={`${window.location.origin}/museum/${item.title.replace(" ","-")}/${item.link2_entity_id}`}></Social>
                :
                item.f_master_meta_entity_type=='9'?
                <Social type={'other'} title={item.title} summary={item.summary} url={`${window.location.origin}/trailDetail/${item.title.replace(" ","-")}/${item.link2_entity_id}`}></Social>
              : item.f_master_meta_entity_type=='25'?
                <Social type={'other'} title={item.title} summary={item.summary} url={`${window.location.origin}/timelineDetail/${item.title.replace(" ","-")}/${item.link2_entity_id}`}></Social>
              :item.f_master_meta_entity_type=='23'?
                <Social type={'other'} title={item.title} summary={item.summary} url={`${window.location.origin}/video/${item.link2_entity_id}`}></Social>
              :item.f_master_meta_entity_type=='29'?
                <Social type={'other'} title={item.title} summary={item.summary} url={`${window.location.origin}/gallery/${item.link2_entity_id}`}></Social>
              :
                  <Social type={'other'} title={item.title} summary={item.summary} url={`${window.location.origin}/home/${item.link2_entity_id}`}></Social>
                
                }
                            </div>
                        
                        </Popup>
                        <Bookmark style={{height:"40px", width:"50px"}}  id={item.link2_entity_id} type={item.f_master_meta_entity_type} relation={'6'} user={navigator.cookieEnabled && localStorage.getItem('User')}></Bookmark>
                        {/*<img class="card-icon-img-library" src={icon2} alt="bookmark"  onClick={() => this.test(item.id)} />*/}
                    </div>
                    </div>
                </div>
                ))}
                {this.state.display < items.length &&
                        <div class="load-wrapper">
                        <div class="load-btn"  onClick={this.loadMore}>Load More</div>
                      </div>
            //  <button  type="button" className="load-more">Load more</button>
          }
                </main>
            </div>
         );
    }
}
 
