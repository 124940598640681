import React, { Component } from 'react';
import Header from '../header';
import { BASE_API_URL, makeAPICall, REQUEST } from '../../services/apiService';
import SecondaryHeader from './secondaryHeader';
export default class NavigateVideo extends Component {
    constructor(props) {
		super(props);
		this.state={
           link:""
        }
    }
    componentDidMount(){
        var projectID = this.props.match.params.projectId;
        fetch(`${BASE_API_URL}Api/getProjectLink?id=${projectID}`)	
        .then(res => res.json())
        .then(data => {
            this.setState({
                link:data[0]['project_link']
            })
  });	
}
        render() { 
        var projectID = this.props.match.params.projectId;
        return ( 
            <div class="navigateVideo">
            <Header></Header>
            <SecondaryHeader projectID={projectID} langId=""></SecondaryHeader>
            <iframe src={this.state.link?this.state.link:"https://www.youtube.com/embed/TYb825xCGxo?autoplay=1&mute=1"} frameborder="0" style={{overflow:"hidden",height:"96%",width:"100%"}} height="100%" width="100%"></iframe>
            </div>
         );
    }
}
 
