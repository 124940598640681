import React, { Component } from "react";
import Modal from "react-awesome-modal";
import renderHTML from "react-render-html";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Bookmark from "../bookmark";
import icon1 from "../images/icon-1.png";
import unBookmark from "../images/icon-4.png";
import lib1 from "../images/lib-1.png";
import Signin from "../signin2";
import Social from "../socialicon";
import "./searchStyle.css";

export default class Search extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      items: [],
      data: [],
      f: [],
      id: "",
      display: 6,
      bookmarkId: "",
      bookmarkType: "",
      relation: "",
      unbookmarkId: "",
      created: "",
    };
    this.loadMore = this.loadMore.bind(this);
  }
  scrollToMyRef = () => window.scrollTo(0, this.myRef.current.offsetTop);
  loadMore() {
    this.props.count(this.state.display + 6);
    this.setState((prev) => {
      return { display: prev.display + 6 };
    });
  }
  openModal(a, b, c, d, e) {
    if (a == "") {
      this.setState({
        bookmarkId: b,
        bookmarkType: c,
        relation: d,
        created: e,
      });
    } else {
      this.setState({
        unbookmarkId: a,
      });
    }
    this.setState({
      visible: true,
    });
  }

  closeModal() {
    this.setState({
      visible: false,
    });
  }

  render() {
    var items = this.props.Data;
    var dta = [];
    var data = items.data.map((itm) =>
      itm.f_workflow_state == "publish" ? dta.push(itm) : ""
    );
    var user = this.props.user;
    var userId = this.props.userId;
    var { bookmarkId, bookmarkType, relation, unbookmarkId, created } =
      this.state;
    return (
      <div className="searchList">
        <main style={{ marginTop: "-20px" }}>
          {dta.slice(0, this.state.display).map((item) => (
            <div className="blog-card2">
              <div className="card-left-search">
                <div className="card-type-search">{item.entity_type === "Overview"?"Article":(item.entity_type === "Module"?"Article":(item.entity_type === "Basic page"? "Article": (item.entity_type === "Interview" ? "Video":(item.entity_type === "Slider Banners" ? "Image Gallery" : item.entity_type))))}</div>
                <div className="card-title-search">
                  <a
                    className="search-hover"
                    target = "_blank"
                    href={
                      item.type == "external"
                        ? item.url
                        : item.f_master_meta_entity_type == '15' ? `/${item.directory}/POI/${item.project_id}/${item.id}` : `/${item.entity_type.toLowerCase() == 'image gallery' ? 'gallery' : item.entity_type.toLowerCase() == 'timeline' ? 'timelineDetail' : item.entity_type.toLowerCase() == 'trail' ? 'trailDetail' : item.entity_type.toLowerCase() }/${item.title.replace(/[? /]/g,"-")}/${item.id}`
                    }
                  >
                    {renderHTML(item.title)}
                  </a>
                </div>
                {item.subdomain_name ? (
                  <div className="card-category-search">
                    {item.subdomain_name.join(" / ")}
                  </div>
                ) : (
                  ""
                )}
                <div className="card-description-search">
                  {renderHTML(item.summary)}
                </div>
                <div className="card-author-search">
                  {item.f_name ? item.f_name + ' ':''}
                  {item.m_name ? item.m_name + ' ':''}
                  {item.l_name ? item.l_name + ' ':''}
                </div>
                <div className="card-icons-search">
                  <Popup
                    trigger={
                      <img
                        className="card-icon-img-search"
                        src={icon1}
                        alt="share"
                      />
                    }
                    position="left center"
                  >
                    <div>
                    <Social
                        type={"other"}
                        summary={item.summary ? item.summary : ""}
                        url={
                          item.type == "external"
                            ? item.url
                            :
                            //`${apiURL}search/${
                           //     item.entity_type
                             // }/${item.title.replace(" ", "-")}/${item.id}`
                              item.entity_type==='Article'?
                              `${window.location.origin}/article/${item.title.replace(/[? /]/g,"-")}/${item.id}`
                              :
                              item.entity_type==='Museum'?
                              `${window.location.origin}/museum/${item.title.replace(" ", "-")}/${item.id}`
                              :
                              item.entity_type==='Video'?
                              `${window.location.origin}/video/${item.id}`
                              :
                              item.entity_type==='Trail'?
                              `${window.location.origin}/trailDetail/${item.title.replace(" ", "-")}/${item.id}`
                              :
                              item.entity_type==='Timeline'?
                              `${window.location.origin}/timelineDetail/${item.title.replace(" ", "-")}/${item.id}`
                              :
                              item.entity_type==='image_gallery'?
                              `${window.location.origin}/gallery/${item.id}`
                              :
                              `${window.location.origin}/${item.entity_type}/${item.title.replace(" ", "-")}/${item.id}`
                        }
                      ></Social>
                    </div>
                  </Popup>
                  {user ? (
                    <>
                      <Bookmark
                        id={item.id}
                        type={item.f_master_meta_entity_type}
                        relation={"6"}
                        user={userId}
                      ></Bookmark>
                    </>
                  ) : (
                    <>
                      <img
                        className="card-icon-img-search"
                        value="Bookmark"
                        onClick={() =>
                          this.openModal(
                            item.linkage_id,
                            item.id,
                            item.f_master_meta_entity_type,
                            6,
                            userId
                          )
                        }
                        src={unBookmark}
                        alt="bookmark"
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="image-size" style={{ width: "300px" }}>
              <a
                    className="search-hover"
                    target = "_blank"
                    href={
                      item.type == "external"
                        ? item.url
                        : item.f_master_meta_entity_type == '15' ? `/${item.directory}/POI/${item.project_id}/${item.id}` : `/search/${item.entity_type}/${item.title}/${item.id}`
                    }
                  >
                <img
                  className="card-image-search"
                  src={item.thumbnail_file_path ? item.thumbnail_file_path : lib1}
                  alt = {renderHTML(item.title)}
                  />
                  </a>
              </div>
              <Modal
                visible={this.state.visible}
                width="800"
                height="550"
                effect="fadeInUp"
                onClickAway={() => this.closeModal()}
              >
                <div style={{ backgroundColor: "#e2e2e2" }}>
                  <div className="row" style={{ marginLeft: "0px" }}>
                    <div
                      className="col-md-6"
                      style={{ backgroundColor: "#fff" }}
                    ></div>
                    <div
                      className="col-md-6"
                      align="right"
                      style={{
                        color: "grey",
                        padding: "10px 30px",
                        fontSize: "20px",
                      }}
                    >
                      <a
                        href="javascript:void(0);"
                        style={{ color: "grey" }}
                        onClick={() => this.closeModal()}
                      >
                        X
                      </a>
                    </div>
                  </div>
                  <Signin
                    bookID={bookmarkId}
                    bookType={bookmarkType}
                    rel={relation}
                    unbook={unbookmarkId}
                    created={created}
                    close={() => this.closeModal()}
                  ></Signin>
                </div>
              </Modal>
            </div>
          ))}
          {
            this.state.display < items.data.length && (
              <div className="load-wrapper">
                <div className="load-btn" onClick={this.loadMore}>
                  Load More
                </div>
              </div>
            )
            //  <button  type="button" className="load-more">Load more</button>
          }
        </main>
      </div>
    );
  }
}
