import $ from "jquery";
import React, { Component } from "react";
import Modal from "react-awesome-modal";
import ham_open from "../assets/img/ic_close@2x.png";
import museumBook from "../assets/img/ic_tag@2x.png";
import museumunBook from "../assets/img/ic_tag_b@2x.png";
import Bookmark from "../components/bookmark2";
import Headersearch from "../components/headerSearch";
import ham_closed from "../components/images/ic_menu@2x.png";
import search from "../components/images/ic_search@2x.png";
import logo from "../components/images/left-logo.png";
import slogo from "../components/images/right-logo.png";
import user2 from "../components/images/user.png";
import Timer from "../components/pop/Timer";
// import '../css/style.css';
import "../css/App.css";
import "../css/bootstrap-grid.css";
import "../css/bootstrap.css";
import "../css/owl.theme.default.min.css";
import HamburgerManu from "./hamburgerMenu";
import Signin from "./signin2";

export default class Header extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            afterLoginUrl: '',
            menuOpen:false,
            shown: true,         
        }
    }
    openModal(url) {
        this.setState({
            afterLoginUrl: url,
            visible: true
        });
    }



  closeModal() {
    this.setState({
      visible: false,
    });
  }
  logout() {
    localStorage.clear();
    window.location.reload();
  }
  showBox() {
    this.setState({
      search: false,
    });
  }
  toggleHamburgerMenu() {
    if (this.state.menuOpen) {
      $("body").css({
        overflow: "auto",
      });
    } else {
      $("body").css({
        overflow: "hidden",
      });
    }

  }
  HamburgerOpen(){
    $("body").css({
      overflow: "auto",
    });
    this.setState({
      menuOpen:true
    })
  }
  HamburgerClose(){
    $("body").css({
      overflow: "hidden",
    });
    this.setState({
      menuOpen:false
    })
  }
  
    render() {
        var project = this.props.projectDirectory;
        var user = this.props.user;
        var keyword = this.props.keyword;
        var entity = this.props.entityDet;
        var pagename= this.props.pagename;
        

        return (
            <>
            <div class="timer">
            <Timer></Timer>
            </div>
                <div class="shHeader clearfix">
                    <div class="shTopHeader clearfix">
                        <div class="logoSecp float-left">
                            <a href="#" class="shMenu">
                                <a href="javascript:void(0);" id="burger-menu" onClick={() => this.state.menuOpen ?this.HamburgerClose():this.HamburgerOpen()}><img style={{ marginTop: "-28px" }} src={this.state.menuOpen ? ham_open : ham_closed} alt="burgur menu" /></a>
                            </a>
                            <a href="https://www.sahapedia.org/" class="sahaLogo">
                                <img class="roundLogo" src={logo} />
                            </a>
                            <img class="slogo" src={slogo} />
                            <a class="slogo1" href="https://www.sahapedia.org/" >
                            <img class="slogo" src={slogo} />
                            </a>
                        </div>
            <ul className="topLinks">
              {this.props.projectDirectory?.map((project) => (
                <li>
                  <a target="_blank" href={`/${project.directory}/${project.id}`}>
                    {project.directory ? " / " + project.directory : ""}
                  </a>
                  {pagename=="trails"?
                  <a href="/trails">
                  /All TRAILS</a>
                  :pagename=="timelines"?
                  <a href="/timelines">
                  /All timelines</a>
                  :""}
                </li>
              ))}
            </ul>
            <div class="shSearchSec">
              <a class="bookmarkIcon">
                {navigator.cookieEnabled && localStorage.getItem("User") ? (
                  <>
                    {entity ? (
                      <Bookmark
                        class="headerBook"
                        style={{ height: "40px", width: "50px" }}
                        deleteId={entity.id ? entity.id : ""}
                        id={entity.id}
                        type={entity.f_master_meta_entity_type}
                        relation={"6"}
                        user={localStorage.getItem("User")}
                        logo={museumBook}
                        logo2={museumunBook}
                      ></Bookmark>
                    ) : (
                      <Bookmark
                        class="headerBook"
                        style={{ height: "40px", width: "50px" }}
                        deleteId=""
                        id=""
                        type=""
                        relation=""
                        user=""
                        logo={museumBook}
                        logo2={museumunBook}
                      ></Bookmark>
                    )}
                  </>
                ) : (
                  <img
                    class="card-icon-img"
                    value="Bookmark"
                    onClick={() => this.openModal()}
                    src={museumBook}
                    alt="bookmark"
                  />
                )}
              </a>
            </div>
            <div class="shSearchSec float-right">
              <a href="#" class="shSearch">
                {this.state.shown ? (
                  <img
                    id="nav-search"
                    src={search}
                    onClick={() => this.setState({ shown: !this.state.shown })}
                    alt="search"
                  />
                ) : (
                  <Headersearch keyword={keyword}></Headersearch>
                )}{" "}
              </a>
              <div href="#" class="shProfile">
                <div class="dropdown">
                  <a
                    class="btn btn-secondary dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {navigator.cookieEnabled && localStorage.getItem("myData") ? (
                      <div
                        class=""
                        style={{
                          fontSize: "25px",
                          marginTop: "-5px",
                          marginLeft: "-2px",
                          textTransform: "uppercase",
                        }}
                      >
                        {navigator.cookieEnabled && localStorage.getItem("myData")}
                      </div>
                    ) : (
                      <div
                        class=""
                        style={{ width: "25px", marginLeft: "-6px" }}
                      >
                        <img
                          src={user2}
                          style={{ filter: "invert(100%)" }}
                          width="100%"
                        />
                      </div>
                    )}
                  </a>


                  <div
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuLink"
                    x-placement="bottom-end"
                    style={{
                      position: "absolute",
                      transform: "translate3d(39px, 39px, 0px)",
                      top: "0px",
                      left: "0px",
                      willChange: "transform",
                    }}
                  >
                    {navigator.cookieEnabled && localStorage.getItem("User") ? (
                      <a
                        class="dropdown-item"
                        target="_blank"
                        href={
                          localStorage.getItem("User")
                            ? "/search/myTrails"
                            : "#"
                        }
                      >
                        My Trails
                      </a>
                    ) : (
                      <a
                        class="dropdown-item"
                        target="_blank"
                        onClick={() => this.openModal("/search/myTrails")}
                      >
                        My Trails
                      </a>
                    )}
                    {navigator.cookieEnabled && localStorage.getItem("User") ? (
                      <a
                        class="dropdown-item"
                        target="_blank"
                        href={
                           localStorage.getItem("User")
                            ? "/search/libraries"
                            : "#"
                        }
                      >
                        My Library
                      </a>
                    ) : (
                      <a
                        class="dropdown-item"
                        target="_blank"
                        onClick={() => this.openModal(`/search/libraries`)}
                      >
                        My Library
                      </a>
                    )}

                    {navigator.cookieEnabled && localStorage.getItem("myData") ? (
                      <a
                        class="dropdown-item dropdown-sign-out"
                        value="Open"
                        onClick={() => this.logout()}
                      >
                        Sign Out
                      </a>
                    ) : (
                      <a
                        class="dropdown-item dropdown-sign-out"
                        value="Open"
                        onClick={() => this.openModal()}
                      >
                        Sign In
                      </a>
                    )}
                  </div>

                </div>
              </div>
            </div>
          </div>
          {this.state.menuOpen && <HamburgerManu />}
        </div>
        {this.state.visible?
        <Modal
          visible={this.state.visible}
          width="800"
          height="550"
          effect="fadeInUp"
          onClickAway={() => this.openModal()}
          
        >
          <div style={{ backgroundColor: "#e2e2e2" }}>
            <div class="row" style={{ marginLeft: "0px" }}>
              <div class="col-md-6" style={{ backgroundColor: "#fff" }}></div>
              <div
                class="col-md-6"
                align="right"
                style={{
                  color: "grey",
                  padding: "10px 30px",
                  fontSize: "20px",
                }}
              >
                <a
                  href="javascript:void(0);"
                  style={{ color: "grey" }}
                  onClick={() => this.closeModal()}
                >
                  X
                </a>
              </div>
            </div>
            <Signin
           
              afterLoginUrl={this.state.afterLoginUrl} close={() => this.closeModal()}></Signin>
             {/* <Signup afterLoginUrl={this.state.afterLoginUrl} close={() => this.closeModal()}></Signup>  */}
          </div>
        </Modal>
        :""}
      </>
    );
  }

}