import React, { Component } from 'react';
import search from '../images/ic_search@2x.png';
export default class Heading extends Component {
    constructor(props){
        super(props);
        
        this.state = {
          type: 'all',

        }
        this.entityType = this.entityType.bind(this);
        }
    entityType(ev){
        this.setState({type: ev.target.value});
        window.location.href=`/search/libraries?type=${ev.target.value}`
    }
    render() { 
        var items = this.props.Data;
        var type = this.props.Data2;
        var entity = this.props.Entity;
        return ( 
            <div>
                    <div id="header">
                    <h1 class="header-h1">My Library</h1>
                    <div class="sub-title">Bookmarked items are added to My Library</div>
                    <div id="filters2">
                        <div id="search-result-count">{items.status == 'false'? '0':items.length} Items Saved</div>
                        <div class="filter-inputs-wrapper">
                        <div class="filter-select">
                            <div class="filter-label">Filter by</div>
                            <select class="library-select" onChange={this.entityType} value={entity ? entity : this.state.type}>
                            <option value="">All</option>
                            {type.map(entity=>(
                                <option value={entity.id}>{entity.meta_entity_type}</option>
                            ))}
                            </select>
                        </div>
                        </div>
                    </div>
                    </div>
            </div>
         );
    }
}