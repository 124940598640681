import React, { useEffect, useState } from 'react';
import Loader from "react-loader-spinner";
import renderHTML from 'react-render-html';
import { IoIosArrowDropupCircle } from 'react-icons/io';
import { IoIosArrowDropdownCircle } from 'react-icons/io';
import museumBook from '../../assets/img/ic_tag_g1@2x.png';
import museumunBook from '../../assets/img/redBookmark.png';
import Bookmark from '../../components/bookmark3';
import { useWindowSize } from '../../Helpers/hooks/windows';
import { importAll } from '../../Helpers/utility';
import { BASE_API_URL, makeAPICall, REQUEST } from '../../services/apiService';
import SocialDark from '../common/socialDark';
import Authors from './authors';

export default function Banner({ bannerDetail, articleName, articleID, type = 'story' }) {
    const [entityLang, setEntityLang] = useState([]);
    const [activeLang, setActiveLang] = useState(null);
    const images = importAll(require.context('../../assets/img', false, /\.(png|jpe?g|svg)$/));
    const size = useWindowSize();
    const [load, setLoader]=useState(true);
    
    useEffect(() => {
        // Language\
        let data = { "entityType": "story", "entity_id": articleID };
        let reqParams = {
            type: REQUEST.POST,
            url: 'Entity/getEntityLanguages',
            data: JSON.stringify(data)
        };
        makeAPICall(reqParams)
            .then((result) => {
                if (result.data){
                    setEntityLang(result.data);
                    result.data?.forEach((lang) => {
                        if (lang.entity_id == articleID) {
                            setActiveLang(lang.language);
                        }
                    });
                }
            })
            .catch(err => console.log(err));
    }, []);

    return (
        <section className="shBanner"  style={{marginTop:'-70px'}}>
            <div className="shBnIfr">
            {load ? (
           <div align="center"><Loader
           type="ThreeDots"
           color="#da3a50"
           height={100}
           width={100}
           align="center"
         /></div>
        ) : null}
                <iframe className="banner-image" onLoad={()=>setLoader(false)} src={`${BASE_API_URL}blogs/trail?id=${bannerDetail.id}`} alt="" ></iframe>
                <a style={{color:"#444444"}} href= {articleID ? '/search/trailDetailFullScreen/'+articleName+'/'+articleID : '#'} className="resize row" ><img src={images['resize.png'].default} alt="" />Fullscreen</a>
            </div>
            <div className="shBnTimeline">
                <p id="subDomain_p">
                {bannerDetail?.domain_subdomain?.length == 0 ? 
                        <a className="sub-title" target="_blank" href={`${window.location.origin}/search?keyword=${bannerDetail.domain_subdomain[0].subdomain_name.replace(/\s+$/,'')}&type=Article&lang=`}>{bannerDetail.domain_subdomain[0].subdomain_name}</a>
                    : 
                        <>
                        <a className="sub-title" target="_blank" href={`${window.location.origin}/search?keyword=${bannerDetail?.domain_subdomain[0].subdomain_name.replace(/\s+$/,'')}&type=Article&lang=`}>{bannerDetail?.domain_subdomain[0].subdomain_name}&nbsp;</a>
                        {bannerDetail?.domain_subdomain.slice(1).map(subdomain => (
                            <a className="sub-title" target="_blank" href={`${window.location.origin}/search?keyword=${subdomain.subdomain_name.replace(/\s+$/,'')}&type=Article&lang=`}>/&nbsp;{subdomain.subdomain_name} </a>
                        ))}
                        </>
                    }                    </p>

                {/*bannerDetail?.theme.map(theme => (
                    <a style={{color:"#DA3A50"}} target="_blank" className="shBnCat" href={`${window.location.origin}/theme/${theme.theme}/${theme.f_master_theme_id}`} key={theme.f_master_theme_id}>{theme.theme}</a>
                ))*/}
                {bannerDetail?.theme?.length == 0 ? 
                    <>
                        {type == 'theme' ?
                        <a className="shBnCat" target="_blank" style={{cursor:"text",color:"#DA3A50"}}>{bannerDetail.theme[0] ? bannerDetail.theme[0].theme : ''}</a>
                        : 
                        <a className="shBnCat" target="_blank" style={{color:"#DA3A50"}} href={`${window.location.origin}/theme/${bannerDetail.theme[0] ? bannerDetail.theme[0].theme : ''}/${bannerDetail.theme[0] ? bannerDetail.theme[0].f_master_theme_id : ''}`}>{bannerDetail.theme[0] ? bannerDetail.theme[0].theme : ''}</a>

                        }
                        </>
                    : 
                        <>
                        {type == 'theme' ?
                        <a className="shBnCat" target="_blank" style={{cursor :"text",color:"#DA3A50"}}>{bannerDetail.theme ? bannerDetail.theme[0].theme : ''}&nbsp;</a>
                        :
                        <a className="shBnCat" target="_blank" style={{color:"#DA3A50"}} href={`${window.location.origin}/theme/${bannerDetail.theme ? bannerDetail.theme[0].theme : ''}/${bannerDetail.theme ? bannerDetail.theme[0].f_master_theme_id : ''}`}>{bannerDetail.theme ? bannerDetail.theme[0].theme : ''}&nbsp;</a>
                    }
                        {bannerDetail?.theme.slice(1).map(theme => (
                            <a className="shBnCat" target="_blank" style={{color:"#DA3A50"}} href={`${window.location.origin}/theme/${theme.theme}/${theme.f_master_theme_id}`}>/&nbsp;{theme.theme} </a>
                        ))}
                        </>
                    }
                <h2 id="title_heading_entity">{bannerDetail.title}</h2>
                {bannerDetail?.f_master_meta_entity_type &&
                        <div className="paraItalic timeline-summary">{bannerDetail?.summary ? renderHTML(bannerDetail.summary) : ''}</div>
                    } 
                <Authors authors={bannerDetail.Author} createdAt={bannerDetail.date_time_modified} />
                <div className="shSocialSec clearfix">
                    <ul className="shSocialList">
                        
                    <SocialDark title={bannerDetail.title} url={`${window.location.origin}/search/trailDetail/${bannerDetail.title}/${bannerDetail.id}`} summary={bannerDetail.summary} />
                    
                    <li className="balign">
                        <a>
                            <Bookmark style={{height:"40px", width:"50px"}} deleteId = {bannerDetail.id ? bannerDetail.id : ''} id={bannerDetail.id} type={bannerDetail.f_master_meta_entity_type} relation={'6'} user={navigator.cookieEnabled && localStorage.getItem('User')} logo = {museumBook} logo2 = {museumunBook}></Bookmark>
                        </a>
                    </li>
                    </ul>
                    <div href="#" className="shLang muLang">
                            {entityLang.length > 0 &&
                                (
                                    <div className="dropdown">
                                        <a aria-disabled={entityLang.length === 1 ? true : false} className="btn btn-secondary dropdown-toggle" target="_blank" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ paddingTop: '10px',cursor:"text" }}>
                                        <p style={{color:'black',marginTop:'-13px'}}><b>{bannerDetail.language ? bannerDetail.language : entityLang[0].language}</b>&nbsp;</p>
                                        {entityLang.length > 1 && <IoIosArrowDropdownCircle style={{margin: "-54% 110%",color:'black',width:'12px',cursor:'pointer'}}></IoIosArrowDropdownCircle>}
                                        </a>
                                        {/*<a target="_blank" className="btn btn-secondary dropdown-toggle languageMuseum" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{color:'#000000',cursor:'text'}}>
                                            <p style={{color:'black',marginTop:'-13px'}}><b>{entityLang[0].language}</b>&nbsp;</p>
                                        {entityLang.length > 1 && (<img style={{cursor:"pointer"}} src={images[`${size.width > 768 ? 'ic_drop@2x.png' : 'ic_drop_b@2x.png'}`].default} alt="" />)}
                                </a>*/}
                                        {entityLang.length > 1 && (
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                {entityLang.map((lang, index) => (
                                                <>
                                                {lang.language == bannerDetail.language &&
                                                <a style={{cursor:"pointer"}} className="dropdown-item">

                                                    {lang.language == bannerDetail.language && lang.language } {lang.language == bannerDetail.language && <IoIosArrowDropupCircle style={{margin: "-2% 35%"}}></IoIosArrowDropupCircle>}

                                                </a>}
    
                                            </>
                                            ))}
                                                {entityLang.map((lang, index) => (
                                                    lang.language != bannerDetail.language ?
                                            <a style={{cursor:"pointer"}} className="dropdown-item" target="_blank" href={`/search/timelineDetail/${lang.title}/${lang.entity_id}`}>{lang.language}
                                                    {/*lang.language != bannerDetail.language ? lang.language :''*/}
                                                
                                                </a>
                                                :''
                                            ))}
                                                
                                            </div>
                                        )}
                                    </div>
                                )}
                                    </div>
                                </div>
 
                           </div>
        
        <div className="sectioning">
            </div>
        </section>

    );
}