import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import ReactFullscreenSlideshow from 'react-fullscreen-slideshow';
import Carousel from './carousel';
import GalleryStandalone from './galleryStanadalone';

/* global $ */
let myRef = React.createRef();
export default function GalleryImg({ carouselImg, imageGallery, slideImg }) {
    const currentSlide = useRef(0);

    const onOpenSlideShow = (index) => {
        myRef.current.viewCurrentThumbnail(index);
        myRef.current.openModal();
    }

    const getIndex = src => {
        let srcInfo = src.split("/");
        let srcName = srcInfo[srcInfo.length - 1];

        return slideImg?.findIndex(img => img.image.includes(srcName));
    }

    useEffect(() => {
        let refElements2 = $('div[refid]');
        let refElements = refElements2;

        let cId = carouselImg[0] != undefined ? carouselImg[0].id : '';
        console.log('jadu' + refElements);
        let index = 0;
        let j = 0;
        for (let el in refElements) {
            console.log('jaduss' + refElements[el].attributes?.refid?.value);
            var data =  refElements[el].attributes?.refid?.value;
           // if(data == cId ? cId : ''){
            if (refElements[el].attributes && refElements[el].attributes.reftype?.value === "embedImageCarousel") {
                index++;
                ReactDOM.render(<Carousel onClickCarousel={onOpenSlideShow} imageList={carouselImg} refIDD={refElements[el].attributes?.refid?.value} />, refElements[el]);
            } else if (refElements[el].attributes?.reftype?.value === 'embedImageGallery' && imageGallery[j]?.images.length > 0) {
                j++;
                ReactDOM.render(<GalleryStandalone imageGallery={imageGallery[j - 1]} />, refElements[el]);
            }
            //return false;
        //}

        }

        // .medium-insert-images-Description
        let imgtags = document.querySelectorAll('.articlePage .medium-insert-images figure');

        imgtags.forEach((imgtag) => {
            let src = $(imgtag).find('img').attr('src');
            let imgIdx = getIndex(src);
            if (imgIdx !== -1) {
                slideImg[imgIdx].caption = imgtag.childNodes[1].innerHTML;
                ReactDOM.render(
                    <div>
                        <ReactFullscreenSlideshow
                            displayOverlay={false}
                            images={slideImg}
                            currentSlideIndex={imgIdx}
                            BannerImgIndex={imgIdx}
                            thumbnailsToBeDisplayed={10}
                            cycle={true}
                        />
                        <div className="banner-details">
                            <div className="left-des"></div>
                            <div className="right-des">{imgtag.childNodes[1].innerHTML}</div>
                        </div>
                    </div>
                    , imgtag);
            }
        });

        console.log("Sildeimg", slideImg);

    }, [currentSlide]);

    return (
        <div className="fullBoxMain">
            <ReactFullscreenSlideshow
                ref={myRef}
                displayOverlay={false}
                displayPreviewBanner={false}
                images={slideImg}
                currentSlideIndex={currentSlide.current}
                BannerImgIndex={currentSlide.current}
                cycle={true}
                thumbnailsToBeDisplayed={10}
            />
        </div>
    );
}
