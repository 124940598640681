import React, { useEffect, useRef, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { entityType, importAll } from '../../Helpers/utility';
import { BASE_API_URL, makeAPICall, REQUEST } from '../../services/apiService';
import Banner from '../common/banner';
import CardListing from '../common/card-listing';
import LoaderScreen from '../common/loader';
import RecommendList from '../common/recommendList';
import RecommendProjects from '../common/recommendProjects';
import References from '../common/references';
import Social from '../common/socialArticle';
import Tags from '../common/tags';
import Text from '../common/text';
import Footer from '../footer';
import GalleryImg from '../gallery/galleryImg';
import Header from '../header';
import Map from '../Map/map';
import apiURL from "../url";





const License = [
    {
        title: 'License 1',
        url: 'http://localhost/license-1'
    },
    {
        title: 'License 2',
        url: 'http://localhost/license-2'
    },
    {
        title: 'License 3',
        url: 'http://localhost/license-3'
    },
]


/* global $ */
export default function Article({ Article = null, ImgGallery = null, VideoGallery = null }) {
    const [entityDetail, setEntityDetail] = useState(null);
    const [entityProjects, setEntityProjects] = useState([]);
    const [entityRelation, setEntityRelation] = useState({ "curated": null, "automated": null });
    const [slider, setSlider] = useState(null);
    const autoSuggestion = useRef(6);
    const isLoadedCount = useRef(0);
    const pageType = useRef('article');
    const { articleId } = useParams();
    const article = useRef(null);
    const [readMore, setReadMore] = useState("");
    let history = useHistory ();
    const images = importAll(require.context('../../assets/img', false, /\.(png|jpe?g|svg)$/));

    
    useEffect(() => {
        let unique_article_id = articleId;
        if (unique_article_id == undefined && Article) {
            unique_article_id = Article.article.id;
            pageType.current = 'theme';
        } else if (unique_article_id == undefined && ImgGallery) {
            unique_article_id = ImgGallery.id;
            pageType.current = 'imgGallery';
        } else if (unique_article_id == undefined && VideoGallery) {
            unique_article_id = VideoGallery.id;
            pageType.current = 'videoGallery';
        }
        fetch(`${apiURL}Api/getThemeFromArticleId?articleId=${articleId}`)		
    .then(res => res.json())
    .then(json => {
        if(json.THEME_ID != 0){
             window.location.href = `/${json.THEME_DIR}/theme/${json.THEME_NAME}/${json.THEME_ID}`;
       }
    }
    );

        article.current = unique_article_id;
        if (unique_article_id !== undefined) {
            getEntityDetails(unique_article_id);
            getEntityProjects(unique_article_id);
            getEntityRelations(unique_article_id);
        }

        $(window).on("scroll", function () {
            if ($(window).scrollTop() > 100) {
                $(".shHeader ").addClass("smHeader");
            } else {
                //remove the background property so it comes transparent again (defined in your css)
                $(".shHeader ").removeClass("smHeader");
                $(".shHeader ").removeClass("card-icon-img");
            }
        });

    }, []);


    const getEntityDetails = (article_id) => {
        let reqParam = {
            type: REQUEST.POST,
            url: 'Entity/getEntityDetail',
            data: JSON.stringify({
                entityType: entityType[pageType.current],
                id: article_id
            })
        }

        makeAPICall(reqParam)
            .then((result) => {
                console.log({ "entity-details": result });
                if (result && result.data && result.data.status !== "false") {
                    let res = result.data[0];
                    setEntityDetail(res);

                    let img = [];
                    console.log("IMAGES PUSH start 1", img);
                    res.embedded_Carousel?.forEach((carouselImgList) => {
                        carouselImgList.images.forEach(image => {
                            img.push({ description: image.description, image: BASE_API_URL + image.file_path, caption: '' });
                        })
                    })
                    res.embedded_ImageGallery?.forEach((galleryImgList) => {
                        galleryImgList.images.forEach(image => {
                            img.push({ description: image.description, image: BASE_API_URL + image.file_path, caption: '' });
                        })
                    })

                    console.log("IMAGES PUSH start 2", img);

                    res.standalone_images?.forEach((standaloneImg) => {
                        console.log("standaloneImg", standaloneImg);
                        let a  = {description: pageType.current == 'imgGallery' ? standaloneImg.description : "",
                        image:  BASE_API_URL + standaloneImg.file_path ,
                        caption:  "", };
                        console.log("a : ", a);
                        // if(pageType.current == 'imgGallery'){
                            img.push(a);
                        // }
                        // else{
                        //     img.push();
                        // }
                        // console.log("IMAGES PUSH", img);
                    })
                    console.log("Slider", img);
                    setSlider(img)

                }
                isLoadedCount.current++;
            })
            .catch(err => { console.log(err); isLoadedCount.current++ });
    }

    // const getEntityRelations_curated = (article_id) => {
    //     let reqParam = {
    //         type: REQUEST.POST,
    //         url: 'Entity/getEntityRelations',
    //         data: JSON.stringify({
    //             relation: "curated_recommendation",
    //             entity_id: article_id
    //         })
    //     }
    //     makeAPICall(reqParam)
    //         .then((result) => {
    //             if (result) {
    //                 let res = result.data;
    //                 setEntityRelation({ ...entityRelation, "curated": res });
    //             }
    //             isLoadedCount.current++;
    //         })
    //         .catch(err => { console.log(err); isLoadedCount.current++ });
    // }

    const getEntityRelations = (article_id) => {
        let reqParam = {
            type: REQUEST.POST,
            url: 'Entity/getEntityRelations',
            data: JSON.stringify({
                relation: "curatedAndAutomated_recommendation",
                entity_id: article_id,
                backend:''
            })
        }
        makeAPICall(reqParam)
            .then((result) => {
                if (result && result.data && result.data.status !== "false") {
                    let res = result.data;
                    let autoRec = [];
                    let curatedRec = [];
                    res?.forEach((recommendation) => {
                        if (recommendation.recommendation_type === 'automated_recommendation')
                            autoRec.push(recommendation);
                        else if (recommendation.recommendation_type === 'curated_recommendation')
                            curatedRec.push(recommendation);
                    })
                    setEntityRelation({
                        "automated": autoRec,
                        "curated": curatedRec
                    });
                }
                isLoadedCount.current++;
            })
            .catch(err => { console.log(err); isLoadedCount.current++ });
    }

    const getEntityProjects = (article_id) => {
        let reqParam = {
            type: REQUEST.POST,
            url: 'Entity/getEntityProjects',
            data: JSON.stringify({
                id: article_id
            })
        }
        makeAPICall(reqParam)
            .then((result) => {
                if (result && result.data && result.data.status !== "false") {
                    let res = result.data;
                    setEntityProjects(res['primary_projects'].concat(res['secondary_projects']));
                }
                isLoadedCount.current++;
            })
            .catch(err => { console.log(err); isLoadedCount.current++ });
    }


    if (isLoadedCount.current < 2) {
        return <LoaderScreen />
    }

    return (
        
        <div className="wrapper"style={{marginTop:'-100px',maxWidth:'100%',overflowX:'hidden'}}>
            {/* {entityProjects && pageType.current === 'article' && < Header projectDirectory={entityProjects} />}
            {entityProjects && < Header />} */}
            {entityProjects.length > 0 && <Header projectDirectory={entityProjects} entityDet = {entityDetail} />}

            <section className="mainPlaceholder articlePlaceMain">

                {entityDetail && <Banner bannerDetail={entityDetail} articleID={article.current} type={pageType.current} slideImg={slider} />}

                <section className={`articlePageTheme ${pageType?.current === 'videoGallery' ? 'videoPageTheme' : ''}`} >
                    <div className="container">
                        <div className="row">

                            <div className="col-md-8">
                                {
                                    entityDetail && pageType.current === 'theme'
                                        ? (<Text text={entityDetail} themePOI={Article?.theme_poi} type={pageType.current} themeName={entityDetail.theme[0] ? entityDetail.theme[0].theme : ''} themeId={entityDetail.theme[0] ? entityDetail.theme[0].f_master_theme_id : ''} />)
                                        : (<Text text={entityDetail} />)
                                }
                                {entityDetail && entityDetail.tags && (<Tags taglist={entityDetail.tags} />)}

                                {entityDetail && <References
                                    notes={entityDetail.notes}
                                    bibliography={entityDetail.bibliography}
                                    explore={entityDetail.exploremore}
                                    notes_content={entityDetail.Notes_content}
                                />}

                                <div className="artNotesTabSocial clearfix">

                                    <ul class="shSocialList socialMuseum socialNew3">
                                    <Social url = {pageType.current === 'theme' ? `${window.location.origin}/${pageType.current}/${entityDetail.theme[0] ? entityDetail.theme[0].theme : ''}/${entityDetail.theme[0] ? entityDetail.theme[0].f_master_theme_id : ''}` :pageType.current === 'imgGallery' ?`${window.location.origin}/gallery/${entityDetail.id}`:pageType.current === 'videoGallery' ?`${window.location.origin}/video/${entityDetail.id}`: `${window.location.origin}/${pageType.current}/${entityDetail.title.replace(/\s+/g,'-')}/${entityDetail.id}`} summary = {entityDetail.summary}></Social>
                                    </ul>

                                    <>
                                        {entityDetail?.License?.length > 0 && entityDetail?.License.map((license) => (
                                            <>
                                                {license.url && <a href="/search/license" target="_blank" className="someRight">
                                                    <img src={images['ic_rights@2x.png'].default} alt="" />{license.title}
                                                </a>}
                                                {!license.url && <a href="/search/license" target="_blank" className="someRight someRightdis">
                                                    <img src={images['ic_rights@2x.png'].default} alt="" />{license.title}
                                                </a>}
                                            </>
                                        ))}
                                    </>
                                </div>
                            </div>


                            <div className="col-md-4">

                                <div className="moreMainSec">
                                    {entityRelation.curated && <RecommendList recommendations={entityRelation.curated} />}

                                    {entityProjects.length > 0 && <RecommendProjects projects={entityProjects} />}
                                </div>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="readMore">
                                    {readMore}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="stFtThumb">
                    <div className="container">
                        <div className="row">
                            {entityRelation && entityRelation.automated?.map((relation, index) => (
                                <>
                                    {autoSuggestion.current > index && (
                                        <CardListing setReadMore={setReadMore} cardDetail={entityDetail.id} type={pageType.current} />
                                    )}
                                </>
                            ))}
                        </div>
                        
                        {autoSuggestion.current < entityRelation.automated.length - 1 ? (
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <button className="btn btn-load" onClick={() => autoSuggestion.current += 6}>Load more</button>
                                </div>
                            </div>
                        ) : ''}
                    </div>
                </section>
                {/* image Gallery */}
                {/* {slider?.length > 0 && <ReactFullscreenSlideshow displayOverlay={false} images={slider} currentSlideIndex={2} BannerImgIndex={2} cycle={true} />} */}
                <Footer />
            </section>

            {entityDetail && pageType.current !== 'theme' && (<Map pagetype={pageType.current} Locations={entityDetail.attachPoi} />)}

            {entityDetail && slider?.length > 0 && <GalleryImg carouselImg={entityDetail.embedded_Carousel} imageGallery={entityDetail.embedded_ImageGallery} slideImg={slider} />}
        </div>
    );
}
