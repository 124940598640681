import React, { Component } from 'react';
import { IoLogoWhatsapp } from 'react-icons/io';
import {
    FacebookShareButton, TwitterShareButton, WhatsappShareButton
} from "react-share";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { stripHtml } from "string-strip-html";
import Socialicon from '../../components/socialicon';
import arrow from '../images/arro.png';
import fb from '../images/fb.png';
import twitter from '../images/twitter.png';

// import email from '../images/email.png';

export default class Social extends Component {
    render() {
        const size = window.innerWidth;
        var re = /,?[ ]/g;
        const regex = /<[^>]*>/g;
        const button = {
            width: "auto"
        }
        //var title = this.props.title?.replace(regex,'');
        var imgUrl = this.props.img;
        var entityUrl = this.props.url.replace(re,'-');
        //var summary2 = this.props.summary?.replace(regex, ''); 
        //var summary = summary2?.replace(/^\s+|\s+$/g, '');

        var summary2 = stripHtml(this.props.summary || '').result;
        var summary = summary2?.replace(/^\s+|\s+$/g, '');
        var title = stripHtml(this.props.title || '').result;

        return (
            <>
            <li>
                    <FacebookShareButton
                        url={entityUrl}
                        hashtag = {title}
                        quote={title+' - '+summary +' - '+entityUrl}
                        style={button}
                        media={window.location.origin+"/assets/logo/sahapedialogo.png"}
                        separator=" - "
                        className="Demo__some-network__share-button"
                    >
                        <a href="#"><img src={fb} alt="" /></a>

                        {/* <a target="_blank" href={`https://www.facebook.com/sharer.php?u=${entityUrl}?imageurl=${imgUrl}}`}><img src={fb} alt="" /></a> */}
                    </FacebookShareButton>
                </li>
                <li>
                    <TwitterShareButton
                        title={title +' - '+ summary}
                        // via={summary}
                        url={entityUrl}
                        style={button}
                        separator=" - "
                        className="Demo__some-network__share-button"
                    >
                        <a href="#"><img src={twitter} alt="" /></a>
                    </TwitterShareButton>
                </li>
                <li>
                <WhatsappShareButton
                            url={entityUrl}
                            title= {title+' - '+summary}
                            style={button}
                            separator=" - "
                            className="Demo__some-network__share-button"
                        >
                            <a href="#" class="whatsappHover" style={{marginTop:'-1px'}}><IoLogoWhatsapp style={{color: "#000"}} class="mobileSocialView"></IoLogoWhatsapp></a>
                        </WhatsappShareButton>
                </li>
                <li>
                    <a>
                        <Popup trigger={<img src={arrow} alt="share" />} position={size > 400 ? 'right center' : 'bottom center'}>
                            <div><Socialicon title = {title} url={entityUrl} summary={summary} type={'entity'}></Socialicon></div>
                        </Popup>
                    </a>
                </li>
            </>
        );
    }
}

