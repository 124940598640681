import React, { Component } from 'react';
import search from '../images/ic_search@2x.png';
import {FiSearch} from 'react-icons/fi';
import './searchStyle.css';
export default class Heading extends Component {
    constructor(props){
        super(props);

        this.state = {
          keyword : '',
          type: 'all',
          lang: ''

        }
        this.updateInput = this.updateInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.language = this.language.bind(this);
        this.entityType = this.entityType.bind(this);
        }
        updateInput(event){
            this.setState({keyword : event.target.value})
            var lang = this.props.language;
            var type = this.props.entity;
            if(event.key === 'Enter'){
                event.preventDefault();
                window.location.href=`/search?keyword=${event.target.value}&type=${type ? type : this.state.type}&lang=${lang ? lang : this.state.lang}`;
            }
            }
            handleSubmit(){
            var lang = this.props.language;
            var type = this.props.entity;

            window.location.href=`/search?keyword=${this.state.keyword}&type=${type ? type : this.state.type}&lang=${lang ? lang : this.state.lang}`
            }
            entityType(ev){
                var lang = this.props.language;
                var keyword = this.props.keyword;

                this.setState({type: ev.target.value});
                window.location.href=`/search?keyword=${this.state.keyword ? this.state.keyword : keyword}&type=${ev.target.value}&lang=${lang ? lang : this.state.lang}`
            }
            language(event){
                var type = this.props.entity;
                var keyword = this.props.keyword;

                this.setState({lang: event.target.value});
                window.location.href=`/search?keyword=${this.state.keyword ? this.state.keyword : keyword}&type=${type ? type : this.state.type}&lang=${event.target.value}`
            }
    render() {
        var items = this.props.Data;
        var type = this.props.Data2;
        var lang = this.props.Data3;
        var entitytype = this.props.entity;
        var language = this.props.language;
        var keyword = this.props.keyword;
        var l = this.props.language;
        var len = 0;
        var d = items.data.map(item=>(item.f_workflow_state == 'publish' ? len++ : ''))

        return (
            <div>
                    <div id="header-search">
                    <h1>Search Results</h1>
                    <form id="search-form">
                        <input type="text" name="keyword" id="pageSearch" onChange={this.updateInput} onKeyPress={this.updateInput}  defaultValue={keyword} />
                        <FiSearch id="search-form-icon" type="submit" style={{display:"block"}} onClick={this.handleSubmit}></FiSearch>
                        {/* <img id="search-form-icon" src={search} alt="search"  /> */}
                    </form>
                    <div id="filters">

                        <div id="search-result-count">{items.Count.value} Results Found</div>
                        <div class="filter-inputs-wrapper">
                        <div class="filter-select">
                            <div class="filter-label">Filter by</div>
                            <select onChange={this.entityType} value={entitytype ? entitytype : this.state.type}>
                            <option value="all">All</option>
                            {type.map(entity=>(
                                <option value={entity.meta_entity_type}>{entity.meta_entity_type}</option>
                            ))}
                            </select>
                        </div>
                        <div class="filter-select">
                            <div class="filter-label">Language</div>
                            <select onChange={this.language} value={language ? language : this.state.lang}>
                            <option value="">All</option>
                            {lang.map(entity=>(
                                <option value={entity.id}>{entity.language}</option>
                            ))}
                            </select>
                        </div>
                        </div>
                    </div>
                    </div>
            </div>
         );
    }
}