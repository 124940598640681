import React from 'react';

export default function RecommendProjects({ projects }) {

    return (
        <div class="projSection">
            {projects.map((project, index) => (
                <div className={`shArtLogo ${index !== 0 ? 'shArtLogoSec' : ''}`} key={`project_${index}`} data-id={project.id || index}>
                    <a href={`/home/${project.id}`} target="_blank">
                        <img src={project.projectLogo.slice(-1)} alt={project.project} />
                    </a>
                </div>
            ))}
        </div>
    );
}