import { BASE_API_URL } from "../../services/apiService";

export default function GalleryStandalone({ imageGallery }) {
    let url = window.location.origin;
    console.log(window.location);

    return (
        <div className="pageImg text-center clearfix">
            <div className="playItem viewGalItem">
                <h6>{imageGallery.title}</h6>
                <a href={`${url}/gallery/${imageGallery.title}/${imageGallery['id']}`} target="_blank" className="btn btn-con">View Gallery</a>
            </div>
            <div className="overLay" />
            <img src={BASE_API_URL + imageGallery.images[0].file_path} alt="" />
        </div>

    );
}