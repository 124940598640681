import $ from 'jquery';
import React, { useState } from "react";
import renderHTML from "react-render-html";
import Popup from "reactjs-popup";
import defaultImg from "../../components/images/default-thumbnail.jpg";
import { importAll, makeURLPath } from "../../Helpers/utility";
import { makeAPICall, REQUEST } from "../../services/apiService";
import Bookmark from "../bookmark";
import icon1 from "../images/icon-1.png";
import Social from "../socialicon";

export default function MapPopup({ entities }) {
  // console.log('jjkk'+entities);
  const images = importAll(
    require.context("../../assets/img", false, /\.(png|jpe?g|svg)$/)
  );
  const [projectDir, setProjctDir] = useState("");
  const openModal = (a, b, c, d, e) => {
    if (a == "") {
      setbookmarkId(b);
      setbookmarkType(c);
      setrelation(d);
      setcreated(e);
    } else {
      setunbookmarkId(a);
    }
    setVisible(true);
  };

  function getProjectDir(themeID) {
    let reqParams = {
      type: REQUEST.GET,
      url: "Api/getProjectDirFromThemeId?themeId=" + themeID,
    };
    makeAPICall(reqParams).then((result) => {
        // console.log("result", result.data.replace(/(^[ \t]*\n)/gm, ""));
      setProjctDir(result.data.replace(/(^[ \t]*\n)/gm, ""));
    });
  }
  $(".card-icons-bl").click(function(){ 
    $(".popup-content").hide();
    // $("#search-box-toggle").show();
});

  const closeModal = () => {
    setVisible(false);
  };
  const [bookmarkId, setbookmarkId] = useState();
  const [bookmarkType, setbookmarkType] = useState();
  const [relation, setrelation] = useState();
  const [created, setcreated] = useState();
  const [unbookmarkId, setunbookmarkId] = useState();
  const [visible, setVisible] = useState(false);
  return (
    <>
      {entities &&
        entities.map((entity, index) => (
          <div className="shPoiHead">
            <h2>{entity.title ? renderHTML(entity.title) : ""}</h2>
            {index === 0 && (
              <div className="shPoiImg">
                <img
                  src={entity.thumbnail_url ? entity.thumbnail_url : defaultImg}
                  alt=""
                />
              </div>
            )}
            <div class="wrapper-pop">
              {index === 0 && (
                <div className="shPoiBody">
                  <p className="pop-summary">
                    {entity.summary ? renderHTML(entity.summary) : ""}
                  </p>
                  <div className="shPoiBreadcrumb" style={{ padding: "0px" }}>
                    <div aria-label="breadcrumb" className="shBreadcrumb">
                      <ol className="breadcrumb">
                        {entity.theme?.map((_theme) => ( <>
                            {getProjectDir(_theme.f_master_theme_id)}
                          <li className="breadcrumb-item">
                            <a
                              href={`${
                                window.location.origin
                              }/${projectDir}/theme/${makeURLPath(_theme.theme)}/${
                                _theme.f_master_theme_id
                              }`}
                            >
                              {_theme.theme}
                            </a>
                          </li>
                          </>
                        ))}
                      </ol>
                    </div>
                  </div>
                </div>
              )}
              {entity.entity?.map((article) => (
                <>
                  {article.type != "poi" ? (
                    <div>
                      <div className="shPoiBreadcrumb">
                        <div aria-label="breadcrumb" className="shBreadcrumb">
                        {article.title != entity.title ? (
                            <p style={{ fontSize: "18px", margin: "0px 0px" }}>
                              {article.title != entity.title
                                ?renderHTML(article.title)
                                : ""}
                            </p>
                          ) : (
                            ""
                          )}
                          {article.title != entity.title ? (
                            <ol className="breadcrumb">
                              {entity.theme?.map((_theme) => (
                                <li className="breadcrumb-item">
                                  <a
                                    href={`${
                                      window.location.origin
                                    }/${projectDir}/theme/${makeURLPath(_theme.theme)}/${
                                      _theme.f_master_theme_id
                                    }`}
                                  >
                                    {_theme.theme}
                                  </a>
                                </li>
                              ))}
                            </ol>
                          ) : (
                            ""
                          )}
                          <a
                            href={`/${article.type}/${article.title.replace(
                              /,?[ ]/g,
                              "-"
                            )}/${article.id}`}
                            target="_blank"
                            style={{ marginTop: "5px" }}
                          >
                            <button className="btn btn-more">More</button>
                          </a>
                        </div>
                      </div>
                      <div className="shPoiFoot clearfix">
                        <div className="stThumbItemCtrl">
                          <a href={`https://maps.mapmyindia.com/direction?places=mmi000;${entity.Location.latitude},${entity.Location.longitude}`}target="_blank">
                            <img
                              src={images["navigation.png"].default}
                              alt
                              style={{
                                color: "#444",
                                width: "16px",
                                marginTop: "1px",
                                filter: "invert(25%)",
                              }}
                            />
                          </a>
                          <a>
                            <Popup
                              trigger={
                                <img
                                  class="card-icon-img-search"
                                  src={icon1}
                                  alt="share"
                                />
                              }
                              position="top center"
                            >
                              <div>
                                <Social
                                  type={"other"}
                                  title={entity.title}
                                  summary={entity.summary ? entity.summary : ""}
                                  url={
                                    entity.type == "external"
                                      ? entity.url
                                      : //`${apiURL}search/${
                                      //     entity.entity_type
                                      // }/${entity.title.replace(" ", "-")}/${entity.id}`
                                      article.type === "article"
                                      ? `${window.location.origin}/article/${article.title.replace(
                                          " ",
                                          "-"
                                        )}/${article.id}`
                                      : article.type === "museum"
                                      ? `${window.location.origin}/museum/${article.title.replace(
                                          " ",
                                          "-"
                                        )}/${article.id}`
                                      : article.type === "video"
                                      ? `${window.location.origin}/video/${article.id}`
                                      : article.type === "story"
                                      ? `${window.location.origin}/trailDetail/${article.title.replace(
                                          " ",
                                          "-"
                                        )}/${article.id}`
                                      : article.type === "timeline"
                                      ? `${window.location.origin}/timelineDetail/${article.title.replace(
                                          " ",
                                          "-"
                                        )}/${article.id}`
                                      : article.type === "image_gallery"
                                      ? `${window.location.origin}/gallery/${article.id}`
                                      : `${window.location.origin}/poi/${article.title.replace(
                                          " ",
                                          "-"
                                        )}/${article.id}`
                                  }
                                ></Social>
                              </div>
                            </Popup>
                          </a>
                          <a className="mapSignin">
                            <Bookmark
                              id={article.id}
                              deleteId={article.id}
                              type={article.typeId}
                              relation={"6"}
                              user={navigator.cookieEnabled && localStorage.getItem("User")}
                            ></Bookmark>
                          </a>
                        </div>
                      </div>
                      {entity.length > 0 ? <hr></hr> : ""}
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ))}
            </div>
          </div>
        ))}
    </>
  );
}
