import $ from "jquery";
import React, { useEffect } from "react";
import renderHTML from "react-render-html";

export default function References({ notes, bibliography, explore, notes_content }) {
  let references = false;
  const regex = /(<([^>]+)>)/gi;
  useEffect(() => {
    $("#artNotesTabContent a").attr("target", "_blank");
    $("#artNotesTabContent a").css("cursor", "pointer");
  });
  if (
    notes.replace(regex, "") != "" ||
    bibliography.replace(regex, "") != "" ||
    explore.replace(regex, "") != ""
  ) {
    references = true;
  }

  return (
    <div className="articleNotesTab">
      <ul className="nav nav-tabs" id="artNotesTab" role="tablist">
        {notes.replace(regex, "") != "" && (
          <li className="nav-item">
            <a
              className="nav-link active"
              id="notes-tab"
              data-toggle="tab"
              href="#notes"
              role="tab"
              aria-controls="notes"
              aria-selected="true"
            >
              Notes
            </a>
          </li>
        )}
        {bibliography.replace(regex, "") != "" && (
          <li className="nav-item">
            <a
              className={
                notes.replace(regex, "") === ""
                  ? "nav-link active show"
                  : "nav-link"
              }
              id="bibliography-tab"
              data-toggle="tab"
              href="#bibliography"
              role="tab"
              aria-controls="bibliography"
              aria-selected="false"
            >
              Bibliography
            </a>
          </li>
        )}
        {explore.replace(regex, "") != "" && (
          <li className="nav-item">
            <a
              className={
                bibliography.replace(regex, "") === "" &&
                notes.replace(regex, "") === ""
                  ? "nav-link active show"
                  : "nav-link"
              }
              id="explore-tab"
              data-toggle="tab"
              href="#explore"
              role="tab"
              aria-controls="explore"
              aria-selected="false"
            >
              Explore
            </a>
          </li>
        )}
      </ul>
      {references && (
        <div className="tab-content" id="artNotesTabContent">
          {notes.replace(regex, "") != "" && (
            <div
              className="tab-pane fade show active"
              id="notes"
              role="tabpanel"
              aria-labelledby="notes-tab"
            >
              <div className="notTabMain">
              {notes_content != undefined ?
                <>
                {notes_content != '' ? 
                <>
                {notes_content.map(nt=>(
                  <p class="lineNote">{renderHTML(nt.note)}</p>
                ))}
                </>
                :''}
                </>
                : ''}
                {renderHTML(notes)}</div>
            </div>
          )}
          {bibliography.replace(regex, "") != "" && (
            <div
            className={
                notes.replace(regex, "") === ""
                  ? "tab-pane fade active show"
                  : "tab-pane fade"
              }
            id="bibliography"
              role="tabpanel"
              aria-labelledby="bibliography-tab"
            >
              <div className="notTabMain">{renderHTML(bibliography)}</div>
            </div>
          )}
          {explore.replace(regex, "") != "" && (
            <div
              className={
                bibliography.replace(regex, "") === "" &&
                notes.replace(regex, "") === ""
                  ? "tab-pane fade active show"
                  : "tab-pane fade"
              }
              id="explore"
              role="tabpanel"
              aria-labelledby="explore-tab"
            >
              <div className="notTabMain">{renderHTML(explore)}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
