import React from 'react';
import { useParams } from 'react-router-dom';
import Trailfull from './trailFull';




/* global $ */
export default function TrialViewFull({ }) {
    const { name } = useParams();
    const { id } = useParams();
    const { user } = useParams();
  
  //  if (isLoadedCount.current < 2) {
  //      return <LoaderScreen />
//    }

    return (
        <div className="wrapper">
            <section class="mainPlaceholder articlePlaceMain">
                <Trailfull id={id} user={user} name={name} />
            </section>
        </div>
    );
}
