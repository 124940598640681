import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import museumBook from '../assets/img/ic_tag_g1@2x.png';
import RedmuseumBook from '../assets/img/redBookmark.png';
import Signin from '../components/signin2';
import apiURL from '../components/url';
import unBookmark from './images/icon-4.png';


export default class Bookmark extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        // this.refresh = this.refresh.bind(this)

        this.state = {
            items: [],
            sta:""
            
        }
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);

    }
    // refresh () {
    //     this.componentDidMount()
    //   }

    refresh = () => {
        this.setState({});
      };
      componentWillMount(){
        this.bookmarkEntity();
        
      }
      forceUpdateHandler(){
        this.forceUpdate();

      };
      
    bookmarkEntity() {
        var id = this.props.id;
        var type = this.props.type;
        var user = this.props.user;
        fetch(`${apiURL}Api/getBookmarkList?relation=6&type=${type ? type : ''}&user=${user}&entity_id=${id}`)
            .then(res => res.json())
            .then(json => {
                if (json) {
                    this.setState({
                        isLoaded: true,
                        items: json,
                        noData: false,
                    })
                }
                else {
                    this.setState({
                        isLoaded: true,
                        items: [],
                        noData: true,

                    })
                }
            });
    }
    test(id, type, relation, user) {

        var idBanner = this.props.id;
        fetch(`${apiURL}Api/addBookmark?id=${id ? id : idBanner}&entityType=${type}&relation=${relation}&user=${user}`)
            .then(res => res.json())
            .then(json => {
                if (json) {
                    // window.location.reload();
                    this.setState({
                        items: json,
                        noData: false,
                    })
                    // this.addBookmark();
                    navigator.cookieEnabled && localStorage.setItem('id', id);
                    this.componentWillMount();
                    this.forceUpdateHandler();
                    this.bookmarkEntity();

                }
                else {
                    this.setState({
                    })
                }
            });
    }
    test2(id, relation, user) {
        fetch(`${apiURL}Api/deleteBookmark?id=${id}&user=${navigator.cookieEnabled && localStorage.getItem('User')}`)
            .then(res => res.json())
            .then(json => {
                if (json) {
                    // window.location.reload();
                    this.setState({
                        f: json,
                        noData: false,
                    })
                    // this.deleteBookmark();
                    // this.componentDidUpdate();
                    this.forceUpdateHandler();
                    navigator.cookieEnabled &&   localStorage.setItem('unBookId', id);
                    this.componentWillMount();
                    this.bookmarkEntity();


                    // window.location.reload();

                }
                else {
                    this.setState({
                    })
                }
            });
            this.render();

    }
    openModal() {
        this.setState({
            visible : true
        });
    }
    
    closeModal() {
        this.setState({
            visible : false
        });
    }
    render() {
        var id = this.props.id;
        var type = this.props.type;
        var deleteId = this.props.deleteId;
        var relation = this.props.relation;
        var user = this.props.user;
        var { items } = this.state;
        var museumLogo = this.props.logo;
        var museumLogo2 = this.props.logo2;
// alert(this.state.sta);
        return (
            <div style={{filter:"none !important"}}>{
                navigator.cookieEnabled && localStorage.getItem('User') ? 
                <>
                <div class="card-icons-bl">
                    {items.status == "false" ?
                        <img class="card-icon-img-red" value="Bookmark" onClick={() => this.test(id, type, 6, user)} src={museumLogo ? museumLogo : unBookmark} alt="bookmark" />
                        :
                        <img src={RedmuseumBook} class="card-icon-img-red" onClick={() => this.test2(deleteId)}></img>
                        // <img class="card-icon-img" value="Bookmark" onClick={() => this.test2(deleteId)} src={bookmarkRed} alt="bookmark" />
                    }
                </div>
                
            </>
            : 
            <div class="card-icons-bl">
            <img class="card-icon-img-red" value="Bookmark" onClick={() => this.openModal()} src={museumBook} alt="bookmark" />   
            </div>
            }
            {this.state.visible?
            <Modal visible={this.state.visible} width="800" height="550" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                        <div style={{backgroundColor:"#e2e2e2"}}>
                            <div class="row" style={{marginLeft:"0px"}}>
                            <div class="col-md-6" style={{backgroundColor:"#fff"}}></div>
                            <div class="col-md-6" align="right" style={{color:"grey",padding:"10px 30px",fontSize:"20px"}}>
                                <a href="javascript:void(0);" style={{color:"grey"}} onClick={() => this.closeModal()}>X</a>
                            </div>
                            </div>
                            <Signin bookID={id} bookType={type} rel={relation} close={() => this.closeModal()} unbook="" created={relation}></Signin>
                        </div>
                        </Modal>
                        :''
    }
            </div>
        );
    }
}

