import React, { Component } from 'react';
// import saha from '../images/saha.png';
import 'reactjs-popup/dist/index.css';
import apiURL from './url';

export default class Sign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            name: '',
            pass: '',
            cpass: '',
            uname: '',
            mobile: '',
            dob: '',
            org_or_ind: 'Individual',
            messemail: '',
            massage: '',
            massage1: '',
            massage2: '',
            massage3: '',
            massage4: '',

        }
        this.dataSubmit = this.dataSubmit.bind(this);
    }
    dataSubmit() {
        var count = 0;
        var email = this.state.email;
        var name = this.state.name;
        var pass = this.state.pass;
        var cpass = this.state.cpass;
        var uname = this.state.uname;
        var mobile = this.state.mobile;
        var dob = this.state.dob;
        var org_or_ind = this.state.org_or_ind;
        var pattern = new RegExp(/\D/i);
        // if (email == '' || !pattern.test(email))
        if (pass.length < 6 || !pattern.test(pass)) {
            this.setState({

                massage5: "Password minimum length is 6 character and must contain 1 digit, 1alphabet",
                //07540076474-shashi
            })
        }
        if (pass == '') {
            count = count + 1;
            this.setState({

                massage4: "Please Enter Password",

            })
        }
        else {
            this.setState({

                massage4: "",

            })
        }
        if (name == '') {
            count = count + 1;
            this.setState({

                massage1: "Please Enter Name",

            })
        }
        else {
            this.setState({

                massage1: "",

            })
        }
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (email === '' || !pattern.test(email)) {
            count = count + 1;
            this.setState({

                massage2: "Please Enter Valid Email",

            })
        }
        else {
            this.setState({

                massage2: "",

            })
        }
        if (pass !== cpass) {
            count = count + 1;
            this.setState({

                massage3: "Please Enter Valid Confirm Password",

            })
        }
        else {
            this.setState({

                massage3: "",

            })
        }


        if (count === 0) {
            fetch(`${apiURL}master/signupForm?name=${name}&dob=${dob}&email=${email}&mobile=${mobile}&org_or_ind=${org_or_ind}&password=${pass}`)
                .then(res => res.json())
                .then(json => {
                    if (json.status === "true") {
                        this.setState({
                            massage: 'Signup successful',
                            //items: json,
                            //noData:false,
                        })

                    }
                    else {
                        this.setState({
                            massage: json.msg,

                        })
                    }
                });



        }



    }

    openModal() {
        this.setState({
            visible: true
        });
    }


    closeModal() {
        this.props.close();
        this.setState({
            visible: false
        });
    }

    handleEmail = event => {
        this.setState({ email: event.target.value });
    }

    handlePass = event => {
        this.setState({ pass: event.target.value });
    }
    handleName = event => {
        this.setState({ name: event.target.value });
    }
    handleCpass = event => {
        this.setState({ cpass: event.target.value });
    }
    handleUname = event => {
        this.setState({ uname: event.target.value });
    }

     onPass(params) {
 
        this.props.closePopup(params);
        // event.preventDefault();
    }
    render() {
        const size = window.innerWidth;

        return (
            <>

                <div class="row" style={{ padding: "0px 0px 0px 15px" }}>
                    <div class={`col-md-6 ${size < 768 ? 'signMobile' : ''}`} style={{ backgroundColor: "#fff" }} align="center">
                        <img src={`${apiURL}assets/logo/sahapediaHdlogo.png`} className="" alt="logo" style={{ marginTop: "35%" }} />
                        <div class="field"  id="donate1" style={{ marginTop: "25px" }}>
                            <a href=" https://www.sahapedia.org/donate" >  <button class="buttonStyl">Donate Now</button></a></div>

                    </div>
                    <div class="col-md-6" style={{ color: "#000", padding: "0px 15px 0px 0px" }}>
                        <div class="signInForm">
                            <div className="result" style={{ color: "#008000", fontSize: "14px" }}>{this.state.massage}</div>
                            <p style={{ fontSize: "30px" }} align="left"><b class="signin-text">Sign Up</b></p>
                            <p class="BoldTexts" id="BoldTexts1">Already have an account?<span class="Colortexts" onClick={() => this.props.signIn()} value="Open"> Sign In</span></p>
                            <div class="form-area">
                                <div class="field">
                                    <div class="left-field"><input type="text" placeholder="Name" onChange={this.handleName} /></div>
                                    <div className="result" style={{ color: "#FF0000", fontSize: "14px" }}>{this.state.massage1}</div>
                                </div>
                                <div class="field"><input type="text" placeholder="Email" onChange={this.handleEmail} /></div>
                                <div className="result" style={{ color: "#FF0000", fontSize: "14px" }}>{this.state.massage2}</div>
                                <div class="field"><input type="password" placeholder="Password" onChange={this.handlePass} /></div>
                                <div className="result" style={{ color: "#FF0000", fontSize: "14px" }}>{this.state.massage5}</div>
                                <div class="field"><input type="password" placeholder="Confirm Password" onChange={this.handleCpass} /></div>
                                <div className="result" style={{ color: "#FF0000", fontSize: "14px" }}>{this.state.massage3}</div>
                                <br></br><div class="field" id="field2" style={{marginTop:'5px'}}><button class="buttonStyl" onClick={this.dataSubmit}>Sign up</button></div>
                                {/*<div class="or-signin-with">
                                    <div class="line"></div>
                                    <div class="text"></div>
                                </div>
                                 <div class="social-login">

											<a href="#" class="google"><i class="fa fa-google" aria-hidden="true"></i></a>
											<a href="#" class="facebook"><i class="fa fa-facebook" aria-hidden="true"></i></a>
											<a href="#" class="twitter"><i class="fa fa-twitter" aria-hidden="true"></i></a>
										</div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}