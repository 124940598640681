import ReactDOM from 'react-dom';
import MapPopup from '../components/Map/mapPopup';
import { importAll } from '../Helpers/utility';
import { makeAPICall, REQUEST } from './apiService';

/* global L, $, MapmyIndia */

const images = importAll(require.context('../assets/img', false, /\.(png|jpe?g|svg|gif)$/));

export const mapUtils = {
    map: null,
    locations: [],
    markers: [],
    page:'',
    mapLocations: {},
    mapMarkers: {},
    clustor: null,
    popId:  null,
    initMap: function (container) {
        this.map = new MapmyIndia.Map(container, { center: [28.61, 77.23], zoom:4, zoomControl: true, hybrid: true, coronaLink:false, fullscreenControl: true});
    },
    addMarkers: function (locationList = []) {
        // this.removeMarker();
        var placeList = [];
        if (locationList.length > 0) {
            placeList = locationList;
        } else if (this.locations.length > 0) {
            placeList = this.locations;
        }
        var icon = L.divIcon({
            html: '<div class="mapMarker" style="background-color: #da3a50;"></div>',
            iconSize: [30, 42],
            iconAnchor: [15, 42]
        });
        // console.log(placeList);
        let markerList = [];
        for (var i = 0; i < placeList.length; i++) {
            let uniqueIcon = null;
            if (placeList[i].color_code_html && placeList[i].color_code_html !== "") {
                uniqueIcon = L.divIcon({
                    html: '<div class="mapMarker" style="' + placeList[i].color_code_html + ';"></div>',
                    iconSize: [30, 42],
                    iconAnchor: [15, 42]
                });
            }
            var mk = new L.Marker([placeList[i].meta_latitude, placeList[i].meta_longitude], {
                draggable: false,
                title: placeList[i].museum_address ? placeList[i].museum_address : placeList[i].title,
                icon: uniqueIcon ? uniqueIcon : icon
            });
            this.map.addLayer(mk);
            // var content = this.createContentPopup(placeList[i]);
            // mk.bindPopup(content);
            // markerList.push(mk);
            console.log(mapUtils.page);
            // this.map.setView(mk.getLatLng(), 13);/*function that modifies both center map position and zoom level.*/
            
            // this.map.fitBounds(this.map.getBounds());/*Sets the center map position and level so that all markers is the area of the map that is displayed in the map area*/
            this.markers.push(mk);
            if(mapUtils.page==="article" || mapUtils.page==="museum"){

            }else{
            let contents = this.createContentPopup(placeList[i]);
            mk.bindPopup(contents);
            mk.on('click', function(e){
                let place;
                for (let index = 0; index < placeList.length; index++) {
                    if(placeList[index].meta_latitude === e.latlng.lat &&  placeList[index].meta_longitude === e.latlng.lng){
                      place = placeList[index];
                      break;
                    }
                }
                mapUtils.plotPopup(place);
              });
            }
            markerList.push(mk);

            // if(this.locations.length > 0) {
            //     this.boundMarkers();
            // }
        }
        setTimeout(()=>
        {
        var bounds = L.featureGroup(markerList).addTo(this.map);
        this.map.fitBounds(bounds.getBounds());
        }, 1000);
        return markerList;
    },
    conditionalAddMarker: function () {
        for (const markerListKey in this.mapLocations) {
            let isExist = this.isMarkerListPlotted(markerListKey);
            if (!isExist) {
                let plottedMarkers = this.addMarkers(this.mapLocations[markerListKey]);
                this.mapMarkers[markerListKey] = plottedMarkers;
            }
        }
    },
    isMarkerListPlotted: function (key) {
        for (const plotted in this.mapMarkers) {
            if (plotted === key) {
                return plotted;
            }
        }
        return null
    },
    removeMarker: function (markerList = []) {
        if (markerList.length > 0) {
            for (let i = 0; i < markerList.length; i++) {
                this.map.removeLayer(markerList[i]);
            }
        }
        // if (this.markers.length > 0) {
        //     for (var i = 0; i < this.markers.length; i++) {
        //         this.map.removeLayer(this.markers[i]);
        //     }
        //     this.markers = [];
        // }
    },
    boundMarkers: function () {
        let markerList = [];
        if (this.markers.length === 0) {
            for (const m in this.mapMarkers) {
                markerList = markerList.concat(this.mapMarkers[m]);
            }
        }
        else if (this.markers.length > 0) {
            markerList = this.markers;
        }
        if (markerList.length > 0) {
            var featureGroup = L.featureGroup(markerList);
            this.map.fitBounds(featureGroup.getBounds());
        }
    },
    generateClustor: function () {
        this.clustor = L.markerClusterGroup({
            chunkedLoading: true,
            maxClusterRadius: 100,
            disableClusteringAtZoom: 15,
            singleMarkerMode: false,
            addRegionToolTips: false
        });
        this.map.addLayer(this.clustor);
    },
    plotConditionalClustorMarker: function () {
        //debgger;
        for (const markerListKey in this.mapLocations) {
            let isExist = this.isMarkerListPlotted(markerListKey);
            if (!isExist) {
                let plottedMarkers = this.plotClustor(this.mapLocations[markerListKey]);
                this.mapMarkers[markerListKey] = plottedMarkers;
            }
        }
    },
    removeConditionalClustorMarker: function (markers) {
        this.clustor.removeLayers(markers);
    },
    plotClustor: function (markerList) {
        
        let color_codes = JSON.parse(localStorage.getItem('COLOR_CODES'));
        var icon = {
            html: '<div class="mapMarker" style="background:' + color_codes.DEFAULT_COLOR + ';"></div>',
            iconSize: [30, 42],
            iconAnchor: [15, 42]
        };

        let markersList = [];

        // console.table(markerList);

        //debgger;
        if(markerList && markerList.length > 0) {
            markerList.forEach((placeList) => {
                if (placeList.theme_id.length === 1){
                    let icon_html = '<div class="mapMarker" style="background:';
                    if(color_codes && color_codes.THEME_COLORS && color_codes.THEME_COLORS[placeList.theme_id[0]]) {
                        icon_html += color_codes.THEME_COLORS[placeList.theme_id[0]];
                    }else {
                        icon_html += color_codes.DEFAULT_COLOR ? color_codes.DEFAULT_COLOR : '#DA3A50';
                    }
                    icon_html += ';"></div>';
                    icon['html'] = icon_html.replace('#White','#DA3A50');
                }
                if (placeList.theme_id.length > 1){
                    icon['html'] = '<div class="mapMarker" style="background:' + color_codes.MULTI_COLOR + ';"></div>';
                }
                var mk = new L.Marker([placeList.meta_latitude, placeList.meta_longitude], {
                    draggable: false,
                    title: placeList.museum_address ? placeList.museum_address : placeList.title,
                    icon: L.divIcon(icon)
                });
                let content = this.createContentPopup(placeList);
                mk.bindPopup(content);
                mk.on('click', function(){
                    if(this.popId === placeList.id){
                      this.popId = null;
                    //   return false; 
                    }
                     mapUtils.plotPopup(placeList);
                     this.popId = placeList.id;
                });
                markersList.push(mk);
            });
        }
        
        if(!this.clustor) {
            this.generateClustor();
            setTimeout(()=>this.clustor.addLayers(markersList), 500);
        }else {
            this.clustor.addLayers(markersList);
        }
        return markersList;
    },
    createContentPopup: function (place) {
        return '<div>' +
            `<div class="content-wrapper">` +
            `<div class="content" id=popup__${place.id}>` +
            // `${place.title ? place.title : place.museum_address}` +
            '<div class="loader">' + 
            `<img src=${images['popuploader.gif'].default} alt="Please wait...">` +
            '</div>' +
            '</div>' +
            '</div>' +
        '</div>';
    },
    plotPopup: async function (place) {
        let reqParams = {
            type: REQUEST.POST,
            url: 'api/getLandingEntities',
            data: JSON.stringify({
                "entityType": "POI",
                "id": place.id
            })
        };

        try{
            let result = await makeAPICall(reqParams);
            let entities = result.data;

            if(entities && entities.length > 0){
                ReactDOM.render(<MapPopup entities={entities} />, document.querySelector(`#popup__${place.id}`));
            }

        }catch(err) {
            console.log(err);
        }
    }
};

$(function() {
    $('#share-post').on('click', function() {
        console.log("Clicked");
    })
});

// Show map Popup
export function showMap(page) {
    $('.locMapPopup').removeClass('hidden');
    console.log(page);
    mapUtils.page=page;
    if (!mapUtils.map) {
        mapUtils.initMap("map");
    }
    mapUtils.removeMarker();
    let plottedMarkers = mapUtils.addMarkers();
    mapUtils.markers = plottedMarkers;
    mapUtils.boundMarkers();
}

export function scrollToMap() {
    $('html, body').animate({
        scrollTop: $("#map").offset().top
    }, 500);
}

// Hiding Map
export function hideMap() {
    $('.locMapPopup').addClass('hidden');
}
