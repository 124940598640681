import React, { Component } from 'react';
import renderHTML from 'react-render-html';
export default class Heading extends Component {
    constructor(props){
        super(props);
        
        this.state = {
          type: 'all',

        }
        this.entityType = this.entityType.bind(this);
        }
    entityType(ev){
        this.setState({type: ev.target.value});
        window.location.href=`/search/timelines?type=${ev.target.value}`
    }
    render() { 
        var items = this.props.Data;
        var type = this.props.Data2;
        var entity = this.props.Entity;
        return ( 
            <div>
                    <div id="header">
                    <h1 class="header-h1" style={{marginTop:'6%'}}>{items[0].title}</h1>
                    <div class="sub-title">{renderHTML(items[0].summary)}</div>
                    
                    </div>
            </div>
         );
    }
}