import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { actionCreators } from '../../redux/actions/actionCreators';
import { makeAPICall, REQUEST } from '../../services/apiService';
import ThemeFull from './themeFull';


export default function ThemeMapFull() {
    const [theme, setTheme] = useState(null);
    const { themeId } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        getThemeDetails();
        // fetchThemeFilters();
    }, [themeId]);

    const getThemeDetails = () => {
        let params = {
            id: themeId
        }
        let reqParams = {
            type: REQUEST.GET,
            url: '/project/searchProjectThemes',
            params
        };
        makeAPICall(reqParams)
            .then((result) => {
                if (result) {
                    setTheme(result.data[0]);
                    let articleId = result.data[0]?.article?.id;
                    if (!articleId || articleId === "") {
                        history.push(`/home/${result.data[0]?.projectId}/${result.data[0]?.f_language_id}/${themeId}`);
                    }else{
                        fetchThemeFilters(themeId, articleId);
                    }
                }
            }).catch((err) => { console.log(err); })
    }

    const fetchThemeFilters = (themeId, projectId) => {
        let reqParam = {
            type: REQUEST.POST,
            url: 'project/getProjectThemeFilters',
            data: JSON.stringify({
                entity_type: "POI",
                project_id: "1",
                theme_id: themeId
            })
        }
        makeAPICall(reqParam).then((result) => {
            console.log({ theme: result });
            if (result) {
                if (result.data && result.data) {
                    let themepoi = result.data;

                    let color_codes = {
                        ALL_COLOR: themepoi['ALL_COLOR'],
                        DEFAULT_COLOR: themepoi['DEFAULT_COLOR'],
                        MULTI_COLOR: themepoi['MULTI_COLOR']
                    };
                    setTimeout(()=>{
                        dispatch(actionCreators.landingPage.setThemeList([themepoi]));

                    if(themepoi && themepoi.Id && themepoi.Id !== ""){
                        dispatch(actionCreators.landingPage.themeItemClick(themepoi.Id));
                    }
                    navigator.cookieEnabled &&   localStorage.setItem('COLOR_CODES', JSON.stringify(color_codes));
                    }, 1500);

                }
            }
        }).catch(err => { console.log(err); });
    }

    return (
        <div class="theme-t-full">
            {theme && <ThemeFull Article={theme} />}
        </div>
    );
}