import React, { Component } from 'react';
import Header from '../header';
import Footer from '../footer'; 
import human from "../../assets/img/ic_rights@2x.png";
import './license.css';

export default class License extends Component {
    render() { 
        return ( 
            <>
            <Header></Header>
            <div>
                <div class= "design">
                <div class="method"> Copyright</div><br></br>
                <div class="val"> Welcome to Sahapedia. Please review carefully our copyright policy.</div><br></br>
                <div class="val"> All of the content featured on Sahapedia, unless otherwise specified, is made available under CC BY NC SA 4.0 license.</div><br/>
                <div class="equip"><b>1. <img class="image"src={human} alt=""/> Some rights reserved</b></div>
                <div class="val">If the material is licensed under a Creative Commons Attribution-NonCommercial-
ShareAlike 4.0 International License, it indicated by the sign <img class="image"src={human} alt=""/> Some rights reserved on the relevant page. Under the CC BY NC SA 4.0 International License, </div><br/>
                <div>
                <div class="txt">
                <div class="equip"><b>You are free to</b></div><br/></div>
                <div class="pop">
                <ul className="drop">
                <li><b>Share </b>— copy and redistribute the material in any medium or format</li><br/>
                <li><b>Adapt</b> — remix, transform, and build upon the material</li><br/>
                </ul></div>
                <div class="txt">
                <div class="value">The licensor cannot revoke these freedoms as long as you follow the license terms.</div><br/>
                <div class="equip"><b>Under the following terms</b></div><br/></div>
                <div class="pop">
                <ul className="drop">
                    <li><b>Attribution</b> — You must give <u>appropriate credit,</u> provide a link to the license, and <u>indicate if changes were made.</u> You may do so in any reasonable manner, but not in any way that suggests the licensor endorses you or your use.</li><br/>
                    <li><b>NonCommercial</b> — You may not use the material for <u>commercial purposes.</u></li><br/>
                    <li><b>ShareAlike </b>— If you remix, transform, or build upon the material, you must distribute your contributions under the <u>same license</u> as the original.</li><br/>
                    <li><b>No additional restrictions </b>— You may not apply legal terms or <u>technological measures</u> that legally restrict others from doing anything the license permits.</li><br/>
                </ul></div>
                </div><br/>
                <div class="equip"><b>2. ©All rights reserved</b></div>
                <div class="val">If copyright of the material belongs to a third party or if it is unclear, it indicated by the sign ©All rights reserved on the relevant page.<br/>
                In respect of such content, except as permitted by the Indian Copyright Act, 1957 (including where the work is already in the public domain or fair dealing for the purposes of private study, research, criticism or review as permitted by law), no part thereof, may in any form, or by any electronic, mechanical, photocopying, recording, or any other means, be reproduced, stored in a retrieval system or be broadcast or transmitted, in whole or in part, in particular for any commercial purpose, without the prior permission of Sahapedia or the relevant copyright holder.
                </div><br/><br/>
                <div class="val">If you have any questions with regards to these terms and conditions, please contact us at <u>contact@sahapedia.org.</u></div><br/>
            </div><br/>
            </div><br/>
            <Footer></Footer>
            </>
         );
    }
}
 
